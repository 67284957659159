export type WindowWithDataLayer = Window & {
  dataLayer: Record<string, string | object | null>[];
};

export type GA4EcommerceItem = {
  item_id: string;
  item_name: string;
  item_brand?: string;
  price?: number;
  quantity?: number;
};

export type GA4EcommerceOrder = {
  transaction_id: string;
  value: number;
  tax: number;
  shipping: number;
  coupon: string;
  items: GA4EcommerceItem[];
};

export type GA4EcommercePromotion = {
  promotion_id: string;
  promotion_name: string;
  creative_name: string;
  creative_slot: string;
};

export type UAEcommerceItem = {
  id: string;
  name: string;
  brand?: string;
  price: number;
  quantity?: number;
};

export type UAEcommerceOrderData = {
  id: string;
  revenue: number;
  tax: number;
  shipping: number;
  coupon: string;
};

export type UAEcommerceOrder = {
  purchase: {
    actionField: UAEcommerceOrderData;
    products: UAEcommerceItem[];
  };
};

export type UAEcommercePromotion = {
  id: string;
  name: string;
  creative: string;
  position: string;
};

export const enum CreativeName {
  LARGE_BANNERS = "Front Page Carousel",
  SMALL_BANNERS = "Front Page Small Banners",
}

export const enum CreativeType {
  LARGE_BANNERS = "large-banner",
  SMALL_BANNERS = "small-banner",
}

export const enum GA4EventNames {
  PAGEVIEW = "page_view",
  PROMOTION_CLICK = "select_promotion",
  PROMOTION_VIEW = "view_promotion",
  PURCHASE = "purchase",
  VIEW_ITEM = "view_item",
  VIEW_ITEM_LIST = "view_item_list",
}

export const enum UAEventNames {
  PROMOTION_CLICK = "eec.promotionClick",
  PROMOTION_VIEW = "eec.promotionView",
  PURCHASE = "eec.purchase",
  VIEW_ITEM = "eec.detail",
}
