import {
  ChatWindowParameters,
  useChatWindow,
} from "components/buttons/useChatWindow";
import Icon from "assets/Icon";
import Button from "components/buttons/Button";

export default function ChatButton({
  encryptedUserIndex,
  siteData,
  useSwitchboardRewrite,
  id = "chat-button",
}: ChatWindowParameters & {
  readonly id?: string;
}): JSX.Element {
  const openChatWindow = useChatWindow({
    encryptedUserIndex,
    siteData,
    useSwitchboardRewrite,
  });

  return (
    <Button
      className="w-fit"
      icon={<Icon name="chat" className="size-5x5" fill="none" />}
      iconOrientation="left"
      id={id}
      label="Chat"
      onClick={openChatWindow}
      size="group"
      variant="primary"
    />
  );
}
