import {
    ChangeEvent,
    JSX,
    useEffect,
    useMemo,
    useRef,
    Children,
    isValidElement,
    cloneElement,
    PropsWithChildren,
} from "react";
import { useConsistentValue, useControlledValue } from "@Utilities/hooks";
import { isElement } from "@Utilities/react";
import { useFormContext } from "./hooks/useFormContext";
import { useFormContextMethods } from "./hooks/useFormContextMethods";
import { Validation } from "./hooks/useFormMethods";
import { FieldValue } from "./types";

type ValuePropName = "value" | "checked";

export type FormItemProps = {
    alertLabel?: string;
    defaultValue?: FieldValue;
    id?: string;
    name: string;
    required?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validate?: Validation<any> | Validation<any>[];
    valuePropName?: ValuePropName;
};

const formElementNodeNames = [
    "Checkbox",
    "EmailInput",
    "Group",
    "NumberInput",
    "PhoneInput",
    "Select",
    "Textarea",
    "TextInput",
];

const formElementTypes = ["input", "select", "textarea", "fieldset"];

/**
 * A component wrapper used to register an input to the form.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-form)}
 */
const FormItem = ({
    alertLabel,
    children,
    defaultValue,
    id: controlledId,
    name,
    required = false,
    validate,
    valuePropName = "value",
}: PropsWithChildren<FormItemProps>): JSX.Element => {
    const { formMetaValues, values } = useFormContext();
    const formContextMethods = useFormContextMethods();
    const internalId = `${name}-${formMetaValues.name}`;
    const id = controlledId ?? internalId;

    const valueDefaults: Record<ValuePropName, FieldValue> = useMemo(
        () => ({
            value: "",
            checked: false,
        }),
        []
    );

    const validationsRef = useRef<Validation | Validation[] | undefined>(
        validate
    );
    const nameRef = useRef<string>(name);
    const defaultValueRef = useRef<FieldValue | undefined>(defaultValue);
    const valuePropNameRef = useRef<ValuePropName>(valuePropName);
    const requiredRef = useRef<boolean>(required);
    const fieldValue = values[name];

    useControlledValue(name);
    useConsistentValue(
        name,
        `The name of form item: ${nameRef.current} has changed and should remain consistent throughout the component lifecycle.`
    );
    useConsistentValue(
        valuePropName,
        `Value prop name of form item: ${nameRef.current} has changed and should remain consistent throughout component lifecycle.`
    );

    /** Handles the change event from a Form Item child */
    const handleChange = (
        e: ChangeEvent<{ checked: boolean; value: FieldValue }>
    ): void => {
        if (valuePropName in e.target) {
            formContextMethods.onChange(name, e.target[valuePropName]);
        }
    };

    useEffect(() => {
        const unregister = formContextMethods.register(
            nameRef.current,
            id,
            defaultValueRef.current ?? valueDefaults[valuePropNameRef.current],
            requiredRef.current,
            validationsRef.current
        );
        return unregister;
    }, [
        defaultValueRef,
        id,
        valueDefaults,
        valuePropNameRef,
        requiredRef,
        formContextMethods,
    ]);

    /** If the form item changes it's required state, we will update that in the form field meta data */
    useEffect(() => {
        formContextMethods.setFieldMeta(name, {
            required: required ?? false,
        });
    }, [formContextMethods, name, required]);

    /** Registers the alert label and ID to the label ref, which is accessed in the form alert. */
    useEffect(() => {
        formContextMethods.setFieldMeta(name, {
            alert: { alertLabel, id },
        });
    }, [alertLabel, formContextMethods, id, name]);

    return (
        <>
            {Children.map(children, (child) => {
                return isValidElement(child) &&
                    isElement(formElementNodeNames, formElementTypes, child)
                    ? cloneElement(child, {
                          name,
                          error: !!fieldValue?.errors.length,
                          errorMessage: fieldValue?.errors,
                          hideError: formMetaValues.errorDisplay === "alert",
                          id,
                          required,
                          [valuePropName]:
                              fieldValue?.value ?? valueDefaults[valuePropName],
                          onChange: handleChange,
                          ...child.props,
                      })
                    : child;
            })}
        </>
    );
};

export { FormItem };
