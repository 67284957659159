/** @param {string} string value of phone number */
export const formatPhoneNumber = (value: string): string => {
    // if input value is falsy - ex. the user deletes the input
    if (!value) return value;

    // remove non-digit values typed in
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength: used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // returns the value with no formatting if its less then four digits
    // avoids janky formatting to the area code to early
    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // if the phoneNumberLength > seven, add the last set of formatting(-)
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 10)}`;
};
