import { Reducer } from "react";
import { FormValues } from "../../types";
import { MetaAction } from "./types";

const meta: Reducer<FormValues, MetaAction> = (state, action) => {
    const metaFormValues: FormValues = {};
    Object.keys(action.value).forEach((key) => {
        // Check that a value exists in state
        // All values need to be registered before getting their meta data updated
        if (state[key] === undefined) {
            throw new Error(
                `Form values does not contain "${key}" and cannot have their meta data updated.`
            );
        }
        metaFormValues[key] = {
            ...state[key],
            ...action.value[key],
        };
    });

    return {
        ...state,
        ...metaFormValues,
    };
};

export default meta;
