export type SeparatorProps = {
  className?: string;
};

export default function Separator({ className }: SeparatorProps): JSX.Element {
  return <div className={className} aria-hidden></div>;
}

export function SmallSeparator(): JSX.Element {
  return <Separator className={"h-6 border-l border-slate-200"} />;
}
