import { FC, InputHTMLAttributes, ReactNode } from "react";
import { concat, props } from "@Utilities/string";
import {
    InputErrorProps,
    InputHelperProps,
    InputLabelProps,
    InputWrapperProps,
} from ".";
import { DataTypes } from "@Types/element";

export type InputProps = InputHTMLAttributes<HTMLInputElement> &
    DataTypes & {
        element?: InputWrapperProps["element"];
        /**
         * Controls whether or not the input is in an error state.
         *
         * This includes styling the input and allowing the error message to be displayed.
         */
        error?: boolean;
        /**
         * Used when the developer wants to provide a custom error component.
         *
         * It is **not** recommended to use this prop as it does not guarantee the
         * component will be accessible.
         */
        errorElement?: ReactNode;
        /** Used to render the errors as a list. */
        errorMessage?: string | string[];
        errorProps?: InputErrorProps;
        helperProps?: InputHelperProps;
        helperText?: ReactNode;
        /**
         * Controls whether the error(s) for the input will be visible to the user.
         *
         * When set to false the errors will be added to the input via a `aria-label` attribute.
         */
        hideError?: boolean;
        hideLabel?: boolean;
        label: string;
        labelProps?: Omit<InputLabelProps, "htmlFor">;
        optional?: boolean;
        theme?: string;
        wrapperProps?: InputWrapperProps;
    };

/**
 * This is the base input used to compose the other Zest input components (phone, text, email, checkbox, number, radio, select, textarea).
 *
 * It is not recommended to use this component since it does not include a label or helper text.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-inputs)}
 */
const Input: FC<
    InputHTMLAttributes<HTMLInputElement> & {
        theme?: string;
    }
> = ({ className, required, theme, ...rest }) => {
    const ariaProps = props({
        "aria-required": {
            value: required,
            condition: required,
        },
    });
    const classes = concat("zest-input-base", className, theme);

    return <input {...rest} {...ariaProps} className={classes} />;
};

export { Input };
