import { delay } from "@Utilities/functions";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export type Rect = { height: number; width: number; x: number; y: number };

export const placements = {
    TopRight: "top-right",
    Top: "top",
    TopLeft: "top-left",
    RightTop: "right-top",
    Right: "right",
    RightBottom: "right-bottom",
    BottomLeft: "bottom-left",
    Bottom: "bottom",
    BottomRight: "bottom-right",
    LeftBottom: "left-bottom",
    Left: "left",
    LeftTop: "left-top",
} as const;

export type Cords = { x: number; y: number };

export type PlacementTypes = (typeof placements)[keyof typeof placements];

type UseSharedFloatingProps<T extends PlacementTypes> = {
    anchor: React.MutableRefObject<HTMLElement | null>;
    floating: React.MutableRefObject<HTMLElement | null>;
    offset?: number;
    placement: T;
    placementsToCheck: React.MutableRefObject<T[]>;
    positionResolver: (placement: T) => Rect;
    wrapper: React.MutableRefObject<HTMLElement | null>;
};

type RectWindowPosition = {
    bottom: number;
    left: number;
    right: number;
    top: number;
};

export const getRect = (elem: HTMLElement | SVGElement): Rect => {
    const rect = elem.getBoundingClientRect();
    return {
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
    };
};

/**
 * Calculates how much the rect needs to be shifted in order to
 * be positioned relative to the top left corner of the wrapper
 * @returns {Rect} The new rect with the x & y coordinates offset
 */
export const offsetFromWrapper = (rect: Rect, wrapper: Rect): Rect => {
    return {
        ...rect,
        x: rect.x - wrapper.x,
        y: rect.y - wrapper.y,
    };
};

export type UseSharedFloating = {
    adjustedPlacement: React.MutableRefObject<PlacementTypes>;
    /**
     * Get the coordinates for a floating element.
     * Also, updates the hooks return value with the new coordinates.
     */
    float: () => Rect;
    height: number;
    /**
     * Set the element to float the element relative to.
     * @example
     * if (anchorRef.current) {
     *   setAnchor(anchorRef.current)
     * }
     */
    setAnchor: (elem: HTMLElement) => void;
    /**
     * Set the reference to the element to be floated.
     * @example
     * if (ref.current) {
     *   setFloating(ref.current)
     * }
     */
    setFloating: (elem: HTMLElement) => void;
    /**
     * Set the wrapper element to offset the floating element from.
     * This is only used when using the "absolute" position.
     * @example
     * if (wrapperRef.current) {
     *   setWrapper(wrapperRef.current)
     * }
     */
    setWrapper: (elem: HTMLElement) => void;
    /**
     * Will pause the floating process.
     * This should be called when the content is no longer visible or
     * no longer needs the floating position updated.
     */
    unFloat: () => void;
    width: number;
    x: number;
    y: number;
};

const useSharedFloating = <T extends PlacementTypes>({
    anchor,
    floating,
    offset,
    placement,
    placementsToCheck,
    positionResolver,
    wrapper,
}: UseSharedFloatingProps<T>): UseSharedFloating => {
    const isFloating = useRef<boolean>(false);
    const [rectState, setRectState] = useState<Rect>({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    });

    const adjustedPlacement = useRef<PlacementTypes>(placement);

    const setFloating = useCallback<UseSharedFloating["setFloating"]>(
        (elem) => (floating.current = elem),
        [floating]
    );
    const setAnchor = useCallback<UseSharedFloating["setAnchor"]>(
        (elem) => (anchor.current = elem),
        [anchor]
    );
    const setWrapper = useCallback<UseSharedFloating["setWrapper"]>(
        (elem) => (wrapper.current = elem),
        [wrapper]
    );

    const isRectInViewport = useCallback((rect: Rect) => {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        let isInsideOfWindow = true;

        const distanceToWindowEdge: RectWindowPosition = {
            top: rect.y,
            right: viewportWidth - rect.x - rect.width,
            bottom: viewportHeight - rect.y - rect.height,
            left: rect.x,
        };

        const { bottom, left, right, top } = distanceToWindowEdge;

        const isOnTopEdge = top <= 0;
        const isOnRightEdge = right <= 0;
        const isOnBottomEdge = bottom <= 0;
        const isOnLeftEdge = left <= 0;

        if (isOnTopEdge || isOnRightEdge || isOnBottomEdge || isOnLeftEdge) {
            isInsideOfWindow = false;
        }

        return isInsideOfWindow;
    }, []);

    const recursiveAdjustPlacement = useCallback(
        (placementToCheck: T, placementsToCheck: T[]): Rect => {
            if (!floating.current || !anchor.current || !wrapper.current) {
                throw new Error(
                    "float was called without an anchor and/or floating element"
                );
            }

            const rect = positionResolver(placementToCheck);

            if (!isRectInViewport(rect)) {
                const nextPlacementIndex =
                    placementsToCheck.indexOf(placementToCheck) + 1;
                const nextPlacement =
                    placementsToCheck[nextPlacementIndex] ||
                    placementsToCheck[0];

                const newPlacementsToCheck = placementsToCheck.filter(
                    (placement) => placement !== placementToCheck
                );

                if (newPlacementsToCheck.length === 0) {
                    // return the initial placement if all placements have been checked and failed
                    const defaultRect = positionResolver(placement);
                    adjustedPlacement.current = placement;
                    return defaultRect;
                }

                return recursiveAdjustPlacement(
                    nextPlacement,
                    newPlacementsToCheck
                );
            }
            adjustedPlacement.current = placementToCheck;
            return rect;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isRectInViewport, offset, placement]
    );

    const float = useCallback<UseSharedFloating["float"]>(() => {
        if (!floating.current || !anchor.current) {
            throw new Error(
                "float was called without an anchor and/or floating element"
            );
        }
        isFloating.current = true;
        let rect: Rect = { x: 0, y: 0, width: 0, height: 0 };

        if (wrapper.current) {
            rect = recursiveAdjustPlacement(
                placement,
                placementsToCheck.current
            );
            // Offset from the trigger once the correct placement is determined
            rect = offsetFromWrapper(rect, getRect(wrapper.current));
        }

        setRectState(() => ({
            ...rect,
        }));
        return rect;
    }, [
        anchor,
        floating,
        placement,
        placementsToCheck,
        recursiveAdjustPlacement,
        wrapper,
    ]);

    const unFloat = useCallback<UseSharedFloating["unFloat"]>(() => {
        isFloating.current = false;
    }, []);

    useEffect(
        function initMutationObserver() {
            /**
             * We observe any changes to the wrapper's children then re-determine
             * where to place the tooltip based on the new children.
             */
            function handleMutation(): void {
                if (isFloating.current) {
                    float();
                }
            }

            const observer = new MutationObserver(handleMutation);

            if (wrapper.current) {
                observer.observe(wrapper.current, {
                    childList: true,
                    subtree: true,
                    characterData: true,
                });
            }
            return function cleanupMutationObserver() {
                observer.disconnect();
            };
        },
        [float, wrapper]
    );

    useEffect(
        function initIntersectionObserver() {
            const debounced = delay(float, 30);
            function handleIntersectionObserver(): void {
                if (isFloating.current) debounced();
            }
            const options = {
                rootMargin: "0px",
                threshold: [0, 1],
            };
            const observer = new IntersectionObserver(
                handleIntersectionObserver,
                options
            );
            if (floating.current) {
                observer.observe(floating.current);
            }

            return function cleanupIntersectionObserve() {
                observer.disconnect();
            };
        },
        [float, floating]
    );

    return useMemo(
        () => ({
            ...rectState,
            float,
            setAnchor,
            setFloating,
            setWrapper,
            unFloat,
            adjustedPlacement,
        }),
        [float, rectState, setAnchor, setFloating, setWrapper, unFloat]
    );
};

export { useSharedFloating };
