/** Make sure this matches `tailwind.config.js` theme.screens config! */
export const breakpoints = {
  "2xs": 414,
  xs: 640,
  sm: 680,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1440,
  "3xl": 1920,
} as const;

export type BreakpointName = keyof typeof breakpoints;
export type Breakpoint = typeof breakpoints[BreakpointName];

export function getBreakpoint(width: number): Breakpoint {
  for (const breakpointName of orderedBreakpointNames) {
    if (width >= breakpoints[breakpointName]) {
      return breakpoints[breakpointName];
    }
  }

  return breakpoints["2xs"];
}

export const orderedBreakpointNames: BreakpointName[] = [
  "3xl",
  "2xl",
  "xl",
  "lg",
  "md",
  "sm",
  "xs",
  "2xs",
];
