import { LayoutAPI } from "layouts/api/layoutAPI.types";
import { concat, currencyFormat } from "util/string";

type AdminUserInfoDisplayProps = {
  firstName: LayoutAPI["baseAccount"]["firstName"];
  lastName: LayoutAPI["baseAccount"]["lastName"];
  remainingBudget?: LayoutAPI["remainingBudget"];
};

export const adminUserInfoDisplayId = "user-info";

function AdminUserInfoDisplay({
  firstName,
  lastName,
  remainingBudget,
}: AdminUserInfoDisplayProps): JSX.Element {
  return (
    <div
      id={adminUserInfoDisplayId}
      className={concat(
        "flex flex-col h-10 min-w-fit",
        !remainingBudget && "place-content-center"
      )}
    >
      <div className="flex flex-row justify-start max-w-[387px] text-xl leading-6 font-bold">
        <span title={`${firstName} ${lastName}`} className="truncate">
          {firstName}
          &nbsp;
          {lastName}
        </span>
      </div>
      {remainingBudget !== undefined && (
        <div className="flex flex-row justify-start place-items-center max-w-[387px] text-xs leading-4">
          <span>Remaining budget</span>
          <span className="font-bold">
            &nbsp;{currencyFormat(remainingBudget)}
          </span>
        </div>
      )}
    </div>
  );
}

export default AdminUserInfoDisplay;
