import { getCurrentDate } from "util/date";

export default function Recall({
  className,
  recallNoticeUrl,
  recallNoticeEndDate,
}: {
  className?: string;
  recallNoticeUrl: string;
  recallNoticeEndDate: string;
}): React.ReactElement | null {
  const showRecallLink = new Date(recallNoticeEndDate) >= getCurrentDate();
  if (!showRecallLink) {
    return null;
  }

  return (
    <a className={className} href={recallNoticeUrl}>
      Safety Recall
    </a>
  );
}
