import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { UserClaims } from "authentication/contexts/UserData.types";
import {
  getEmailAddress,
  getClarkAccountId,
  getStoreLocation,
  getFullName,
} from "util/user/userClaims";

export default function useSentryContextEffect(
  userClaims: UserClaims | null
): void {
  useEffect(
    function sentryUserContextEffect() {
      if (userClaims) {
        Sentry.setUser({
          id: userClaims.AccountId,
          email: getEmailAddress(userClaims),
          username: getFullName(userClaims),
          clarkAccountId: getClarkAccountId(userClaims),
          storeLocation: getStoreLocation(userClaims),
          accountCredentialId: userClaims.accountCredentialId,
        });
      }
    },
    [userClaims]
  );
}
