import { ButtonHTMLAttributes, ReactNode, PropsWithChildren, JSX } from "react";
import { concat } from "@Utilities/string";
import { DataTypes, SizeLike } from "@Types/element";
import { Icon, IconProps } from "@Components/icon";
import { getResponsiveValues } from "@Utilities/sizing";

// If no children are passed, then the aria-label is required.
// If children are passed, then the aria-label is optional.
export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
    (
        | { "aria-label"?: string; children: ReactNode }
        | { "aria-label": string; children?: never }
    ) &
    DataTypes & {
        full?: boolean;
        iconPosition?: "left" | "right";
        iconProps?: IconProps;
        size?: SizeLike<"xlarge">;
        theme?: string;
        variant?:
            | "blue"
            | "green"
            | "orange"
            | "purple"
            | "red"
            | "white"
            | "gray";
    };

/**
 * A clickable element that triggers an event or action.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-button-button--button)}
 */
const Button = ({
    children,
    className,
    full,
    iconPosition = "right",
    iconProps = {},
    size = "large",
    theme,
    variant,
    ...rest
}: PropsWithChildren<ButtonProps>): JSX.Element => {
    const isHeadless = theme === "headless";

    const sizeClasses = getResponsiveValues<"xlarge">("button", size);

    const classes = concat(
        "zest-button",
        theme,
        !className && isHeadless && "focus:outline-blue-600",
        !isHeadless && variant,
        sizeClasses,
        iconProps.name && "icon",
        !children && "icon-only",
        className,
        full && "full"
    );

    return (
        <>
            {children ? (
                <button {...rest} className={classes}>
                    {!!iconProps.name && iconPosition !== "right" && (
                        <Icon
                            {...iconProps}
                            className={concat(iconProps.className, "icon-left")}
                        />
                    )}
                    {children}
                    {!!iconProps.name && iconPosition === "right" && (
                        <Icon
                            {...iconProps}
                            className={concat(
                                iconProps.className,
                                "icon-right"
                            )}
                        />
                    )}
                </button>
            ) : (
                <button {...rest} className={classes}>
                    <Icon
                        {...iconProps}
                        className={concat(iconProps.className, "icon-button")}
                    />
                </button>
            )}
        </>
    );
};

Button.displayName = "Button";

export { Button };
