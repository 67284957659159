import { FC, HTMLAttributes, ReactNode } from "react";
import { concat } from "@Utilities/string";
import { Modal } from "./modal";
import { ButtonProps, Icon, IconProps } from "..";

export type ModalHeaderProps = HTMLAttributes<HTMLHeadingElement> & {
    children?: ReactNode;
    className?: string;
    closeButtonIconProps?: IconProps;
    closeButtonProps?: ButtonProps;
    element?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    headerTitle?: string;
    htmlFor?: string;
};

const ModalHeader: FC<ModalHeaderProps> = ({
    children,
    className,
    closeButtonIconProps,
    closeButtonProps,
    element = "h2",
    headerTitle,
    htmlFor,
    ...rest
}) => {
    const Element = element;
    return (
        <header className={concat(className, "zest-modal-header")} {...rest}>
            <div>
                <Element className="zest-modal-header-title">
                    {headerTitle}
                </Element>
                {children}
            </div>
            <Modal.Trigger
                htmlFor={htmlFor}
                CustomTrigger={
                    <Icon name="ri-close-fill" {...closeButtonIconProps} />
                }
                variant="white"
                aria-label="Close"
                type="button"
                {...closeButtonProps}
                className={concat(
                    "zest-modal-header-close",
                    "icon-only",
                    closeButtonProps?.className
                )}
            />
        </header>
    );
};

export { ModalHeader };
