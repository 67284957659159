import { FC, ReactNode, useLayoutEffect, useMemo } from "react";
import { ModalContext, ModalContextType } from "./context";
import { useUuid } from "@Utilities/hooks";
import { Modal } from "./modal";

export type ModalContextProps = {
    children?: ReactNode;
    id?: string;
};

const ModalProvider: FC<ModalContextProps> = ({
    children,
    id: controlledId,
}) => {
    const internalId = useUuid("zest-modal");
    const id = controlledId ?? internalId;

    const value = useMemo<ModalContextType>(
        () => ({
            id,
        }),
        [id]
    );

    useLayoutEffect(() => {
        return Modal.register(id);
    }, [id]);

    return (
        <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
    );
};

export { ModalProvider };
