import { Environments, env } from "env";

const LocalRouter = {
  devGateway: "", // gets intercepted by package.json "proxy" value
  localGateway: "https://localhost:7125",
};

/**
 * Method to determine backend host.
 *
 * @returns Base URL string for the Marketplace API backend.
 */
export const getDefaultBaseRequestUrl = (): string => {
  switch (env.REACT_APP_CD_ENV) {
    case Environments.PROD:
      return "https://marketplace-gateway.cfse.biz";
    case Environments.TEST:
      return "https://marketplace-gateway.test.cfse.biz";
    case Environments.LOC:
      //! If msw is running, this will get intercepted by handlers.tsx regardless of value. You can disable msw in appconstants.json.
      return LocalRouter.devGateway;
    case Environments.DEV:
    default:
      return "https://marketplace-gateway.dev.cfse.biz";
  }
};
