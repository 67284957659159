import {
  Form,
  FormItem,
  TextInput,
  Textarea,
  useForm,
} from "@clarkinc/zest-design-system";
import { useState } from "react";
import {
  inputClassNames,
  inputLabelProps,
} from "components/Addresses/Modals/Fields/styles";
import Modal, { toggle } from "components/Modal/Modal";
import Button from "components/buttons/Button";
import useToast from "contexts/toast/ToastContext";
import useAccountLinksAPI from "layouts/api/useAccountLinksAPI";
import { gatewayApi } from "util/requests/gatewayApi";
import { concat } from "util/string";
import { ErrorMessages } from "util/requests/response.types";
import Alert from "components/Alerts";

export const requestQuoteModalId = "request-a-quote-modal";
export function RequestQuoteModal(): React.ReactElement {
  const { data: accountLinksData, isLoading } = useAccountLinksAPI();
  const { showToast } = useToast();
  const form = useForm<{ subject: string; body: string }>();
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  if (isLoading || !(accountLinksData?.isRequestQuoteAllowed ?? false)) {
    return <></>;
  }
  return (
    <Form
      name={"Request Quote"}
      form={form}
      onFinish={async (): Promise<void> => {
        setErrors([]);
        const formState = form.getFormState();
        setSubmitting(true);
        const subject = formState.subject.value;
        const body = formState.body.value;
        try {
          await gatewayApi.post({
            urlSegment: "accounts/quote-request",
            body: {
              subject: subject,
              body: body,
            },
            throwErrorCodes: [422],
          });
        } catch (error) {
          const errorMessage = JSON.parse(
            (error as Error).message
          ) as ErrorMessages;
          const errorMessages: string[] = [];
          for (const error of Object.values(errorMessage)) {
            for (const message of error.filter((e) => e)) {
              errorMessages.push(message);
            }
          }
          setErrors(errorMessages);
          setSubmitting(false);
          return;
        }
        form.reset();
        toggle(requestQuoteModalId);
        setSubmitting(false);
        showToast("Quote Request Submitted.", "success");
      }}
    >
      <Modal
        id={requestQuoteModalId}
        ariaLabel={"Request a Quote"}
        header={"Request a Quote"}
        className="w-full md:w-1/2"
        body={
          <div>
            {errors.length > 0 && (
              <Alert type={"Danger"} className=" mb-2 px-8">
                <ul className=" list-disc">
                  {errors.map((e) => (
                    <li key={e}>{e}</li>
                  ))}
                </ul>
              </Alert>
            )}
            <FormItem
              name={"subject"}
              defaultValue={"Quote Request"}
              required={true}
            >
              <TextInput
                label={"Subject "}
                className={inputClassNames}
                labelProps={inputLabelProps}
              />
            </FormItem>
            <FormItem name={"body"} required={true}>
              <Textarea
                label={"Request Details "}
                className={concat(inputClassNames, " h-36")}
                id="quote-details"
                labelProps={{ ...inputLabelProps, htmlFor: "quote-details" }}
              />
            </FormItem>
          </div>
        }
        footer={
          <div className="w-full flex justify-end gap-2">
            <Button
              label={"Close"}
              variant={"tertiary"}
              onClick={(): void => toggle(requestQuoteModalId)}
            />
            <Button
              label={"Submit"}
              disabled={submitting}
              variant={"secondary"}
              type="submit"
            />
          </div>
        }
      />
    </Form>
  );
}
