import { JSX, useEffect, ReactNode } from "react";
import { InputError } from "@Components/formComponents/utilities";
import { Alert, AlertProps, useAlert } from "@Components/alert";
import { AlertTitleProps } from "@Components/alert/alert.title";
import { UseForm } from "./hooks/useForm";
import { FormEvents } from "./types";
import { FormAlertError } from "./form.alert.error";
import { useFormContext } from ".";
import { useFormContextMethods } from "./hooks/useFormContextMethods";

export type FormAlertProps = {
    alertProps?: AlertProps;
    alertTitle?: ReactNode;
    alertTitleProps?: AlertTitleProps;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form?: any;
    /** Determines if the error message should also display under the form field */
    hideInlineErrors?: boolean;
};

/**
 * An alert is a non-modal dialog used to communicate information to the user.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-form)}
 */
const FormAlert = ({
    alertProps,
    alertTitle = "Form Fields With Errors",
    alertTitleProps,
    form: controlledForm,
    hideInlineErrors = true,
}: FormAlertProps): JSX.Element => {
    const alert = useAlert();
    const formMethods = useFormContextMethods();
    const form: UseForm = controlledForm || formMethods;
    const errorFields = form.getErrors();
    const labelsObj = form.getAlerts();
    const errorKeys = Object.keys(errorFields);
    const hasErrors = !!errorKeys.length;
    useFormContext();

    /** A sub list of errors that will be referenced back to the error link */
    const ComposedErrors = errorKeys.map((key) => {
        const obj = labelsObj[key];
        const errors = errorFields[key];
        if (!errors || !obj) return null;
        if (!obj.alertLabel) {
            throw new Error(
                `Form Items must have an "alertLabel" if displaying errors with Form.Alert. Error present in form field "${key}".`
            );
        }
        return (
            <FormAlertError
                key={obj.id}
                id={obj.id}
                label={obj.alertLabel}
                errors={errors}
            />
        );
    });

    /** Determines where to show the form errors. */
    useEffect(() => {
        if (hideInlineErrors) {
            form.setFormMeta({ errorDisplay: "alert" });
        } else {
            form.setFormMeta({ errorDisplay: "both" });
        }
        return function cleanupFormAlertSettings(): void {
            form.setFormMeta({ errorDisplay: "inline" });
        };
    }, [form, hideInlineErrors]);

    /** Handles moving focus to the lert when an error occurs in the submit process */
    useEffect(() => {
        const focusAlert = (): void => alert.focus();
        form.subscribe(FormEvents.FINISH_FAILED, focusAlert);

        return function formAlertSubscriptionCleanup(): void {
            form.unsubscribe(FormEvents.FINISH_FAILED, focusAlert);
        };
    }, [form, alert]);

    return (
        <Alert {...alertProps} alert={alert} variant="error" isOpen={hasErrors}>
            <Alert.Title {...alertTitleProps}>{alertTitle}</Alert.Title>
            <InputError hasError={hasErrors} errorMessages={ComposedErrors} />
        </Alert>
    );
};

export { FormAlert };
