import {
    JSX,
    PropsWithChildren,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { concat } from "@Utilities/string";
import {
    useUuid,
    useConsistentValue,
    useControlledValue,
    useTabTrap,
} from "@Utilities/hooks";
import { Icon, IconProps } from "@Components/icon";
import { Button, ButtonProps } from "@Components/button";
import { AlertTitle } from "./alert.title";
import { UseAlert } from "./useAlert";
import { InputWrapperProps } from "..";
import { DataTypes } from "../../types/element";

type AlertVariants = "error" | "info" | "success" | "warning";

/** The default icon names for each  */
const iconNames: Record<AlertVariants, string> = {
    error: "ri-alert-fill",
    info: "ri-information-fill",
    success: "ri-checkbox-circle-fill",
    warning: "ri-spam-fill",
};

const ariaLabels = {
    error: "Error",
    warning: "Warning",
    success: "Success",
    info: "Information",
};

export type AlertProps = DataTypes & {
    alert?: UseAlert;
    /** Sets the aria-live attribute to "assertive" */
    assertive?: true;
    /** Tells Typescript that children can be any valid React node */
    children: React.ReactNode;
    /** Displays a close button that hides the alert */
    closable?: boolean;
    /** Props for the wrapper button around the close icon */
    closeButtonProps?: Omit<ButtonProps, "onClick">;
    /** The icon element used within the close button */
    CloseIcon?: JSX.Element;
    /** The props that will be passed to the close icon if a custom close icon is not being used */
    closeIconProps?: IconProps;
    /** The Icon element next to the children */
    Icon?: JSX.Element | false;
    /** The props that will be passed to the icon if a custom icon is not being used */
    iconProps?: IconProps;
    id?: string;
    /**
     * Controls whether the alert is visible
     *
     * This can be omitted to allow the alert to be closed but it will not re-open
     */
    isOpen?: boolean;
    /** Called when the close button is clicked */
    onClose?: () => void;
    /** Sets the aria-live attribute to "polite" */
    polite?: true;
    theme?: string;
    /** Controls the style of the alert. Defaults to "info" */
    variant?: AlertVariants;
    /** Props for main wrapping element in the alert */
    wrapperProps?: Omit<InputWrapperProps, "id">;
};

/**
 * An element that displays a brief, important message in a way that attracts the user's attention without interrupting the user's task.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-alert--alert)}
 */
const Alert = ({
    alert,
    assertive,
    children,
    closable,
    closeButtonProps = {},
    CloseIcon: CustomCloseIcon,
    closeIconProps = {},
    Icon: CustomIcon,
    iconProps = {},
    id: controlledId,
    isOpen: controlledIsOpen,
    onClose,
    polite,
    theme,
    variant = "info",
    wrapperProps = {},
}: PropsWithChildren<AlertProps>): JSX.Element => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { initTrap, resetFocus } = useTabTrap({ contentRef: wrapperRef });
    const [internalIsOpen, setInternalIsOpen] = useState<boolean>(true);
    const internalId = useUuid();
    const id = controlledId ?? internalId;
    const isOpen = controlledIsOpen ?? internalIsOpen;
    useControlledValue(controlledIsOpen);
    useConsistentValue(id, "The ID of an alert should remain consistent.");

    const wrapperClassName = concat(
        "zest-alert",
        closable && "closable",
        variant,
        theme,
        isOpen && "active",
        wrapperProps.className
    );
    const ariaLive = (): "polite" | "assertive" => {
        // If assertive is passed, it takes precendence over the rest
        if (assertive) {
            return "assertive";
        } else if (polite) {
            return "polite";
        } else if (variant === "success" || variant === "info") {
            return "polite";
        } else {
            return "assertive";
        }
    };

    const ariaProps = {
        "aria-live": ariaLive(),
    };

    const handleClose = (): void => {
        if (controlledIsOpen === undefined) {
            setInternalIsOpen(() => false);
        }
        onClose?.();
    };

    /**
     * This allows the useTabTrap hook to work with the focusIn event.
     * Instead of letting the hook use the document.activeElement as the
     * element to return focus to, it instead accepts an element which is used in
     * place of document.activeElement.
     * @see {@link [MDN Related Target](https://developer.mozilla.org/en-US/docs/Web/API/FocusEvent/relatedTarget)}
     */
    const handleFocusIn = useCallback<(e: FocusEvent) => void>(
        (e) => {
            const relatedTarget = e.relatedTarget;
            if (relatedTarget === wrapperRef.current) {
                return;
            }
            if (relatedTarget instanceof HTMLElement) {
                initTrap(relatedTarget);
            }
        },
        [initTrap]
    );

    /** Ensures that both the polite and the assertive props are not both used */
    useEffect(() => {
        if (polite && assertive) {
            console.error(
                "%cZest Error:\n",
                "background-color: red; color: yellow; font-size: xsmall",
                "The <Alert> component has both assertive and polite props set to true.",
                "Only one of the other should be used.",
                "In this case, polite will be used as the aria-live value."
            );
        }
    }, [polite, assertive]);

    useEffect(() => {
        const shouldRegister = alert && id;
        if (shouldRegister) {
            alert.register(id, wrapperRef);
        }
        return function alertHookCleanup(): void {
            if (shouldRegister) {
                alert.unregister(id);
            }
        };
    }, [alert, id]);

    /** Handles managing focus */
    useEffect(() => {
        if (!closable) {
            return;
        }
        const wrapper = wrapperRef.current;
        if (wrapper) {
            wrapper.addEventListener("focusin", handleFocusIn);
        }
        return function alertTabTrapCleanup(): void {
            if (wrapper) {
                wrapper.removeEventListener("focusin", handleFocusIn);
            }
        };
    }, [closable, handleFocusIn]);

    useLayoutEffect(() => {
        if (!isOpen) {
            resetFocus();
        }
    }, [isOpen, resetFocus]);

    return (
        <div
            {...wrapperProps}
            {...ariaProps}
            aria-atomic="true"
            id={id}
            ref={wrapperRef}
            className={wrapperClassName}
            data-testid="alert"
            tabIndex={-1}
        >
            {isOpen && (
                <>
                    <div className="alert-icon-and-content">
                        {CustomIcon ?? (
                            <Icon
                                aria-label={ariaLabels[variant]}
                                name={iconNames[variant]}
                                {...iconProps}
                                className={concat(
                                    "zest-alert-icon",
                                    iconProps.className
                                )}
                            />
                        )}
                        <div>{children}</div>
                    </div>
                    {closable && (
                        <Button
                            type="button"
                            size="medium"
                            {...closeButtonProps}
                            className={concat(
                                "icon-only",
                                closeButtonProps.className
                            )}
                            aria-label={
                                closeButtonProps["aria-label"] ?? "Close alert"
                            }
                            onClick={handleClose}
                        >
                            {CustomCloseIcon ?? (
                                <Icon
                                    name="ri-close-line"
                                    size="xsmall"
                                    {...closeIconProps}
                                />
                            )}
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};

Alert.Title = AlertTitle;
Alert.displayName = "Alert";

export { Alert };
