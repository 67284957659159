import { HTMLAttributes, useCallback, useEffect, JSX } from "react";
import { concat } from "@Utilities/string";
import { Button, ButtonProps } from "@Components/button";
import { Tooltip } from "@Components/tooltip";
import { IconProps } from "@Components/icon";
import { useStore } from "@Utilities/hooks/useStoreData";
import VolumeBar from "./volumeBar";
import ProgressBar from "./progressBar";
import useFullScreen, { useVideoPlayerContext } from "./utilities";
import { isPlayingSelector, showControlsSelector } from "./selectors";
import Duration from "./duration";
import { isFullScreenElement } from "./fullscreenUtils";

export type ControlsProps = HTMLAttributes<HTMLElement> & {
    buttonProps?: ButtonProps;
    currentTime?: number;
    duration?: number;
    handleBlur?: (e: React.FocusEvent) => void;
    handlePlayPause?: () => void;
    iconProps?: IconProps;
    isLoading?: boolean;
    isPlaying?: boolean;
    spriteSheetHref?: string;
    theme?: string;
    wrapperRef?: React.RefObject<HTMLDivElement>;
};

// InternalControlsProps is a type that extends ControlsProps to include internal control props we don't want exposed
type InternalControlsProps = ControlsProps & {
    muted?: boolean;
};

const Controls = ({
    buttonProps,
    className,
    handleBlur,
    iconProps = {},
    muted,
    spriteSheetHref,
    theme,
    wrapperRef: videoWrapper,
    ...rest
}: InternalControlsProps): JSX.Element => {
    const { setFullscreen, setShowControls, store, togglePlayPause } =
        useVideoPlayerContext();
    const isFullscreen = useStore(store, (state) => state.isFullscreen);
    const showControls = useStore(store, showControlsSelector);
    const isPlaying = useStore(store, isPlayingSelector);
    const { toggle } = useFullScreen(videoWrapper);
    const controlsWrapperClasses = concat(
        "zest-controls-wrapper",
        theme,
        className,
        !showControls && "hide-controls"
    );

    const handleMouseLeave = (): void => {
        // if video is playing and overlay is hidden, then hide controls
        isPlaying && !showControls && setShowControls(false);
    };

    useEffect(() => {
        const handleFullscreen = (): void => {
            const videoWrapper =
                document.getElementsByClassName("zest-video-wrapper")[0];
            setFullscreen(isFullScreenElement(videoWrapper));
        };

        document.addEventListener("fullscreenchange", handleFullscreen);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreen);
        };
    }, [setFullscreen]);

    const videoPlayerFullscreen = useCallback(() => {
        if (!videoWrapper) return;
        const videoWrapperContainer = videoWrapper.current;
        const video = videoWrapperContainer?.querySelector("video");
        if (
            videoWrapperContainer?.requestFullscreen ||
            videoWrapperContainer?.mozRequestFullscreen ||
            videoWrapperContainer?.webkitRequestFullscreen ||
            videoWrapperContainer?.msRequestFullscreen
        ) {
            toggle();
        } else {
            video?.webkitEnterFullScreen();
        }
    }, [toggle, videoWrapper]);

    return (
        <div
            {...rest}
            className={controlsWrapperClasses}
            onMouseEnter={(): void => setShowControls(true)}
            onMouseLeave={(): void => handleMouseLeave()}
        >
            <Tooltip
                content={isPlaying ? "Pause" : "Play"}
                contentId="zest-video-player-pauseplay-button"
                placement="top-left"
            >
                <Button
                    {...buttonProps}
                    size="small"
                    onClick={togglePlayPause}
                    aria-label={isPlaying ? "pause" : "play"}
                    className={concat(
                        buttonProps?.className,
                        "zest-play-button"
                    )}
                    iconProps={{
                        ...iconProps,
                        name: isPlaying ? "ri-pause-fill" : "ri-play-fill",
                        spriteSheetHref: spriteSheetHref,
                    }}
                />
            </Tooltip>
            <ProgressBar spriteSheetHref={spriteSheetHref} />
            <Duration />
            <VolumeBar spriteSheetHref={spriteSheetHref} muted={muted} />
            {/* Leave commented out until CC functionality is implemented */}
            {/* <Button
                {...buttonProps}
                size="xsmall"
                aria-label="captions"
                className={concat(
                    buttonProps?.className,
                    "zest-captions-button"
                )}
                iconProps={{
                    ...iconProps,
                    name: "ri-closed-captioning-fill",
                    spriteSheetHref: spriteSheetHref,
                }}
            /> */}
            <Tooltip
                content={isFullscreen ? "Exit full screen" : "Full screen"}
                contentId="zest-video-player-fullscreen-button"
                placement="top-right"
            >
                <Button
                    {...buttonProps}
                    size="small"
                    onClick={videoPlayerFullscreen}
                    aria-label={isFullscreen ? "exit-fullscreen" : "fullscreen"}
                    className={concat(
                        buttonProps?.className,
                        "zest-fullscreen-button"
                    )}
                    iconProps={{
                        ...iconProps,
                        name: "ri-fullscreen-fill",
                        spriteSheetHref: spriteSheetHref,
                    }}
                    onBlur={handleBlur}
                />
            </Tooltip>
        </div>
    );
};

export { Controls };
