import { cloneElement, useEffect, useRef } from "react";
import { concat } from "util/string";

export type ListItemProps = {
  item: JSX.Element;
  className: string;
  focus: boolean;
  setFocus?: (index: number) => void;
  index: number;
  onClick: () => void;
};

/**
 *  ListItem for use in MenuButton dropdown pattern for keyboard accessibility and roving tab index.
 *  Each ListItem should be interactible and focusable, such as `<a>` or `<button>`.
 * @export
 * @param {ListItemProps} {
 *   className,
 *   focus,
 *   item,
 * }
 * @return {*}  {JSX.Element}
 */
export default function ListItem({
  className,
  focus,
  item,
  onClick,
}: ListItemProps): JSX.Element {
  const ref = useRef<HTMLButtonElement | HTMLAnchorElement>(null);

  useEffect(() => {
    if (focus) {
      ref.current?.focus();
    }
  }, [focus]);

  const linkStyle = item.props?.disablelink
    ? ""
    : concat(
        "active:bg-[#F2F7F8]",
        "hover:bg-[#F2F7F8]",
        "focus:bg-[#F2F7F8]",
        "focus:outline-none", // disable default HTML focus ring
        "focus:ring-2",
        "focus:ring-blue-400"
      );

  const overrideOnClick = item.props?.disablelink
    ? (ev: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
        ev.stopPropagation();
      }
    : onClick;

  return (
    <li
      role="none"
      tabIndex={-1}
      onClick={overrideOnClick}
      className={className}
    >
      {
        /* cloneElement allows us to apply props to the items themselves */
        cloneElement(item, {
          ref: ref, // ref will keep the Element interactible in the case of a link, etc.
          role: "menuitem",
          tabIndex: focus ? 0 : -1, // tabIndex will be -1 (untabbable) unless it is currently focused (0)
          className: concat(
            "block",
            "rounded",
            "px-2",
            "py-1",
            linkStyle,
            item.props.className
          ),
        })
      }
    </li>
  );
}
