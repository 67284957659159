import { ActiveInvoiceFilters } from "components/Invoice/Invoices.types";
import { FilteredInvoicesSearchParameters } from "routes/Invoice/Invoice.types";

export function buildFilteredInvoicesApiUrl(
  filters: ActiveInvoiceFilters,
  pageNumber: number,
  isExport = false
): string {
  const url = `invoices${isExport ? "/export" : ""}`;
  const queryString = buildFilteredInvoicesQueryString(filters, pageNumber);

  return `${url}${queryString}`;
}

export function buildFilteredInvoicesQueryString(
  filters: ActiveInvoiceFilters,
  pageNumber: number
): string {
  const params = new URLSearchParams();

  if (filters.invoiceOrPONumber) {
    params.append("invoiceNumber", filters.invoiceOrPONumber);
  }

  if (filters.orderHeaderId) {
    params.append("orderHeaderId", filters.orderHeaderId);
  }

  if (filters.storeNumber) {
    params.append("storeNumber", filters.storeNumber);
  }

  if (filters.issuedFrom) {
    params.append("startDate", `${filters.issuedFrom}T00:00:00`);
  }

  if (filters.issuedTo) {
    params.append("endDate", `${filters.issuedTo}T23:59:59`);
  }

  if (filters.selectedAccountId) {
    params.append("searchAccountId", filters.selectedAccountId);
  }

  if (filters.searchInvoicePaymentStatus) {
    params.append(
      "searchInvoicePaymentStatus",
      filters.searchInvoicePaymentStatus
    );
  }

  if (filters.subordinatesSearchType) {
    params.append("subordinatesSearchType", filters.subordinatesSearchType);
  }

  if (pageNumber > 0) {
    params.append("pageNumber", pageNumber.toString());
  }

  const paramsString = params.toString();

  return paramsString === "" ? paramsString : `?${paramsString}`;
}

export function createFilteredInvoiceSearchParameters(
  filters: ActiveInvoiceFilters
): FilteredInvoicesSearchParameters {
  const params: FilteredInvoicesSearchParameters = {};

  if (filters.issuedFrom) {
    params.startDate = filters.issuedFrom;
  }

  if (filters.issuedTo) {
    params.endDate = filters.issuedTo;
  }

  if (filters.invoiceOrPONumber) {
    params.invoiceNumber = filters.invoiceOrPONumber;
  }

  if (filters.orderHeaderId) {
    params.orderHeaderId = filters.orderHeaderId;
  }

  if (filters.storeNumber) {
    params.storeNumber = filters.storeNumber;
  }

  if (filters.selectedAccountId) {
    params.selectedAccountId = filters.selectedAccountId;
  }

  if (filters.searchInvoicePaymentStatus) {
    params.searchInvoicePaymentStatus =
      filters.searchInvoicePaymentStatus !== "0"
        ? filters.searchInvoicePaymentStatus
        : undefined;
  }

  if (filters.subordinatesSearchType) {
    params.subordinatesSearchType = filters.subordinatesSearchType;
  }

  return params;
}
