import { AriaAttributes, FC, SVGAttributes } from "react";
import { DataTypes, Sizes } from "@Types/element";
import { concat, props } from "@Utilities/string";
import { SFSpriteSheetHref } from "@Utilities/index";

export type IconProps = SVGAttributes<SVGElement> &
    DataTypes & {
        name?: string;
        size?: Sizes | "2xsmall" | "xsmall" | "xlarge";
        spriteSheetHref?: string;
        theme?: string;
        variant?: string;
    };

/**
 * An icon component that uses sprite sheets to load in SVG icons.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-icon--icon)}
 * @see {@link [Icon Options](http://remixicon.com/)}
 * @see {@link [Sprite Sheets Explanation](https://tfs.clarkinc.biz/DefaultCollection/Design/_wiki/wikis/Design.wiki/3276/SVGs?anchor=understanding-sprites)}
 */
const Icon: FC<IconProps> = ({
    "aria-label": ariaLabel,
    className,
    name,
    role,
    size,
    spriteSheetHref = SFSpriteSheetHref,
    theme,
    variant,
    ...rest
}) => {
    const classes = concat(
        "zest-icon",
        !!variant && `zest-icon-${variant}`,
        size,
        theme,
        className
    );

    const ariaAttributes: AriaAttributes = props({
        "aria-label": { condition: !!ariaLabel, value: ariaLabel },
        "aria-hidden": { condition: !ariaLabel, value: true },
    });

    const svgProps: SVGAttributes<SVGElement> = props({
        role: { condition: !!ariaLabel, value: role || "img" },
    });

    return (
        <svg
            data-testid="zest-icon"
            {...rest}
            {...ariaAttributes}
            {...svgProps}
            className={classes}
            focusable={false}
        >
            <use
                data-testid="zest-icon-use"
                aria-hidden={true}
                href={`${spriteSheetHref}#${name}`}
            />
        </svg>
    );
};

Icon.displayName = "Icon";

export { Icon };
