import { useEffect } from "react";
import { useUserContext } from "authentication/contexts/user.context";
import { LayoutAPI } from "layouts/api/layoutAPI.types";
import {
  GA4EventNames,
  WindowWithDataLayer,
} from "util/GoogleAnalytics/google-analytics.types";
import { useStore } from "util/store";

declare const window: WindowWithDataLayer;

export default function useGoogleAnalyticsPageview(
  userLayoutData: LayoutAPI | undefined,
  location: string
): void {
  const userContext = useUserContext();
  const userClaims = useStore(userContext.store, (user) => user.userClaims);
  const accountId = userClaims?.AccountId ?? "";

  useEffect(
    function GoogleAnalyticsPageviewEffect() {
      if (userLayoutData) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: GA4EventNames.PAGEVIEW,
          company_name: userLayoutData.customerCompanyName,
          customer_code: userLayoutData.customerCode,
          is_internal: userLayoutData.isInternalUser.toString(),
          userId: accountId,
        });
      }
    },
    [location, userLayoutData, accountId]
  );
}
