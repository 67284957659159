import { useCallback, useMemo } from "react";
import { CartNumberContextType } from "contexts/cart/cartnumber.context";
import { useCreateStore } from "util/store";

export const useCartNumberStore = (
  refetchCartNumber: () => void
): CartNumberContextType => {
  const store = useCreateStore<{ cartNumber: number }>({ cartNumber: 0 });
  const setCartNumber = useCallback(
    (number: number) => {
      store.set({ cartNumber: number });
    },
    [store]
  );
  const refetch = useCallback(refetchCartNumber, [refetchCartNumber]);
  return useMemo(
    () => ({ store, setCartNumber, refetch }),
    [store, setCartNumber, refetch]
  );
};
