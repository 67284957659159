import { FC } from "react";
import { concat, props } from "@Utilities/string";
import {
    InputError,
    InputHelper,
    InputWrapper,
    InputLabel,
    Input,
    placeholderError,
    InputProps,
} from "@Components/formComponents/utilities";
import { SizeLike } from "@Types/element";
import { getResponsiveValues } from "@Utilities/sizing";

export type TextInputProps = InputProps & {
    inputSize?: SizeLike<"xlarge">;
    variant?: string;
};

type ComposedInputProps = FC<TextInputProps> & {
    /** @see {@link InputError} */
    Error: typeof InputError;
    /** @see {@link InputHelper} */
    Helper: typeof InputHelper;
    /** @see {@link Input} */
    Input: typeof Input;
    /** @see {@link InputLabel} */
    Label: typeof InputLabel;
    /** @see {@link InputWrapper} */
    Wrapper: typeof InputWrapper;
};

/**
 * A text input with a built-in label, helper text, and error component.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-text-input--text-input-story)}
 */
const TextInput: ComposedInputProps = ({
    className,
    element = "div",
    error,
    errorElement,
    errorMessage,
    errorProps = {},
    helperProps = {},
    helperText,
    hideError,
    hideLabel,
    id,
    inputSize = "medium",
    label,
    labelProps = {},
    optional,
    placeholder,
    required,
    theme,
    type = "text",
    wrapperProps = {},
    ...rest
}) => {
    const errorId = `text-error-${id}`;
    const helperId = `text-helper-${id}`;

    const isHeadless = theme === "headless";
    const errors = Array.isArray(errorMessage)
        ? errorMessage.join(" ")
        : errorMessage;
    const ariaProps = props({
        "aria-describedby": {
            condition: error || !!helperText,
            value: concat(error && errorId, !!helperText && helperId),
        },
        "aria-label": {
            condition: hideLabel || hideError,
            value: concat(label, hideError && errors),
        },
        "aria-invalid": { condition: error, value: "true" },
    });

    if (placeholder) placeholderError();

    return (
        <InputWrapper
            data-testid="text-input-wrapper"
            {...wrapperProps}
            element={element}
            theme={theme}
        >
            {!hideLabel && (
                <InputLabel
                    {...labelProps}
                    htmlFor={id as string}
                    hidden={hideLabel}
                    optional={optional}
                    required={required}
                    theme={theme}
                >
                    {label}
                </InputLabel>
            )}
            <InputHelper {...helperProps} id={helperId} theme={theme}>
                {helperText}
            </InputHelper>
            <Input
                {...rest}
                {...ariaProps}
                id={id}
                required={required}
                type={type}
                className={concat(
                    "zest-input-text",
                    className,
                    !isHeadless && getResponsiveValues("input", inputSize),
                    !className && isHeadless && "focus:outline-blue-600"
                )}
            />
            <InputError
                {...errorProps}
                id={errorId}
                hasError={error && !hideError}
                errorMessages={errorMessage}
                theme={theme}
            >
                {errorElement}
            </InputError>
        </InputWrapper>
    );
};

TextInput.Error = InputError;
TextInput.Input = Input;
TextInput.Helper = InputHelper;
TextInput.Label = InputLabel;
TextInput.Wrapper = InputWrapper;

TextInput.displayName = "TextInput";

export { TextInput };
