import ErrorMessageToastDisplay from "components/Toast/ErrorMessageToastDisplay";
import { ShowToastFunction } from "contexts/toast/ToastContext";
import { ErrorMessages } from "util/requests/response.types";

export function showErrorToasts(
  showToast: ShowToastFunction,
  errors: ErrorMessages | string[] | undefined
): void {
  if (!errors) {
    return;
  }

  if (Array.isArray(errors)) {
    for (const message of errors.filter((e) => e)) {
      showToast(<ErrorMessageToastDisplay errors={[message]} />, "error");
    }
  } else {
    showToast(<ErrorMessageToastDisplay errors={errors} />, "error");
  }
}
