import { InputHTMLAttributes } from "react";
import { useControlledValue } from "@Utilities/hooks";
import { concat } from "@Utilities/string";
import { Input } from "..";
import { useRadioContext } from "./context";

export type RadioInputProps = InputHTMLAttributes<HTMLInputElement> & {
    theme?: string;
};

/** An input with type of radio.
 *
 * It is not recommended to use this component since
 * it does not include a label or helper text.
 *
 * Instead use the `<Radio>` component.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-inputs-radio--radio)}
 */
const RadioInput = ({
    className,
    value,
    ...rest
}: RadioInputProps): JSX.Element => {
    const { name, onChange, value: selectedValue } = useRadioContext();
    const checked = selectedValue === value;
    useControlledValue(value);

    return (
        <Input
            {...rest}
            type="radio"
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            className={concat(className, "zest-input-radio")}
        />
    );
};

export { RadioInput };
