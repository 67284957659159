import { FC, HTMLAttributes, useCallback, useEffect, useRef } from "react";
import { concat } from "@Utilities/string";
import { PopoverContext } from "./context";
import {
    PopoverEventCallbacks,
    PopoverEvents,
    UsePopover,
    usePopover,
} from "./hooks/usePopover";
import { PopoverContent, PopoverContentProps } from "./popover.content";
import { PopoverTrigger, PopoverTriggerProps } from "./popover.trigger";
import { placements, PlacementTypes } from "@Utilities/hooks";

export type PopoverProps = HTMLAttributes<HTMLElement> & {
    // absolute?: boolean; - commenting out due to functionality not being in the MVP
    contentProps?: PopoverContentProps;
    element?: "div" | "p" | "span";
    offset?: number;
    onClose?: PopoverEventCallbacks;
    onOpen?: PopoverEventCallbacks;
    placement?: PlacementTypes;
    popover?: UsePopover;
    theme?: string;
    triggerProps?: PopoverTriggerProps;
};

type ComposedPopoverProps = FC<PopoverProps> & {
    Content: typeof PopoverContent;
    Trigger: typeof PopoverTrigger;
};

/**
 * A small overlay triggered by a button that displays additional copy or interactive content related to an element or area.
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-popover--popover)}
 *
 */
const Popover: ComposedPopoverProps = ({
    // absolute = true,
    children,
    className,
    contentProps,
    element = "div",
    offset,
    onClose,
    onOpen,
    placement = placements.Top,
    popover: controlledPopover,
    theme = "wss",
    ...rest
}) => {
    const internalUsePopover = usePopover();
    const ref = useRef<HTMLDivElement>(null);
    const popover = controlledPopover || internalUsePopover;
    const Element = element;

    const classes = concat(
        className,
        "relative",
        "zest-popover-wrapper",
        theme
    );

    /** Will focus the trigger element, run when the popover is closed */
    const focusTrigger = useCallback((id: string): void => {
        if (!ref.current) {
            console.error(
                "%cZest Error:\n",
                "background-color: red; color: yellow; font-size: xsmall",
                "Focus trigger failed. The ref is not available."
            );
        } else {
            const triggerWithId = document.querySelector<HTMLElement>(
                `[data-content="${id}"]`
            );
            if (!triggerWithId) {
                console.error(
                    "%cZest Error:\n",
                    "background-color: red; color: yellow; font-size: xsmall",
                    "Focus trigger failed. The trigger with the ID is not available."
                );
                return;
            } else {
                triggerWithId.focus();
            }
        }
    }, []);

    const handleEscape = useCallback<(e: KeyboardEvent) => void>(
        (e) => {
            const openPopoverId = popover.store.get().openId;
            if (e.key === "Escape" && openPopoverId) {
                focusTrigger(openPopoverId);
                popover.close(openPopoverId);
            }
        },
        [focusTrigger, popover]
    );

    useEffect(() => {
        const cleanups: (() => void)[] = [];
        if (onClose) {
            const unsubClose = popover.subscribe(PopoverEvents.CLOSE, onClose);
            cleanups.push(unsubClose);
        }
        if (onOpen) {
            const unsubOpen = popover.subscribe(PopoverEvents.OPEN, onOpen);
            cleanups.push(unsubOpen);
        }
        return (): void => {
            cleanups.forEach((cleanup) => {
                cleanup();
            });
        };
    }, [popover, onOpen, onClose]);

    useEffect(() => {
        const popoverRef = ref.current;
        popoverRef?.addEventListener("keydown", handleEscape);
        return function popoverClickOutsideCleanup(): void {
            popoverRef?.removeEventListener("keydown", handleEscape);
        };
    }, [handleEscape]);

    return (
        <PopoverContext.Provider
            value={{
                store: popover.store,
                contentProps: {
                    ...contentProps,
                    placement,
                    offset,
                },
                close: popover.close,
                toggle: popover.toggle,
                register: popover.register,
            }}
        >
            <Element {...rest} ref={ref} className={classes}>
                {children}
            </Element>
        </PopoverContext.Provider>
    );
};
/** flex justify-evenly w-full */
Popover.Content = PopoverContent;
Popover.Trigger = PopoverTrigger;
Popover.displayName = "Popover";

export { Popover };
