import { Suspense, lazy } from "react";
import {
  AccountIllustrationSymbol,
  CheckoutIllustrationSymbol,
  CommonIllustrationSymbol,
  IllustrationSymbols,
  OrderHistoryIllustrationSymbol,
  InvoiceIllustrationSymbol,
  accountIllustrationSymbols,
  checkoutIllustrationSymbols,
  commonIllustrationSymbols,
  orderHistoryIllustrationSymbols,
  invoiceIllustrationSymbols,
  projectBuilderIllustrationSymbols,
  ProjectBuilderIllustrationSymbol,
  orderGuideIllustrationSymbols,
  OrderGuideIllustrationSymbol,
  userManagementIllustrationSymbols,
  UserManagementIllustrationSymbol,
  LoginIllustrationSymbol,
  loginIllustrationSymbols,
  RapidReorderIllustrationSymbol,
  rapidReorderIllustrationSymbols,
  SelectPagesIllustrationSymbol,
  selectPagesIllustrationSymbols,
  emailIllustrationSymbols,
  EmailIllustrationSymbol,
} from "assets/illustrations/IllustrationSymbols.types";

const LoginIllustration = lazy(
  () => import("assets/illustrations/login/LoginIllustration")
);

const AccountIllustration = lazy(
  () => import("assets/illustrations/account/AccountIllustration")
);

const CommonIllustration = lazy(
  () => import("assets/illustrations/common/CommonIllustration")
);

const CheckoutIllustration = lazy(
  () => import("assets/illustrations/checkout/CheckoutIllustration")
);

const OrderHistoryIllustration = lazy(
  () => import("assets/illustrations/order-history/OrderHistoryIllustration")
);

const InvoiceIllustration = lazy(
  () => import("assets/illustrations/invoice/InvoiceIllustration")
);

const ProjectBuilderIllustration = lazy(
  () =>
    import("assets/illustrations/project-builder/ProjectBuilderIllustration")
);

const OrderGuideIllustration = lazy(
  () => import("assets/illustrations/order-guide/OrderGuideIllustration")
);

const UserManagementIllustration = lazy(
  () =>
    import("assets/illustrations/user-management/UserManagementIllustration")
);

const SelectPagesIllustration = lazy(
  () => import("assets/illustrations/select/SelectPagesIllustration")
);

const RapidReorderIllustration = lazy(
  () => import("assets/illustrations/rapid-reorder/RapidReorderIllustration")
);

const EmailIllustration = lazy(
  () => import("assets/illustrations/emails/EmailIllustration")
);

export type IllustrationProps = {
  className?: string;
  name: IllustrationSymbols;
  themed?: boolean;
} & React.ComponentProps<"svg">;

/**
 * @description A fallback primary illustration color if the css token file fails to load the "illustration" class.
 */
export const IllustrationThemeFallback = "text-[#DC2626]";

/**
 * @description Lazy Render an Illustration matching the illustrations.types configuration.
 */
export default function Illustration({
  fallback,
  className,
  name,
  themed,
  ...rest
}: IllustrationProps & { fallback: React.ReactElement }): React.ReactElement {
  const passthroughProps: IllustrationProps = {
    className,
    name,
    themed,
    ...rest,
  };

  const illustration = getIllustrationComponent(name, null, passthroughProps);

  return <Suspense fallback={fallback}>{illustration}</Suspense>;
}

// Checks each Illustration const to return the matching Illustration. Needs to assert Type values because of lazy loading. If the indexOf search finds a match, then the 'as' typecast will work.
function getIllustrationComponent(
  name: string,
  ref: null, // For some reason typescript has issues with the ref prop type for a lazy loaded component. This is a workaround.
  passthroughProps: IllustrationProps
): React.ReactElement {
  let illustration;
  if (
    accountIllustrationSymbols.indexOf(name as AccountIllustrationSymbol) !== -1
  ) {
    illustration = (
      <AccountIllustration
        {...passthroughProps}
        ref={ref}
        name={name as AccountIllustrationSymbol}
      />
    );
  } else if (
    commonIllustrationSymbols.indexOf(name as CommonIllustrationSymbol) !== -1
  ) {
    illustration = (
      <CommonIllustration
        {...passthroughProps}
        ref={ref}
        name={name as CommonIllustrationSymbol}
      />
    );
  } else if (
    checkoutIllustrationSymbols.indexOf(name as CheckoutIllustrationSymbol) !==
    -1
  ) {
    illustration = (
      <CheckoutIllustration
        {...passthroughProps}
        ref={ref}
        name={name as CheckoutIllustrationSymbol}
      />
    );
  } else if (
    orderHistoryIllustrationSymbols.indexOf(
      name as OrderHistoryIllustrationSymbol
    ) !== -1
  ) {
    illustration = (
      <OrderHistoryIllustration
        {...passthroughProps}
        ref={ref}
        name={name as OrderHistoryIllustrationSymbol}
      />
    );
  } else if (
    invoiceIllustrationSymbols.indexOf(name as InvoiceIllustrationSymbol) !== -1
  ) {
    illustration = (
      <InvoiceIllustration
        {...passthroughProps}
        ref={ref}
        name={name as InvoiceIllustrationSymbol}
      />
    );
  } else if (
    projectBuilderIllustrationSymbols.indexOf(
      name as ProjectBuilderIllustrationSymbol
    ) !== -1
  ) {
    illustration = (
      <ProjectBuilderIllustration
        {...passthroughProps}
        ref={ref}
        name={name as ProjectBuilderIllustrationSymbol}
      />
    );
  } else if (
    orderGuideIllustrationSymbols.indexOf(
      name as OrderGuideIllustrationSymbol
    ) !== -1
  ) {
    illustration = (
      <OrderGuideIllustration
        {...passthroughProps}
        ref={ref}
        name={name as OrderGuideIllustrationSymbol}
      />
    );
  } else if (
    userManagementIllustrationSymbols.indexOf(
      name as UserManagementIllustrationSymbol
    ) !== -1
  ) {
    illustration = (
      <UserManagementIllustration
        {...passthroughProps}
        ref={ref}
        name={name as UserManagementIllustrationSymbol}
      />
    );
  } else if (
    loginIllustrationSymbols.indexOf(name as LoginIllustrationSymbol) !== -1
  ) {
    illustration = (
      <LoginIllustration
        {...passthroughProps}
        ref={ref}
        name={name as LoginIllustrationSymbol}
      />
    );
  } else if (
    rapidReorderIllustrationSymbols.indexOf(
      name as RapidReorderIllustrationSymbol
    ) !== -1
  ) {
    illustration = (
      <RapidReorderIllustration
        {...passthroughProps}
        ref={ref}
        name={name as RapidReorderIllustrationSymbol}
      />
    );
  } else if (
    selectPagesIllustrationSymbols.indexOf(
      name as SelectPagesIllustrationSymbol
    ) !== -1
  ) {
    illustration = (
      <SelectPagesIllustration
        {...passthroughProps}
        ref={ref}
        name={name as SelectPagesIllustrationSymbol}
      />
    );
  } else if (
    emailIllustrationSymbols.indexOf(name as EmailIllustrationSymbol) !== -1
  ) {
    illustration = (
      <EmailIllustration
        {...passthroughProps}
        ref={ref}
        name={name as EmailIllustrationSymbol}
      />
    );
  } else {
    throw new Error(`Unknown illustration name: ${name}`);
  }
  return illustration;
}
