import { createContext, Dispatch } from "react";
import { noop } from "@Utilities/functions";
import { UseFormMethods } from "./hooks/useFormMethods";
import { FormValues } from "./types";
import { FormMeta } from "./hooks/useFormValues";

export type FormContextType<S extends FormValues = FormValues> = {
    formMetaValues: Partial<FormMeta>;
    values: S;
};

export const FormContext = createContext<FormContextType>({
    formMetaValues: {},
    values: {},
});

export type FormContextMethodsType = {
    getAlerts: UseFormMethods["getAlerts"];
    getErrors: UseFormMethods["getErrors"];
    onChange: UseFormMethods["setValue"];
    register: UseFormMethods["register"];
    setFieldMeta: UseFormMethods["setFieldMeta"];
    setFormMeta: Dispatch<FormMeta>;
    subscribe: UseFormMethods["subscribe"];
    unregister: UseFormMethods["unregister"];
    unsubscribe: UseFormMethods["unsubscribe"];
};

export const FormContextMethods = createContext<FormContextMethodsType>({
    register: () => noop,
    unregister: noop,
    onChange: noop,
    setFieldMeta: noop,
    setFormMeta: noop,
    subscribe: () => noop,
    unsubscribe: noop,
    getErrors: () => {
        return {};
    },
    getAlerts: () => {
        return {};
    },
});
