import {
  AnnotationIcon,
  BadgeCheckIcon,
  ClipboardListIcon,
  CogIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  DocumentDuplicateIcon,
  DocumentReportIcon,
  MailIcon,
  OfficeBuildingIcon,
  RefreshIcon,
  ShoppingCartIcon,
  TruckIcon,
  UserGroupIcon,
  UserIcon,
  ViewGridAddIcon,
} from "@heroicons/react/solid";
import { useUserContext } from "authentication/contexts/user.context";
import { AccountLink } from "layouts/Account/AccountLinks.types";
import useLayoutAPI from "layouts/api/useLayoutAPI";
import { hasPermission, permissionTypes } from "util/user/permissions";
import { useStore } from "util/store";
import AppConstants from "appconstants.json";
import useAccountLinksAPI from "layouts/api/useAccountLinksAPI";
import routes from "routes/routes";
import Icon from "assets/Icon";
import { LayoutAPI } from "layouts/api/layoutAPI.types";
import { AccountLinksData } from "layouts/Account/AccountLinksData.types";

const sharedIconProps = { className: "size-5x5 my-1" };

export const accountLinks = (): AccountLink[] => {
  return [
    {
      title: "My Account",
      href: routes.MyAccount.getPath(),
      icon: <UserIcon {...sharedIconProps} />,
    },
    {
      title: "Order History",
      href: routes.OrderHistory.getPath(),
      icon: <ShoppingCartIcon {...sharedIconProps} />,
    },
    {
      title: "Rapid Reorder",
      href: routes.RapidReorder.getPath(),
      icon: <RefreshIcon {...sharedIconProps} />,
    },
    {
      title: "Account Settings",
      href: routes.AccountSettings.getPath(),
      icon: <CogIcon {...sharedIconProps} />,
    },
    {
      title: "Shipping Information",
      href: routes.ShippingAddresses.getPath(),
      icon: <TruckIcon {...sharedIconProps} />,
    },
    {
      title: "Billing Information",
      href: routes.BillingAddresses.getPath(),
      icon: <OfficeBuildingIcon {...sharedIconProps} />,
    },
    {
      title: "Payment Methods",
      href: routes.PaymentMethods.getPath(),
      icon: <CreditCardIcon {...sharedIconProps} />,
    },
    {
      title: "View Invoices",
      href: routes.Invoices.getPath(),
      icon: <ClipboardListIcon {...sharedIconProps} />,
    },
    {
      title: "Customer Support",
      href: routes.Support.getPath(),
      icon: <AnnotationIcon {...sharedIconProps} />,
    },
    {
      title: "Suppliers",
      href: routes.MySuppliers.getPath(),
      icon: <ViewGridAddIcon {...sharedIconProps} />,
    },
    {
      title: "Emails",
      href: routes.Emails.getPath(),
      icon: <MailIcon {...sharedIconProps} />,
    },
    {
      title: "Manage Users",
      href: routes.ManageUsers.getPath(),
      icon: <UserGroupIcon {...sharedIconProps} />,
    },
    {
      title: "Manage Budgets",
      href: routes.ManageBudgets.getPath(),
      icon: <CurrencyDollarIcon {...sharedIconProps} />,
    },
    {
      title: "Equipment Projects",
      href: routes.EquipmentProjectsListing.getPath(),
      icon: <Icon name="rectangle-stack-solid" {...sharedIconProps} />,
    },
    {
      title: "About Select",
      href: routes.AboutSelect.getPath(),
      icon: <BadgeCheckIcon {...sharedIconProps} />,
    },
    {
      title: "Manage Select Addresses",
      href: routes.ManageSelectAddresses.getPath(),
      icon: <BadgeCheckIcon {...sharedIconProps} />,
    },
    {
      title: "Reporting",
      href: routes.Reporting.getPath(),
      icon: <DocumentReportIcon {...sharedIconProps} />,
    },
    {
      title: "Quotes",
      href: routes.Quotes.getPath(),
      icon: <DocumentDuplicateIcon {...sharedIconProps} />,
    },
  ];
};

export default function useAccountLinks(isHeaderMenu: boolean): AccountLink[] {
  const userContext = useUserContext();
  const userClaims = useStore(userContext.store, (user) => user.userClaims);

  const canManageAccount = hasPermission(
    userClaims,
    permissionTypes.manageAccount
  );
  const canManageAddresses = hasPermission(
    userClaims,
    permissionTypes.manageAddresses
  );

  const canManageUsers = hasPermission(
    userClaims,
    permissionTypes.createManageUsers
  );

  const canManageBudget =
    hasPermission(userClaims, permissionTypes.createManageCompanyBudget) ||
    hasPermission(userClaims, permissionTypes.createManageGroupBudgets);

  const { data: userLayoutData } = useLayoutAPI();
  const { data: accountLinksData } = useAccountLinksAPI();

  const visibleAccountLinks = accountLinks().filter((link) => {
    switch (link.title) {
      case "Payment Methods":
      case "Account Settings":
      case "Emails":
        return areNonPunchOutLinksVisible(
          isHeaderMenu,
          canManageAccount,
          userLayoutData
        );
      case "Shipping Information":
      case "Billing Information":
        return areNonPunchOutLinksVisible(
          isHeaderMenu,
          canManageAddresses,
          userLayoutData
        );
      case "My Account":
        return canManageAccount;
      case "Manage Users":
        return isAuthorizedLinkEnabled(isHeaderMenu, canManageUsers);
      case "Manage Budgets":
        return isAuthorizedLinkEnabled(isHeaderMenu, canManageBudget);
      case "Equipment Projects":
        return isEquipmentProjectLinkVisible(isHeaderMenu, userLayoutData);
      case "About Select":
      case "Manage Select Addresses":
        return areSelectLinksVisible(isHeaderMenu, accountLinksData);
      case "Reporting":
        return isReportingLinkVisible(isHeaderMenu, accountLinksData);
      case "Quotes":
        return accountLinksData?.userHasPublishedQuotes ?? false;
      case "Suppliers":
        return isSuppliersLinkVisible(isHeaderMenu, accountLinksData);
      case "Rapid Reorder":
        return !isHeaderMenu;
      case "Customer Support":
        return AppConstants.enableSupportPageLinks;
      //case "Order History":
      //case "View Invoices":
      default:
        return true;
    }
  });

  return visibleAccountLinks;
}

const isAuthorizedLinkEnabled = (
  isHeaderMenu: boolean,
  permission: boolean
): boolean => !isHeaderMenu && permission;

const areNonPunchOutLinksVisible = (
  isHeaderMenu: boolean,
  permission: boolean,
  userLayoutData: LayoutAPI | undefined
): boolean =>
  isAuthorizedLinkEnabled(isHeaderMenu, permission) &&
  !(userLayoutData?.hasPunchOutSession ?? false);

const isEquipmentProjectLinkVisible = (
  isHeaderMenu: boolean,
  userLayoutData: LayoutAPI | undefined
): boolean =>
  !isHeaderMenu && (userLayoutData?.equipmentProjects?.length ?? 0) > 0;

const areSelectLinksVisible = (
  isHeaderMenu: boolean,
  accountLinksData: AccountLinksData | undefined
): boolean => !isHeaderMenu && (accountLinksData?.isSelectMenuEnabled ?? false);

const isReportingLinkVisible = (
  isHeaderMenu: boolean,
  accountLinksData: AccountLinksData | undefined
): boolean => !isHeaderMenu && (accountLinksData?.hasViewableReports ?? false);

const isSuppliersLinkVisible = (
  isHeaderMenu: boolean,
  accountLinksData: AccountLinksData | undefined
): boolean =>
  !isHeaderMenu && (accountLinksData?.isSuppliersLinkEnabled ?? false);
