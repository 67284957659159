import { useContext } from "react";
import { FormContext, FormContextType } from "../context";

/** Gets the form state and methods from context */
const useFormContext = (): FormContextType => {
    const context = useContext(FormContext);

    if (!context) {
        throw new Error(
            "useFormContext must be used within a <FormContext.Provider>"
        );
    }

    return context;
};

export { useFormContext };
