import { ButtonHTMLAttributes, FC, useEffect, useRef } from "react";
import { concat, props } from "@Utilities/string";
import { UseTimeout } from "@Utilities/hooks";
import { DataTypes } from "@Types/element";
import { useTooltipContext } from "./context";

export type TooltipTriggerProps = ButtonHTMLAttributes<HTMLElement> &
    DataTypes & {
        contentId?: string;
        element?: "button" | "div" | "span";
        timeout?: UseTimeout;
    };

const TooltipTrigger: FC<TooltipTriggerProps> = ({
    children,
    className,
    contentId,
    timeout,
    ...rest
}) => {
    const { open, toggleIsMouseInside, triggerProps } = useTooltipContext();
    const ref = useRef<HTMLElement>(null);

    const conditionalProps = props({
        "data-content": {
            value: contentId,
            condition: !!contentId,
        },
    });

    const classes = concat("zest-tooltip-trigger", className);

    function handleMouseEnter(): void {
        open();
        toggleIsMouseInside();
        timeout && timeout.clear();
    }

    function handleMouseLeave(): void {
        if (ref.current && timeout) {
            toggleIsMouseInside();
            timeout.start();
        }
    }

    return (
        <div
            {...triggerProps}
            {...rest}
            {...conditionalProps}
            className={classes}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref={ref as any}
        >
            {children}
        </div>
    );
};

export { TooltipTrigger };
