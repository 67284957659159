import { FC, HTMLAttributes, ReactNode } from "react";
import {
    InputErrorProps,
    InputHelperProps,
    Legend,
} from "@Components/formComponents";
import { DataTypes, HTMLSpanAttributes } from "../../../types/element";
import { useRadioContext } from "./context";

export type RadioLegendProps = HTMLAttributes<HTMLLegendElement> &
    DataTypes & {
        error?: boolean;
        errorMessage?: string | string[];
        errorProps?: InputErrorProps;
        helperProps?: InputHelperProps;
        helperText?: ReactNode;
        hideError?: boolean;
        hideLabel?: boolean;
        optional?: boolean;
        optionalProps?: HTMLSpanAttributes;
        required?: boolean;
        requiredProps?: HTMLSpanAttributes;
    };

/**
 * A legend component for communicating the purpose of the radio buttons.
 *
 * It is not recommended to use this component directly.
 *
 * Instead use `<Radio.Group>` to enable that functionality.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-inputs-radio--radio)}
 */
const RadioLegend: FC<RadioLegendProps> = ({
    children,
    errorProps = {},
    hideLabel,
    optional: controlledOptional,
    required: controlledRequired,
    ...rest
}) => {
    const { optional: inheritedOptional, required: inheritedRequired } =
        useRadioContext();
    const required = controlledRequired ?? inheritedRequired;
    const optional = controlledOptional ?? inheritedOptional;

    return (
        <Legend
            {...rest}
            {...errorProps}
            hideLabel={hideLabel}
            required={required}
            optional={optional}
        >
            {children}
        </Legend>
    );
};

export { RadioLegend };
