/** Generates a unique id intended for DOM elements, NOT to be used for security. */
export const uuid = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/** Filters out falsey classNames and returns them joined together with a space
 * @example
 * concat("class-one", "") // Output "class-one"
 * concat("class-one", undefined) // Output "class-one"
 * concat("class-one", true && "class-two") // Output "class-one class-two"
 * concat("class-one", false && "class-two") // Output "class-one"
 */
export const concat = (...args: (string | undefined | false)[]): string => {
  const classNames = concatStrings(...args);
  // if args contains , throw an error
  if (classNames.includes(",")) {
    console.error(
      "concat() should not include commas in the output, because it would invalidate any tailwind classNames. You likely made a mistake adding a comma in one of the strings, and should use a space instead, or should use concatStrings() if you expect your strings to include commas."
    );
  }

  return classNames;
};

/** Filters out falsey strings / values and returns them joined together with a space
 * @example
 * concat("class-one", "") // Output "class-one"
 * concat("class-one", undefined) // Output "class-one"
 * concat("class-one", true && "class-two") // Output "class-one class-two"
 * concat("class-one", false && "class-two") // Output "class-one"
 */
export const concatStrings = (
  ...args: (string | undefined | false)[]
): string => {
  return args.filter(Boolean).join(" ").trim();
};

/**
 * Search an array of strings for a value.
 * @param arr Array to search
 * @param value value to find
 * @returns true if arr contains value
 */
export const contains = (arr: string[], value: string): boolean => {
  return arr.indexOf(value) > -1;
};

export const currencyFormat = (price: number): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return price >= 0 ? formatter.format(price) : `(${formatter.format(-price)})`;
};

// Month, d, YYYY
export const dateFormat = (date: Date): string => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const obj = new Date(date);
  return `${months[obj.getMonth()]} ${obj.getDate()}, ${obj.getFullYear()}`;
};

//MM/DD/YYYY
export const dateTimeFormat = (date: Date): string => {
  const formattedDate = date.toLocaleString([], {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return formattedDate;
};

// m/d/YYYY
export const shortDateFormat = (date: string): string => {
  const obj = new Date(date);
  if (
    isNaN(obj.getMonth()) ||
    isNaN(obj.getDate()) ||
    isNaN(obj.getFullYear())
  ) {
    return "";
  }
  return `${obj.getMonth() + 1}/${obj.getDate()}/${obj.getFullYear()}`;
};

// YYYY-m-d
export const shortDateFormatWithDash = (date: string): string => {
  const obj = new Date(date);
  if (
    isNaN(obj.getMonth()) ||
    isNaN(obj.getDate()) ||
    isNaN(obj.getFullYear())
  ) {
    return "";
  }
  return `${obj.getFullYear()}-${obj.getMonth() + 1}-${obj.getDate()}`;
};

// Month, d
export function deliveryDateFormat(date: string): string {
  // UTC is needed in tests to avoid timezone issues with toLocaleDateString.
  // This occurs when the input is in an ISO format such as "2021-06-20T00:00:00.000Z" or "2021-06-20".
  const timeZone = process.env.NODE_ENV === "test" ? "UTC" : undefined;
  return new Date(date).toLocaleDateString("en-US", {
    timeZone: timeZone,
    month: "long",
    day: "numeric",
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumberValidator = (str: any): void => {
  if (str.trim().length < 1 || isNaN(str.trim())) {
    throw new Error("Quantity must be a number.");
  }
};

export const getOnlyNumbers = (text: string): number => {
  return +text.replace(/\D/g, "");
};

export const phoneNumberFormat = (phoneNumber: string): string => {
  const phoneNumberMatch = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!phoneNumberMatch) {
    return phoneNumber;
  }

  return `(${phoneNumberMatch[1]}) ${phoneNumberMatch[2]}-${phoneNumberMatch[3]}`;
};

export const sanitizedPhoneNumberFormat = (
  phoneNumber: string | undefined
): string => {
  if (!phoneNumber) {
    return "";
  }
  return phoneNumberFormat(phoneNumber);
};

/**
 * Removes all non-numeric characters from a phone number
 * @param input The phone number to normalize
 * @returns The normalized phone number
 */
export function normalizePhoneNumber(input: string): string {
  return input.replace(/[^\d]/g, "");
}

export function appendIgnoreTimeout(url: string): string {
  let char = "?";
  if (url.indexOf("?") !== -1) {
    char = "&";
  }
  return `${url}${char}ignore_timeout=true`;
}

export function createTestId(input: string): string {
  return input.trim().toLowerCase().replaceAll(" ", "-");
}
