import {
    FC,
    HTMLAttributes,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
} from "react";
import { DataTypes } from "@Types/element";
import { useStore } from "@Utilities/hooks/useStoreData";
import { concat } from "@Utilities/string";
import { DropdownMenuContent } from "./dropdownMenu.content";
import { DropdownMenuContext } from "./dropdownMenu.context";
import { DropdownMenuItem } from "./dropdownMenu.item";
import { DropdownMenuTrigger } from "./dropdownMenu.trigger";
import {
    DropdownMenuEventCallbacks,
    DropdownMenuEvents,
    DropdownMenuState,
    useDropdownMenu,
} from "./useDropdownMenu";

export type DropdownMenuProps = HTMLAttributes<HTMLElement> &
    DataTypes & {
        dropdownMenu?: useDropdownMenu;
        onClose?: DropdownMenuEventCallbacks;
        onOpen?: DropdownMenuEventCallbacks;
        theme?: string;
    };

function selectIsOpen(state: DropdownMenuState): boolean {
    return state.isOpen;
}

type ComposedDropdownMenuProps = FC<DropdownMenuProps> & {
    Content: typeof DropdownMenuContent;
    Item: typeof DropdownMenuItem;
    Trigger: typeof DropdownMenuTrigger;
};

/**
 * A menu in which options are hidden by default but can be shown by interacting with a button.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-dropdown-menu--dropdown-menu-story)}
 */
const DropdownMenu: ComposedDropdownMenuProps = ({
    children,
    className,
    dropdownMenu: controlledDropdownMenu,
    onClose,
    onOpen,
    theme = "wss",
    ...rest
}) => {
    const classes = concat("zest-dropdownMenu-wrapper", className, theme);
    const containerRef = useRef<HTMLDivElement>(null);
    const internalDropdownMenu = useDropdownMenu();
    const dropdownMenu = controlledDropdownMenu || internalDropdownMenu;
    const {
        close,
        handleKeyDown,
        open,
        register,
        registerTrigger,
        resetFocus,
        store,
        subscribe,
        toggle,
    } = dropdownMenu;

    const contextValue = useMemo(() => {
        return {
            register,
            registerTrigger,
            store,
            open,
            resetFocus,
            subscribe,
            close,
            toggle,
        };
    }, [
        close,
        open,
        register,
        registerTrigger,
        resetFocus,
        store,
        subscribe,
        toggle,
    ]);
    const isOpen = useStore(store, selectIsOpen);

    const handleBlur = useCallback<(e: FocusEvent) => void>(
        (e: FocusEvent) => {
            const containerElement = containerRef.current;
            const { relatedTarget } = e;

            if (
                isOpen &&
                containerElement &&
                relatedTarget instanceof Node &&
                !containerElement?.contains(relatedTarget)
            ) {
                close();
            }
        },
        [close, isOpen]
    );

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (isOpen && event.target instanceof Node) {
                if (!containerRef.current?.contains(event.target)) {
                    close();
                }
            }
        },
        [close, isOpen]
    );

    useLayoutEffect(() => {
        // Focus the first element whenever isOpen
        if (isOpen) {
            document
                .querySelector<HTMLButtonElement | HTMLLinkElement>(
                    `#${store.get().items[0]}`
                )
                ?.focus();
        }
    }, [isOpen, store]);

    useEffect(() => {
        const containerElement = containerRef.current;
        containerElement?.addEventListener("focusout", handleBlur);

        return () => {
            containerElement?.removeEventListener("focusout", handleBlur);
        };
    }, [handleBlur]);

    // Add the event listener for clicking outside when the DropdownMenu is opened
    useEffect(() => {
        if (isOpen) {
            document.addEventListener("click", handleClickOutside);
        }
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [handleClickOutside, isOpen]);

    // Subscribe to any events
    useEffect(() => {
        const cleanups: (() => void)[] = [];
        if (onOpen) {
            cleanups.push(subscribe(DropdownMenuEvents.OPEN, onOpen));
        }
        if (onClose) {
            cleanups.push(subscribe(DropdownMenuEvents.CLOSE, onClose));
        }
        return (): void => {
            cleanups.forEach((cleanup) => {
                cleanup();
            });
        };
    }, [onClose, onOpen, subscribe]);

    return (
        <DropdownMenuContext.Provider value={contextValue}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
                onKeyDown={handleKeyDown}
                ref={containerRef}
                className={classes}
                {...rest}
            >
                {children}
            </div>
        </DropdownMenuContext.Provider>
    );
};

DropdownMenu.Content = DropdownMenuContent;
DropdownMenu.Item = DropdownMenuItem;
DropdownMenu.Trigger = DropdownMenuTrigger;
DropdownMenu.displayName = "DropdownMenu";

export { DropdownMenu };
