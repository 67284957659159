import { useRef } from "react";
import Button from "components/buttons/Button";
import { ButtonProps } from "components/buttons/Button.types";
import PopoverMenu, { MenuPosition } from "components/PopoverMenu/PopoverMenu";
import { useUuid } from "util/hooks/useUuid";

export type ButtonDropdownProps = ButtonProps & {
  menu: Array<JSX.Element>;
  menuPosition?: MenuPosition;
  menuClassNames?: string;
  wrapperClassNames?: string;
};

export default function MenuButton({
  className,
  hideLabel,
  icon,
  iconOrientation,
  id,
  menu,
  menuPosition = "bottom-left",
  menuClassNames,
  size = "default",
  label,
  labelClassName,
  variant,
  wrapperClassNames,
  ...props
}: ButtonDropdownProps): JSX.Element {
  const menuButtonId = useUuid("menu-button");
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  return (
    <PopoverMenu
      triggerId={id || menuButtonId}
      triggerRef={menuButtonRef}
      menu={menu}
      menuPosition={menuPosition}
      menuClassNames={menuClassNames}
      wrapperClassNames={wrapperClassNames}
    >
      <Button
        id={id}
        innerRef={menuButtonRef} //! note that we must assign ref as innerRef here
        className={className}
        hideLabel={hideLabel}
        iconOrientation={iconOrientation}
        icon={icon}
        variant={variant}
        size={size}
        label={label}
        labelClassName={labelClassName}
        {...props}
      />
    </PopoverMenu>
  );
}
