import { Buffer } from "buffer";
import { useCallback, useMemo } from "react";
import {
  UserClaim,
  UserClaims,
  UserData,
} from "authentication/contexts/UserData.types";
import LocalStorage from "authentication/models/LocalStorage";
import { UseCreateStore, useCreateStore } from "util/store";
import requestLogout from "authentication/api/logout";

// expose the methods from a hook "useUserHook()" returns

export type UseUser = {
  store: UseCreateStore<UserData>;
  // utility methods:
  login: (id: string) => void;
  logout: () => void;
  removeAuthToken: () => void;
  // when subscribing to something, that's when you would return the unsubscribe function () => void
  updateClaims: (newClaims: Partial<UserClaims>) => void;
};

/**
 *  Hook that creates a new memoized piece of User State with manipulation functions ("login", "logout")
 *
 * @return {UseUser} UseUser Hook
 */
const useUserStore = (cleanup: () => void): UseUser => {
  const store = useCreateStore<UserData>({
    authToken: localStorage.getItem(LocalStorage.authToken),
    userClaims: generateClaims(localStorage.getItem(LocalStorage.authToken)),
  });

  const updateClaims = useCallback<UseUser["updateClaims"]>(
    (newClaims: Partial<UserClaims>) => {
      const { authToken, userClaims } = store.get();
      if (authToken && userClaims) {
        store.set({
          userClaims: {
            ...userClaims,
            ...newClaims,
          },
        });
      }
    },
    [store]
  );

  const login = useCallback<UseUser["login"]>(
    (authApiResponse) => {
      localStorage.setItem(LocalStorage.authToken, authApiResponse);
      localStorage.removeItem(LocalStorage.coupon);

      store.set({
        authToken: authApiResponse,
        userClaims: generateClaims(authApiResponse),
      });
    },
    [store]
  );

  const removeAuthToken = useCallback<UseUser["removeAuthToken"]>(() => {
    localStorage.removeItem(LocalStorage.authToken);
  }, []);

  const logout = useCallback<UseUser["logout"]>(() => {
    const authToken = localStorage.getItem(LocalStorage.authToken);
    if (authToken !== null) {
      requestLogout(authToken);
    }
    removeAuthToken();
    localStorage.removeItem(LocalStorage.coupon);
    store.set({ authToken: null, userClaims: null });
    cleanup();
  }, [cleanup, removeAuthToken, store]);

  return useMemo(
    () => ({ store, login, logout, removeAuthToken, updateClaims }),
    [logout, login, store, removeAuthToken, updateClaims]
  );
};

export default useUserStore;

export function generateClaims(authToken: string | null): UserClaims {
  const decodedString = decodeJwt(authToken);
  const obj: UserClaim = {};
  Object.entries(decodedString).forEach((claim) => {
    obj[claim[0]] = claim[1];
  });
  return obj as UserClaims;
}

export function decodeJwt(authToken: string | null): string {
  const data = authToken?.split(".")[1];
  if (data) {
    return JSON.parse(Buffer.from(data, "base64").toString());
  } else {
    return "";
  }
}
