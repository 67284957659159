import { concat } from "@Utilities/string";
import { Icon, IconProps } from "@Components/icon";
import { Link, LinkProps } from ".";

export type PlusLinkProps = {
    iconProps?: Omit<IconProps, "name" | "size">;
    linkProps?: Partial<LinkProps>;
};

/**
 * A clickable icon that navigates users to the Plus Membership page.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-plus-link--plus-link-story)}
 */
const PlusLink = ({
    iconProps = {},
    linkProps = {},
}: PlusLinkProps): JSX.Element => {
    const defaultAriaLabel = "Find out how to save money with Webstaurant Plus";
    const {
        "aria-label": ariaLabel = defaultAriaLabel,
        className: linkClassName,
        href = "/plus/",
        ...restLinkProps
    } = linkProps;
    const {
        className: iconClassName,
        spriteSheetHref,
        style,
        ...restIconProps
    } = iconProps;

    const defaultIconStyles = !style &&
        !iconClassName && { width: "35px", height: "17px" };

    const iconClassNames = concat(iconClassName, "plus-icon");
    const linkClassNames = concat(linkClassName, "plus-link");

    return (
        <Link
            {...restLinkProps}
            className={linkClassNames}
            theme="wss"
            href={href}
            aria-label={ariaLabel}
        >
            <Icon
                {...restIconProps}
                name="plus-icon"
                className={iconClassNames}
                style={defaultIconStyles || {}}
                spriteSheetHref={spriteSheetHref}
            />
        </Link>
    );
};

PlusLink.displayName = "PlusLink";

export { PlusLink };
