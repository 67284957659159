import { useCallback, useMemo, useRef } from "react";
import {
    ReadOnlyUseCreateStore,
    createGlobalStore,
} from "@Utilities/hooks/useStoreData";

export type PopoverState = {
    contents: Set<string>;
    isOpen: Set<string>;
    openId: string;
    triggers: Set<string>;
};

export enum PopoverEvents {
    OPEN = "open",
    CLOSE = "close",
}

export type PopoverEventCallbacks = (contentId: string) => void;

export type UsePopoverMethods = {
    /**
     * Closes a Content
     * Pass an ID to close a specific Content
     * @example
     * const { close } = usePopover();
     * close(); // Will close the first registered content
     * close("my-content-id"); // Will close Content w/ ID
     */
    close: (id?: string) => void;
    /**
     * Registers a Content to Popover
     * Allows the other methods to open / close the content
     * @example
     * const { register } = usePopover();
     * register("my-content-id")
     */
    register: (id: string, type: "contents" | "triggers") => void;
    /**
     * Subscribes a callback to run when the popover opens or closes
     * @example
     * const { subscribe } = usePopover();
     * subscribe(PopoverEvents.OPEN, cb);
     */
    subscribe: <E extends PopoverEvents>(
        event: E,
        cb: PopoverEventCallbacks
    ) => () => void;
    /**
     * Toggle the Content open or closed.
     * Pass an ID to the open a specific Content
     * @example
     * const { toggle } = usePopover();
     * toggle(); // Will toggle the first registered content
     * toggle("my-content-id"); // Will toggle Content w/ ID
     */
    toggle: (id?: string) => void;
};

export type UsePopover = UsePopoverMethods & {
    store: ReadOnlyUseCreateStore<PopoverState>;
};

const store = createGlobalStore<PopoverState>({
    contents: new Set(),
    triggers: new Set(),
    isOpen: new Set(),
    openId: "",
});

const usePopover = (): UsePopover => {
    const subscriptions = useRef<{
        [index in PopoverEvents]: Set<PopoverEventCallbacks>;
    }>({ open: new Set(), close: new Set() });

    const subscribe = useCallback<UsePopoverMethods["subscribe"]>(
        (e, cb) => {
            subscriptions.current[e].add(cb);
            return () => subscriptions.current[e].delete(cb);
        },
        [subscriptions]
    );

    // adds a popover trigger or content to the store
    const register = useCallback<UsePopoverMethods["register"]>((id, type) => {
        const state = store.get();
        // add the id to the correct set in state
        state[type].add(id);

        return store.set(state);
    }, []);

    // opens or closes the popover content
    const toggle = useCallback<UsePopoverMethods["toggle"]>((controlledId) => {
        const { contents, isOpen } = store.get();
        const [internalId] = contents;
        const id = controlledId ?? internalId;

        if (!id) {
            throw new Error(
                `Popover does not contain any <Popover.Content> elements.`
            );
        }
        if (!contents.has(id)) {
            throw new Error(
                `Popover does not contain a <Popover.Content> with id "${id}".`
            );
        }

        if (isOpen.has(id)) {
            isOpen.delete(id);
            store.set({ openId: undefined });
            subscriptions.current.close.forEach((cb) => {
                cb(id);
            });
        } else {
            // close all other open popovers
            isOpen.clear();
            isOpen.add(id);
            store.set({ openId: id });
            subscriptions.current.open.forEach((cb) => {
                cb(id);
            });
        }
        return store.set({ isOpen });
    }, []);

    const close = useCallback<UsePopoverMethods["close"]>((controlledId) => {
        const { contents, isOpen } = store.get();
        const [internalId] = contents;
        const id = controlledId ?? internalId;

        if (!id) {
            throw new Error(
                `Popover does not contain any <Popover.Content> elements.`
            );
        }
        if (!contents.has(id)) {
            throw new Error(
                `Popover does not contain a <Popover.Content> with id "${id}".`
            );
        }

        isOpen.delete(id);

        subscriptions.current.close.forEach((cb) => {
            cb(id);
        });

        return store.set({ isOpen });
    }, []);

    return useMemo<UsePopover>(
        () => ({
            store,
            register,
            toggle,
            close,
            subscribe,
        }),
        [register, toggle, close, subscribe]
    );
};

export { usePopover };
