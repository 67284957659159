import { createContext, useContext } from "react";
import { UseTooltipMethods, UseTooltip } from "./hooks/useTooltip";
import { TooltipTriggerProps } from "./tooltip.trigger";
import { TooltipContentProps } from "./tooltip.content";
type NoneShareableTooltipTriggerProps = "content" | "id";
type NoneShareableTooltipContentProps = "id";

export type TooltipContextValue = {
    close: UseTooltipMethods["close"];
    contentProps?: Omit<TooltipContentProps, NoneShareableTooltipContentProps>;
    open: UseTooltipMethods["open"];
    register: UseTooltipMethods["register"];
    restrictFocus?: boolean;
    store: UseTooltip["store"];
    toggle: UseTooltipMethods["toggle"];
    toggleIsMouseInside: UseTooltipMethods["toggleIsMouseInside"];
    triggerProps?: Omit<TooltipTriggerProps, NoneShareableTooltipTriggerProps>;
};

const TooltipContext = createContext<TooltipContextValue | null>(null);

export { TooltipContext };

const useTooltipContext = (): TooltipContextValue => {
    const context = useContext(TooltipContext);

    if (!context) {
        throw new Error(
            `useTooltipContext is being used outside of a <Tooltip> element.`
        );
    }
    return context;
};

export { useTooltipContext };
