import { Sides } from "@Types/layout";

/** Generates a unique id */
export const uuid = (): string => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        const r = (Math.random() * 16) | 0;
        // eslint-disable-next-line no-bitwise
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

/** Returns an objects key if the value is truthy or returns a truthy value
 * @example
 * classNames({
 *  "class-one": true,
 *  "class-two": false,
 *  "class-three": () => true
 * })
 * // Output
 * ["class-one", "class-three"]
 */
export const classNames = (classes: {
    [index: string]: boolean | undefined | (() => boolean | undefined);
}): string[] => {
    return Object.entries(classes)
        .filter(([, fnOrValue]) =>
            typeof fnOrValue === "function" ? fnOrValue() : fnOrValue
        )
        .map(([className]) => className);
};

/** Filters out falsey strings / values and returns them joined together with a space
 * @example
 * concat("class-one", "") // Output "class-one"
 * concat("class-one", undefined) // Output "class-one"
 * concat("class-one", true && "class-two") // Output "class-one class-two"
 * concat("class-one", false && "class-two") // Output "class-one"
 */
export const concat = (...args: (string | undefined | false)[]): string => {
    return args.filter(Boolean).join(" ").trim();
};

/** Converts a number or sides to tailwind classes
 * @deprecated - Pass a class to override margin or padding
 * @example
 * boxModelToTw("m", { bottom: 1 }) => [ "mb-1" ]
 */
export const boxModelToTw = (
    prefix: string,
    sides?: number | Sides
): string[] => {
    if (typeof sides === "number") {
        return [`${prefix}-${sides}`];
    } else if (sides) {
        return Object.entries(sides).map(
            ([side, value]) => `${prefix}${side.slice(0, 1)}-${value}`
        );
    }
    return [];
};

export const capitalize = (...strings: string[]): string[] => {
    return strings.map(
        (str) => str.substring(0, 1).toUpperCase() + str.substring(1)
    );
};

/** Returns an object containing element properties that are truthy
 * @example
 * const ariaProps = props({
 *  "aria-label": { condition: true, value: "Your Aria Label" },
 *  "aria-required": { condition: () => true, value: true }
 * })
 * // Output
 * { aria-label: "Your Aria Label", aria-required: true }
 * // In Use
 * <div {...ariaProps} />
 */
export const props = (properties: {
    [property: string]: {
        condition: boolean | undefined | (() => boolean | undefined);
        value: string | boolean | undefined;
    };
}): Record<string, string | boolean | undefined> => {
    const props: Record<string, string | boolean | undefined> = {};
    for (const [key, { condition, value }] of Object.entries(properties)) {
        condition && (props[key] = value);
    }

    return props;
};
