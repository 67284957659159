import { Reducer } from "react";
import { FormValues } from "../../types";
import { UpdateAction } from "./types";

const updateAction: Reducer<FormValues, UpdateAction> = (state, action) => {
    const updateValues: FormValues = {};
    Object.keys(action.value).forEach((key) => {
        // Check that a value exists in state
        // All values need to be registered before being set
        if (state[key] === undefined) {
            throw new Error(
                `Form values does not contain "${key}" and cannot be set.`
            );
        }
        updateValues[key] = {
            ...state[key],
            ...action.value[key],
            errors: action.value[key]?.errors ?? state[key].errors,
            touched: action.value[key].touched ?? true,
        };
    });

    return {
        ...state,
        ...updateValues,
    };
};

export default updateAction;
