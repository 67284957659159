import { useRef, useEffect } from "react";

/** A hook for ensuring a values either remains defined 
 * or undefined through the lifecycle of a component.


An example maybe the value prop for an input, if the input is controlled 
( value prop is provided ) it should remain controlled through the lifecycle 
of the input.
*/
const useControlledValue = <T>(value: T): void => {
    const isControlled = useRef(value !== undefined);
    useEffect(() => {
        if (isControlled.current && value === undefined) {
            console.error(
                "%cZest Error:\n",
                "background-color: red; color: yellow; font-size: xsmall",
                "A component is changing a controlled input to be uncontrolled. This is likely caused by the value changing from undefined to a defined value, which should not happen. Decide between using a controlled or uncontrolled input element for the lifetime of the component."
            );
        } else if (!isControlled.current && value !== undefined) {
            console.error(
                "%cZest Error:\n",
                "background-color: red; color: yellow; font-size: xsmall",
                "A component is changing an uncontrolled input to be controlled. This is likely caused by the value changing from undefined to a defined value, which should not happen. Decide between using a controlled or uncontrolled input element for the lifetime of the component."
            );
        }
    }, [value]);
};

export { useControlledValue };
