import { useCallback, useMemo, useRef } from "react";
import {
    ReadOnlyUseCreateStore,
    useCreateStore,
} from "@Utilities/hooks/useStoreData";

export type TooltipState = {
    contents: Set<string>;
    isMouseInside: Set<string>;
    isOpen: Set<string>;
    openId: string;
    triggers: Set<string>;
};

export enum TooltipEvents {
    OPEN = "open",
    CLOSE = "close",
}

export type TooltipEventCallbacks = (contentId: string) => void;

export type UseTooltipMethods = {
    /**
     * Closes a Content
     * Pass an ID to close a specific Content
     * @example
     * const { close } = useTooltip();
     * close(); // Will close the first registered content
     * close("my-content-id"); // Will close Content w/ ID
     */
    close: (id?: string) => void;
    /**
     * Opens a Content
     * Pass an ID to open a specific Content
     * @example
     * const { open } = useTooltip();
     * open(); // Will open the first registered content
     * open("my-content-id"); // Will open Content w/ ID
     *
     */
    open: (id?: string) => void;
    /**
     * Registers a Content to Tooltip
     * Allows the other methods to open / close the content
     * @example
     * const { register } = useTooltip();
     * register("my-content-id")
     */
    register: (id: string, type: "contents" | "triggers") => void;
    /**
     * Subscribes a callback to run when the tooltip opens or closes
     * @example
     * const { subscribe } = useTooltip();
     * subscribe(TooltipEvents.OPEN, cb);
     */
    subscribe: <E extends TooltipEvents>(
        event: E,
        cb: TooltipEventCallbacks
    ) => () => void;
    /**
     * Toggle the Content open or closed.
     * Pass an ID to the open a specific Content
     * @example
     * const { toggle } = useTooltip();
     * toggle(); // Will toggle the first registered content
     * toggle("my-content-id"); // Will toggle Content w/ ID
     */
    toggle: (id?: string) => void;

    /**
     * Toggles isMouseInside
     * @example
     * const { toggleIsMouseInside } = useTooltip();
     * toggleIsMouseInside(); // Will toggle isMouseInside
     */
    toggleIsMouseInside: (id?: string) => void;
};

export type UseTooltip = UseTooltipMethods & {
    store: ReadOnlyUseCreateStore<TooltipState>;
};

const useTooltip = (): UseTooltip => {
    const store = useCreateStore<TooltipState>({
        contents: new Set(),
        triggers: new Set(),
        isOpen: new Set(),
        isMouseInside: new Set(),
        openId: "",
    });

    const subscriptions = useRef<{
        [index in TooltipEvents]: Set<TooltipEventCallbacks>;
    }>({ open: new Set(), close: new Set() });

    const subscribe = useCallback<UseTooltipMethods["subscribe"]>(
        (e, cb) => {
            subscriptions.current[e].add(cb);
            return () => subscriptions.current[e].delete(cb);
        },
        [subscriptions]
    );

    // adds a tooltip trigger or content to the store
    const register = useCallback<UseTooltipMethods["register"]>(
        (id, type) => {
            const state = store.get();
            // add the id to the correct set in state
            state[type].add(id);

            return store.set(state);
        },
        [store]
    );

    // opens or closes the tooltip content
    const toggle = useCallback<UseTooltipMethods["toggle"]>(
        (controlledId) => {
            const { contents, isOpen } = store.get();
            const [internalId] = contents;
            const id = controlledId ?? internalId;

            if (!id) {
                throw new Error(
                    `Tooltip does not contain any <Tooltip.Content> elements.`
                );
            }
            if (!contents.has(id)) {
                throw new Error(
                    `Tooltip does not contain a <Tooltip.Content> with id "${id}".`
                );
            }

            if (isOpen.has(id)) {
                isOpen.delete(id);
                store.set({ openId: "" });
                subscriptions.current.close.forEach((cb) => {
                    cb(id);
                });
            } else {
                // close all other open tooltips
                isOpen.clear();
                isOpen.add(id);
                store.set({ openId: id });
                subscriptions.current.open.forEach((cb) => {
                    cb(id);
                });
            }
            return store.set({ isOpen });
        },
        [store]
    );

    // toggles isMouseInside
    const toggleIsMouseInside = useCallback<
        UseTooltipMethods["toggleIsMouseInside"]
    >(
        (controlledId) => {
            const { contents, isMouseInside } = store.get();
            const [internalId] = contents;
            const id = controlledId ?? internalId;

            if (!id) {
                throw new Error(
                    `Tooltip does not contain any <Tooltip.Content> elements.`
                );
            }
            if (!contents.has(id)) {
                throw new Error(
                    `Tooltip does not contain a <Tooltip.Content> with id "${id}".`
                );
            }

            if (isMouseInside.has(id)) {
                isMouseInside.delete(id);
            } else {
                isMouseInside.add(id);
            }
            return store.set({ isMouseInside });
        },
        [store]
    );

    const close = useCallback<UseTooltipMethods["close"]>(
        (controlledId) => {
            const { contents, isOpen } = store.get();
            const [internalId] = contents;
            const id = controlledId ?? internalId;

            if (!id) {
                throw new Error(
                    `Tooltip does not contain any <Tooltip.Content> elements.`
                );
            }
            if (!contents.has(id)) {
                throw new Error(
                    `Tooltip does not contain a <Tooltip.Content> with id "${id}".`
                );
            }

            isOpen.delete(id);
            store.set({ openId: "" });
            subscriptions.current.close.forEach((cb) => {
                cb(id);
            });

            return store.set({ isOpen });
        },
        [store]
    );

    const open = useCallback<UseTooltipMethods["open"]>(
        (controlledId) => {
            const { contents, isOpen } = store.get();
            const [internalId] = contents;
            const id = controlledId ?? internalId;

            if (!id) {
                throw new Error(
                    `Tooltip does not contain any <Tooltip.Content> elements.`
                );
            }
            if (!contents.has(id)) {
                throw new Error(
                    `Tooltip does not contain a <Tooltip.Content> with id "${id}".`
                );
            }

            isOpen.add(id);
            store.set({ openId: id });

            subscriptions.current.open.forEach((cb) => {
                cb(id);
            });

            return store.set({ isOpen });
        },
        [store]
    );
    return useMemo<UseTooltip>(
        () => ({
            store,
            register,
            toggle,
            close,
            open,
            subscribe,
            toggleIsMouseInside,
        }),
        [store, register, toggle, close, subscribe, toggleIsMouseInside, open]
    );
};

export { useTooltip };
