import { RefObject, useCallback, useEffect, useRef } from "react";

/** All elements that can only be checked by the focus out event */
const specialFocusElements: string[] = ["VIDEO", "IFRAME"];

/** This function filters out all elements that can not be focused */
const treeWalkerFilter = (node: Node, root: HTMLElement): number => {
    /** Skip all non HTML Elements */
    if (!(node instanceof HTMLElement)) {
        return NodeFilter.FILTER_SKIP;
    }

    const { display, visibility } = getComputedStyle(node);
    if (display === "hidden" || visibility === "hidden") {
        return NodeFilter.FILTER_SKIP;
    }

    /** Inputs need additional checks due to type and other attributes */
    if (
        node instanceof HTMLInputElement ||
        node instanceof HTMLTextAreaElement ||
        node instanceof HTMLSelectElement
    ) {
        if (node.disabled || node.hidden || node.type === "hidden") {
            return NodeFilter.FILTER_SKIP;
        }
    }

    /** Videos w/ controls have a negative tab index so we include them here */
    if (node instanceof HTMLVideoElement) {
        if (node.controls) {
            return NodeFilter.FILTER_ACCEPT;
        }
    }

    let parent = node.parentElement;
    /**
     * Check to ensure the none of the elements parents are hidden
     * This is important for nested popovers
     */
    while (parent !== root) {
        if (!(parent instanceof HTMLElement)) {
            return NodeFilter.FILTER_SKIP;
        }
        const { display: parentDisplay, visibility: parentVisibility } =
            getComputedStyle(parent);
        if (parentDisplay === "none" || parentVisibility === "hidden") {
            return NodeFilter.FILTER_SKIP;
        }
        parent = parent.parentElement;
    }

    return node.tabIndex >= 0
        ? NodeFilter.FILTER_ACCEPT
        : NodeFilter.FILTER_SKIP;
};

type UseTabTrapProps = {
    contentRef: RefObject<HTMLElement>;
};

type UseTabTrap = {
    beginTrap: (originalTarget?: Element | null) => void;
    initTrap: (originalTarget?: Element | null) => void;
    releaseTrap: () => void;
    resetFocus: () => void;
};

/**
 * This hooks allows you to trap focus within some content
 * It will move focus between the first and last elements.
 * @example
 * const [isOpen, setIsOpen] = useState<boolean>(false)
 * const ref = useRef<HTMLDivElement>(null);
 * const { beginTrap, releaseTrap } = useTabTrap({ contentRef: ref });
 *
 * useEffect(() => {
 *  if (isOpen) {
 *    beginTrap();
 *  } else {
 *    releaseTrap();
 *  }
 * }, [isOpen, beginTrap, releaseTrap])
 */
const useTabTrap = ({ contentRef }: UseTabTrapProps): UseTabTrap => {
    const firstFocusableElement = useRef<HTMLElement>();
    const lastFocusableElement = useRef<HTMLElement | undefined>();
    const originalFocus = useRef<HTMLElement>();
    const isFocusWithin = useRef<boolean>(false);
    const isTrapping = useRef<boolean>(false);

    const setFirstAndLastFocusableElements = useCallback<
        (root: HTMLElement) => void
    >((root) => {
        const nodes: HTMLElement[] = [];
        const treeWalker = document.createTreeWalker(
            root,
            NodeFilter.SHOW_ELEMENT,
            (node) => treeWalkerFilter(node, root)
        );

        while (treeWalker.nextNode()) {
            const node = treeWalker.currentNode;
            if (node instanceof HTMLElement) {
                nodes.push(node);
            }
        }

        firstFocusableElement.current = nodes[0];
        lastFocusableElement.current = nodes.pop();
    }, []);

    /**
     * Handles moving focus back into the content via focus out event
     * Some elements, like <fieldset>, act as a single control which manages its own focus but
     * the children all are considered focusable.
     * When we query for the last focusable element, the last child of the control is returned.
     * However, focus will leave the control after one tab press, regardless if the last child is focused.
     * This event checks if the focus has left the wrapper and will reset it accordingly.
     */
    const handleFocusOut = useCallback<(e: FocusEvent) => void>(
        (e: FocusEvent): void => {
            const content = contentRef.current;
            const nextFocus = e.relatedTarget;
            if (nextFocus && nextFocus instanceof HTMLElement) {
                if (!contentRef.current?.contains(nextFocus)) {
                    const index = content?.compareDocumentPosition(nextFocus);
                    // Determine if the next focus element is before or after the wrapper
                    if (index === Node.DOCUMENT_POSITION_PRECEDING) {
                        lastFocusableElement.current?.focus();
                    } else {
                        firstFocusableElement.current?.focus();
                    }
                }
            }
        },
        [contentRef]
    );

    const handleTab = (e: KeyboardEvent): void => {
        const activeElement = document.activeElement;
        if (activeElement) {
            /** We will let the focusout event handle updating focus for specialFocusElements */
            if (specialFocusElements.includes(activeElement.nodeName)) {
                return;
            }
        }

        if (e.key !== "Tab") {
            return;
        }

        const firstElement = firstFocusableElement.current;
        const lastElement = lastFocusableElement.current || firstElement;

        if (!firstElement || !lastElement) {
            return;
        }

        if (e.shiftKey) {
            if (activeElement === firstElement) {
                lastElement.focus();
                e.preventDefault();
            }
        } else {
            if (activeElement === lastElement) {
                firstElement.focus();
                e.preventDefault();
            }
        }
    };

    /**
     * Watches when the focus leaves an element to see if it is still within the content
     * If the focus has moved outside of the content ( due to calling initTrap instead of beginTrap )
     * then we do not want to reset focus if the trap is released.
     * An example is if you open a popover, tab to another popover, and then open the second popover.
     * The first popover will close but should not return focus to the trigger element.
     */
    const handleWatchTab = useCallback<(e: FocusEvent) => void>(
        (e) => {
            const target = e.relatedTarget;
            if (contentRef.current && target instanceof HTMLElement) {
                isFocusWithin.current = contentRef.current.contains(target);
            }
        },
        [contentRef]
    );

    const handleEscape = useCallback<(e: KeyboardEvent) => void>(
        (e) => {
            if (e.key === "Escape") {
                document.removeEventListener("keydown", handleTab);
                document.removeEventListener("keydown", handleEscape);
                document.removeEventListener("focusout", handleWatchTab);
                document.removeEventListener("focusout", handleFocusOut);
                isTrapping.current = false;
                if (isFocusWithin.current) {
                    originalFocus.current?.focus();
                    isFocusWithin.current = false;
                }
            }
        },
        [handleFocusOut, handleWatchTab]
    );

    const initTrap = useCallback<UseTabTrap["initTrap"]>(
        (originalElement) => {
            if (originalElement instanceof HTMLElement) {
                originalFocus.current = originalElement;
                document.addEventListener("focusout", handleWatchTab);
            } else if (document.activeElement instanceof HTMLElement) {
                originalFocus.current = document.activeElement;
                document.addEventListener("focusout", handleWatchTab);
            } else {
                throw new Error(
                    "useTabTrap was initialized with an active element that is not a HTMLElement"
                );
            }
        },
        [handleWatchTab]
    );

    /** Starts locking the user's tab to the focusable elements in the content */
    const beginTrap = useCallback<UseTabTrap["beginTrap"]>(
        (originalElement) => {
            if (originalElement instanceof HTMLElement) {
                originalFocus.current = originalElement;
            } else if (document.activeElement instanceof HTMLElement) {
                originalFocus.current = document.activeElement;
            } else {
                throw new Error(
                    "useTabTrap was started with an active element that is not a HTMLElement"
                );
            }
            if (contentRef.current) {
                setFirstAndLastFocusableElements(contentRef.current);
                firstFocusableElement.current?.focus();
                document.addEventListener("keydown", handleTab);
                document.addEventListener("keydown", handleEscape);
                document.addEventListener("focusout", handleWatchTab);
                document.addEventListener("focusout", handleFocusOut);
                isTrapping.current = true;
                if (firstFocusableElement.current) {
                    isFocusWithin.current = true;
                }
            } else {
                throw new Error(
                    `contentRef has not been set for the useTabTrap hook`
                );
            }
        },
        [
            contentRef,
            handleEscape,
            handleFocusOut,
            handleWatchTab,
            setFirstAndLastFocusableElements,
        ]
    );

    /** Returns the focus to the ordinal element and removes event listener */
    const releaseTrap = useCallback<UseTabTrap["releaseTrap"]>(() => {
        document.removeEventListener("keydown", handleTab);
        document.removeEventListener("keydown", handleEscape);
        document.removeEventListener("focusout", handleWatchTab);
        document.removeEventListener("focusout", handleFocusOut);
        isTrapping.current = false;
        if (isFocusWithin.current) {
            originalFocus.current?.focus();
            isFocusWithin.current = false;
        }
    }, [handleEscape, handleFocusOut, handleWatchTab]);

    const resetFocus = useCallback<UseTabTrap["resetFocus"]>(() => {
        document.removeEventListener("focusout", handleWatchTab);
        if (originalFocus.current) {
            originalFocus.current.focus();
            isFocusWithin.current = false;
        }
    }, [handleWatchTab]);

    useEffect(() => {
        /**
         * We observe any changes to the wrapper then re-determine which
         * elements are the first / last focusable elements.
         * This is more performant than checking on each tab key.
         */
        const observer: MutationObserver = new MutationObserver(() => {
            /**
             * We only want to re-evaluate the focusable elements
             * when a mutation occurs and the trap is active
             */
            if (contentRef.current && isTrapping.current) {
                setFirstAndLastFocusableElements(contentRef.current);
            }
        });

        if (contentRef.current) {
            observer.observe(contentRef.current, {
                subtree: true,
                childList: true,
            });
        }

        /**
         * We still remove the event listener when the component unmounts.
         * In case the component is removed without calling release trap.
         */
        return function useTabTrapCleanup(): void {
            document.removeEventListener("keydown", handleTab);
            document.removeEventListener("keydown", handleEscape);
            document.removeEventListener("focusout", handleFocusOut);
            observer.disconnect();
        };
    }, [
        contentRef,
        handleEscape,
        handleFocusOut,
        setFirstAndLastFocusableElements,
    ]);

    return { initTrap, beginTrap, releaseTrap, resetFocus };
};

export { useTabTrap };
