import { createContext, useContext } from "react";
import { UsePopoverMethods, UsePopover } from "./hooks/usePopover";
import { PopoverTriggerProps } from "./popover.trigger";
import { PopoverContentProps } from "./popover.content";
type NoneShareablePopoverTriggerProps = "content" | "id";
type NoneShareablePopoverContentProps = "id";

export type PopoverContextValue = {
    close: UsePopoverMethods["close"];
    contentProps?: Omit<PopoverContentProps, NoneShareablePopoverContentProps>;
    register: UsePopoverMethods["register"];
    restrictFocus?: boolean;
    store: UsePopover["store"];
    toggle: UsePopoverMethods["toggle"];
    triggerProps?: Omit<PopoverTriggerProps, NoneShareablePopoverTriggerProps>;
};

const PopoverContext = createContext<PopoverContextValue | null>(null);

export { PopoverContext };

const usePopoverContext = (): PopoverContextValue => {
    const context = useContext(PopoverContext);

    if (!context) {
        throw new Error(
            `usePopoverContext is being used outside of a <Popover> element.`
        );
    }

    return context;
};

export { usePopoverContext };
