import { RefreshIcon } from "@heroicons/react/outline";
import { concat } from "util/string";

export default function LoadingSpinner({
  className,
  wrapperClassName,
  ...rest
}: {
  className?: string;
  wrapperClassName?: string;
}): JSX.Element {
  return (
    <div className={wrapperClassName}>
      <RefreshIcon
        className={concat("animate-spin -scale-100 text-gray-400", className)}
        {...rest}
      />
    </div>
  );
}
