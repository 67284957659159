import { useEffect } from "react";
import { useNavigate } from "react-router";
import routes, { AppRoute } from "routes/routes";
import { useInspectMode } from "util/hooks/useInspectMode";

export default function InspectModeRedirect({
  children,
  route,
}: {
  readonly children: React.ReactNode;
  readonly route: AppRoute;
}): React.ReactElement {
  const isInspectMode = useInspectMode();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      isInspectMode &&
      !route.path.startsWith(routes.PunchoutLogin.path) &&
      !route.path.startsWith(routes.Cart.path) &&
      !route.path.startsWith(routes.CartReview.path)
    ) {
      navigate(`${routes.Cart.path}?ignore-timeout=true`);
    }
  }, [isInspectMode, route.path, navigate]);

  return <>{children}</>;
}
