import { useRef } from "react";
import useOnLocationChange from "util/hooks/useOnLocationChange";
import { useOnOutsideClick } from "util/hooks/useOnOutsideClick";
import { useKeyListener } from "util/hooks/useKeyListener";
import Button from "components/buttons/Button";
import { ButtonProps } from "components/buttons/Button.types";

export default function Popover({
  buttonProps,
  children,
  componentClassName = "",
  showPopOver,
  setShowPopOver,
  triggerRef,
  handleCloseAndRefocus,
}: Readonly<{
  buttonProps: ButtonProps;
  children: JSX.Element;
  componentClassName?: string;
  showPopOver: boolean;
  setShowPopOver: (value: boolean) => void;
  triggerRef: React.RefObject<HTMLButtonElement>;
  handleCloseAndRefocus: () => void;
}>): JSX.Element {
  const popoverRef = useRef<HTMLDivElement>(null);

  useOnLocationChange((): void => setShowPopOver(false));

  useOnOutsideClick(triggerRef, popoverRef, (): void => {
    if (showPopOver) {
      handleCloseAndRefocus();
    }
  });

  useKeyListener("Escape", "keyup", () => {
    // close and reset focus to this trigger:
    if (showPopOver) {
      handleCloseAndRefocus();
    }
  });

  return (
    <div className={componentClassName}>
      <Button
        innerRef={triggerRef}
        {...buttonProps}
        onClick={(): void => setShowPopOver(!showPopOver)}
      />
      <div ref={popoverRef} hidden={!showPopOver}>
        {children}
      </div>
    </div>
  );
}
