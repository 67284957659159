import { FC, HTMLAttributes, useContext } from "react";
import { concat } from "@Utilities/string";
import { ListContext } from "./context";
import ListItem, { ListItemProps } from "./list.item";

export type ListProps = HTMLAttributes<HTMLElement> & {
    listItemProps?: ListItemProps;
    theme?: string;
};

type ComposedListProps = FC<ListProps> & {
    /** @see {@link Item} */
    Item: typeof ListItem;
};

/**
 * A list component used to display groupings of related content.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/experimental-alpha-list--item)}
 */
const List: ComposedListProps = ({
    children,
    className,
    listItemProps = {},
    theme = "tailwind",
    ...rest
}) => {
    const { listStyle = "bullet" } = listItemProps;
    const Element = listStyle === "number" ? "ol" : "ul";
    const listContext = useContext(ListContext) || {};
    const classes = concat(theme, className, "zest-list", "col-span-2");

    return (
        <ListContext.Provider
            value={{
                ...listContext,
                ...listItemProps,
            }}
        >
            <Element {...rest} className={classes}>
                {children}
            </Element>
        </ListContext.Provider>
    );
};

List.Item = ListItem;

export { List };
