import { FC, ReactNode } from "react";
import { concat } from "@Utilities/string";
import { Button, ButtonProps } from "..";

export type ModalFooterProps = {
    className?: string;
    primaryButtonProps?: Partial<ButtonProps>;
    primaryButtonText: ReactNode;
    secondaryButtonProps?: Partial<ButtonProps>;
    secondaryButtonText?: ReactNode;
    theme?: string;
};

const ModalFooter: FC<ModalFooterProps> = ({
    className,
    primaryButtonProps,
    primaryButtonText,
    secondaryButtonProps,
    secondaryButtonText,
    theme,
}) => {
    return (
        <footer className={concat("zest-modal-footer", className, theme)}>
            {secondaryButtonText && (
                <Button
                    variant="white"
                    {...secondaryButtonProps}
                    className={concat(
                        secondaryButtonProps?.className,
                        "zest-modal-footer-button"
                    )}
                >
                    {secondaryButtonText}
                </Button>
            )}
            <Button
                variant="green"
                {...primaryButtonProps}
                className={concat(
                    primaryButtonProps?.className,
                    "zest-modal-footer-button"
                )}
            >
                {primaryButtonText}
            </Button>
        </footer>
    );
};

export { ModalFooter };
