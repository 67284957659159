import {
  Form as ZestForm,
  FormItem as ZestFormItem,
  Textarea as ZestTextArea,
  TextInput as ZestTextInput,
  useForm,
} from "@clarkinc/zest-design-system";
import { FormValueMap } from "@clarkinc/zest-design-system/dist/components/formComponents/form/types";
import { useState } from "react";
import { emailAccountSpecialist } from "components/Header/components/utils/supportUtils";
import Button from "components/buttons/Button";
import useToast from "contexts/toast/ToastContext";
import { concat } from "util/string";
import { showErrorToasts } from "components/Toast/utils";

export default function SupportForm({
  onFinish,
}: {
  onFinish: () => void;
}): JSX.Element {
  type EmailAccountSpecialistForm = {
    subject: string;
    itemNumber: string;
    message: string;
  };
  const [submitting, setSubmitting] = useState(false);
  const form = useForm<EmailAccountSpecialistForm>();
  const message = form.getFormState()?.message?.value || "";
  const { showToast } = useToast();

  const inputClassNames =
    "mt-1 w-full flex flex-col gap-1 rounded border border-gray-300";
  const inputWrapperClassNames = "w-full ";
  const inputLabelProps = { className: "text-sm font-semibold" };

  return (
    <ZestForm
      form={form}
      name="Support"
      onFinish={async (
        formState: FormValueMap<EmailAccountSpecialistForm>
      ): Promise<void> => {
        try {
          setSubmitting(true);
          const request = {
            message: formState.message.value ?? "",
            subject: formState.subject.value ?? "",
            itemNumber: formState.itemNumber?.value ?? "",
            pageUrl: window.location.href ?? "",
          };
          const result = await emailAccountSpecialist(request);
          if (result.success) {
            onFinish();
            showToast("Your message has been sent.", "success");
            form.reset();
          } else {
            showErrorToasts(showToast, result.errors);
          }
        } finally {
          setSubmitting(false);
        }
      }}
      className="col-span-2 flex flex-col gap-4"
    >
      <ZestFormItem required name="subject">
        <ZestTextInput
          className={inputClassNames}
          label="What can we help with? "
          labelProps={inputLabelProps}
          maxLength={100}
          errorProps={{ className: "text-red-600" }}
          wrapperProps={{ className: inputWrapperClassNames }}
        />
      </ZestFormItem>
      <ZestFormItem name="itemNumber">
        <ZestTextInput
          className={inputClassNames}
          label="Enter item # (if applicable)"
          labelProps={inputLabelProps}
          maxLength={20}
          wrapperProps={{ className: inputWrapperClassNames }}
        />
      </ZestFormItem>
      <ZestFormItem name="message">
        <ZestTextArea
          id="support-message"
          className={inputClassNames}
          label="Message"
          characterCounterProps={{
            className: "text-xs font-medium absolute top-1 right-0",
            characterCount: message.length,
            id: "message-remaining-characters",
            maxLength: 1500,
          }}
          labelProps={{ ...inputLabelProps, htmlFor: "message" }}
          maxLength={1500}
          wrapperProps={{
            className: concat(inputWrapperClassNames, "relative"),
          }}
        />
      </ZestFormItem>
      <Button
        disabled={submitting}
        className="w-fit"
        label="Submit"
        type="submit"
        variant="secondary"
      />
    </ZestForm>
  );
}
