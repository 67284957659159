export function setFaviconUrl(faviconUrl: string): void {
  if (isDefaultFavicon(faviconUrl)) {
    // Forces the favicon to be reloaded. Otherwise
    // the browser will get stuck with the default favicon.
    faviconUrl += "?";
  }

  let link = document.querySelector<HTMLLinkElement>('link[rel="icon"]');

  if (!link) {
    link = document.createElement("link");
    link.id = "favicon";
    link.rel = "icon";
    document.head.appendChild(link);
  }

  link.href = faviconUrl;
}

function isDefaultFavicon(faviconUrl: string): boolean {
  return faviconUrl === "/favicon.ico";
}
