import { StoreEmptyProjectBuilderForm } from "components/ProjectBuilders/Modals/CreateProjectBuilderModal";
import { gatewayApi } from "util/requests/gatewayApi";
import { ErrorMessages } from "util/requests/response.types";

export async function storeEmptyProjectBuilder(
  form: StoreEmptyProjectBuilderForm
): Promise<{ projectBuilderId?: number; errors?: ErrorMessages }> {
  let projectBuilderId: number | undefined;
  let errors: ErrorMessages | undefined;

  try {
    projectBuilderId = await gatewayApi.post<
      StoreEmptyProjectBuilderForm,
      number
    >({
      urlSegment: "project-builders/empty",
      body: form,
      throwErrorCodes: [422],
    });

    if (!projectBuilderId) {
      errors = { store: ["Failed to store empty project builder."] };
    }
  } catch (error) {
    errors = JSON.parse((error as Error).message) as ErrorMessages;
  }

  return {
    projectBuilderId,
    errors,
  };
}
