import { AriaAttributes, FormHTMLAttributes, useEffect, useMemo } from "react";
import { concat, props } from "@Utilities/string";
import {
    FormContext,
    FormContextMethods,
    FormContextMethodsType,
} from "./context";
import { useForm, UseForm } from "./hooks/useForm";
import {
    FormEvents,
    FormEventCallbacks,
    FormValueMap,
    GenericFieldValue,
} from "./types";

export type FormProps<S extends GenericFieldValue = GenericFieldValue> = Omit<
    FormHTMLAttributes<HTMLFormElement>,
    "onSubmit"
> & {
    disableMoveFocusOnSubmit?: boolean;
    form?: UseForm<FormValueMap<S>>;
    name: string;
    onFinish?: FormEventCallbacks<FormValueMap<S>>["finish"];
    onFinishFailed?: FormEventCallbacks<FormValueMap<S>>["finish_failed"];
    onUpdate?: FormEventCallbacks<FormValueMap<S>>["update"];
    theme?: string;
};

/**
 * The Form component is a wrapper used with inputs to collect and submit user information.
 * State management and validation tools are built in and customizable to your needs.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-form--form)}
 */
const Form = <S extends GenericFieldValue>({
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    children,
    className,
    disableMoveFocusOnSubmit,
    form: controlledForm,
    name,
    onFinish,
    onFinishFailed,
    onUpdate,
    ...rest
}: FormProps<S>): JSX.Element => {
    const internalForm = useForm<S>();

    const form: UseForm<FormValueMap<S>> = controlledForm || internalForm;

    const formProviderMethods = useMemo(
        () => ({
            register: form.register,
            onChange: form.setValue,
            getErrors: form.getErrors,
            getAlerts: form.getAlerts,
            setFormMeta: form.setFormMeta,
            setFieldMeta: form.setFieldMeta,
            subscribe: form.subscribe,
            unsubscribe: form.unsubscribe,
            unregister: form.unregister,
        }),
        [form]
    );
    useEffect(() => {
        const cleanups: ((state: FormValueMap<S>) => void)[] = [];
        if (onFinish) {
            cleanups.push(form.subscribe(FormEvents.FINISH, onFinish));
        }
        if (onFinishFailed) {
            cleanups.push(
                form.subscribe(FormEvents.FINISH_FAILED, onFinishFailed)
            );
        }
        if (onUpdate) {
            cleanups.push(form.subscribe(FormEvents.UPDATE, onUpdate));
        }
        return (): void => {
            cleanups.forEach((cleanup) => {
                cleanup(form.getFormState());
            });
        };
    }, [form, onFinish, onFinishFailed, onUpdate]);

    useEffect(() => {
        form.setFormMeta({ name, disableMoveFocusOnSubmit });
    }, [disableMoveFocusOnSubmit, form, name]);

    useEffect(() => {
        if (ariaLabel && ariaLabelledBy) {
            console.error(
                "%cZest Error:\n",
                "background-color: red; color: yellow; font-size: xsmall",
                "Both ariaLable and ariaLablledby have been passed. Only one or the other should be used to avoid bugs with screen readers."
            );
        }
    });

    const classes = concat(className, "zest-form");

    // Pass ariaLabelledby unless ariaLabel is provided (as this takes higher priority)
    const ariaAttributes: AriaAttributes = props({
        "aria-label": {
            condition: !ariaLabelledBy && !!ariaLabel,
            value: ariaLabel,
        },
        "aria-labelledby": {
            condition: !!ariaLabelledBy,
            value: ariaLabelledBy,
        },
    });

    return (
        <FormContextMethods.Provider
            value={formProviderMethods as FormContextMethodsType}
        >
            <FormContext.Provider
                value={{
                    values: form.getFormState(),
                    formMetaValues: form.getFormMeta(),
                }}
            >
                <form
                    {...rest}
                    name={name}
                    ref={form.ref}
                    onSubmit={form.submit}
                    {...ariaAttributes}
                    className={classes}
                >
                    {children}
                </form>
            </FormContext.Provider>
        </FormContextMethods.Provider>
    );
};

export { Form };
