import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { LayoutAPI } from "layouts/api/layoutAPI.types";
import getUserLayoutData, {
  userLayoutURI,
} from "layouts/api/getUserLayoutData";

export default function useLayoutAPI(): {
  data: LayoutAPI | undefined;
  isLoading: boolean;
  isError: boolean;
  refetch: () => Promise<UseQueryResult>;
} {
  const { data, isLoading, isError, refetch } = useQuery<
    LayoutAPI,
    Error,
    LayoutAPI,
    [string]
  >({
    queryKey: [userLayoutURI], // must be unique to identify this query
    queryFn: getUserLayoutData,
    staleTime: 60000,
  });

  return { data: data, isLoading, isError, refetch };
}
