import { FC } from "react";
import { concat, props } from "@Utilities/string";
import {
    InputProps,
    InputError,
    InputHelper,
    InputLabel,
    InputWrapper,
    InputWrapperProps,
} from "@Components/formComponents";
import { Group } from "./group";
import { RadioLegend } from "./legend";
import { RadioInput } from "./input";

/**
 * We remove name because the name is provided from `<Radio.Group>` via context.
 *
 * We remove `error` and `errorMessage` because they are handled in `<Radio.Group>`.
 */
type RemovedRadioProps = "error" | "errorMessage" | "hideLabel" | "name";

export type RadioProps = Omit<InputProps, RemovedRadioProps> & {
    /** Should the error be shown. */
    error?: boolean;
    /** Props that will be passed to the wrapping element. */
    wrapperProps?: InputWrapperProps;
};

type ComposedRadioProps = FC<RadioProps> & {
    /** @see {@link InputError} */
    Error: typeof InputError;
    /** @see {@link Group} */
    Group: typeof Group;
    /** @see {@link InputHelper} */
    Helper: typeof InputHelper;
    /** @see {@link RadioInput} */
    Input: typeof RadioInput;
    /** @see {@link InputLabel} */
    Label: typeof InputLabel;
    /** @see {@link Legend} */
    Legend: typeof RadioLegend;
    /** @see {@link InputWrapper} */
    Wrapper: typeof InputWrapper;
};

/**
 * A radio input with a built-in label, helper text, and error component.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-radio-input--radio-story)}
 */
const Radio: ComposedRadioProps = ({
    error,
    helperProps = {},
    helperText,
    id,
    label,
    labelProps = {},
    required,
    theme,
    value,
    wrapperProps = {},
    ...rest
}) => {
    const helperId = `radio-group-helper-${id}`;

    const ariaProps = props({
        "aria-describedby": {
            condition: !!helperText,
            value: concat(!!helperText && helperId),
        },
        "aria-required": {
            condition: required,
            value: required,
        },
        "aria-invalid": { condition: error, value: "true" },
    });

    return (
        <InputWrapper
            {...wrapperProps}
            className={concat(
                theme,
                "zest-input-radio-wrapper",
                wrapperProps.className
            )}
        >
            <RadioInput
                {...rest}
                {...ariaProps}
                id={id}
                value={value}
                required={required}
            />
            <div className="zest-input-radio-label-wrapper">
                <InputLabel {...labelProps} htmlFor={id as string}>
                    {label}
                </InputLabel>
                <InputHelper {...helperProps} id={helperId}>
                    {helperText}
                </InputHelper>
            </div>
        </InputWrapper>
    );
};

Radio.Error = InputError;
Radio.Group = Group;
Radio.Helper = InputHelper;
Radio.Input = RadioInput;
Radio.Label = InputLabel;
Radio.Legend = RadioLegend;
Radio.Wrapper = InputWrapper;

Radio.displayName = "Radio";

export { Radio };
