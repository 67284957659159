import { FC, ReactChild, SelectHTMLAttributes } from "react";
import { concat, props } from "@Utilities/string";
import {
    InputProps,
    InputError,
    InputErrorProps,
    InputHelper,
    InputHelperProps,
    InputLabel,
    InputLabelProps,
    InputWrapper,
    placeholderError,
    InputWrapperProps,
} from "@Components/formComponents/utilities";
import { Option } from "./option";
import { SizeLike } from "@Types/element";
import { getResponsiveValues } from "@Utilities/sizing";

export type SelectInputProps = InputProps &
    SelectHTMLAttributes<HTMLSelectElement> & {
        inputSize?: SizeLike<"xlarge">;
    };

export type ComposedSelectProps = SelectInputProps & {
    errorProps?: InputErrorProps;
    helperProps?: Omit<InputHelperProps, "helperText">;
    helperText?: ReactChild;
    label: ReactChild;
    labelProps?: Omit<InputLabelProps, "id" | "label">;
    theme?: string;
    wrapperProps?: InputWrapperProps;
};

type SelectProps = FC<ComposedSelectProps> & {
    /** @see {@link InputError} */
    Error: typeof InputError;
    /** @see {@link InputHelper} */
    Helper: typeof InputHelper;
    /** @see {@link InputLabel} */
    Label: typeof InputLabel;
    /** @see {@link Option} */
    Option: typeof Option;
    /** @see {@link InputWrapper} */
    Wrapper: typeof InputWrapper;
};

/**
 * A select input with a built-in label, helper text, and error component.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-select-input--select-story)}
 */
const Select: SelectProps = ({
    children,
    className,
    element = "div",
    error,
    errorElement,
    errorMessage,
    errorProps = {},
    helperProps = {},
    helperText,
    hideError,
    hideLabel,
    id,
    inputSize = "medium",
    label,
    labelProps = {},
    optional,
    placeholder,
    required,
    theme,
    wrapperProps = {},
    ...rest
}) => {
    const errorId = `select-error-${id}`;
    const helperId = `select-helper-${id}`;
    const isHeadless = theme === "headless";
    const errors = Array.isArray(errorMessage)
        ? errorMessage.join(" ")
        : errorMessage;

    const ariaProps = props({
        "aria-describedby": {
            condition: error || !!helperText,
            value: concat(error && errorId, !!helperText && helperId),
        },
        "aria-label": {
            condition: hideLabel || hideError,
            value: concat(label, hideError && errors),
        },
        "aria-invalid": { condition: error, value: "true" },
        "aria-required": {
            value: required,
            condition: required,
        },
    });

    if (placeholder) placeholderError();
    return (
        <InputWrapper
            data-testid="select-input-wrapper"
            {...wrapperProps}
            element={element}
            theme={theme}
        >
            {!hideLabel && (
                <InputLabel
                    {...labelProps}
                    hidden={hideLabel}
                    htmlFor={id as string}
                    optional={optional}
                    required={required}
                    theme={theme}
                >
                    {label}
                </InputLabel>
            )}
            <InputHelper {...helperProps} id={helperId} theme={theme}>
                {helperText}
            </InputHelper>
            <select
                {...rest}
                {...ariaProps}
                className={concat(
                    "zest-input-select",
                    className,
                    !isHeadless && getResponsiveValues("input", inputSize),
                    !className && isHeadless && "focus:outline-blue-600"
                )}
                id={id}
            >
                {children}
            </select>
            <InputError
                {...errorProps}
                hasError={error && !hideError}
                id={errorId}
                theme={theme}
                errorMessages={errorMessage}
            >
                {errorElement}
            </InputError>
        </InputWrapper>
    );
};

Select.Error = InputError;
Select.Helper = InputHelper;
Select.Label = InputLabel;
Select.Option = Option;
Select.Wrapper = InputWrapper;

Select.displayName = "Select";

export { Select };
