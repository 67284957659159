import { useState } from "react";
import { concatStrings, uuid } from "util/string";

/**
 * useState hook to create and store a unique identifier string used for DOM elements.
 *
 * @param {string} [prefix]
 * @return {*}  {string}
 */
export const useUuid = (prefix?: string): string => {
  const [id] = useState(`${concatStrings(prefix && prefix + "-")}${uuid()}`);

  return id;
};
