/**
 *  Split a string and return a JSX Fragment containing spans to apply bold to the substring matches.
 *
 * @export
 * @param {string} substr
 * @param {string} string
 * @return {*}  {JSX.Element}
 */
export function applyBoldSubstring(
  substr: string,
  string: string
): JSX.Element {
  if (substr.length === 0) {
    return <span>{string}</span>;
  }
  const regex = new RegExp("[\\s]?" + substr + "[\\s]?", "gi");
  const parts = string.split(regex);
  const matches = string.match(regex);
  const elements: React.ReactElement[] = [];
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length > 0) {
      elements.push(<span key={`parts-${i}`}>{parts[i]}</span>);
    }
    if (matches && matches[i]) {
      elements.push(
        <span key={`matches-${i}`} className="font-bold">
          {matches[i]}
        </span>
      );
    }
  }
  return <>{elements}</>;
}
