import { FC, useRef, useCallback, useEffect, useLayoutEffect } from "react";
import { useTabTrap } from "@Utilities/hooks";
import { concat } from "@Utilities/string";
import { Icon, IconProps } from "..";

export type ChipProps = {
    chipType?: string;
    chipValue: string;
    className?: string;
    closeIconProps?: IconProps;
    onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    size?: "small" | "medium";
    theme?: string;
};

/**
 * Chips represent a set of interactive keywords that help label, organize, and categorize objects.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-chip--chip)}
 */
const Chip: FC<ChipProps> = ({
    chipType,
    chipValue,
    className,
    closeIconProps,
    onClose,
    size = "medium",
    theme,
}) => {
    const classes = concat(theme, className, size, "zest-chip");
    const wrapperRef = useRef<HTMLButtonElement>(null);
    const { initTrap, resetFocus } = useTabTrap({ contentRef: wrapperRef });

    const handleFocusIn = useCallback<(e: FocusEvent) => void>(
        (e) => {
            const relatedTarget = e.relatedTarget;
            if (relatedTarget === wrapperRef.current) {
                return;
            }
            if (relatedTarget instanceof HTMLElement) {
                initTrap(relatedTarget);
            }
        },
        [initTrap]
    );

    useLayoutEffect(() => {
        return () => {
            resetFocus();
        };
    }, [resetFocus]);

    useEffect(() => {
        const wrapper = wrapperRef.current;
        if (wrapper) {
            wrapper.addEventListener("focusin", handleFocusIn);
        }
        return function alertTabTrapCleanup(): void {
            if (wrapper) {
                wrapper.removeEventListener("focusin", handleFocusIn);
            }
        };
    }, [handleFocusIn]);

    const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onClose && onClose(event);
    };

    return (
        <button
            className={classes}
            type="button"
            onClick={handleClose}
            data-testid="zest-chip"
            ref={wrapperRef}
        >
            <span className="sr-only">remove filter</span>
            <div>
                {chipType && (
                    <span className="zest-chip-type">{chipType}:</span>
                )}
                <span className="zest-chip-value">{chipValue}</span>
            </div>
            <Icon
                name="ri-close-fill"
                size="2xsmall"
                className={concat("zest-chip-close", closeIconProps?.className)}
                {...closeIconProps}
            />
        </button>
    );
};

Chip.displayName = "Chip";

export { Chip };
