import { FC } from "react";
import { useStore } from "@Utilities/hooks/useStoreData";
import { formatTime, useVideoPlayerContext } from "./utilities";
import { currentTimeSelector, durationSelector } from "./selectors";

const Duration: FC = () => {
    const { store } = useVideoPlayerContext();
    const currentTime = useStore(store, currentTimeSelector);
    const duration = useStore(store, durationSelector);

    return (
        <div className="zest-video-player-duration">
            {formatTime(currentTime ?? 0)} / {formatTime(duration ?? 0)}
        </div>
    );
};

export default Duration;
