import { api } from "util/requests/api";
import { getUrl } from "util/requests/gatewayApi";

/** /logoff Gateway URI
 * @type {string} */
export const logoutURI = getUrl("authentication/logoff");

const secureHeaders = {
  "Cache-Control": "no-cache, no-store",
  Pragma: "no-cache",
  Expires: "0",
};

/**
 *  Log out of the API server.
 *
 * @export
 * @return {*}  {Promise<boolean>} Resolves true if we are successfully logged out.
 */
export default async function logout(authToken: string): Promise<boolean> {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...secureHeaders,
  };

  const resp = await api.post({
    url: logoutURI,
    authToken,
    bodyParams: headers,
  });

  if (resp?.ok) {
    return true;
  } else {
    return false;
  }
}
