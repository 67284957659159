import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";

export default function addNewRelicBrowserAgent(
  environment: "LOC" | "DEV" | "TEST" | "PROD"
): BrowserAgent | undefined {
  switch (environment) {
    case "DEV":
      return addDevBrowserAgent();
    case "TEST":
      return addTestBrowserAgent();
    case "PROD":
      return addProdBrowserAgent();
    default:
      return;
  }
}

function addDevBrowserAgent(): BrowserAgent {
  return new BrowserAgent({
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ["bam.nr-data.net"] },
    }, // NREUM.init
    info: {
      beacon: "bam.nr-data.net",
      errorBeacon: "bam.nr-data.net",
      licenseKey: "8272e8a3d1",
      applicationID: "1134400291",
      sa: 1,
    }, // NREUM.info
    loader_config: {
      accountID: "2003868",
      trustKey: "1806281",
      agentID: "1134400291",
      licenseKey: "8272e8a3d1",
      applicationID: "1134400291",
    }, // NREUM.loader_config
  });
}

function addTestBrowserAgent(): BrowserAgent {
  return new BrowserAgent({
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ["bam.nr-data.net"] },
    }, // NREUM.init
    info: {
      beacon: "bam.nr-data.net",
      errorBeacon: "bam.nr-data.net",
      licenseKey: "2eb84fe54a",
      applicationID: "1134401296",
      sa: 1,
    }, // NREUM.info
    loader_config: {
      accountID: "2003869",
      trustKey: "1806281",
      agentID: "1134401296",
      licenseKey: "2eb84fe54a",
      applicationID: "1134401296",
    }, // NREUM.loader_config
  });
}

function addProdBrowserAgent(): BrowserAgent {
  return new BrowserAgent({
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ["bam.nr-data.net"] },
    }, // NREUM.init
    info: {
      beacon: "bam.nr-data.net",
      errorBeacon: "bam.nr-data.net",
      licenseKey: "763efa3f3b",
      applicationID: "1134401298",
      sa: 1,
    }, // NREUM.info
    loader_config: {
      accountID: "1806281",
      trustKey: "1806281",
      agentID: "1134401298",
      licenseKey: "763efa3f3b",
      applicationID: "1134401298",
    }, // NREUM.loader_config
  });
}
