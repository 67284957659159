import { ImgHTMLAttributes, HTMLAttributes, JSX } from "react";
import { Icon } from "@Components/icon";
import { DataTypes } from "@Types/element";
import { concat, props } from "@Utilities/string";
import { SFSpriteSheetHref } from "@Utilities/index";

export type OverlayIconProps = {
    iconName: string;
    spriteSheetHref?: string;
};

export type ThumbnailProps = Omit<
    ImgHTMLAttributes<HTMLImageElement>,
    "onClick"
> &
    // Accessibility:
    // when href is passed, onClick is not to be passed, aria-label is required and alt is not to be passed
    // when onclick is passed, href is not to be passed, aria-label is required and alt is not to be passed
    // when href or onclick is not passed, alt is required and aria-label is not to be passed
    (| {
              alt?: never;
              "aria-label": string;
              href?: string;
              onClick?: never;
          }
        | {
              alt?: never;
              "aria-label": string;
              href?: never;
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onClick?: (e: React.MouseEvent<any>) => void;
          }
        | {
              alt: string;
              "aria-label"?: never;
              href?: never;
              onClick?: never;
              outOfStock?: never;
              unavailable?: never;
          }
    ) &
    (
        | { outOfStock?: boolean; unavailable?: never }
        | { outOfStock?: never; unavailable?: boolean }
    ) &
    DataTypes & {
        border?: boolean;
        imgWrapperClassName?: string;
        label?: string;
        overlay?: string | React.ReactElement<OverlayIconProps>;
        pdf?: boolean;
        rel?: string;
        selected?: boolean;
        size?: "small" | "medium" | "large";
        src: string;
        target?: string;
        theme?: string;
        wrapperClassName?: string;
        wrapperProps?: DataTypes & HTMLAttributes<HTMLElement>;
    };

/**
 * A smaller, reduced-size version of an image or a video frame used to preview content.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-thumbnail--thumbnail)}
 */
const Thumbnail = ({
    alt,
    border,
    className,
    href,
    imgWrapperClassName,
    label,
    onClick,
    outOfStock,
    overlay,
    pdf = false,
    rel,
    selected,
    size = "medium",
    src,
    target,
    theme = "wss",
    unavailable,
    wrapperClassName,
    wrapperProps,
    ...rest
}: ThumbnailProps): JSX.Element => {
    let Element: "div" | "a" | "button" = "div";
    let overlayElement;
    const interactive = !!onClick || !!href;
    let height, width, altText, overlayText;

    // Set explicit height to prevent layout shift when img is loading
    if (pdf) {
        height = "131px";
        width = "104px";
    } else if (size === "small") {
        height = "56px";
        width = "56px";
    } else if (size === "medium") {
        height = "64px";
        width = "64px";
    } else if (size === "large") {
        height = "80px";
        width = "80px";
    }

    // Set semantic element based on the prop types passed
    if (href || pdf) {
        Element = "a";
    } else if (onClick) {
        Element = "button";
    }

    // if overlay is a string and is more than 4 characters, truncate it and take only the first 4 characters
    if (typeof overlay === "string" && overlay.length > 4) {
        overlayText = overlay.slice(0, 4);
    } else {
        overlayText = overlay;
    }

    // Accessibility:
    // If pdf is true, alt=""
    // For outOfStock and unavailable alt text should read "Out of Stock" and "Unavailable" respectively
    // If overlay is passed, alt should be the overlay text
    if (alt) {
        altText = alt;
    } else if (pdf) {
        altText = "";
    } else if (outOfStock) {
        altText = "Out of Stock";
    } else if (unavailable) {
        altText = "Unavailable";
    } else if (overlay && typeof overlay === "string") {
        altText = overlay;
    }

    if (
        typeof overlay === "object" &&
        ("iconName" in overlay || "spriteSheetHref" in overlay)
    ) {
        const overlayIconProps = overlay as OverlayIconProps;
        overlayElement = (
            <Icon
                name={overlayIconProps.iconName}
                spriteSheetHref={
                    overlayIconProps.spriteSheetHref || SFSpriteSheetHref
                }
            />
        );
    }

    const conditionalWrapperProps = props({
        href: { condition: !!href, value: href },
        rel: { condition: !!href, value: rel || "noopener noreferrer" },
        target: { condition: !!href, value: target || "_blank" },
    });

    const wrapperClasses = concat(
        theme,
        wrapperClassName,
        "zest-thumbnail-wrapper",
        interactive && "interactive"
    );

    const imgWrapperClasses = concat(
        "zest-thumbnail-img-wrapper",
        imgWrapperClassName,
        border && !interactive && "static-border",
        selected && "selected",
        outOfStock && interactive && !pdf && !overlay && "outOfStock",
        unavailable && interactive && !pdf && !overlay && "unavailable",
        overlay && "overlay",
        !pdf && size,
        pdf && "pdf"
    );

    const thumbnailClasses = concat("zest-thumbnail-img", className);

    const strikethroughProps = props({
        className: {
            condition: outOfStock && interactive && !pdf && !overlay,
            value: "strikethrough",
        },
    });

    return (
        <Element
            {...wrapperProps}
            {...conditionalWrapperProps}
            onClick={onClick}
            className={wrapperClasses}
        >
            <div className={imgWrapperClasses}>
                {overlay && (
                    <div className="zest-thumbnail-overlay">
                        {overlayElement || overlayText}
                    </div>
                )}
                <div {...strikethroughProps}>
                    <img
                        {...rest}
                        height={height}
                        width={width}
                        src={src}
                        alt={altText}
                        className={thumbnailClasses}
                    />
                </div>
            </div>
            {label && interactive && (
                <div className={concat("zest-thumbnail-label")}>{label}</div>
            )}
        </Element>
    );
};

Thumbnail.displayName = "Thumbnail";

export { Thumbnail };
