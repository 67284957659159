/**
 * Console warning that should be used in all input types
 * that have the option of displaying a placeholder.
 *
 * @see {@link [Accessability Research](https://tfs.clarkinc.biz/DefaultCollection/Design/_wiki/wikis/Design.wiki/3807/Placeholders-are-Problematic)}
 */
export const placeholderError = (): void => {
    console.error(
        "%cZest Error:\n",
        "background-color: red; color: yellow; font-size: xsmall",
        "Warning: Using a placeholder is strongly discouraged due to accessibility concerns.",
        "Read more here, https://tfs.clarkinc.biz/DefaultCollection/Design/_wiki/wikis/Design.wiki/3807/Placeholders-are-Problematic"
    );
};
