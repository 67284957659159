import { FC, HTMLAttributes } from "react";
import { concat } from "@Utilities/string";
import { DataTypes } from "@Types/element";

export type CharacterCounterProps = HTMLAttributes<HTMLDivElement> &
    DataTypes & {
        characterCount: number;
        id: string;
        maxLength: number;
    };

/**
 * A character counter component that states the number of
 * characters remaining before reaching the maximum allowed length.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-inputs-text-area--text-area)}
 */
const CharacterCounter: FC<CharacterCounterProps> = ({
    characterCount,
    className,
    id,
    maxLength,
}) => {
    const charactersRemaining = maxLength - characterCount;
    return (
        <>
            <div
                aria-hidden="true"
                className={concat(className, "zest-characters-remaining")}
            >
                {characterCount} / {maxLength}
            </div>
            <div
                aria-live="polite"
                aria-atomic="true"
                id={id}
                className="sr-only"
                data-testid="zest-characters-remaining"
            >
                {charactersRemaining} characters remaining
            </div>
        </>
    );
};

export { CharacterCounter };
