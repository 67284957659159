import {
    JSX,
    PropsWithChildren,
    useEffect,
    useLayoutEffect,
    useRef,
} from "react";
import { concat } from "@Utilities/string";
import { HTMLDataAttributes } from "@Types/element";
import {
    useFloating,
    useTabTrap,
    PlacementTypes,
    UseTimeout,
} from "@Utilities/hooks";
import { useStore } from "@Utilities/hooks/useStoreData";
import { useTooltipContext } from "./context";

type ElementRootProps = "id";

export type TooltipContentProps = {
    className?: string;
    element?: "div" | "p" | "span";
    elementProps?: Omit<HTMLDataAttributes, ElementRootProps>;
    id: string;
    offset?: number;
    placement?: PlacementTypes;
    restrictFocus?: boolean;
    timeout?: UseTimeout;
    wrapper?: "div" | "p" | "span";
    wrapperProps?: HTMLDataAttributes;
};

const TooltipContent = ({
    children,
    className,
    element = "div",
    elementProps = {},
    id,
    offset: controlledOffset,
    placement: controlledPlacement,
    restrictFocus: controlledRestrictFocus,
    timeout,
    wrapper = "div",
    wrapperProps = {},
}: PropsWithChildren<TooltipContentProps>): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const caretRef = useRef<HTMLDivElement>(null);
    const {
        contentProps = {},
        register,
        restrictFocus: inheritedRestrictFocus,
        store,
    } = useTooltipContext();

    const Wrapper = wrapper;
    const Element = element;
    const placement = controlledPlacement ?? contentProps.placement;
    const offset = controlledOffset ?? contentProps.offset ?? 4;
    const { beginTrap, initTrap, releaseTrap } = useTabTrap({
        contentRef: ref,
    });

    const {
        adjustedPlacement,
        float,
        setAnchor,
        setCaret,
        setFloating,
        setWrapper,
        unFloat,
        x,
        y,
    } = useFloating({
        placement,
        offset,
        caretOffset: { x: 6, y: 6 },
    });
    const restrictFocus = controlledRestrictFocus ?? inheritedRestrictFocus;
    const isOpen = useStore(store, function selectIsOpenId(state) {
        return state.isOpen.has(id);
    });

    const wrapperClasses = concat(
        isOpen ? "visible" : "invisible",
        wrapperProps.className,
        contentProps.wrapperProps?.className,
        "zest-tooltip-content"
    );

    const classes = concat(
        className,
        contentProps.elementProps?.className,
        "zest-tooltip-body"
    );

    useEffect(() => {
        register(id, "contents");
    }, [id, register]);

    /** Handles enabling / disabling the tab trap */
    useEffect(() => {
        if (isOpen) {
            restrictFocus ? beginTrap() : initTrap();
        } else {
            releaseTrap();
        }
    }, [isOpen, restrictFocus, beginTrap, releaseTrap, initTrap]);

    /** Handles positioning the content before the browser displays the content */
    useLayoutEffect(() => {
        isOpen ? float() : unFloat();
        return function tooltipFloatingCleanup(): void {
            unFloat();
        };
    }, [isOpen, float, unFloat]);

    /** Handles finding the elements needed for the useFloating hook */
    useLayoutEffect(() => {
        if (wrapperRef.current) {
            setFloating(wrapperRef.current);
            let parentWithPosition = wrapperRef.current.parentElement;
            while (parentWithPosition !== document.body) {
                if (parentWithPosition instanceof HTMLElement) {
                    const { position: parentPosition } =
                        getComputedStyle(parentWithPosition);
                    if (parentPosition !== "static") {
                        break;
                    }
                    parentWithPosition = parentWithPosition.parentElement;
                }
            }
            if (parentWithPosition) {
                setWrapper(parentWithPosition);
            }
        }
        const anchor =
            document.querySelector<HTMLElement>(`[data-content="${id}"]`) ||
            document.querySelector(`.zest-tooltip-trigger`);
        if (anchor) {
            setAnchor(anchor);
        }
        if (caretRef.current) {
            setCaret(caretRef.current);
        }
    }, [id, setAnchor, setCaret, setFloating, setWrapper]);

    return (
        <Wrapper
            {...(contentProps.wrapperProps || {})}
            {...wrapperProps}
            id={id}
            ref={wrapperRef}
            className={wrapperClasses}
            style={{ top: `${y}px`, left: `${x}px` }}
            onMouseEnter={(): void => timeout && timeout.clear()}
            onMouseLeave={(): void => timeout && timeout.start()}
        >
            <Element {...elementProps} ref={ref} className={classes}>
                {children}
            </Element>
            <div
                ref={caretRef}
                className={concat(
                    "zest-tooltip-caret",
                    adjustedPlacement.current
                )}
            />
        </Wrapper>
    );
};

export { TooltipContent };
