import { gatewayApi } from "util/requests/gatewayApi";

/** /check-token Gateway URI
 * @type {string} */
export const tokenRefreshURI = "accounts/logins/check-token";

/**
 *  Validate the current session authToken against the Gateway API.
 *
 * @export
 * @param {UserData} userData
 * @return {*}  {Promise<boolean>} True if still valid, False if timed out or invalid.
 */
export default async function validateToken(
  claimExpiration: number,
  localStorageAuthToken: string | null
): Promise<boolean> {
  const dateInSeconds = Date.now() / 1000;

  let isValidToken = true;
  // ignore token expiry in local development:
  const isExpired =
    process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
      ? false
      : claimExpiration < dateInSeconds;

  if (!localStorageAuthToken) {
    return false; // if authToken is gone, we need to log in again regardless of check-token
  }

  // Call the API
  const checkTokenResult = await gatewayApi.get<boolean>({
    urlSegment: tokenRefreshURI,
    keepAlive: true, // this is needed for catalog switching so that the request doesn't get canceled on reload
  });

  // handle the response
  if (checkTokenResult === undefined || checkTokenResult === null) {
    isValidToken = !isExpired;
  } else {
    isValidToken = checkTokenResult && !isExpired;
  }

  return isValidToken;
}
