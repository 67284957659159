import Modal, { toggle } from "components/Modal/Modal";
import Button from "components/buttons/Button";
import useLayoutAPI from "layouts/api/useLayoutAPI";
import { useCatalogSwitch } from "util/hooks/useCatalogSwitch";
export const switchToFullCatalogModalId = "switch-to-full-catalog-modal-id";

export default function SwitchToFullCatalogModal(): JSX.Element {
  const { data: userLayoutData } = useLayoutAPI();
  const { switchCatalog } = useCatalogSwitch();

  return (
    <Content
      switchCatalog={switchCatalog}
      themeCompanyName={userLayoutData?.themeCompanyName}
    />
  );
}

export function Content({
  switchCatalog,
  themeCompanyName,
}: {
  switchCatalog: () => Promise<void>;
  themeCompanyName: string | undefined;
}): JSX.Element {
  const footer = (
    <>
      <Button
        label="Cancel"
        variant="tertiary"
        onClick={(): void => toggle(switchToFullCatalogModalId)}
      />
      <Button label="Switch" variant="primary" onClick={switchCatalog} />
    </>
  );

  const body = (
    <p>
      You are about to switch from the{" "}
      {themeCompanyName ? (
        <span className="font-semibold w-">{themeCompanyName}-approved </span>
      ) : (
        "approved "
      )}
      catalog to the Full Webstaurant/Clark Catalog. Many of the items found on
      the Full Catalog may not be approved.
    </p>
  );

  return (
    <Modal
      id={switchToFullCatalogModalId}
      body={body}
      bodyClassNames="w-full md:w-[75vw] lg:w-[50vw]"
      footer={footer}
      footerClassNames="flex flex-row justify-end space-x-2"
      header="Switch catalog"
      ariaLabel="Switch catalog"
    />
  );
}
