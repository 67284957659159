import { createContext, useContext } from "react";
import { ToastType } from "contexts/toast/Toast.types";

export type ShowToastFunction = (
  content: React.ReactElement | string,
  type?: ToastType
) => void;

export type ToastContextType = {
  showToast: ShowToastFunction;
};

export const ToastContext = createContext<ToastContextType | null>(null);

export default function useToast(): ToastContextType {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error(
      '"useToast" needs to be used within the <ToastProvider> component.'
    );
  }

  return context;
}
