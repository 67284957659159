import { FC, HTMLAttributes, Children, ReactNode } from "react";
import { List, ListItemProps, ListProps } from "@Components/list";
import { concat } from "@Utilities/string";
import { DataTypes } from "../../../types/element";

export type InputErrorProps = HTMLAttributes<HTMLElement> &
    DataTypes & {
        element?: "p" | "span" | "div";
        /**
         * A prop for displaying the form messages in the a nested list.
         *
         * This is the preferred way to show errors within a form.
         */
        errorMessages?: ReactNode;
        hasError?: boolean;
        listItemProps?: ListItemProps;
        listProps?: ListProps;
        theme?: string;
    };

/**
 * A component used to associate an error to an input.
 *
 * It is not recommended to use this component directly.
 * Instead, use the other Zest input components (phone, text, email, checkbox, number, radio, select, textarea).
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-inputs)}
 */
const InputError: FC<InputErrorProps> = ({
    children,
    className,
    element = "div",
    errorMessages,
    hasError,
    listItemProps = {},
    listProps = {},
    ...rest
}) => {
    const Element = element;
    const classes = concat(className, "zest-input-error");

    /** Short circuit as early as possible to reduce checks later checks on */
    if (!hasError || !errorMessages) {
        return null;
    }

    /** If there is a single entry in the errorMessages array then return it as a span */
    /** If there are multiple entries in the errorMessages array then return them as a `<List>`  */
    const ListElement = Children.count(errorMessages) > 1 ? List : "span";
    const ListItemElement =
        Children.count(errorMessages) > 1 ? List.Item : "span";
    return (
        <Element data-testid="inputError" {...rest} className={classes}>
            {children}
            <ListElement {...listProps}>
                {Children.map(errorMessages, (child, i) => (
                    <ListItemElement {...listItemProps} key={i}>
                        {child}
                    </ListItemElement>
                ))}
            </ListElement>
        </Element>
    );
};

export { InputError };
