import { FC, HTMLAttributes, ReactNode } from "react";
import { concat } from "@Utilities/string";

export type ModalContentProps = HTMLAttributes<HTMLElement> & {
    children?: ReactNode;
};

const ModalContent: FC<ModalContentProps> = ({
    children,
    className,
    ...rest
}) => {
    return (
        <main {...rest} className={concat("zest-modal-main", className)}>
            {children}
        </main>
    );
};

export { ModalContent };
