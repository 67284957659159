import { useContext } from "react";
import { ModalContext, ModalContextType } from "./context";

/** Gets the form state and methods from context */
const useModalContext = (): ModalContextType => {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error(
            "useModalContext must be used within a <Modal.Provider>"
        );
    }

    return context;
};

const useMaybeModalContext = (): ModalContextType | null => {
    const context = useContext(ModalContext);

    return context;
};

export { useModalContext, useMaybeModalContext };
