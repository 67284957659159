import { useCallback, useEffect } from "react";

/**
 * Event listener hook for clicks outside of an element.
 *
 * @param {(React.MutableRefObject<HTMLElement | null>)} ref - the Element which will perform the callback when clicked outside of it.
 * @param {() => void} callback - the function which will trigger.
 */
export const useOnOutsideClick = (
  triggerRef: React.MutableRefObject<HTMLElement | null>,
  exclusionRef: React.MutableRefObject<HTMLElement | null>,
  outsideClickCallback: () => void
): void => {
  const handleMouseDown = useCallback(
    (e: MouseEvent): void => {
      if (!triggerRef) {
        return;
      }
      const clickTarget = e.target as Node | null;

      // Not an "outside click" if it's excluded:
      if (exclusionRef.current?.contains(clickTarget)) {
        return;
      }

      if (!triggerRef.current?.contains(clickTarget)) {
        // trigger the callback function if they clicked outside of the given triggerRef
        outsideClickCallback();
      }
    },
    [triggerRef, exclusionRef, outsideClickCallback]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, [handleMouseDown]);
};
