import { InputHTMLAttributes, FC, AriaAttributes } from "react";
import { concat, props } from "@Utilities/string";

/**
 * A component that renders a textarea element.
 */
const TextareaInput: FC<
    InputHTMLAttributes<HTMLTextAreaElement> & {
        theme?: string;
    }
> = ({ className, required, theme, ...rest }) => {
    const ariaProps: AriaAttributes = props({
        "aria-required": {
            value: required,
            condition: required,
        },
    });

    const classes = concat("zest-input-textarea", className, theme);

    return <textarea {...ariaProps} {...rest} className={classes} />;
};

export { TextareaInput };
