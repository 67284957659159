/** This is a configuration file for CI/CD environments.
 * Used because we want to deploy the same static build to multiple environments.
 * Note: Defaults for LOC are in the static/ folder.
 *       When the app is being deployed, the CI/CD pipeline must run `REACT_APP_CD_ENV="XYZ" npx react-inject-env set` to inject the correct values into `build/env.js`
 *       This is basically the React way of injecting ADO variables post-build because appsettings.json cannot be modified post-build.
 */

// Custom variables to be set via commandline post-build:
type EnvironmentVariables = {
  REACT_APP_CD_ENV: EnvironmentType;
  REACT_APP_SENTRY_DSN: string;
};

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    env: any;
  }
}

// ADO Environments:
export const Environments = {
  LOC: "LOC",
  DEV: "DEV",
  TEST: "TEST",
  PROD: "PROD",
} as const;

export type EnvironmentType = keyof typeof Environments;

export const env: EnvironmentVariables = { ...process.env, ...window.env };
