import {
  AutoCompleteSearchResultRecord,
  AutoCompleteSearchResultType,
} from "components/Header/components/Search.types";
import routes from "routes/routes";

export function getAutoCompleteSearchResultValue(
  rawValue: string,
  type: AutoCompleteSearchResultType
): string {
  switch (type) {
    case "brand":
    case "category":
    case "item":
      return rawValue;
    case "keyword":
      return rawValue.replace(/<strong>/g, "").replace(/<\/strong>/g, "");
  }
}

export function getAutoCompleteSearchResultLinkHref(
  value: string,
  type: AutoCompleteSearchResultType
): string {
  switch (type) {
    case "brand":
      return routes.BrandCategories.getPath(value);
    case "category":
      return routes.Categories.getPath(value);
    case "item":
      return routes.PDPItemId.getPath(value);
    case "keyword":
      return routes.PLP.getPath(value);
  }
}

export function getAutoCompleteSearchResultAccessibleLabel(
  record: AutoCompleteSearchResultRecord,
  value: string,
  type: AutoCompleteSearchResultType
): string {
  switch (type) {
    case "brand":
    case "item":
      return record.label;
    case "category": {
      if (record.subtext) {
        return `${record.label} in ${record.subtext}`;
      }
      return record.label;
    }
    case "keyword":
      return value;
  }
}

export function removeStrongHtmlTags(
  label: string,
  type: AutoCompleteSearchResultType
): string {
  if (type === "keyword") {
    return label.replace(/<strong>/g, "").replace(/<\/strong>/g, "");
  }
  return label;
}
