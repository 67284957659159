import type { Breakpoints, SizeLike } from "@Types/element";

/** Function for adding the correct responsive style class names to a component.
 * Defaults to using "small", "medium", and "large" sizes.
 * Can be extended by passing a generic of all additional sizes.
 *
 * @example
 * getResponsiveValues<"mini">("button", size)
 */
function getResponsiveValues<T extends string>(
    component: string,
    sizes: SizeLike<T>
): string {
    if (typeof sizes === "string") return sizes;
    else {
        const sizeKeys = Object.keys(sizes) as Breakpoints[];
        if (sizeKeys.length === 1) {
            return sizes[sizeKeys[0]] as string;
        }
        const { desktop, mobile, tablet } = sizes;
        const mobileFallback = mobile || tablet || desktop;
        const tabletFallback = tablet || mobile || desktop;
        const desktopFallback = desktop || tablet || mobile;

        return [
            `zest-${component}-mobile-${mobile || mobileFallback}`,
            `zest-${component}-tablet-${tablet || tabletFallback}`,
            `zest-${component}-desktop-${desktop || desktopFallback}`,
        ].join(" ");
    }
}

export { getResponsiveValues };
