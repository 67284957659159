import Image from "components/Image/Image";
import { ApiEmployeeRecordDTO } from "layouts/api/layoutAPI.types";
import { concat, phoneNumberFormat } from "util/string";

export default function AccountSpecialistCard({
  accountSpecialist,
  isSpecialistPhoneNumberVisible,
  className,
  photoClassName,
}: {
  readonly accountSpecialist: ApiEmployeeRecordDTO;
  readonly isSpecialistPhoneNumberVisible: boolean;
  readonly className?: string;
  readonly photoClassName?: string;
}): JSX.Element {
  return (
    <div className={concat("flex gap-4 items-center", className)}>
      {accountSpecialist.photoUrl && (
        <Image
          className={photoClassName}
          src={accountSpecialist.photoUrl}
          alt={accountSpecialist.name}
          width={128}
          height={160}
        />
      )}
      <div className="min-w-0">
        <div className="mb-1">
          {accountSpecialist.name && (
            <div className="font-bold text-black">{accountSpecialist.name}</div>
          )}
          {accountSpecialist.title?.name && (
            <div className="text-black">{accountSpecialist.title.name}</div>
          )}
        </div>
        {isSpecialistPhoneNumberVisible && accountSpecialist.workPhone && (
          <AccountSpecialistPhoneNumber
            workPhone={accountSpecialist.workPhone}
            workPhoneExtension={accountSpecialist.workPhoneExtension}
          />
        )}
        {accountSpecialist.emailAddress && (
          <div className="text-sm overflow-auto">
            <span className="text-black">Email:&nbsp;</span>
            <a
              href={`mailto:${accountSpecialist.emailAddress}`}
              className="secondary underline"
            >
              {accountSpecialist.emailAddress}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

function AccountSpecialistPhoneNumber({
  workPhone,
  workPhoneExtension,
}: {
  readonly workPhone?: string;
  readonly workPhoneExtension?: string;
}): JSX.Element | null {
  if (!workPhone) {
    return null;
  }

  const formattedWorkPhone = phoneNumberFormat(workPhone);
  const label = workPhoneExtension
    ? `${formattedWorkPhone} ext: ${workPhoneExtension}`
    : formattedWorkPhone;

  const href = workPhoneExtension
    ? `tel:${workPhone},${workPhoneExtension}`
    : `tel:${workPhone}`;

  return (
    <div className="text-sm overflow-auto text-black">
      <span>Phone:&nbsp;</span>
      <a href={href} className="secondary underline">
        {label}
      </a>
    </div>
  );
}
