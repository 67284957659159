import { FC } from "react";
import { concat, props } from "@Utilities/string";
import {
    InputError,
    InputHelper,
    InputWrapper,
    InputLabel,
    Input,
    placeholderError,
    InputProps,
} from "@Components/formComponents/utilities";
import { getResponsiveValues } from "@Utilities/sizing";
import { SizeLike } from "@Types/element";

export type NumberInputProps = InputProps & {
    inputSize?: SizeLike<"xlarge">;
    variant?: string;
};

type ComposedNumberInputProps = FC<NumberInputProps> & {
    /** @see {@link InputError} */
    Error: typeof InputError;
    /** @see {@link InputHelper} */
    Helper: typeof InputHelper;
    /** @see {@link Input} */
    Input: typeof Input;
    /** @see {@link InputLabel} */
    Label: typeof InputLabel;
    /** @see {@link InputWrapper} */
    Wrapper: typeof InputWrapper;
};

/**
 * A number input with a built-in label, helper text, and error component.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-number-input--number-input-story)}
 */
const NumberInput: ComposedNumberInputProps = ({
    className,
    element = "div",
    error,
    errorElement,
    errorMessage,
    errorProps = {},
    helperProps = {},
    helperText,
    hideError,
    hideLabel,
    id,
    inputMode = "numeric",
    inputSize = "medium",
    label,
    labelProps = {},
    optional,
    placeholder,
    required,
    theme,
    type = "text",
    wrapperProps = {},
    ...rest
}) => {
    const errorId = `number-error-${id}`;
    const helperId = `number-helper-${id}`;
    const isHeadless = theme === "headless";
    const errors = Array.isArray(errorMessage)
        ? errorMessage.join(" ")
        : errorMessage;

    const ariaProps = props({
        "aria-describedby": {
            condition: error || !!helperText,
            value: concat(error && errorId, !!helperText && helperId),
        },
        "aria-label": {
            condition: hideLabel || hideError,
            value: concat(label, hideError && errors),
        },
        "aria-invalid": { condition: error, value: "true" },
    });

    if (placeholder) placeholderError();

    return (
        <InputWrapper
            data-testid="number-input-wrapper"
            {...wrapperProps}
            element={element}
            theme={theme}
        >
            {!hideLabel && (
                <InputLabel
                    data-testid="number-input-label"
                    {...labelProps}
                    htmlFor={id as string}
                    hidden={hideLabel}
                    optional={optional}
                    required={required}
                    theme={theme}
                >
                    {label}
                </InputLabel>
            )}
            <InputHelper
                data-testid="number-input-helper"
                {...helperProps}
                id={helperId}
                theme={theme}
            >
                {helperText}
            </InputHelper>
            <Input
                {...rest}
                {...ariaProps}
                id={id}
                required={required}
                type={type}
                className={concat(
                    "zest-input-number",
                    className,
                    !isHeadless && getResponsiveValues("input", inputSize),
                    !className && isHeadless && "focus:outline-blue-600"
                )}
                inputMode={inputMode}
            />
            <InputError
                data-testid="number-input-error"
                {...errorProps}
                id={errorId}
                hasError={error && !hideError}
                errorMessages={errorMessage}
                theme={theme}
            >
                {errorElement}
            </InputError>
        </InputWrapper>
    );
};

NumberInput.Error = InputError;
NumberInput.Helper = InputHelper;
NumberInput.Input = Input;
NumberInput.Label = InputLabel;
NumberInput.Wrapper = InputWrapper;

NumberInput.displayName = "NumberInput";

export { NumberInput };
