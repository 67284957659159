import { useState } from "react";

export enum Status {
    Initial = "INITIAL",
    Idle = "IDLE",
    Loading = "LOADING",
    Success = "SUCCESS",
    Error = "ERROR",
    Validating = "VALIDATING",
    Submitting = "SUBMITTING",
}

interface UseStatus<T> {
    setStatus: (status: Status | T) => void;
    status: Status | T;
}

const useStatus = <T extends Status>(
    initialStatus?: T | Status
): UseStatus<T> => {
    const [status, setStatus] = useState<T | Status>(
        initialStatus || Status.Initial
    );

    return { status, setStatus };
};

export { useStatus };
