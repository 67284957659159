import { Reducer } from "react";
import { FormValues } from "../../types";
import { UnregisterAction } from "./types";

const unregister: Reducer<FormValues, UnregisterAction> = (state, action) => {
    if (!(action.value in state)) {
        console.warn(
            "%cZest Error:\n",
            "background-color: red; color: yellow; font-size: xsmall",
            `Form item "${action.value}" is trying to unregister when it has not previously been registered.`
        );
    } else {
        delete state[action.value];
    }
    return state;
};

export default unregister;
