import { useNavigate } from "react-router";
import {
  Form as ZestForm,
  FormItem as ZestFormItem,
  TextInput as ZestTextInput,
  useForm,
} from "@clarkinc/zest-design-system";
import { useState } from "react";
import Modal, { toggle } from "components/Modal/Modal";
import { storeEmptyProjectBuilder } from "components/ProjectBuilders/Modals/utils/storeEmptyProjectBuilder";
import Button from "components/buttons/Button";
import LoadingSpinner from "components/Spinner/LoadingSpinner";
import useLayoutAPI from "layouts/api/useLayoutAPI";
import { ErrorMessages } from "util/requests/response.types";
import ModalFormErrors from "components/Modal/ModalFormErrors";
import { concat } from "util/string";
import routes from "routes/routes";

export const createProjectBuilderModalId = "create-project-builder-modal";

export type StoreEmptyProjectBuilderForm = {
  name: string;
};

const inputClassNames =
  "mt-1 w-full flex flex-col gap-1 rounded border border-gray-300";
const inputWrapperClassNames = "w-full ";
const inputLabelProps = { className: "text-sm font-semibold" };

export default function CreateProjectBuilderModal(): JSX.Element {
  const navigate = useNavigate();
  const { refetch } = useLayoutAPI();

  const form = useForm<StoreEmptyProjectBuilderForm>();
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<ErrorMessages>();

  const reset = (): void => {
    form.reset();
    setErrors(undefined);
  };

  const onFinish = async (): Promise<void> => {
    const formData: StoreEmptyProjectBuilderForm = {
      name: form.getFormState().name.value,
    };

    let projectBuilderId: number | undefined;
    let errors: ErrorMessages | undefined;

    setErrors(undefined);
    setSubmitting(true);

    try {
      ({ projectBuilderId, errors } = await storeEmptyProjectBuilder(formData));
    } finally {
      setSubmitting(false);
    }

    if (projectBuilderId) {
      reset();
      toggle(createProjectBuilderModalId);
      refetch();
      navigate(routes.ProjectBuilderView.getPath(projectBuilderId + ""));
    } else if (errors) {
      setErrors(errors);
    }
  };

  const body = (
    <ZestForm
      className="col-span-2 flex flex-col"
      form={form}
      name="Store Empty Project Builder Form"
      onFinish={onFinish}
    >
      <ModalFormErrors errors={errors} />
      <CreateProjectBuilderModalFormContent submitting={submitting} />
      <div className="px-6 py-5 flex gap-4 justify-end border-t border-gray-200">
        <Button
          disabled={submitting}
          label="Cancel"
          onClick={(): void => {
            reset();
            toggle(createProjectBuilderModalId);
          }}
          variant="tertiary"
        />
        <Button
          disabled={submitting}
          label="Create"
          type="submit"
          variant="primary"
        />
      </div>
    </ZestForm>
  );

  const header = (
    <span className="font-medium text-lg">Create Project Builder</span>
  );

  return (
    <Modal
      ariaLabel="Create Project Builder"
      className="px-0 py-0 rounded-2xl w-[480px]"
      body={body}
      bodyClassNames="pr-0 pb-0 pl-0 pt-0"
      header={header}
      headerClassNames="pb-6 pr-6 pl-6 font-medium text-lg"
      id={createProjectBuilderModalId}
      showFooterSeparator={true}
      showHeaderSeparator={true}
      onCancel={reset}
    />
  );
}

function CreateProjectBuilderModalFormContent({
  submitting,
}: {
  submitting: boolean;
}): JSX.Element {
  return (
    <>
      <div
        className={concat(
          "h-28 flex items-center justify-center",
          !submitting && "hidden"
        )}
      >
        <LoadingSpinner className="h-20 w-20" />
      </div>
      <div className={concat("p-6", submitting && "hidden")}>
        <ZestFormItem required name="name">
          <ZestTextInput
            className={inputClassNames}
            label="Title "
            labelProps={inputLabelProps}
            maxLength={100}
            errorProps={{ className: "text-red-600" }}
            wrapperProps={{ className: inputWrapperClassNames }}
          />
        </ZestFormItem>
      </div>
    </>
  );
}
