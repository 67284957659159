import CTABadgeWithText from "assets/CTABadgeWithText";
import useInvoiceCountApi from "routes/Invoice/useInvoiceCountApi";
import { PaymentStatus } from "components/Invoice/Invoices.types";
import ButtonLink from "components/buttons/ButtonLink";
import routes from "routes/routes";
import { buildFilteredInvoicesQueryString } from "routes/Invoice/utils";

const buttonId = "HeaderAdminReviewPastDueInvoicesButton";
const buttonClasses = "justify-between";
const variant = "secondary";
const label = "Past Due Invoices";
const iconOrientation = "right";
const iconClasses = "h-5 min-w-[1.75em] w-fit px-1";
const queryString = buildFilteredInvoicesQueryString(
  {
    searchInvoicePaymentStatus: PaymentStatus.PAST_DUE.toString(),
  },
  0
);

export default function ReviewPastDueInvoicesButton(): React.ReactElement {
  const { data, isLoading, isError } = useInvoiceCountApi(
    true,
    PaymentStatus.PAST_DUE
  );

  if (isError || isLoading || (!data && data !== 0)) {
    return (
      <ButtonLink
        id={buttonId}
        className={buttonClasses}
        href={routes.Invoices.getPath(queryString)}
        variant={variant}
        label={label}
        icon={<CTABadgeWithText text="" className={iconClasses} />}
        iconOrientation={iconOrientation}
      />
    );
  }

  return (
    <ButtonLink
      id={buttonId}
      className={buttonClasses}
      href={routes.Invoices.getPath(queryString)}
      variant={variant}
      label={label}
      icon={<CTABadgeWithText text={data.toString()} className={iconClasses} />}
      iconOrientation={iconOrientation}
    />
  );
}
