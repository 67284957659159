import { useEffect } from "react";

/**
 * Event listener hook for Escape button presses.
 *
 * @param {() => void} callback - Function to trigger when the Esc key is pressed
 */
export const useKeyListener = (
  key: string | string[],
  event: "keyup" | "keydown",
  callback: () => void
): void => {
  useEffect(() => {
    const handleKey = (e: KeyboardEvent): void => {
      // trigger the callback function if key is pressed
      if (e.key === key || (Array.isArray(key) && key.includes(e.key))) {
        callback();
      }
    };

    document.addEventListener(event, handleKey);

    return () => {
      document.removeEventListener(event, handleKey);
    };
  }, [callback, event, key]);
};
