import { useMemo } from "react";
import { useUserContext } from "authentication/contexts/user.context";
import { useStore } from "util/store";

/**
 * Returns boolean representing whether session was created with Inspect PunchOutRequest.
 */
export const useInspectMode = (): boolean => {
  const userContext = useUserContext();
  const punchOutOperation = useStore(
    userContext.store,
    (user) => user.userClaims?.punchOutOperation
  );

  return useMemo(() => {
    return punchOutOperation === "inspect";
  }, [punchOutOperation]);
};
