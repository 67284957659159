import { Dispatch, useCallback, useReducer, useRef } from "react";

type UseMemoizedReducer<S, A> = [() => S, Dispatch<A>];

/**
 * Returns a memoized getState function instead of the unmemorized reducer value.
 * This allows the getState function to be put in hook dependency arrays
 * @reference https://transang.me/get-state-callback-with-usereducer-in-react/
 */
const useMemoizedReducer = <S = unknown, A = unknown>(
    reducer: Parameters<typeof useReducer>[0],
    initState: Parameters<typeof useReducer>[1],
    initializer?: Parameters<typeof useReducer>[2]
): UseMemoizedReducer<S, A> => {
    const lastState = useRef<ReturnType<typeof reducer>>(initState);
    const getState = useCallback(() => lastState.current, []);
    const memoizedReducer = useCallback(
        (
            state: Parameters<typeof reducer>[0],
            action: Parameters<typeof reducer>[1]
        ) => (lastState.current = reducer(state, action)),
        [reducer]
    );
    const [, dispatch] = useReducer(memoizedReducer, initState, initializer);
    return [getState, dispatch];
};

export { useMemoizedReducer };
