import { useEffect, useState } from "react";

/**
 * A check for if the user's device is mobile without relying on width.
 * First checks if the userAgentData defines a mobile device.
 * userAgentData is not fully supported by all browsers, see @link {@link [caniuse](https://caniuse.com/mdn-api_navigator_useragentdata)}
 * Otherwise checks if the userAgent matches a mobile device via regex.
 */
const useIsMobile = (): boolean => {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (navigator.userAgentData?.mobile) {
            setIsMobile(true);
        }
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i,
        ];

        if (
            toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            })
        ) {
            setIsMobile(true);
        }
    }, []);

    return isMobile;
};

export { useIsMobile };
