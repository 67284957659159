import { HTMLAttributes } from "react";
import { Sizes } from "@Types/element";
import { concat } from "@Utilities/string";
import { IconProps } from "@Components/icon";
import { SFSpriteSheetHref } from "@Utilities/index";
import { Star, starLevels } from "./star";

export type RatingsIconProps = Omit<IconProps, "size">;

export type RatingsProps = HTMLAttributes<HTMLDivElement> & {
    iconProps?: RatingsIconProps;
    rating: number;
    size?: Sizes | "xsmall";
    skipScreenReader?: boolean;
    theme?: string;
};

export const starLevel = (rating: number, i: number): starLevels => {
    if (rating >= i - 0.25) {
        return 100;
    }
    if (rating >= i - 0.75) {
        return 50;
    }
    return 0;
};

const range = (start: number, end: number): number[] => {
    if (start === end) return [start];
    return [start, ...range(start + 1, end)];
};

/**
 * Displays a product rating on a scale of 1 to 5 stars.
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-ratings--ratings)}
 */
const Ratings = ({
    className,
    iconProps = {},
    rating = 0,
    size = "medium",
    skipScreenReader = false,
    theme,
    ...rest
}: RatingsProps): JSX.Element => {
    if (rating > 5 || rating < 0) {
        console.error(
            "%cZest Error:\n",
            "background-color: red; color: yellow; font-size: xsmall",
            "Ratings should be on a 0 to 5 scale."
        );
    }

    return (
        <div
            {...rest}
            className={concat(theme, "zest-ratings", className)}
            data-testid="zest-ratings"
        >
            {!skipScreenReader && (
                <span
                    data-testid="zest-ratings-sr"
                    className="sr-only"
                >{`Rated ${rating} out of 5 stars`}</span>
            )}
            {range(1, 5).map((i) => (
                <Star
                    {...iconProps}
                    size={size}
                    starType={starLevel(rating, i)}
                    key={i}
                    spriteSheetHref={
                        iconProps.spriteSheetHref || SFSpriteSheetHref
                    }
                />
            ))}
        </div>
    );
};

Ratings.displayName = "Ratings";

export { Ratings };
