import { Dispatch } from "react";
import { useMemoizedReducer, Status } from "@Utilities/hooks";
import reducer, { FormActions } from "../state/reducer";
import { FormValues } from "../types";

export type FormMeta = {
    disableMoveFocusOnSubmit: boolean;
    errorDisplay: "both" | "alert" | "inline";
    name: string;
    status: Status;
};

export type UseFormValues<T extends FormValues> = [
    () => T,
    Dispatch<FormActions<T>>,
    () => FormMeta,
    Dispatch<Partial<FormMeta>>
];

export /** The state for the useForm instance */
const useFormValues = <
    T extends FormValues = FormValues
>(): UseFormValues<T> => {
    /** Form Values */
    const [getFormState, dispatch] = useMemoizedReducer<T, FormActions<T>>(
        reducer,
        {}
    );
    const [getFormMetaState, dispatchFormMeta] = useMemoizedReducer<
        FormMeta,
        Partial<FormMeta>
    >(
        (prev, action) => {
            return {
                ...prev,
                ...action,
            };
        },
        {
            errorDisplay: "inline",
            status: Status.Idle,
            disableMoveFocusOnSubmit: false,
        }
    );

    return [getFormState, dispatch, getFormMetaState, dispatchFormMeta];
};

export default useFormValues;
