import { useEffect } from "react";
import { useLocation } from "react-router";
import { scrollToElement } from "util/scroll";

type ScrollToTopWrapperProps = {
  children: React.ReactNode;
};

function ScrollToTopWrapper({
  children,
}: ScrollToTopWrapperProps): JSX.Element {
  const location = useLocation();

  useEffect(() => {
    const element = location.hash
      ? document.getElementById(location.hash.substring(1))
      : null;
    if (element) {
      scrollToElement(element);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{children}</>;
}

export default ScrollToTopWrapper;
