import { FC } from "react";
import { concat } from "@Utilities/string";
import { Icon, IconProps } from "..";

export type starLevels = 100 | 50 | 0;

export type StarProps = IconProps & {
    starType: starLevels;
};

const Star: FC<StarProps> = ({ className, size, starType, ...rest }) => {
    const classes = concat(className, `${starType}%`, size);
    return (
        <Icon
            name="sf-star"
            style={
                {
                    "--star-percentage": `${starType}%`,
                    ...rest.style,
                } as React.CSSProperties
            }
            {...rest}
            className={classes}
        />
    );
};

export { Star };
