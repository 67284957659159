import { concat } from "util/string";

export default function Alert({
  type,
  children,
  className,
}: {
  type: "Warning" | "Info" | "Danger" | "Success";
  children: JSX.Element | string;
  className?: string;
}): JSX.Element {
  const typeClasses = getAlertTypeClasses(type);
  return (
    <div
      className={concat(
        " w-full rounded p-3 font-medium",
        typeClasses,
        className
      )}
    >
      {children}
    </div>
  );
}

function getAlertTypeClasses(type: string): string {
  let typeClasses = "bg-[#e7fbfd] text-[#096e77] border border-[#b8f4fa]";
  switch (type) {
    case "Warning":
      typeClasses = "bg-[#fef5e7] text-[#97630e] border border-[#fce1b6]";
      break;
    case "Danger":
      typeClasses = "bg-[#faeaee] text-[#a7394f] border border-[#f0c1cc]";
      break;
    case "Success":
      typeClasses = "bg-green-50 text-green-800 border border-green-300";
      break;
  }
  return typeClasses;
}
