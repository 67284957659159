import {
    ReactChild,
    FC,
    InputHTMLAttributes,
    ChangeEvent,
    ReactNode,
    AriaAttributes,
} from "react";
import { concat, props } from "@Utilities/string";
import {
    InputError,
    InputHelper,
    InputWrapper,
    InputLabel,
    placeholderError,
} from "@Components/formComponents/utilities";
import { CharacterCounter } from "./characterCounter";
import { TextareaInput } from "./input";
import type { CharacterCounterProps } from "./characterCounter";
import type {
    InputErrorProps,
    InputLabelProps,
    InputWrapperProps,
    InputHelperProps,
} from "..";
import { Sizes } from "@Types/element";
import { getResponsiveValues } from "@Utilities/sizing";

export type TextareaProps = InputHTMLAttributes<HTMLTextAreaElement> & {
    characterCounterProps?: CharacterCounterProps;
    element?: InputWrapperProps["element"];
    error?: boolean;
    errorElement?: ReactNode;
    errorMessage?: string | string[];
    errorProps?: InputErrorProps;
    helperProps?: InputHelperProps;
    helperText?: ReactChild;
    hideError?: boolean;
    hideLabel?: boolean;
    inputSize?: Sizes;
    label: string;
    labelProps?: InputLabelProps;
    optional?: boolean;
    resize?: "none" | "vertical" | "horizontal" | "both";
    theme?: string;
    wrapperProps?: InputWrapperProps;
};

type ComposedTextareaProps = FC<TextareaProps> & {
    /** @see {@link CharacterCounter} */
    CharacterCounter: typeof CharacterCounter;
    /** @see {@link InputError} */
    Error: typeof InputError;
    /** @see {@link Helper} */
    Helper: typeof InputHelper;
    /** @see {@link TextareaInput} */
    Input: typeof TextareaInput;
    /** @see {@link InputLabel} */
    Label: typeof InputLabel;
    /** @see {@link InputWrapper} */
    Wrapper: typeof InputWrapper;
};

/**
 * A textarea input with a label, helper text, error component, and optional character counter component.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-textarea--textarea-story)}
 */
const Textarea: ComposedTextareaProps = ({
    characterCounterProps,
    className,
    element = "div",
    error,
    errorElement,
    errorMessage,
    errorProps = {},
    helperProps,
    helperText,
    hideError,
    hideLabel,
    id,
    inputSize = "medium",
    label,
    labelProps,
    maxLength,
    onChange,
    optional,
    placeholder,
    required,
    theme,
    value,
    wrapperProps,
    ...rest
}) => {
    const errorId = `textarea-error-${id}`;
    const helperId = `textarea-helper-${id}`;
    const characterCountId = `textarea-character-counter-$${id}`;
    const isHeadless = theme === "headless";
    const errors = Array.isArray(errorMessage)
        ? errorMessage.join(" ")
        : errorMessage;

    // Cast to a string here as FormItem is always passing a string, but TS does not know this.
    const textAreaValue = value as string;

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        onChange?.(e);
    };

    const ariaProps: AriaAttributes = props({
        "aria-describedby": {
            condition: error || !!helperText,
            value: concat(
                error && errorId,
                !!helperText && helperId,
                !!maxLength && characterCountId
            ),
        },
        "aria-label": {
            condition: hideLabel || hideError,
            value: concat(label, hideError && errors),
        },
        "aria-invalid": { condition: error, value: "true" },
    });

    if (placeholder) placeholderError();

    return (
        <InputWrapper
            data-testid="text-area-wrapper"
            {...wrapperProps}
            element={element}
            theme={theme}
        >
            {!hideLabel && (
                <InputLabel
                    {...labelProps}
                    htmlFor={id as string}
                    className={concat(labelProps?.className)}
                    optional={optional}
                    required={required}
                    theme={theme}
                >
                    {label}
                </InputLabel>
            )}
            <InputHelper {...helperProps} id={helperId} theme={theme}>
                {helperText}
            </InputHelper>
            <TextareaInput
                {...rest}
                {...ariaProps}
                onChange={handleChange}
                value={value}
                className={concat(
                    className,
                    !isHeadless && getResponsiveValues("input", inputSize),
                    !className && isHeadless && "focus:outline-blue-600"
                )}
                id={id}
                maxLength={maxLength}
                required={required}
            />
            <div className="flex gap-1 justify-end">
                <InputError
                    {...errorProps}
                    hasError={error && !hideError}
                    id={errorId}
                    errorMessages={errorMessage}
                    theme={theme}
                    className="grow"
                >
                    {errorElement}
                </InputError>
                {!!maxLength && (
                    <CharacterCounter
                        {...characterCounterProps}
                        id={characterCountId}
                        maxLength={maxLength}
                        characterCount={textAreaValue.length || 0}
                    />
                )}
            </div>
        </InputWrapper>
    );
};

Textarea.CharacterCounter = CharacterCounter;
Textarea.Error = InputError;
Textarea.Helper = InputHelper;
Textarea.Input = TextareaInput;
Textarea.Label = InputLabel;
Textarea.Wrapper = InputWrapper;

Textarea.displayName = "Textarea";

export { Textarea };
