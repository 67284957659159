export type FormValue<Value = FieldValue> = {
    defaultValue: Value;
    errors: string[];
    id: string;
    required: boolean;
    touched: boolean;
    value: Value;
};

export type FieldValue = string | boolean;

export type GenericFieldValue = { [fieldName: string]: FieldValue };

export type FormValues = { [fieldName: string]: FormValue };

export enum FormEvents {
    // REGISTER = "register",
    UPDATE = "update",
    FINISH = "finish",
    FINISH_FAILED = "finish_failed",
    // RESET = "reset",
    // CLEAR = "clear",
    // VALIDATE = "validate",
}

export type FormEventCallbacks<S extends FormValues> = {
    [FormEvents.FINISH]: (state: S) => PromiseLike<void>;
    [FormEvents.FINISH_FAILED]: (
        state: S,
        failed?: (keyof S)[]
    ) => PromiseLike<void>;
    [FormEvents.UPDATE]: (state: S, fields?: (keyof S)[]) => PromiseLike<void>;
};

export type PromiseLike<T> = T | Promise<T>;

/** Allows users to pass a form interface with just the field type and converts to FormValue<type> */
export type FormValueMap<T extends GenericFieldValue> = {
    [P in keyof T]: FormValue<T[P]>;
};

export type FormOnFinish<Form extends GenericFieldValue> = FormEventCallbacks<
    FormValueMap<Form>
>["finish"];

export type FormOnFinishFailed<Form extends GenericFieldValue> =
    FormEventCallbacks<FormValueMap<Form>>["finish_failed"];

export type FormOnUpdate<Form extends GenericFieldValue> = FormEventCallbacks<
    FormValueMap<Form>
>["update"];
