import React, { ReactNode } from "react";
import { useInspectMode } from "util/hooks/useInspectMode";

interface InertContentWrapperProps {
  children: ReactNode;
  forceReadOnly?: boolean;
}

export const InertContentWrapper = ({
  children,
  forceReadOnly = false,
}: InertContentWrapperProps): React.ReactElement => {
  const isInspectMode = useInspectMode() || forceReadOnly;

  return isInspectMode ? (
    // Temporary workaround until React adds support for the "inert" attribute in React 19
    <div {...{ inert: "" }}>{children}</div>
  ) : (
    <>{children}</>
  );
};
