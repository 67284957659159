import { useNavigate } from "react-router-dom";
import Button from "components/buttons/Button";

export default function SkipNavButton(): React.ReactElement {
  const navigate = useNavigate();

  return (
    <Button
      label="Skip to Main Content"
      className="skip-nav-link"
      onClick={(ev): void => {
        navigate("#main");
        if (ev.target instanceof HTMLElement) {
          ev.target.blur();
        }
      }}
      variant="unstyled"
    />
  );
}
