import { ButtonSize, ButtonVariant } from "components/buttons/Button.types";
import { concat } from "util/string";

const disabledColorsWithoutPsuedoClass = [
  "text-gray-400",
  "hover:text-gray-400",
  "active:text-gray-400",
  "bg-gray-200",
  "hover:bg-gray-200",
  "active:bg-gray-200",
];

const disabledColors = concat(
  "disabled:text-gray-400",
  "disabled:hover:text-gray-400",
  "disabled:active:text-gray-400",
  "disabled:bg-gray-200",
  "disabled:hover:bg-gray-200",
  "disabled:active:bg-gray-200"
);

export const buttonSizes: Record<ButtonSize, string[]> = {
  // use @apply from index.css to allow inline overrides
  small: ["button-small"],
  group: ["button-group"],
  default: ["button-default"],
  custom: [],
};

// tailwind fallback
export const PrimaryButtonThemeFallback =
  "bg-[#D71920] text-white hover:bg-[#B3151B] active:bg-[#6C0D10]";
export const buttonPrimaryTheme = concat(
  PrimaryButtonThemeFallback,
  // figma token class will take precedence if valid:
  "btn-primary"
);

// tailwind fallback
export const SecondaryButtonThemeFallback =
  "bg-[#261F1B] text-white hover:bg-[#6E6A67] active:bg-[#4A4441]";
export const buttonSecondaryTheme = concat(
  SecondaryButtonThemeFallback,
  // figma token class will take precedence if valid:
  "btn-secondary"
);

export const buttonTertiaryTheme = concat(
  "bg-gray-100",
  "text-black",
  "border",
  "border-gray-400",
  "hover:bg-gray-200",
  "active:bg-gray-300"
  // does not change based on Figma theme
);

export const buttonGroupTheme = concat(
  "bg-white",
  "text-black",
  "hover:text-[#B3151B]",
  "active:text-[#6C0D10]",
  "disabled:rounded-none"
  // does not change based on Figma theme
);

export function applyButtonTheme(variant: ButtonVariant): string | undefined {
  return concat(
    variant === "primary" && buttonPrimaryTheme,
    variant === "secondary" && buttonSecondaryTheme,
    variant === "tertiary" && buttonTertiaryTheme,
    variant === "group" && buttonGroupTheme
  );
}

export function applyButtonThemeForNonButton(
  variant: ButtonVariant,
  disabled = false
): string | undefined {
  return concat(
    variant === "primary" && !disabled && buttonPrimaryTheme,
    variant === "secondary" && !disabled && buttonSecondaryTheme,
    variant === "tertiary" && !disabled && buttonTertiaryTheme,
    variant === "group" && !disabled && buttonGroupTheme,
    disabled && concat(...disabledColorsWithoutPsuedoClass)
  );
}

/**
 *  String of tailwind classes defining the button style based on variant, including theme from token
 * @param variant
 * @param size
 * @param flexContainer - whether to use a flex container for icon+text
 */
export function generateButtonStyle(
  variant: ButtonVariant,
  size: ButtonSize,
  flexContainer?: boolean
): string | undefined {
  return concat(
    "marketplace-button", // index.css component layer style
    flexContainer &&
      concat(
        "inline-flex",
        "flex-none" // added to prevent resizing/squishing of the button
      ),
    applyButtonTheme(variant),
    disabledColors, // must take precedence over theme states
    ...buttonSizes[size]
  );
}
