import { DialogHTMLAttributes, HTMLAttributes, useEffect, useRef } from "react";
import {
    ModalEventCallbacks,
    ModalEvents,
    subscribe,
    modalStore,
    toggle,
} from "./modal.store";
import { concat } from "@Utilities/string";
import { useModalContext } from "./useModalContext";

type DialogProps = Omit<DialogHTMLAttributes<HTMLDialogElement>, "id">;

const handleEscape = (): void => {
    const currentId = modalStore.get().openModalId;
    if (currentId) {
        toggle(currentId);
    }
};

export type ModalContainerProps = DialogProps & {
    "aria-label": string;
    containerProps?: HTMLAttributes<HTMLDivElement>;
    onClose?: ModalEventCallbacks["close"];
    onOpen?: ModalEventCallbacks["open"];
    theme?: string;
};

const ModalContainer = ({
    children,
    className,
    containerProps,
    onClose,
    onOpen,
    theme,
    ...rest
}: ModalContainerProps): JSX.Element => {
    const dialogRef = useRef<HTMLDialogElement>(null);
    const openRef = useRef<boolean>(false);
    const context = useModalContext();
    const { id } = context;

    // Add and remove event listener incase the modal is closed via the escape key
    useEffect(() => {
        const dialogNode = dialogRef.current;
        dialogNode?.addEventListener("close", handleEscape);

        return (): void => {
            dialogNode?.removeEventListener("close", handleEscape);
        };
    }, []);

    // From here we can subscribe to any onClose or onOpen functions
    // As well as cleanup in the useEffect
    useEffect(() => {
        const cleanups: (() => void)[] = [];
        if (onOpen) {
            cleanups.push(subscribe(id, ModalEvents.OPEN, onOpen));
        }
        if (onClose) {
            cleanups.push(subscribe(id, ModalEvents.CLOSE, onClose));
        }
        return (): void => {
            cleanups.forEach((cleanup) => {
                cleanup();
            });
        };
    }, [onOpen, onClose, id]);

    useEffect(() => {
        return subscribe(id, ModalEvents.OPEN, function handleOpenModal() {
            if (openRef.current) return;
            openRef.current = true;
            const elem = dialogRef.current;
            if (!elem)
                throw new Error(
                    "Modal ref not correctly set to dialog element."
                );

            elem.showModal();
            document.body.style.overflowY = "hidden";
        });
    }, [id]);

    useEffect(() => {
        return subscribe(id, ModalEvents.CLOSE, function handleCloseModal() {
            openRef.current = false;
            document.body.style.overflowY = "auto";
            dialogRef.current?.classList.add("animate-slide-out");
            const closeModalTimer = setTimeout(() => {
                dialogRef.current?.classList.remove("animate-slide-out");
                dialogRef.current?.close();
            }, 125);
            return () => {
                clearTimeout(closeModalTimer);
            };
        });
    }, [id]);

    return (
        <dialog
            ref={dialogRef}
            className={concat("zest-modal", "z-50", theme, className)}
            {...rest}
        >
            <div
                {...containerProps}
                className={concat(
                    "zest-modal-wrapper",
                    containerProps?.className
                )}
            >
                {children}
            </div>
        </dialog>
    );
};

export { ModalContainer };
