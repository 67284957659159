import {
    VideoHTMLAttributes,
    SyntheticEvent,
    HTMLAttributes,
    useEffect,
    useRef,
} from "react";
import { concat } from "@Utilities/string";
import { useTimeout } from "@Utilities/hooks";
import { SFSpriteSheetHref } from "@Utilities/index";
import { Controls, ControlsProps } from "./controls";
import { Overlay, OverlayProps } from "./overlay";
import {
    getRefValue,
    UseVideoPlayer,
    useVideoPlayer,
    VideoPlayerProvider,
} from "./utilities";
import { useStore } from "@Utilities/hooks/useStoreData";
import { isLoadingSelector } from "./selectors";

export type VideoPlayerProps = VideoHTMLAttributes<HTMLVideoElement> & {
    captionSrc?: string;
    controlsProps?: ControlsProps;
    muted?: boolean;
    overlayProps?: OverlayProps;
    small?: boolean;
    spriteSheetHref?: string;
    src: string;
    theme?: string;
    videoPlayer?: UseVideoPlayer;
    wrapperProps?: HTMLAttributes<HTMLDivElement>;
};
export interface ThumbnailUrls {
    [key: string]: Blob;
}

/**
 * A component used to display MP4 content.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-videoplayer--video-player-story)}
 *
 */
const VideoPlayer = ({
    autoPlay,
    captionSrc = "",
    className,
    controlsProps = {},
    muted,
    overlayProps = {},
    small,
    spriteSheetHref = SFSpriteSheetHref,
    src,
    theme = "wss",
    videoPlayer: controlledVideoPlayer,
    wrapperProps = {},
    ...rest
}: VideoPlayerProps): JSX.Element => {
    const internalVideoPlayer = useVideoPlayer();
    const videoPlayer = controlledVideoPlayer ?? internalVideoPlayer;
    const wrapperRef = useRef<HTMLDivElement>(null);
    const isLoading = useStore(videoPlayer.store, isLoadingSelector);

    const timeout = useTimeout(() => {
        const state = videoPlayer.store.get();
        const videoElem = getRefValue(videoPlayer.ref);
        // if the mouse is not inside the video player exit
        if (state.isMouseInside) {
            videoElem.style.cursor = "none";
            videoPlayer.store.set({
                showControls: false,
                showOverlay: false,
            });
        }
    }, 3000);

    const wrapperClasses = concat(
        theme,
        "zest-video-wrapper",
        wrapperProps.className,
        small && "small"
    );

    // Set the currentTime of the videoPlayer Store to the video elements current time
    const handleTimeUpdate = (
        event: SyntheticEvent<HTMLVideoElement>
    ): void => {
        videoPlayer.store.set({ currentTime: event.currentTarget.currentTime });
    };

    // anytime a user hovers over the video player show the controls and overlay
    const handleOnMouseEnter = (): void => {
        const videoElem = getRefValue(videoPlayer.ref);
        videoPlayer.store.set({
            isMouseInside: true,
            showControls: true,
            showOverlay: true,
        });
        videoElem.style.cursor = "auto";
    };

    // anytime a user hovers out of the video player hide the controls and overlay
    const handleOnMouseLeave = (): void => {
        videoPlayer.store.set({ isMouseInside: false });
        if (videoPlayer.store.get().currentTime > 0) {
            videoPlayer.store.set({ showControls: false, showOverlay: false });
        }
    };

    const handleMouseMove = (): void => {
        const state = videoPlayer.store.get();
        const videoElem = getRefValue(videoPlayer.ref);
        if (state.isPlaying) {
            timeout.restart();
        }
        if (!state.showControls || !state.showOverlay) {
            videoPlayer.store.set({
                showControls: true,
                showOverlay: true,
            });
            videoElem.style.cursor = "auto";
        }
    };

    const handleOnFocus = (): void => {
        handleOnMouseEnter();
        handleMouseMove();
    };

    // when focus leaves the element
    const handleOnBlur = (e: React.FocusEvent): void => {
        e.stopPropagation();
        // if FocusEvent.relatedTarget is not a child of the ref then hide controls
        if (
            wrapperRef.current &&
            !wrapperRef.current.contains(e.relatedTarget) &&
            videoPlayer.store.get().currentTime > 0
        ) {
            videoPlayer.store.set({ showControls: false, showOverlay: false });
        }
    };

    useEffect(() => {
        // const video = videoPlayer.store.get().ref.current;
        const video = getRefValue(videoPlayer.ref);
        videoPlayer.store.set({ src });

        /**
         * @description - Called when the "progress" event is triggered on the video element.
         * It checks if the video element exists and if there is any buffered data.
         */
        function handleDownloadProgress(): void {
            if (!video) return;

            if (video) {
                const { buffered } = video;
                if (buffered.length <= 0) return;
            }
        }

        if (video) {
            video.addEventListener("progress", handleDownloadProgress);
        }

        return (): void => {
            if (video) {
                video.removeEventListener("progress", handleDownloadProgress);
            }
        };
    }, [videoPlayer.ref, src, videoPlayer]);

    // subscribe to any changes on the store value
    useEffect(() => {
        return videoPlayer.store.subscribe((state): void => {
            //  if video is playing and the timeout is not set
            if (state.isPlaying && !timeout.running) {
                timeout.start();
            } else if (!state.isPlaying) {
                // when mouse is inside and active then show the cursor and controls
                const videoElem = getRefValue(videoPlayer.ref);
                timeout.clear();
                videoElem.style.cursor = "auto";
            }
        });
    }, [timeout, videoPlayer]);

    useEffect(() => {
        if (autoPlay) {
            videoPlayer.play();
        }
    }, [autoPlay, videoPlayer]);

    useEffect(() => {
        return () => {
            timeout.clear();
        };
    }, [src, timeout]);

    return (
        <div
            {...wrapperProps}
            className={wrapperClasses}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onMouseMove={handleMouseMove}
            onTouchStart={handleMouseMove}
            onFocus={handleOnFocus}
            ref={wrapperRef}
        >
            <section aria-label="video player">
                {isLoading && (
                    <div className="loading-video-player">
                        <div className="spinner-wrapper">
                            <div className="spinner"></div>
                        </div>
                    </div>
                )}
                <VideoPlayerProvider value={videoPlayer}>
                    <video
                        preload="metadata"
                        {...rest}
                        muted={muted}
                        autoPlay={autoPlay}
                        id="zest-video-player"
                        ref={videoPlayer.ref}
                        onEnded={videoPlayer.pause}
                        onTimeUpdate={handleTimeUpdate}
                        className={concat("zest-video-player", className)}
                        onClick={videoPlayer.togglePlayPause}
                        controls={false} // Disable default controls
                    >
                        <source src={src} type="video/mp4" />
                        <track kind="captions" src={captionSrc} srcLang="en" />
                    </video>
                    {!isLoading && (
                        <Overlay
                            {...overlayProps}
                            handleBlur={handleOnBlur}
                            spriteSheetHref={spriteSheetHref}
                        />
                    )}
                    <Controls
                        {...controlsProps}
                        muted={muted}
                        spriteSheetHref={spriteSheetHref}
                        wrapperRef={wrapperRef}
                        handleBlur={handleOnBlur}
                    />
                </VideoPlayerProvider>
            </section>
        </div>
    );
};

VideoPlayer.displayName = "VideoPlayer";

export { VideoPlayer };
