import { UserClaims } from "authentication/contexts/UserData.types";
import { ApiContentConfigurationToCatalogCacheDTO } from "layouts/api/layoutAPI.types";

export function getFullName(userClaims: UserClaims): string | undefined {
  const fullName = `${userClaims["first_name"] ?? ""} ${
    userClaims["last_name"] ?? ""
  }`;
  return fullName.trim() ? fullName.trim() : undefined;
}

export function getStoreLocation(userClaims: UserClaims): string {
  const locationDescription = userClaims.salesLocationDescription;
  const locationNumber = userClaims.locationNumber;

  return locationNumber?.trim()
    ? `${locationDescription} #${locationNumber}`
    : locationDescription;
}

export function getEmailAddress(userClaims: UserClaims): string {
  return userClaims[
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
  ];
}

export function getClarkAccountId(
  userClaims: UserClaims | null
): number | undefined {
  const rawClarkAccountId = userClaims?.ClarkAccountId;
  if (!rawClarkAccountId) {
    return;
  }

  const clarkAccountId = parseInt(rawClarkAccountId);
  if (isNaN(clarkAccountId) || clarkAccountId <= 0) {
    return;
  }

  return clarkAccountId;
}

export function getActiveCatalog(
  userClaims: UserClaims | null
): number | undefined {
  const activeCatalogString = userClaims?.activeContentConfigurationToCatalogId;

  if (!userClaims || !activeCatalogString) {
    return;
  }

  const parsedActiveCatalog = Number.parseInt(activeCatalogString);

  if (isNaN(parsedActiveCatalog) || parsedActiveCatalog <= 0) {
    return;
  }

  return parsedActiveCatalog;
}

export function hasMultipleCatalogs(
  catalogSwitchLinkText: string | undefined,
  contentConfigurationToCatalogs:
    | ApiContentConfigurationToCatalogCacheDTO[]
    | undefined
): boolean {
  if (!catalogSwitchLinkText) {
    return false;
  }

  return (
    !!contentConfigurationToCatalogs &&
    contentConfigurationToCatalogs.length > 1
  );
}

/**
 * Returns true if the User Layout is set to a non-Primary Catalog.
 * @param userClaims
 * @param contentConfigurationToCatalogs
 * @returns
 */
export function isNonPrimaryCatalog(
  userClaims: UserClaims,
  contentConfigurationToCatalogs:
    | ApiContentConfigurationToCatalogCacheDTO[]
    | undefined
): boolean {
  if (
    !contentConfigurationToCatalogs ||
    contentConfigurationToCatalogs.length <= 1
  ) {
    return false;
  }

  const activeCatalog = getActiveCatalog(userClaims);
  if (!activeCatalog) {
    return false;
  }

  const currentCatalogInList = contentConfigurationToCatalogs.find(
    (catalog) => catalog.contentConfigurationToCatalogId === activeCatalog
  );
  if (!currentCatalogInList) {
    return false;
  }

  return !currentCatalogInList.isPrimary;
}

export function isMultiAccount(userClaims: UserClaims): boolean {
  return userClaims.accountTypeName === "Multi Account";
}
