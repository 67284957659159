import { FC, HTMLAttributes } from "react";
import { concat, props } from "@Utilities/string";
import { DataTypes } from "@Types/element";

export type InputWrapperProps = HTMLAttributes<HTMLElement> &
    DataTypes & {
        element?: "div" | "fieldset" | "span";
        required?: boolean;
        theme?: string;
    };

/**
 * A component used to associate a wrapper to an input.
 *
 * It is not recommended to use this component directly.
 * Instead, use the other Zest input components (phone, text, email, checkbox, number, radio, select, textarea).
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-inputs)}
 */
const InputWrapper: FC<InputWrapperProps> = ({
    children,
    className,
    element = "div",
    required,
    theme,
    ...rest
}) => {
    const Element = element;

    const classes = concat(theme, className);

    const conditionalProps = props({
        required: {
            condition: required && element === "fieldset",
            value: required,
        },
    });

    return (
        <Element {...rest} {...conditionalProps} className={classes}>
            {children}
        </Element>
    );
};

export { InputWrapper };
