import {
    JSX,
    PropsWithChildren,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
} from "react";
import { concat } from "@Utilities/string";
import { HTMLDataAttributes } from "@Types/element";
import {
    useUuid,
    useTabTrap,
    PlacementTypes,
    useFloating,
} from "@Utilities/hooks";
import { Button } from "@Components/button";
import { useStore } from "@Utilities/hooks/useStoreData";
import { usePopoverContext } from "./context";

type ElementRootProps = "id";

export type PopoverContentProps = {
    className?: string;
    element?: "div" | "p" | "span";
    elementProps?: Omit<HTMLDataAttributes, ElementRootProps>;
    headerProps?: HTMLDataAttributes & { spriteSheetHref?: string };
    id: string;
    offset?: number;
    placement?: PlacementTypes;
    restrictFocus?: boolean;
    title?: string;
    wrapper?: "div" | "p" | "span";
    wrapperProps?: HTMLDataAttributes;
};

const PopoverContent = ({
    children,
    className,
    element = "div",
    elementProps = {},
    headerProps = {},
    id: controlledId,
    offset: controlledOffset,
    placement: controlledPlacement,
    restrictFocus: controlledRestrictFocus,
    title,
    wrapper = "div",
    wrapperProps = {},
}: PropsWithChildren<PopoverContentProps>): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const {
        close,
        contentProps = {},
        register,
        restrictFocus: inheritedRestrictFocus,
        store,
    } = usePopoverContext();

    const internalId = useUuid("zest-popover");
    const id = controlledId || internalId;
    const Wrapper = wrapper;
    const Element = element;
    const placement = controlledPlacement ?? contentProps.placement;
    const offset = controlledOffset ?? contentProps.offset ?? 4;
    const caretRef = useRef<HTMLDivElement>(null);
    const { beginTrap, initTrap, releaseTrap } = useTabTrap({
        contentRef: ref,
    });
    const {
        adjustedPlacement,
        float,
        setAnchor,
        setCaret,
        setFloating,
        setWrapper,
        unFloat,
        x,
        y,
    } = useFloating({
        placement,
        offset,
        caretOffset: { x: 13, y: 13 },
    });
    const restrictFocus = controlledRestrictFocus ?? inheritedRestrictFocus;
    const isOpen = useStore(store, function selectIsOpenId(state) {
        return state.isOpen.has(id);
    });

    const wrapperClasses = concat(
        isOpen ? "visible" : "hidden",
        wrapperProps.className,
        contentProps.wrapperProps?.className,
        "zest-popover-content"
    );

    const classes = concat(
        className,
        contentProps.elementProps?.className,
        "zest-popover-body",
        !title && "no-header"
    );

    const handleCloseButtonClick = (): void => {
        close(id);
        const trigger =
            document.querySelector<HTMLElement>(`[data-content="${id}"]`) ||
            document.querySelector(`.zest-popover-trigger`);
        if (trigger) {
            trigger.focus();
        }
    };

    useEffect(() => {
        register(id, "contents");
    }, [id, register]);

    /** Handles enabling / disabling the tab trap */
    useEffect(() => {
        if (isOpen) {
            restrictFocus ? beginTrap() : initTrap();
        } else {
            releaseTrap();
        }
    }, [isOpen, restrictFocus, beginTrap, releaseTrap, initTrap]);

    /** Handles positioning the content before the browser displays the content */
    useLayoutEffect(() => {
        isOpen ? float() : unFloat();
        return function popoverFloatingCleanup(): void {
            unFloat();
        };
    }, [isOpen, float, unFloat]);

    /**
     * Adds initial page load float to prevent hidden popover from extending page
     * Adds resize listener to reposition popover on resize
     **/
    useEffect(() => {
        float();
        const handleResize = (): void => {
            float();
        };
        window.addEventListener("resize", handleResize);
        return () => {
            unFloat();
            window.removeEventListener("resize", handleResize);
        };
    }, [float, unFloat]);

    /** Handles finding the elements needed for the useFloating hook */
    useLayoutEffect(() => {
        if (wrapperRef.current) {
            setFloating(wrapperRef.current);
            let parentWithPosition = wrapperRef.current.parentElement;
            while (parentWithPosition !== document.body) {
                if (parentWithPosition instanceof HTMLElement) {
                    const { position: parentPosition } =
                        getComputedStyle(parentWithPosition);
                    if (parentPosition !== "static") {
                        break;
                    }
                    parentWithPosition = parentWithPosition.parentElement;
                }
            }
            if (parentWithPosition) {
                setWrapper(parentWithPosition);
            }
        }
        const anchor =
            document.querySelector<HTMLElement>(`[data-content="${id}"]`) ||
            document.querySelector(`.zest-popover-trigger`);
        if (anchor) {
            setAnchor(anchor);
        }
        if (caretRef.current) {
            setCaret(caretRef.current);
        }
    }, [id, setAnchor, setWrapper, setFloating, setCaret]);

    const handleClickOutside = useCallback<(e: PointerEvent) => void>(
        (e): void => {
            if (!wrapperRef.current) {
                console.error(
                    "%cZest Error:\n",
                    "background-color: red; color: yellow; font-size: xsmall",
                    "Popover click outside failed. The ref is not available."
                );
            } else {
                const isTrigger = document
                    .querySelector<HTMLElement>(`[data-content="${id}"]`)
                    ?.contains(e.target as Node);

                if (!wrapperRef.current?.contains(e.target as Node)) {
                    if (isOpen && !isTrigger) {
                        close(id);
                    }
                }
            }
        },
        [id, isOpen, close]
    );

    useEffect(
        function initpopoverClickOutside() {
            document.addEventListener("pointerup", handleClickOutside);
            return function popoverClickOutsideCleanup(): void {
                document.removeEventListener("pointerup", handleClickOutside);
            };
        },
        [handleClickOutside]
    );

    return (
        <Wrapper
            {...(contentProps.wrapperProps || {})}
            {...wrapperProps}
            id={id}
            ref={wrapperRef}
            className={wrapperClasses}
            style={{ top: `${y}px`, left: `${x}px` }}
        >
            {title && (
                <div
                    className={concat(
                        "zest-popover-header",
                        headerProps.className
                    )}
                >
                    <div className="zest-popover-title">{title}</div>
                    <Button
                        type="button"
                        aria-label="Close popover"
                        iconProps={{
                            name: "ri-close-line",
                            spriteSheetHref: headerProps.spriteSheetHref,
                        }}
                        size="small"
                        variant="white"
                        onClick={handleCloseButtonClick}
                    />
                </div>
            )}
            {!title && (
                <Button
                    type="button"
                    aria-label="Close popover"
                    iconProps={{
                        name: "ri-close-line",
                        spriteSheetHref: headerProps.spriteSheetHref,
                    }}
                    className="zest-popover-close no-header"
                    size="small"
                    variant="white"
                    onClick={handleCloseButtonClick}
                />
            )}
            <Element {...elementProps} ref={ref} className={classes}>
                {children}
            </Element>
            <div
                ref={caretRef}
                className={concat(
                    "zest-popover-caret",
                    adjustedPlacement.current,
                    !title && "no-header"
                )}
            />
        </Wrapper>
    );
};

export { PopoverContent };
