import { useCallback, useRef } from "react";
import {
    Cords,
    Rect,
    useSharedFloating,
    UseSharedFloating,
} from "./useSharedFloating";

export const placements = {
    TopRight: "top-right",
    Top: "top",
    TopLeft: "top-left",
    RightTop: "right-top",
    Right: "right",
    RightBottom: "right-bottom",
    BottomLeft: "bottom-left",
    Bottom: "bottom",
    BottomRight: "bottom-right",
    LeftBottom: "left-bottom",
    Left: "left",
    LeftTop: "left-top",
} as const;

export type PlacementTypes = (typeof placements)[keyof typeof placements];

export const getRect = (elem: HTMLElement | SVGElement): Rect => {
    const rect = elem.getBoundingClientRect();
    return {
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
    };
};

type UseFloatingProps = {
    /**
     * @param x: controls top and bottom positioning of content
     * @param y: controls left and right positioning of content
     * @param yLR: control right-top and left-top positioning of content
     */
    caretOffset: { x: number; y: number; yLR?: number };
    offset?: number;
    placement?: PlacementTypes;
};

type UseFloating = UseSharedFloating & {
    /**
     *  Set the caret element to be positioned relative to the floating element.
     * This is only used when using the "absolute" position.
     * @example
     * if (caretRef.current) {
     *  setCaret(caretRef.current)
     * }
     */
    setCaret: (elem: HTMLDivElement) => void;
};

const useFloating = ({
    caretOffset,
    offset = 4,
    placement = placements.Top,
}: UseFloatingProps): UseFloating => {
    const floating = useRef<HTMLElement | null>(null);
    const anchor = useRef<HTMLElement | null>(null);
    const wrapper = useRef<HTMLElement | null>(null);
    const caret = useRef<HTMLDivElement | null>(null);
    const placementsToCheck = useRef<PlacementTypes[]>([
        ...Object.values(placements),
    ]);

    const setCaret = useCallback<UseFloating["setCaret"]>(
        (elem) => (caret.current = elem),
        []
    );

    /** Returns the x & y position of the floating element relative to an anchor element */
    const positionResolver = useCallback(
        (placement: PlacementTypes): Rect => {
            if (!anchor.current || !caret.current || !floating.current)
                return { height: 0, width: 0, x: 0, y: 0 };
            const anchorRect = getRect(anchor.current);
            const floatingRect = getRect(floating.current);
            const caretRect = getRect(caret.current);

            const centerX =
                anchorRect.x + anchorRect.width / 2 - floatingRect.width / 2;
            const centerY =
                anchorRect.y + anchorRect.height / 2 - floatingRect.height / 2;

            const isPopover =
                floating.current.classList.value.includes("popover");
            const calculatedCaretOffset = isPopover
                ? caretRect.width / 2 - 1
                : caretRect.width / 2;

            const anchorCenterX = anchorRect.width / 2;
            const anchorCenterY = anchorRect.height / 2;

            const caretOffsetY = caretOffset.yLR || caretOffset.y;

            const leftX =
                anchorRect.x +
                anchorCenterX -
                calculatedCaretOffset -
                caretOffset.x;
            const rightX =
                anchorRect.x +
                anchorCenterX -
                floatingRect.width +
                calculatedCaretOffset +
                caretOffset.x;
            const topY =
                anchorRect.y +
                anchorCenterY -
                calculatedCaretOffset -
                caretOffsetY;
            const bottomY =
                anchorRect.y +
                anchorCenterY -
                floatingRect.height +
                calculatedCaretOffset +
                caretOffsetY;

            const resolver: {
                [P in PlacementTypes]: () => Cords;
            } = {
                [placements.Top]: () => ({
                    x: centerX,
                    y:
                        anchorRect.y -
                        floatingRect.height -
                        offset -
                        calculatedCaretOffset,
                }),
                [placements.TopLeft]: () => ({
                    x: leftX,
                    y:
                        anchorRect.y -
                        floatingRect.height -
                        offset -
                        calculatedCaretOffset,
                }),
                [placements.TopRight]: () => ({
                    x: rightX,
                    y:
                        anchorRect.y -
                        floatingRect.height -
                        offset -
                        calculatedCaretOffset,
                }),
                [placements.Bottom]: () => ({
                    x: centerX,
                    y:
                        anchorRect.y +
                        anchorRect.height +
                        offset +
                        calculatedCaretOffset,
                }),
                [placements.BottomLeft]: () => ({
                    x: leftX,
                    y:
                        anchorRect.y +
                        anchorRect.height +
                        offset +
                        calculatedCaretOffset,
                }),
                [placements.BottomRight]: () => ({
                    x: rightX,
                    y:
                        anchorRect.y +
                        anchorRect.height +
                        offset +
                        calculatedCaretOffset,
                }),
                [placements.Right]: () => ({
                    x:
                        anchorRect.x +
                        anchorRect.width +
                        offset +
                        calculatedCaretOffset,
                    y: centerY,
                }),
                [placements.RightTop]: () => ({
                    x:
                        anchorRect.x +
                        anchorRect.width +
                        offset +
                        calculatedCaretOffset,
                    y: topY,
                }),
                [placements.RightBottom]: () => ({
                    x:
                        anchorRect.x +
                        anchorRect.width +
                        offset +
                        calculatedCaretOffset,
                    y: bottomY,
                }),
                [placements.Left]: () => ({
                    x:
                        anchorRect.x -
                        floatingRect.width -
                        offset -
                        calculatedCaretOffset,
                    y: centerY,
                }),
                [placements.LeftTop]: () => ({
                    x:
                        anchorRect.x -
                        floatingRect.width -
                        offset -
                        calculatedCaretOffset,
                    y: topY,
                }),
                [placements.LeftBottom]: () => ({
                    x:
                        anchorRect.x -
                        floatingRect.width -
                        offset -
                        calculatedCaretOffset,
                    y: bottomY,
                }),
            };

            return {
                ...floatingRect,
                ...resolver[placement](),
            };
        },
        [caretOffset.x, caretOffset.y, caretOffset.yLR, offset]
    );

    const sharedHook = useSharedFloating<PlacementTypes>({
        anchor,
        floating,
        offset,
        placement,
        wrapper,
        positionResolver,
        placementsToCheck,
    });

    return {
        setCaret,
        ...sharedHook,
    };
};

export { useFloating };
