import { FC, HTMLAttributes, useContext, useRef } from "react";
import { classNames, concat } from "@Utilities/string";
import { Icon as ZestIcon, IconProps } from "@Components/icon";
import { ListContext } from "./context";
import "./listItem.styles.css";
import { DataTypes } from "../../types/element";

export type ListItemImgProps = DataTypes & {
    alt?: string;
};

export type ListItemProps = HTMLAttributes<HTMLElement> &
    DataTypes & {
        Icon?: FC<IconProps> | null;
        iconProps?: IconProps;
        imageProps?: ListItemImgProps;
        listStyle?:
            | "bullet"
            | "icon"
            | "number"
            | "outline"
            | "square"
            | "url"
            | "none";
        theme?: string;
        wrapperClassName?: string;
    };

/**
 * A list item component that represents an item in a list.
 *
 * This component must be wrapped in a list component.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/experimental-alpha-list--item)}
 */
const ListItem: FC<ListItemProps> = ({
    children,
    className: controlledClassName,
    Icon: ControlledIcon,
    iconProps: controlledIconProps = {},
    listStyle: controlledListStyle,
    theme: controlledTheme,
    wrapperClassName: controlledWrapperClassName = "",
    ...rest
}) => {
    const listContext = useContext(ListContext) || {};
    const listItemRef = useRef<HTMLLIElement>(null);

    const theme = controlledTheme || listContext.theme;
    const className = concat(
        controlledClassName,
        listContext.className,
        "relative"
    );
    const listItemStyle =
        controlledListStyle || listContext.listStyle || "bullet";

    const Icon = ControlledIcon || listContext.Icon || ZestIcon;
    const mergedIconProps = {
        ...controlledIconProps,
        ...(listContext.iconProps || {}),
    };

    const customMarker = listItemStyle === "icon" || listItemStyle === "url";

    const listItemStyles: Record<string, string> = {
        bullet: "list-disc",
        number: "list-decimal",
        outline: "list-[circle]",
        square: "list-[square]",
        none: "list-none",
    };

    const conditional = classNames({
        ["grid"]: customMarker,
        ["grid-cols-[auto_1fr]"]: customMarker,
    });

    const classes = concat(
        "zest-list-item",
        className,
        theme,
        listItemStyles[listItemStyle],
        ...conditional
    );

    const wrapperClassName = concat(
        "zest-list-marker",
        "shrink-0",
        "w-[1em]",
        controlledWrapperClassName,
        listContext.wrapperClassName
    );

    return (
        <li
            {...rest}
            ref={listItemRef}
            className={classes}
            data-list-style={customMarker ? "custom" : "standard"}
            data-testid="listItem"
        >
            {listItemStyle === "icon" && (
                <span className={wrapperClassName}>
                    <Icon {...mergedIconProps} viewBox="0 0 24 24" />
                </span>
            )}
            {children}
        </li>
    );
};

export default ListItem;
