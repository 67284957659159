import { useRef, useEffect } from "react";

/** Hook for values that should remain consistent throughout lifecycle of component.
 * Throws an error if value changes
 * @example
 * useConsistentValue(
 *    "firstName"
 *    "The form input name should not change after the field has been registered."
 * )
 */
const useConsistentValue = <T>(value: T, error: string): void => {
    const valueRef = useRef(value);
    useEffect(() => {
        if (valueRef.current !== value) {
            throw new Error(error);
        }
    }, [value, error]);
};

export { useConsistentValue };
