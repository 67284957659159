import { Reducer } from "react";
import { FormValues } from "../../types";
import { ResetAction } from "./types";

const resetAction: Reducer<FormValues, ResetAction> = (state) => {
    const clonedState = { ...state };

    Object.entries(clonedState).forEach(([key, formField]) => {
        clonedState[key] = {
            value: formField.defaultValue,
            errors: [],
            id: formField.id,
            touched: false,
            defaultValue: formField.defaultValue,
            required: formField.required,
        };
    });

    return {
        ...clonedState,
    };
};

export default resetAction;
