import ReviewOrdersButton from "components/Header/AdminBar/components/ReviewOrdersButton";
import ReviewPastDueInvoicesButton from "components/Header/AdminBar/components/ReviewPastDueInvoicesButton";
import { concat } from "util/string";

export const adminReviewButtonGroupId = "header-admin-review-button-container";

/**
 *  Container for AdminBar buttons.
 * Can flex vertically with one button per row.
 * @return {*}  {JSX.Element}
 */
export default function AdminReviewButtonGroup({
  className,
  isUserAbleToViewPendingOrders,
  ordersWaitingForApprovalCount,
  isPastDueInvoicesNoticeEnabled,
  id = adminReviewButtonGroupId,
}: {
  readonly className?: string;
  readonly isUserAbleToViewPendingOrders?: boolean;
  readonly ordersWaitingForApprovalCount?: number;
  readonly isPastDueInvoicesNoticeEnabled?: boolean;
  readonly id?: string;
}): React.ReactElement {
  return (
    <div id={id} className={concat(className)}>
      {isUserAbleToViewPendingOrders && (
        <ReviewOrdersButton
          ordersWaitingForApprovalCount={ordersWaitingForApprovalCount}
        />
      )}
      {isPastDueInvoicesNoticeEnabled && <ReviewPastDueInvoicesButton />}
    </div>
  );
}
