/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/**
 * determine if we are in fullscreen mode
 * @param {object} el
 */
export function isFullScreenElement(el: any): boolean {
    if (el && "current" in el && el.current) {
        return Boolean(
            document.fullscreenElement === el.current ||
                document.mozFullScreenElement === el.current ||
                document.webkitFullscreenElement === el.current ||
                document.msFullscreenElement === el.current
        );
    }

    return Boolean(
        document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement ||
            document.fullscreen ||
            document.mozFullScreen ||
            document.webkitIsFullScreen ||
            document.fullScreenMode
    );
}
