import { concat } from "util/string";
import sprites from "assets/sprites.svg";

export type ChevronProps = {
  className?: string;
  fillColor?: string;
  strokeWidth?: string | number;
};

/**
 *
 * @param className Tailwind styles to apply. Use text-{color} to apply icon color.
 * @param fill Apply a fill color, css value for inner SVG element.
 * @returns SVG icon JSX.Element
 */
const Chevron = ({
  className,
  fillColor = "current",
  strokeWidth,
}: ChevronProps): JSX.Element => {
  return (
    <svg
      fill={fillColor}
      className={concat(className, "stroke-current", "fill-current")}
      strokeWidth={strokeWidth}
      data-testid="chevron"
      aria-hidden={true}
    >
      <use xlinkHref={sprites + "#chevron"} />
    </svg>
  );
};

export default Chevron;
