import DOMPurify from "dompurify";
import React from "react";

/**
 * React Element with content provided from an external source to be sanitized by DOMPurify.
 * To be used instead of dangerouslySetInnerHTML.
 *
 * @param element - React element to clone with sanitized innerHtml.
 * @param innerHtml - Content to sanitize and set as innerHtml.
 * @returns
 */
export default function PurifiedDOMElement({
  element,
  innerHtml,
}: {
  element: React.ReactElement;
  innerHtml: string | TrustedHTML;
}): React.ReactElement {
  return React.cloneElement(element, {
    dangerouslySetInnerHTML: {
      __html: DOMPurify.sanitize(innerHtml as string | Node),
    },
  });
}
