import { StoreEmptyOrderGuideForm } from "components/OrderGuides/Modals/CreateOrderGuideModal";
import { gatewayApi } from "util/requests/gatewayApi";
import { ErrorMessages } from "util/requests/response.types";

export async function storeEmptyOrderGuide(
  form: StoreEmptyOrderGuideForm
): Promise<{ orderGuideId?: number; errors?: ErrorMessages }> {
  try {
    const orderGuideId = await gatewayApi.post<
      StoreEmptyOrderGuideForm,
      number
    >({ urlSegment: "order-guides/store", body: form, throwErrorCodes: [422] });

    if (!orderGuideId) {
      return { errors: { store: ["Failed to store empty order guide."] } };
    }

    return { orderGuideId };
  } catch (error) {
    return {
      errors: JSON.parse((error as Error).message) as ErrorMessages,
    };
  }
}
