import { ChangeEvent, createContext, useContext } from "react";
import { noop } from "@Utilities/functions";

type RadioContextType = {
    name: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    optional: boolean;
    required: boolean;
    value: string;
};

/** This creates our context consumer and provider
 * @example
 * const MyProvider = () => {
 *    return <RadioContext.Provider value={}>
 *        {children}
 *    </RadioContext.Provider>
 * }
 *
 * const MyConsumer = () => {
 *   const context = useContext(RadioContext);
 *   return (
 *      <div></div>
 *   )
 * }
 */
export const RadioContext = createContext<RadioContextType | undefined>({
    name: "RADIO",
    optional: false,
    required: false,
    value: "",
    onChange: noop,
});

/** Gets the radio context. Throws if called outside a `<RadioContext.Provider>` */
export const useRadioContext = (): RadioContextType => {
    const context = useContext(RadioContext);

    if (!context) {
        throw new Error(
            "useRadioContext must be used within a <RadioContext.Provider>. Radio.Group can be used to provide the context."
        );
    }

    return context;
};
