import { gatewayApi } from "util/requests/gatewayApi";
import { ErrorMessages } from "util/requests/response.types";

export type EmailAccountSpecialistRequest = {
  message: string;
  subject: string;
  itemNumber?: string;
  pageUrl: string;
};

export const emailAccountSpecialistUrl = "accounts/contact-specialist/";

export async function emailAccountSpecialist(
  request: EmailAccountSpecialistRequest
): Promise<{ success: boolean; errors: ErrorMessages | undefined }> {
  try {
    const response =
      await gatewayApi.post_NoResponseContent<EmailAccountSpecialistRequest>({
        urlSegment: emailAccountSpecialistUrl,
        throwErrorCodes: [422],
        body: request,
      });
    if (response) {
      return {
        success: true,
        errors: undefined,
      };
    }
    return {
      success: false,
      errors: { error: ["Unknown error occured"] },
    };
  } catch (error) {
    const e = error as Error;
    const errors = JSON.parse(e.message) as ErrorMessages;
    return {
      success: false,
      errors: errors,
    };
  }
}
