import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import debounce from "lodash/debounce";
import { LayoutProps } from "layouts/Layout.types";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import ScrollToTopWrapper from "components/ScrollToTopWrapper";
import useLayoutAPI from "layouts/api/useLayoutAPI";
import { setFaviconUrl } from "util/favicon";
import useCompanyPageTitle from "util/hooks/useCompanyPageTitle";
import { applyTheme } from "themes/Themes";
import { addScrollbarWidth } from "util/scroll";
import useCartItemCountApi from "components/Cart/useCartItemCountApi";
import { CartNumberProvider } from "contexts/cart/cartnumber.context";
import { useCartNumberStore } from "contexts/cart/useCartNumberStore";
import useGoogleAnalyticsPageview from "util/GoogleAnalytics/useGoogleAnalyticsPageview";
import { RequestQuoteModal } from "components/Quotes/RequestQuoteModal";
import SkipNavButton from "components/SkipNavButton";
import { InertContentWrapper } from "components/InertContent/InertContentWrapper";

/**
 * Main Wrapper Component which wraps all authenticated content.
 *
 * @export
 * @param {LayoutProps} props
 * @return {*}  {JSX.Element}
 */
export default function Main(props: LayoutProps): JSX.Element {
  const { data: userLayoutData, isLoading, isError } = useLayoutAPI();
  const [isThemeLoading, setIsThemeLoading] = useState(true);
  const location = useLocation();
  const {
    data,
    isLoading: isLoadingCartNumber,
    refetch: refetchCartNumber,
  } = useCartItemCountApi();
  const debouncedRefetchCartNumber = useMemo(
    () => debounce(refetchCartNumber, 500),
    [refetchCartNumber]
  );
  const cartNumber = useCartNumberStore(refetchCartNumber);

  if (isError && !userLayoutData) {
    throw new Error(
      "Critical Error - could not fetch first-time User Layout data."
    );
  }

  useEffect(
    function setThemeEffect() {
      if (userLayoutData) {
        applyTheme(
          userLayoutData.colorScheme,
          userLayoutData.useWhiteHeaderBackgroundColor,
          () => setIsThemeLoading(false) // callbackFn
        );
      }
    },
    [userLayoutData]
  );

  useEffect(
    function setFaviconUrlEffect() {
      if (userLayoutData) {
        setFaviconUrl(userLayoutData.faviconUrl);
      }
    },
    [userLayoutData]
  );

  const setCartNumber = cartNumber.setCartNumber;
  useEffect(
    function setCartNumberEffect() {
      if (userLayoutData) {
        setCartNumber(userLayoutData.cartItemCount);
      }
    },
    [userLayoutData, setCartNumber]
  );

  useEffect(() => {
    debouncedRefetchCartNumber();
  }, [location, debouncedRefetchCartNumber]);

  useEffect(() => {
    if (!isLoadingCartNumber) {
      cartNumber.setCartNumber(data?.totalItemCount ?? 0);
    }
  }, [cartNumber, data, isLoadingCartNumber]);

  useEffect(addScrollbarWidth, [addScrollbarWidth]);

  useCompanyPageTitle(userLayoutData?.defaultPageTitle);

  useGoogleAnalyticsPageview(userLayoutData, location.pathname);

  if (isLoading || isThemeLoading) {
    return <></>;
  }

  return (
    <CartNumberProvider {...cartNumber}>
      <ScrollToTopWrapper>
        <InertContentWrapper>
          <SkipNavButton />
          <Header />
        </InertContentWrapper>
        <main id="main">{props.children}</main>
        <InertContentWrapper>
          <RequestQuoteModal />
          <Footer />
        </InertContentWrapper>
      </ScrollToTopWrapper>
    </CartNumberProvider>
  );
}
