import { createElement, ImgHTMLAttributes } from "react";

type DefaultProps = ImgHTMLAttributes<HTMLImageElement>;

export type ImageProps = DefaultProps & {
  width: NonNullable<DefaultProps["width"]>;
  height: NonNullable<DefaultProps["height"]>;
};

/**
 * An <img> component with required width and height properties.
 *
 * **Props - Width & Height**
 *
 * The width and height properties tell the browser what size of the image will be once rendered allowing it to reserve the space needed to display the image. See {@link https://web.dev/articles/serve-images-with-correct-dimensions#avoid_layout_shifts_by_specifying_dimensions Googles article} on images for more information.
 * Source: https://tfs.clarkinc.biz/DefaultCollection/Webstaurant.StoreFront/_git/Webstaurant.StoreFront?path=/Webstaurant.UI/src/__alias__/image/index.tsx
 */
export default function Image(props: ImageProps): React.ReactElement {
  return createElement("img", props);
}
