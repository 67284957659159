import { HomeIcon } from "@heroicons/react/solid";
import ButtonGroup from "components/buttons/ButtonGroup";
import ButtonLink from "components/buttons/ButtonLink";
import { concat } from "util/string";
import QuickSupply from "assets/logo/QuickSupply.svg";
import Pro from "assets/logo/Pro.svg";
import ClarkPro from "assets/logo/ClarkPro.svg";
import { HeaderButtonGroupTheme } from "components/Header/Header";
import useLayoutAPI from "layouts/api/useLayoutAPI";
import AppConstants from "appconstants.json";
import CategoryMenuLinks from "components/Header/components/CategoryMenuLinks";
import routes from "routes/routes";
import { getVendorFromHost } from "util/host";
import Image from "components/Image/Image";

export default function HeaderIconMenu({
  className,
  hideButtonLabels = false,
}: Readonly<{
  className?: string;
  hideButtonLabels?: boolean;
}>): React.ReactElement {
  const { data: userLayoutData } = useLayoutAPI();

  const logoUrl = userLayoutData?.logoUrl;
  const logoDesc = userLayoutData?.logoDescription
    ? userLayoutData?.logoDescription
    : "Home"; // ternary because it can be an empty string
  const hasNavigationLinks =
    !!userLayoutData?.navigationLinks &&
    userLayoutData.navigationLinks.length > 0;
  const hasMultipleCatalogs =
    !!userLayoutData?.contentConfigurationToCatalogs &&
    userLayoutData.contentConfigurationToCatalogs.length > 1;

  return (
    <div
      id="header-left-section"
      className={concat(
        className,
        "justify-self-start",
        "flex",
        "flex-row",
        "items-center",
        "min-w-fit"
      )}
    >
      <ButtonGroup className={HeaderButtonGroupTheme}>
        <HomeButton />
        {(hasNavigationLinks || hasMultipleCatalogs) && (
          <CategoryMenuLinks
            key="category-menu-links"
            hideButtonLabels={hideButtonLabels}
            navigationLinks={userLayoutData?.navigationLinks ?? []}
          />
        )}
      </ButtonGroup>
      <div
        data-testid="logo-container"
        className="flex flex-row ml-4 xl:ml-6 w-36 xl:w-60"
      >
        <a
          href={routes.Home.getPath()}
          title={logoDesc}
          aria-label="Go to Home Page"
        >
          {logoUrl ? (
            <img
              className="max-h-10 xl:max-h-16"
              src={logoUrl}
              alt={logoDesc}
              title={logoDesc}
            />
          ) : (
            <div className="h-10 w-10 xl:h-16 xl:w-16" />
          )}
        </a>
      </div>
    </div>
  );
}

function HomeButton(): React.ReactElement {
  const vendor = getVendorFromHost(window.location.hostname);
  let icon: React.ReactElement = (
    <HomeIcon className="h-5 w-5" aria-label="Home" />
  );
  let buttonStyle = "";

  if (vendor === "quicksupply") {
    icon = <Image width={25} height={25} src={QuickSupply} alt="Home" />;
  }

  if (vendor === "pro") {
    buttonStyle = "px-3"; // overrides @apply button-group
    if (AppConstants.enableLogoRebranding) {
      icon = <Image width={64} height={46} src={Pro} alt="Home" title="Home" />;
    } else {
      icon = (
        <Image width={32} height={23} src={ClarkPro} alt="Home" title="Home" />
      );
    }
  }

  return (
    <ButtonLink
      href={routes.Home.getPath()}
      id="header-home-button"
      iconOrientation="left"
      reloadDocument={true}
      className={concat(buttonStyle, "min-w-fit")}
      icon={icon}
      variant="group"
      size="group"
      label="Home"
      hideLabel={true}
    />
  );
}
