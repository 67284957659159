import LocalStorage from "authentication/models/LocalStorage";
import { getDefaultBaseRequestUrl } from "util/Environment";
import { api } from "util/requests/api";

/**
 * @description Gateway API functions which either throw an error or return a response. If the response is null or undefined, then the request failed.
 */
export const gatewayApi = {
  get,
  getAsBlob,
  post,
  post_NoResponseContent,
  put,
  put_NoResponseContent,
  delete: _delete,
};

/* Utility functions */
export function getUrl(urlSegment: string): string {
  return `${getDefaultBaseRequestUrl()}/web-bff/${urlSegment}`;
}

export function getAuthToken(): string {
  return localStorage.getItem(LocalStorage.authToken) ?? "";
}

export function getHeaders(): object {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

/* API functions */

/**
 * Type R: Specify the Response Body type (GET should always return a body)
 * @returns Response json body or response.ok, or undefined if a failure has been caught.
 */
export async function get<R>({
  urlSegment,
  keepAlive,
  ignoreErrorCodes = [],
  throwErrorCodes = [],
}: {
  urlSegment: string;
  keepAlive?: boolean;
  ignoreErrorCodes?: number[];
  throwErrorCodes?: number[];
}): Promise<R | undefined> {
  const response = await api.get({
    url: getUrl(urlSegment),
    authToken: getAuthToken(),
    keepAlive: keepAlive,
    headers: getHeaders(),
    ignoreErrorCodes,
    throwErrorCodes,
  });

  return response?.json();
}

/**
 * @returns Response as a blob, or undefined if a failure has been caught.
 */
export async function getAsBlob({
  urlSegment,
  ignoreErrorCodes = [],
  throwErrorCodes = [],
}: {
  urlSegment: string;
  ignoreErrorCodes?: number[];
  throwErrorCodes?: number[];
}): Promise<Blob | undefined> {
  const response = await api.get({
    url: getUrl(urlSegment),
    authToken: getAuthToken(),
    headers: getHeaders(),
    ignoreErrorCodes,
    throwErrorCodes,
  });

  if (!response?.ok) {
    return;
  }

  return await response.blob();
}

/**
 * Type T: Specify the Request Body type (can be undefined)
 * Type R: Specify the Response Body type (POST should always return a body)
 * @returns Response json body or response.ok, or undefined if a failure has been caught.
 */
export async function post<T, R>({
  urlSegment,
  body,
  ignoreErrorCodes = [],
  throwErrorCodes = [],
}: {
  urlSegment: string;
  body?: T;
  ignoreErrorCodes?: number[];
  throwErrorCodes?: number[];
}): Promise<R | undefined> {
  const response = await api.post<T>({
    url: getUrl(urlSegment),
    authToken: getAuthToken(),
    headers: getHeaders(),
    bodyParams: body,
    ignoreErrorCodes,
    throwErrorCodes,
  });
  return response?.json();
}

/**
 * Handle POSTs that don't return a response body
 * Type T: Specify the Request Body type
 * @returns response.ok, or undefined if a failure has been caught.
 */
export async function post_NoResponseContent<T>({
  urlSegment,
  body,
  ignoreErrorCodes = [],
  throwErrorCodes = [],
}: {
  urlSegment: string;
  body?: T;
  ignoreErrorCodes?: number[];
  throwErrorCodes?: number[];
}): Promise<boolean | undefined> {
  const response = await api.post<T>({
    url: getUrl(urlSegment),
    authToken: getAuthToken(),
    headers: getHeaders(),
    bodyParams: body,
    ignoreErrorCodes,
    throwErrorCodes,
  });
  return response?.ok;
}

/**
 * Type T: Specify the Request Body type
 * Type R: Specify the Response Body type (If the API doesn't return a body, then use the put_NoResponseContent)
 * @returns Response json body, or undefined if a failure has been caught.
 */
export async function put<T, R>({
  urlSegment,
  body,
  ignoreErrorCodes = [],
  throwErrorCodes = [],
}: {
  urlSegment: string;
  body: T;
  ignoreErrorCodes?: number[];
  throwErrorCodes?: number[];
}): Promise<R | undefined> {
  const response = await api.put<T>({
    url: getUrl(urlSegment),
    authToken: getAuthToken(),
    headers: getHeaders(),
    bodyParams: body,
    ignoreErrorCodes,
    throwErrorCodes,
  });

  return response?.json();
}

/**
 * Workaround function to handle PUTs that don't return the updated response body (which is an anti-REST pattern)
 * Type T: Specify the Request Body type
 * @returns response.ok, or undefined if a failure has been caught.
 */
export async function put_NoResponseContent<T>({
  urlSegment,
  body,
  ignoreErrorCodes = [],
  throwErrorCodes = [],
}: {
  urlSegment: string;
  body: T;
  ignoreErrorCodes?: number[];
  throwErrorCodes?: number[];
}): Promise<boolean | undefined> {
  const response = await api.put<T>({
    url: getUrl(urlSegment),
    authToken: getAuthToken(),
    headers: getHeaders(),
    bodyParams: body,
    ignoreErrorCodes,
    throwErrorCodes,
  });
  return response?.ok;
}

/**
 * @returns response.ok, or undefined if a failure has been caught.
 */
export async function _delete({
  urlSegment,
  ignoreErrorCodes = [],
  throwErrorCodes = [],
}: {
  urlSegment: string;
  ignoreErrorCodes?: number[];
  throwErrorCodes?: number[];
}): Promise<boolean | undefined> {
  const response = await api.delete({
    url: getUrl(urlSegment),
    authToken: getAuthToken(),
    headers: getHeaders(),
    ignoreErrorCodes,
    throwErrorCodes,
  });
  return response?.ok;
}
