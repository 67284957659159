import { Link } from "react-router-dom";
import Chevron from "assets/Chevron";
import MenuButton from "components/buttons/MenuButton";
import { ApiOrderGuideLayoutDTO } from "layouts/api/layoutAPI.types";
import { buttonTertiaryTheme } from "components/buttons/buttons.styles";
import { concat } from "util/string";
import { toggle } from "components/Modal/Modal";
import { createOrderGuideModalId } from "components/OrderGuides/Modals/CreateOrderGuideModal";
import {
  OrderGuideResult,
  OrderGuideType,
} from "routes/OrderGuides/OrderGuides.types";
import routes from "routes/routes";
import MenuSectionHeader from "components/buttons/MenuSectionHeader";

export default function OrderGuidesMenuButton({
  wrapperClassNames,
  buttonClassNames,
  canEditOrderGuides,
  customGroupOrderGuides,
  orderGuides,
}: {
  readonly wrapperClassNames?: string;
  readonly buttonClassNames?: string;
  readonly canEditOrderGuides: boolean;
  readonly customGroupOrderGuides: ApiOrderGuideLayoutDTO[];
  readonly orderGuides: ApiOrderGuideLayoutDTO[];
}): JSX.Element {
  let menuItems = [
    ...MenuSectionHeader({
      sectionName: "user guides",
      countSetBefore: 0,
      countSet: orderGuides.length,
    }),
    ...getOrderGuideMenuLinks(orderGuides, "account"),
    ...MenuSectionHeader({
      sectionName: "company guides",
      countSetBefore: orderGuides.length,
      countSet: customGroupOrderGuides.length,
    }),
    ...getOrderGuideMenuLinks(customGroupOrderGuides, "company"),
  ];

  if (canEditOrderGuides) {
    menuItems = [
      <button
        className={concat(
          "w-full py-2.5 flex gap-2.5 justify-center items-center",
          buttonTertiaryTheme
        )}
        id="create-order-guide-button"
        key="create-order-guide-button"
        onClick={(): void => toggle(createOrderGuideModalId)}
      >
        <span className="font-medium text-sm">Create New Order Guide</span>
      </button>,
      ...menuItems,
    ];
  }

  return (
    <MenuButton
      id="header-admin-order-guides-button"
      wrapperClassNames={wrapperClassNames}
      className={buttonClassNames}
      label="Order Guides"
      icon={<Chevron className="h-[6px] w-[10px]" />}
      iconOrientation="right"
      variant="secondary"
      menuClassNames="w-64 z-40 max-h-[400px] overflow-y-auto"
      menu={menuItems}
    />
  );
}

function getOrderGuideMenuLinks(
  orderGuides: ApiOrderGuideLayoutDTO[],
  orderGuideType: OrderGuideType,
  className?: string
): React.ReactElement[] {
  return orderGuides.map(({ orderGuideId, name }) => (
    <Link
      className={className}
      key={orderGuideId}
      to={
        orderGuideType === "account"
          ? routes.OrderGuideView.getPath(orderGuideId + "")
          : routes.OrderGuideCompanyView.getPath(orderGuideId + "")
      }
    >
      {name}
    </Link>
  ));
}

export function OrderGuidesMenuDropdown({
  orderGuides,
  customGroupOrderGuides,
  currentOrderGuide,
}: {
  orderGuides: ApiOrderGuideLayoutDTO[];
  customGroupOrderGuides: ApiOrderGuideLayoutDTO[];
  currentOrderGuide?: OrderGuideResult;
}): React.ReactElement {
  const menuItems: React.ReactElement[] = [];

  let currentGuideLink = undefined;
  const isCurrentGuideCompany =
    orderGuides.filter(
      (f) => f.orderGuideId === currentOrderGuide?.orderGuideId
    ).length === 0;

  if (currentOrderGuide) {
    currentGuideLink = getOrderGuideMenuLinks(
      [
        {
          orderGuideId: currentOrderGuide.orderGuideId,
          name: currentOrderGuide.name,
        },
      ],
      isCurrentGuideCompany ? "company" : "account",
      "font-bold bg-gray-100"
    );
  }

  menuItems.push(
    <div className="font-bold border-b">
      <Link to={routes.OrderGuideLandingPage.getPath()}>My Order Guides</Link>
    </div>
  );

  if (currentGuideLink && !isCurrentGuideCompany) {
    menuItems.push(currentGuideLink[0]);
  }

  getOrderGuideMenuLinks(
    orderGuides.filter(
      (f) => f.orderGuideId !== currentOrderGuide?.orderGuideId
    ),
    "account"
  ).forEach((m) => {
    menuItems.push(m);
  });

  menuItems.push(
    <div className="font-bold border-b">
      <Link to={routes.OrderGuideCompanyLandingPage.getPath()}>
        Company Guides
      </Link>
    </div>
  );
  if (currentGuideLink && isCurrentGuideCompany) {
    menuItems.push(currentGuideLink[0]);
  }

  getOrderGuideMenuLinks(
    customGroupOrderGuides.filter(
      (f) => f.orderGuideId !== currentOrderGuide?.orderGuideId
    ),
    "company"
  ).forEach((m) => {
    menuItems.push(m);
  });

  return (
    <MenuButton
      id="header-admin-dropdown-order-guides-button "
      className="border-none bg-gray-200 "
      label="Your Guides"
      icon={<Chevron className="h-[6px] w-[10px]" />}
      iconOrientation="right"
      variant="tertiary"
      menuClassNames="w-64 "
      menu={menuItems}
    />
  );
}
