import { FC, HTMLAttributes } from "react";
import { concat } from "@Utilities/string";
import { DataTypes } from "@Types/element";

export type InputHelperProps = HTMLAttributes<HTMLElement> &
    DataTypes & {
        element?: "p" | "span" | "div";
        theme?: string;
    };

/**
 * A component used to associate an helper to an input.
 *
 * It is not recommended to use this component directly.
 * Instead, use the other Zest input components (phone, text, email, checkbox, number, radio, select, textarea).
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-inputs)}
 */
const InputHelper: FC<InputHelperProps> = ({
    children,
    className,
    element = "span",
    ...rest
}) => {
    const Element = element;
    const classes = concat(className, "zest-input-helper");

    /** Short circuit as early as possible to reduce checks later checks on */
    if (!children) {
        return null;
    }

    return (
        <Element {...rest} className={classes}>
            {children}
        </Element>
    );
};

export { InputHelper };
