import {
    FC,
    HTMLAttributes,
    useEffect,
    Children,
    isValidElement,
    cloneElement,
} from "react";
import { DataTypes } from "@Types/element";
import { concat } from "@Utilities/string";
import { useDropdownMenuContext } from "./dropdownMenu.context";

export type DropdownMenuItemProps = HTMLAttributes<HTMLLIElement> &
    DataTypes & {
        divider?: boolean;
        id: string;
    };

const DropdownMenuItem: FC<DropdownMenuItemProps> = ({
    children,
    className,
    divider,
    id,
    ...rest
}) => {
    const dropdownMenuContext = useDropdownMenuContext();
    const { register } = dropdownMenuContext;

    useEffect(() => {
        return register(id);
    }, [register, id]);

    const classes = concat("zest-dropdownMenu-list-item", className);

    return (
        <>
            {divider && <div className={"zest-divider"}></div>}
            <li className={classes} {...rest}>
                {Children.map(children, (child) => {
                    return isValidElement(child)
                        ? cloneElement(child, {
                              role: "menuitem",
                              tabIndex: -1,
                              id,
                              ...child.props,
                          })
                        : child;
                })}
            </li>
        </>
    );
};

export { DropdownMenuItem };
