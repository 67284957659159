import { lazy } from "react";

export const ManageBudgetPage = lazy(
  () => import("routes/Budgets/ManageBudgetPage")
);
export const LoginAsPage = lazy(() =>
  import("authentication/components/LoginAsPage").then((module) => ({
    default: module.LoginAsPage,
  }))
);
export const LoginAsSsoPage = lazy(() =>
  import("authentication/components/LoginAsPage").then((module) => ({
    default: module.LoginAsSsoPage,
  }))
);
export const LoginPage = lazy(
  () => import("authentication/components/LoginPage")
);
export const LoginAccountSelectPage = lazy(
  () => import("authentication/components/LoginMultiAccountPage")
);
export const MyAccountPage = lazy(() => import("routes/Account/MyAccountPage"));
export const CartCheckoutPage = lazy(
  () => import("routes/Cart/CartCheckoutPage")
);
export const CartCreditKeyCheckoutPage = lazy(
  () => import("routes/Cart/CartCreditKeyCheckoutPage")
);
export const CartConfirmationPage = lazy(
  () => import("routes/Cart/CartConfirmationPage")
);
export const CartDisplayPage = lazy(
  () => import("routes/Cart/CartDisplayPage")
);
export const CategoryDisplayPage = lazy(
  () => import("routes/Category/CategoryDisplayPage")
);
export const HomePage = lazy(() => import("routes/Home/HomePage"));
export const InternalServerErrorPage = lazy(
  () => import("routes/Errors/InternalServerErrorPage")
);
export const NotFoundErrorPage = lazy(
  () => import("routes/Errors/NotFoundErrorPage")
);
export const ProductDisplayPage = lazy(
  () => import("routes/ProductDisplay/ProductDisplayPage")
);
export const ProductListingPage = lazy(
  () => import("routes/ProductListing/ProductListingPage")
);
export const AccountSettingsPage = lazy(
  () => import("routes/Account/AccountSettingsPage")
);
export const OrderHistoryPage = lazy(
  () => import("routes/Order/OrderHistoryPage")
);
export const OrderDetailsPage = lazy(
  () => import("routes/Order/OrderDetailsPage")
);
export const PaymentMethodsPage = lazy(
  () => import("routes/Account/PaymentMethodsPage")
);
export const InvoicePage = lazy(() => import("routes/Invoice/InvoicePage"));
export const AddressBookPage = lazy(
  () => import("routes/Account/AddressBookPage")
);
export const IDSInvoiceDetailPage = lazy(
  () => import("routes/Invoice/IDSInvoiceDetailPage")
);
export const MergedInvoiceDetailPage = lazy(
  () => import("routes/Invoice/MergedInvoiceDetailPage")
);
export const PunchOutLoginPage = lazy(
  () => import("authentication/components/PunchOutLoginPage")
);
export const ProductPartsAndAccessoriesPage = lazy(
  () => import("routes/ProductListing/ProductPartsAndAccessoriesPage")
);
export const CompareItemsPage = lazy(
  () => import("routes/ProductListing/CompareItemsPage")
);
export const SuppliersPage = lazy(
  () => import("routes/Suppliers/SuppliersPage")
);
export const MySuppliersPage = lazy(
  () => import("routes/Suppliers/MySuppliersPage")
);
export const ViewAccountProjectBuilderPage = lazy(
  () => import("routes/ProjectBuilders/Account/ViewAccountProjectBuilderPage")
);
export const AccountProjectBuildersLandingPage = lazy(
  () =>
    import("routes/ProjectBuilders/Account/AccountProjectBuildersLandingPage")
);
export const EditAccountProjectBuilderPage = lazy(
  () => import("routes/ProjectBuilders/Account/EditAccountProjectBuilderPage")
);
export const CompanyProjectBuildersLandingPage = lazy(
  () =>
    import("routes/ProjectBuilders/Company/CompanyProjectBuildersLandingPage")
);
export const ViewCompanyProjectBuilderPage = lazy(
  () => import("routes/ProjectBuilders/Company/ViewCompanyProjectBuilderPage")
);
export const BrandCategoriesPage = lazy(
  () => import("routes/Category/BrandCategoriesPage")
);
export const AccountOrderGuidesLandingPage = lazy(
  () => import("routes/OrderGuides/Account/AccountOrderGuidesLandingPage")
);
export const CompanyOrderGuidesLandingPage = lazy(
  () => import("routes/OrderGuides/Company/CompanyOrderGuidesLandingPage")
);
export const CompanyOrderGuidePage = lazy(
  () => import("routes/OrderGuides/Company/CompanyOrderGuidePage")
);
export const AccountOrderGuidePage = lazy(
  () => import("routes/OrderGuides/Account/AccountOrderGuidePage")
);
export const EditCompanyProjectBuilderPage = lazy(
  () => import("routes/ProjectBuilders/Company/EditCompanyProjectBuilderPage")
);
export const FAQPage = lazy(() => import("routes/FAQ/FAQ"));
export const PoliciesPage = lazy(() => import("routes/Policies/PoliciesPage"));
export const OrderApprovalPage = lazy(
  () => import("routes/Order/Approvals/OrderApprovalPage")
);
export const OrderApprovalListingPage = lazy(
  () => import("routes/Order/Approvals/OrderApprovalListingPage")
);
export const ForgotPasswordPage = lazy(
  () => import("authentication/components/ForgotPasswordPage")
);
export const ForgotPasswordConfirmationPage = lazy(
  () => import("authentication/components/ForgotPasswordConfirmationPage")
);
export const PasswordResetPage = lazy(
  () => import("authentication/components/PasswordResetPage")
);
export const PasswordResetConfirmationPage = lazy(
  () => import("authentication/components/PasswordResetConfirmationPage")
);
export const OrderApprovalEmailPage = lazy(
  () => import("routes/Order/Approvals/OrderApprovalEmailPage")
);
export const SpecializedPage = lazy(
  () => import("routes/SpecializedPages/SpecializedPage")
);
export const ReportingPage = lazy(() => import("routes/Reports/ReportingPage"));
export const ViewReportPage = lazy(
  () => import("routes/Reports/ViewReportPage")
);
export const ManageUsersListingPage = lazy(
  () => import("routes/UserManagement/ManageUsersListingPage")
);
export const ManageUsersEditPage = lazy(
  () => import("routes/UserManagement/ManageUsersEditPage")
);
export const ManageUsersCreatePage = lazy(
  () => import("routes/UserManagement/ManageUsersCreatePage")
);
export const RapidReorderPage = lazy(
  () => import("routes/RapidReorder/RapidReorderPage")
);
export const SelectPage = lazy(() => import("routes/Select/SelectPage"));
export const EmailsPage = lazy(() => import("routes/Emails/EmailsPage"));
export const OktaSSO = lazy(() =>
  import("routes/Account/IncomingSSO").then((module) => ({
    default: module.OktaSSO,
  }))
);
export const IncomingSSO = lazy(() =>
  import("routes/Account/IncomingSSO").then((module) => ({
    default: module.IncomingSSO,
  }))
);
export const ManageSelectPage = lazy(
  () => import("routes/Select/ManageSelectPage")
);
export const ConfirmationSelectPage = lazy(
  () => import("routes/Select/ConfirmationSelectPage")
);
export const QuoteListingPage = lazy(
  () => import("routes/Quotes/QuoteListingPage")
);
export const ViewQuotePage = lazy(() => import("routes/Quotes/ViewQuotePage"));
export const BrandsPage = lazy(() => import("routes/Brands/BrandsPage"));
export const EquipmentProjectListingPage = lazy(
  () => import("routes/EquipmentProjects/EquipmentProjectListingPage")
);
export const ViewEquipmentProjectPage = lazy(
  () => import("routes/EquipmentProjects/ViewEquipmentProjectPage")
);
export const CreateBudgetPage = lazy(
  () => import("routes/Budgets/CreateBudgetPage")
);
export const FrequentlyOrderedItemsPage = lazy(
  () => import("routes/Order/FrequentlyOrderedItems/FrequentlyOrderedItemsPage")
);
export const LoginSingleSignOnSessionPage = lazy(
  () => import("authentication/components/LoginSingleSignOnSessionPage")
);
export const SelfSignUpPage = lazy(
  () => import("routes/SelfSignUp/SelfSignUpPage")
);
export const SelfSignUpSetPasswordPage = lazy(
  () => import("routes/SelfSignUp/SelfSignUpSetPasswordPage")
);
