export function appendCompanyPageTitle(companyPageTitle: string): void {
  const trimmedCompanyPageTitle = companyPageTitle
    .trim()
    .split(" ")
    .filter((x) => x !== "")
    .join(" ");

  const currentPageTitle = document.title;

  if (currentPageTitle) {
    if (!currentPageTitle.endsWith(trimmedCompanyPageTitle)) {
      document.title = `${currentPageTitle} - ${trimmedCompanyPageTitle}`;
    }
  } else {
    document.title = trimmedCompanyPageTitle;
  }
}
