export function isValidUrlPath(url: string): boolean {
  try {
    new URL(url, "https://arbys.quicksupply.com"); // attempt to create an example url
    return true;
  } catch {
    return false;
  }
}

export function ReplaceURLWithoutLoading(url: string): void {
  window.history.replaceState(null, "", url);
}

export function ReplaceOrAddURLParam(
  urlPath: string,
  paramName: string,
  value: string
): URL {
  const url = new URL(urlPath);
  url.searchParams.set(paramName, value);
  return url;
}

export function encode(value: string | undefined | null): string {
  return encodeURIComponent(value ?? "");
}

export function updateUrl({
  parameter,
  value,
}: {
  parameter: string;
  value: string;
}): void {
  const url = ReplaceOrAddURLParam(window.location.href, parameter, value);
  ReplaceURLWithoutLoading(url.href);
}
