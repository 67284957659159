import { Children, cloneElement, isValidElement, ReactNode } from "react";

export const cloneValidElement = <T = unknown>(
    children: ReactNode,
    props: T
): ReactNode => {
    return Children.map(children, (child) =>
        isValidElement(child)
            ? cloneElement(child, { ...child.props, ...props })
            : child
    );
};

export const isElement = (
    validNodeNames: string[],
    validNodeTypes: string[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    element: any
): boolean => {
    return (
        validNodeTypes.includes(element.type) ||
        validNodeNames.includes(element.type.displayName)
    );
};

/** Recursively loops up parent nodes of the target, until the stop point, to find a node matching the given check.
 * If not present then throws an error.
 * @example
 * searchParentNodes(firstElement, (el) => el.getAttribute("data-content"), lastElement)
 */
export const searchParentNodes = (
    target: HTMLElement | SVGElement,
    testFunction: (element: HTMLElement | SVGElement) => boolean,
    stopPoint: HTMLElement | SVGElement
): HTMLElement | SVGElement | undefined => {
    let currentNode = target;
    while (currentNode !== stopPoint) {
        if (testFunction(currentNode)) {
            return currentNode;
        }
        if (!currentNode.parentElement) {
            throw new Error("Matching parent node not found.");
        }
        currentNode = currentNode.parentElement;
    }
};

export const getParentNode = (
    ...args: Parameters<typeof searchParentNodes>
): Element => {
    const element = searchParentNodes(...args);
    if (!element) {
        throw new Error("Unable to find element");
    }
    return element;
};

export const isInstanceOf = <T extends typeof Element>(
    element: unknown,
    instances: T[]
): element is InstanceType<T> => {
    return instances.some((instance) => element instanceof instance);
};
