import { FC, LabelHTMLAttributes } from "react";
import { DataTypes, HTMLSpanAttributes } from "@Types/element";
import { concat } from "@Utilities/string";

export type InputLabelProps = LabelHTMLAttributes<HTMLLabelElement> &
    DataTypes & {
        htmlFor: string;
        optional?: boolean;
        optionalProps?: HTMLSpanAttributes;
        required?: boolean;
        requiredProps?: HTMLSpanAttributes;
        theme?: string;
    };

/**
 * A component used to associate an label to an input.
 *
 * It is not recommended to use this component directly.
 * Instead, use the other Zest input components (phone, text, email, checkbox, number, radio, select, textarea).
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-inputs)}
 */
const InputLabel: FC<InputLabelProps> = ({
    children,
    className,
    optional,
    optionalProps = {},
    required,
    requiredProps = {},
    theme,
    ...rest
}) => {
    const classes = concat("zest-input-label", className, theme);

    const optionalClassName = concat(optionalProps.className, "zest-optional");

    const requiredClassName = concat(requiredProps.className, "zest-required");

    if (required && optional) {
        console.error(
            "%cZest Error:\n",
            "background-color: red; color: yellow; font-size: xsmall",
            "InputLabel: 'required' and 'optional' props cannot be used together."
        );
    }

    return (
        <label className={classes} {...rest}>
            {children}
            {!!required && (
                <span {...requiredProps} className={requiredClassName}>
                    (required)
                </span>
            )}
            {!!optional && !required && (
                <span {...optionalProps} className={optionalClassName}>
                    (optional)
                </span>
            )}
        </label>
    );
};

export { InputLabel };
