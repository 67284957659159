import sprites from "assets/sprites.svg";
import { Symbols } from "assets/Icon.types";
import { concat } from "util/string";

export interface IconProps {
  className?: string;
  name: Symbols;
  height?: string;
  width?: string;
  style?: React.CSSProperties;
  fill?: string;
  ariaLabel?: string;
  stroke?: string;
  strokeWidth?: string | number;
  testId?: string;
  strokeClass?: string;
}

export default function Icon(properties: IconProps): JSX.Element {
  const { name, fill, testId, ariaLabel, strokeClass, className, ...rest } =
    properties;
  const strokeNoneCheckClass =
    strokeClass !== "none" ? "stroke-" + strokeClass : "";
  const appliedStyle = concat(
    "svg",
    strokeClass ? strokeNoneCheckClass : "stroke-current"
  );
  return (
    <svg
      aria-hidden="true"
      aria-label={ariaLabel}
      className={concat(appliedStyle, className)}
      {...rest}
      data-testid={testId}
      fill={fill ? fill : "current"}
    >
      <use xlinkHref={sprites + `#${name}`} />
    </svg>
  );
}
