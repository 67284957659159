import { useEffect } from "react";
import { useLocation } from "react-router";
import { appendCompanyPageTitle } from "util/title";

export default function useCompanyPageTitle(
  companyPageTitle: string | undefined
): void {
  const location = useLocation();

  useEffect(
    function companyPageTitleEffect() {
      if (companyPageTitle) {
        const title = document.querySelector("title");
        if (title) {
          const observer = new MutationObserver(() => {
            appendCompanyPageTitle(companyPageTitle);
          });

          observer.observe(title, {
            subtree: true,
            characterData: true,
            childList: true,
          });

          appendCompanyPageTitle(companyPageTitle);

          return () => observer.disconnect();
        }
      }
    },
    [location, companyPageTitle]
  );
}
