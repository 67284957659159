import { useStore } from "@Utilities/hooks/useStoreData";
import { srcSelector } from "./selectors";
import { useThumbnails } from "./useThumbnails";
import { useVideoPlayerContext } from "./utilities";

export function LoadThumbnails(): JSX.Element {
    const context = useVideoPlayerContext();
    const src = useStore(context.store, srcSelector);

    useThumbnails(context.ref, src, context);

    return <></>;
}
