import "./index.css";
import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import AppConstants from "appconstants.json";
import { env } from "env";
import fetch from "util/requests/fetch";
import addNewRelicBrowserAgent from "util/NewRelic/addNewRelicBrowserAgent";

// Import the mock service worker in LOC only, and when enabled
if (process.env.NODE_ENV === "development" && AppConstants.ENABLE_MSW_LOC) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require("./mocks/browser");
  worker.start({ onUnhandledRequest: "bypass" });
}

// Startup actions when running bundle deployed by `yarn build` (DEV+TEST+PROD)
if (process.env.NODE_ENV === "production") {
  // Fetch Override: Adds the Authorization header with our Bearer token to requests initiated inside the Clark Payments Client package.
  fetch();

  // Initialize Sentry using the environment variables
  Sentry.init({
    dsn: env.REACT_APP_SENTRY_DSN,
    environment:
      env.REACT_APP_CD_ENV === "PROD"
        ? "Production"
        : env.REACT_APP_CD_ENV === "TEST"
        ? "Test"
        : env.REACT_APP_CD_ENV === "DEV"
        ? "Development"
        : "Local",
    ignoreErrors: ["Non-Error promise rejection captured"],
  });
  addNewRelicBrowserAgent(env.REACT_APP_CD_ENV);
}

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
