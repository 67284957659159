import { EnvironmentType, Environments } from "env";
import { ApiLayoutSiteDataDTO } from "layouts/api/layoutAPI.types";

export function getChatUrl(
  encryptedUserIndex: string | undefined,
  environment: EnvironmentType,
  { domainName }: ApiLayoutSiteDataDTO,
  useSwitchboardRewrite: boolean
): string {
  const chatSubdomain = "chat.";

  let environmentSubdomain = "";
  if (environment === Environments.DEV || environment === Environments.LOC) {
    environmentSubdomain = "dev.";
  } else if (environment === Environments.TEST) {
    environmentSubdomain = "test.";
  }

  const chatUrl = new URL(
    `https://${chatSubdomain}${environmentSubdomain}${domainName}`
  );

  if (useSwitchboardRewrite) {
    chatUrl.searchParams.append("v", "1");
  }

  if (encryptedUserIndex) {
    chatUrl.searchParams.append("user_index", encryptedUserIndex);
  }

  return chatUrl.toString();
}
