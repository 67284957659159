import CTABadgeWithText from "assets/CTABadgeWithText";
import ButtonLink from "components/buttons/ButtonLink";
import routes from "routes/routes";

export default function ReviewOrdersButton({
  ordersWaitingForApprovalCount,
}: {
  ordersWaitingForApprovalCount?: number;
}): JSX.Element {
  return (
    <ButtonLink
      className="justify-between"
      id="HeaderAdminReviewOrdersButton"
      href={routes.OrdersPendingApproval.getPath()}
      variant="secondary"
      label="Review Orders"
      icon={
        <CTABadgeWithText
          text={ordersWaitingForApprovalCount?.toString()}
          className="h-5 min-w-[1.75em] w-fit px-1"
        />
      }
      iconOrientation="right"
    />
  );
}
