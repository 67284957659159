export function isViewableInOverflowHiddenWidth(
  parent: HTMLElement,
  child: HTMLElement,
  threshold = 0
): boolean {
  const parentRect = parent.getBoundingClientRect();
  const childRect = child.getBoundingClientRect();
  const childLeftEdgeInView = childRect.left >= parentRect.left - threshold;
  const childRightEdgeInView =
    childRect.right <= parentRect.left + parent.offsetWidth + threshold;

  return childLeftEdgeInView && childRightEdgeInView;
}

export function focusOnNextViewableElementInSlide(
  focusableElementSelector: string,
  container: HTMLElement,
  slides: HTMLCollectionOf<Element>,
  viewingThreshold: number
): void {
  let index = 0;
  for (let i = slides.length - 1; i >= 0; i--) {
    const slide = slides[i] as HTMLElement;
    if (isViewableInOverflowHiddenWidth(container, slide, viewingThreshold)) {
      index = i;
      break;
    }
  }
  const nextSlide = slides[Math.min(index + 1, slides.length - 1)];
  const firstFocusable = nextSlide.querySelectorAll<HTMLElement>(
    focusableElementSelector
  )[0];
  firstFocusable?.focus({ focusVisible: true } as FocusOptionsExtended);
}

export function focusOnPreviousViewableElementInSlide(
  focusableElementSelector: string,
  container: HTMLElement,
  slides: HTMLCollectionOf<Element>,
  viewingThreshold: number
): void {
  let index = 0;
  for (let i = 0; i < slides.length; i++) {
    const slide = slides[i] as HTMLElement;
    if (isViewableInOverflowHiddenWidth(container, slide, viewingThreshold)) {
      index = i;
      break;
    }
  }
  const nextSlide = slides[Math.max(index - 1, 0)];
  const focusableElementsInSlide = nextSlide.querySelectorAll<HTMLElement>(
    focusableElementSelector
  );
  const firstFocusable =
    focusableElementsInSlide[focusableElementsInSlide.length - 1];
  firstFocusable?.focus({ focusVisible: true } as FocusOptionsExtended);
}

type FocusOptionsExtended = FocusOptions & {
  focusVisible?: boolean;
};

export function scrollToElement(element: HTMLElement | null | undefined): void {
  const largeHeaderHeight = -96; // TODO: handle smaller screen size heights
  if (element) {
    window.scrollTo({
      top: element.offsetTop + largeHeaderHeight,
      behavior: "smooth",
    });
  }
}

export function scrollToTop(): void {
  window.scrollTo(0, 0);
}

const getScrollbarWidth = (): number => {
  // Create a temporary div container and append it into the body
  const container = document.createElement("div");
  // Append the container in the body
  document.body.appendChild(container);
  // Force scrollbar on the container
  container.style.overflow = "scroll";

  // Add a fake div inside the container
  const inner = document.createElement("div");
  container.appendChild(inner);

  // Calculate the width based on the container width minus its child width
  const width = container.offsetWidth - inner.offsetWidth;
  // Remove the container from the body
  document.body.removeChild(container);

  return width;
};

// Creates a variable to store the scrollbar width to be used in calculations
export function addScrollbarWidth(): void {
  // Get the scrollbar dimension
  const scrollbarWidth = getScrollbarWidth();
  // Set a custom property with the value we calculated
  document.documentElement.style.setProperty(
    "--scrollbar-width",
    `${scrollbarWidth}px`
  );
}
