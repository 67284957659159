import { FC, OptionHTMLAttributes } from "react";
import { concat } from "@Utilities/string";

export interface OptionProps extends OptionHTMLAttributes<HTMLOptionElement> {
    children: string | number;
    id?: string;
    value: string | number;
}

/**
 * A component that is meant to be used in conjunction with the <Select/> component.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-inputs-select--select)}
 */
const Option: FC<OptionProps> = ({ children, className, ...rest }) => {
    return (
        <option className={concat(className)} {...rest}>
            {children}
        </option>
    );
};

export { Option };
