import {
    Children,
    cloneElement,
    isValidElement,
    JSX,
    PropsWithChildren,
    useCallback,
    useEffect,
    useRef,
} from "react";
import { isElement } from "@Utilities/react";
import { concat } from "@Utilities/string";
import { TooltipContext } from "./context";
import {
    TooltipEventCallbacks,
    TooltipEvents,
    UseTooltip,
    useTooltip,
} from "./hooks/useTooltip";
import { TooltipContent, TooltipContentProps } from "./tooltip.content";
import { TooltipTrigger, TooltipTriggerProps } from "./tooltip.trigger";
import { placements, PlacementTypes, useTimeout } from "@Utilities/hooks";

export type TooltipProps = {
    className?: string;
    content: string;
    contentId: string;
    contentProps?: TooltipContentProps;
    element?: "div" | "p" | "span";
    offset?: number;
    onClose?: TooltipEventCallbacks;
    onOpen?: TooltipEventCallbacks;
    placement?: PlacementTypes;
    theme?: string;
    tooltip?: UseTooltip;
    triggerProps?: TooltipTriggerProps;
};

/**
 * Informational floating labels that briefly explain a feature or function of a user interface element.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-tooltip--tooltip)}
 *
 */
const Tooltip = ({
    children,
    className,
    content,
    contentId,
    contentProps,
    element = "div",
    offset,
    onClose,
    onOpen,
    placement = placements.Top,
    theme = "wss",
    tooltip: controlledTooltip,
    triggerProps,
    ...rest
}: PropsWithChildren<TooltipProps>): JSX.Element => {
    const internalUseTooltip = useTooltip();
    const ref = useRef<HTMLDivElement>(null);
    const tooltip = controlledTooltip || internalUseTooltip;
    const Element = element;

    if (content.length > 140) {
        throw new Error("Tooltip content must be 140 characters or less.");
    }

    const classes = concat(
        className,
        "relative",
        "zest-tooltip-wrapper",
        theme
    );

    /** Will focus the trigger element, run when the tooltip is closed */
    const focusTrigger = useCallback((id: string): void => {
        if (ref.current) {
            const triggerWithId = ref.current.querySelector<HTMLElement>(
                `[data-content="${id}"]`
            );
            triggerWithId && triggerWithId.focus();
        }
    }, []);

    /** Will close all open contents if the click occurs outside the root tooltip element */
    const handleClickOutside = useCallback<(e: MouseEvent) => void>(
        (e): void => {
            if (e.target instanceof HTMLElement) {
                const openTooltipId = tooltip.store.get().openId;
                if (
                    ref.current?.contains(e.target) === false &&
                    openTooltipId
                ) {
                    tooltip.close(openTooltipId);
                }
            }
        },
        [tooltip]
    );

    const handleEscape = useCallback<(e: KeyboardEvent) => void>(
        (e) => {
            const openTooltipId = tooltip.store.get().openId;
            if (e.key === "Escape" && openTooltipId) {
                tooltip.close(openTooltipId);
                focusTrigger(openTooltipId);
            }
        },
        [focusTrigger, tooltip]
    );

    const timeout = useTimeout(() => {
        if (ref.current) {
            tooltip.close();
            tooltip.toggleIsMouseInside();
        }
    }, 25);

    useEffect(() => {
        const cleanups: (() => void)[] = [];
        if (onClose) {
            const unsubClose = tooltip.subscribe(TooltipEvents.CLOSE, onClose);
            cleanups.push(unsubClose);
        }
        if (onOpen) {
            const unsubOpen = tooltip.subscribe(TooltipEvents.OPEN, onOpen);
            cleanups.push(unsubOpen);
        }
        return (): void => {
            cleanups.forEach((cleanup) => {
                cleanup();
            });
        };
    }, [tooltip, onOpen, onClose]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        document.addEventListener("keydown", handleEscape);
        return function tooltipClickOutsideCleanup(): void {
            document.removeEventListener("click", handleClickOutside);
            document.removeEventListener("keydown", handleEscape);
        };
    }, [handleClickOutside, handleEscape]);

    return (
        <TooltipContext.Provider
            value={{
                store: tooltip.store,
                triggerProps: {
                    ...triggerProps,
                },
                contentProps: {
                    ...contentProps,
                    placement,
                    offset,
                },
                open: tooltip.open,
                close: tooltip.close,
                toggle: tooltip.toggle,
                register: tooltip.register,
                toggleIsMouseInside: tooltip.toggleIsMouseInside,
            }}
        >
            <Element {...rest} ref={ref} className={classes}>
                <TooltipTrigger
                    {...triggerProps}
                    contentId={contentId}
                    onFocus={(): void => tooltip.open(contentId)}
                    onBlur={(): void => tooltip.close(contentId)}
                    timeout={timeout}
                >
                    {Children.map(children, (child) => {
                        if (
                            !isElement(
                                ["Button", "Anchor"],
                                ["button", "a"],
                                child
                            )
                        ) {
                            console.error(
                                "%cZest Error:\n",
                                "background-color: red; color: yellow; font-size: xsmall",
                                "Tooltip children must be a Button or Anchor element."
                            );
                        }
                        return isValidElement(child)
                            ? cloneElement(child, {
                                  "aria-describedby": contentId,
                                  ...child.props,
                              })
                            : child;
                    })}
                </TooltipTrigger>
                <TooltipContent
                    {...contentProps}
                    id={contentId}
                    timeout={timeout}
                >
                    {content}
                </TooltipContent>
            </Element>
        </TooltipContext.Provider>
    );
};

Tooltip.displayName = "Tooltip";

export { Tooltip };
