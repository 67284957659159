import { RefObject, useRef, useCallback, useMemo } from "react";

type AlertElement = HTMLDivElement | HTMLDialogElement;

export type UseAlert = {
    /**
     * Focuses the <Alert> with the given ID or the first registered alert
     * @example
     * const alert = useAlert();
     * const id = 'my-alert'
     * const callback = () => alert.focus(id)
     */
    focus: (id?: string) => void;
    /**
     * Used to save an alert to the hook so it can be focused later.
     * In most cases this will be done internally within the <Alert> component
     */
    register: (id: string, element: RefObject<AlertElement>) => void;
    /**
     * This maybe used if the <Alert> is removed from the DOM
     */
    unregister: (id: string) => void;
};

/**
 * Used to gain access to the methods associated with the <Alert> component.
 *
 * @example
 * const alert = useAlert();
 *
 * const callback = () => alert.focus()
 *
 * return (
 *   <Alert alert={alert}>Content</Alert>
 * )
 */
const useAlert = (): UseAlert => {
    const alerts = useRef<{ [id: string]: RefObject<AlertElement> }>({});

    const register = useCallback<UseAlert["register"]>((id, element) => {
        alerts.current[id] = element;
    }, []);

    const unregister = useCallback<UseAlert["unregister"]>((id) => {
        if (id in alerts.current) {
            delete alerts.current[id];
        }
    }, []);

    const focus = useCallback<UseAlert["focus"]>((id) => {
        if (id && !alerts.current[id]) {
            console.error(
                "%cZest Error:\n",
                "background-color: red; color: yellow; font-size: xsmall",
                `ID "${id}" is not assigned to an <Alert> component and can not be focused.`,
                "Please ensure that the ID was passed to the <Alert> component"
            );
        } else if (id) {
            /** We wait one frame until the CSS hidden property is removed before focusing */
            return requestAnimationFrame(() =>
                alerts.current[id].current?.focus()
            );
        }
        if (!Object.values(alerts.current).length) {
            console.error(
                "%cZest Error:\n",
                "background-color: red; color: yellow; font-size: xsmall",
                "useAlert's focus function was called but no alerts have been registered.",
                "This most likely means the alert controller was not passed to the <Alert> component"
            );
        } else {
            const element = Object.values(alerts.current)[0];
            /** We wait one frame until the CSS hidden property is removed before focusing */
            return requestAnimationFrame(() => element.current?.focus());
        }
    }, []);

    return useMemo(
        () => ({ register, focus, unregister }),
        [focus, register, unregister]
    );
};

export { useAlert };
