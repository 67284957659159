import { HTMLAttributes } from "react";
import { concat } from "@Utilities/string";
import { Button, ButtonProps } from "@Components/button";
import { IconProps } from "@Components/icon";
import { useVideoPlayerContext } from "./utilities";
import { useStore } from "@Utilities/hooks/useStoreData";
import {
    isAtBeginningSelector,
    isPlayingSelector,
    showOverlaySelector,
} from "./selectors";

export type OverlayProps = HTMLAttributes<HTMLDivElement> & {
    buttonProps?: ButtonProps;
    handleBlur?: (e: React.FocusEvent) => void;
    iconProps?: IconProps;
    spriteSheetHref?: string;
};

const Overlay = ({
    buttonProps,
    className,
    handleBlur,
    iconProps = {},
    spriteSheetHref,
    ...rest
}: OverlayProps): JSX.Element => {
    const { pause, play, seek, store } = useVideoPlayerContext();
    const isPlaying = useStore(store, isPlayingSelector);
    const atBeginning = useStore(store, isAtBeginningSelector);
    const showOverlay = useStore(store, showOverlaySelector);

    const handleForwards = (): void => {
        const currentTime = store.get().currentTime;
        seek(currentTime + 10);
    };

    const handleBackwards = (): void => {
        const currentTime = store.get().currentTime;
        seek(currentTime - 10);
    };

    const handlePlayPause = (): void => (isPlaying ? pause() : play());

    const buttonSharedProps: { [key: string]: string } = {
        variant: "green",
    };

    const classes = concat(
        "zest-video-overlay",
        className,
        !showOverlay && "hide-controls"
    );

    return (
        <div {...rest} className={classes}>
            {!atBeginning && (
                <Button
                    {...buttonProps}
                    {...buttonSharedProps}
                    onClick={handleBackwards}
                    onBlur={handleBlur}
                    aria-label="backwards 10 seconds"
                    className={concat(
                        buttonProps?.className,
                        "zest-backwards-overlay-button"
                    )}
                    iconProps={{
                        ...iconProps,
                        name: "ri-replay-10-fill",
                        spriteSheetHref: spriteSheetHref,
                    }}
                />
            )}
            <Button
                {...buttonProps}
                {...buttonSharedProps}
                onClick={handlePlayPause}
                aria-label={isPlaying ? "pause" : "play"}
                className={concat(
                    buttonProps?.className,
                    "zest-play-overlay-button"
                )}
                iconProps={{
                    ...iconProps,
                    name: isPlaying ? "ri-pause-fill" : "ri-play-fill",
                    spriteSheetHref: spriteSheetHref,
                }}
            />
            {!atBeginning && (
                <Button
                    {...buttonProps}
                    {...buttonSharedProps}
                    onClick={handleForwards}
                    aria-label="forwards 10 seconds"
                    className={concat(
                        buttonProps?.className,
                        "zest-forward-overlay-button"
                    )}
                    iconProps={{
                        ...iconProps,
                        name: "ri-forward-10-fill",
                        spriteSheetHref: spriteSheetHref,
                    }}
                />
            )}
        </div>
    );
};

export { Overlay };
