import { FC, useEffect } from "react";
import { useStore } from "@Utilities/hooks/useStoreData";
import { concat } from "@Utilities/string";
import { SFSpriteSheetHref } from "@Utilities/index";
import { DataTypes } from "@Types/element";
import { useDropdownMenuContext } from "./dropdownMenu.context";
import { DropdownMenuState } from "./useDropdownMenu";
import { Button, ButtonProps, Icon, IconProps } from "..";

export type DropdownMenuTriggerProps = ButtonProps &
    DataTypes & {
        "aria-label"?: string;
        contentId: string;
        iconProps?: IconProps;
        spriteSheetHref?: string;
    };

function selectIsOpen(state: DropdownMenuState): boolean {
    return state.isOpen;
}

const DropdownMenuTrigger: FC<DropdownMenuTriggerProps> = ({
    children,
    className,
    contentId,
    iconProps,
    onClick,
    spriteSheetHref = SFSpriteSheetHref,
    ...rest
}) => {
    const context = useDropdownMenuContext();
    const { registerTrigger, resetFocus, store, toggle } = context;
    const isOpen = useStore(store, selectIsOpen);
    const triggerId = `zest-dropdownMenu-trigger-${contentId}`;

    useEffect(() => {
        return registerTrigger(contentId);
    }, [contentId, registerTrigger]);

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (
        e
    ): void => {
        onClick?.(e);
        toggle();
    };

    const classes = concat("zest-dropdownMenu-trigger", className);
    const iconClasses = concat(iconProps?.className);

    return (
        <Button
            id={triggerId}
            aria-haspopup={true}
            aria-controls={`zest-dropdownMenu-content-${contentId}`}
            aria-expanded={isOpen}
            className={classes}
            onClick={handleClick}
            onFocus={(): void => resetFocus()}
            type="button"
            {...rest}
        >
            {children}
            <Icon
                size="xsmall"
                name="ri-arrow-down-s-line"
                spriteSheetHref={spriteSheetHref}
                {...iconProps}
                className={iconClasses}
            />
        </Button>
    );
};

export { DropdownMenuTrigger };
