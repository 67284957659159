import { Link } from "react-router-dom";
import { generateButtonStyle } from "components/buttons/buttons.styles";
import { concat } from "util/string";
import { useUuid } from "util/hooks/useUuid";
import { ButtonProps } from "components/buttons/Button.types";

export type ButtonLinkProps = ButtonProps & {
  href: string;
  reloadDocument?: boolean;
  disabled?: never; // disabled links are not supported in HTML5. use Button with onClick=navigate() instead.
};

export default function ButtonLink({
  className,
  hideLabel,
  href,
  icon,
  iconOrientation,
  id,
  size = "default",
  label,
  labelClassName = "font-medium",
  variant,
  reloadDocument = false,
  ...rest
}: ButtonLinkProps): JSX.Element {
  const shouldFlex = icon && !hideLabel; // only flex content if both icon+label present
  const style = generateButtonStyle(variant, size, shouldFlex);
  const uuid = useUuid("button-link");

  return (
    <Link
      type="button"
      reloadDocument={reloadDocument}
      to={href}
      id={id || uuid}
      className={concat(style, className)}
      aria-label={hideLabel ? label : undefined}
      {...rest}
    >
      {iconOrientation === "left" && icon}
      {!hideLabel && <span className={labelClassName}>{label}</span>}
      {iconOrientation === "right" && icon}
    </Link>
  );
}
