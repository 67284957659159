import { createContext, useContext } from "react";
import { UseDropdownMenuMethods, useDropdownMenu } from "./useDropdownMenu";

export type DropdownMenuContextValue = Omit<
    UseDropdownMenuMethods,
    "handleKeyDown"
> & {
    store: useDropdownMenu["store"];
};

const DropdownMenuContext = createContext<DropdownMenuContextValue | null>(
    null
);

export { DropdownMenuContext };

const useDropdownMenuContext = (): DropdownMenuContextValue => {
    const context = useContext(DropdownMenuContext);

    if (!context) {
        throw new Error(
            "useDropdownMenuContext is being used outside of a <DropdownMenu> element."
        );
    }
    return context;
};

export { useDropdownMenuContext };
