import { HTMLAttributes, JSX, PropsWithChildren } from "react";
import { concat } from "@Utilities/string";
import { InputWrapperProps } from "..";
import { DataTypes } from "../../types/element";

export type AlertTitleProps = HTMLAttributes<HTMLParagraphElement> &
    DataTypes & {
        element?: "h1" | "h2" | "h3" | "h4" | "p";
        wrapperProps?: InputWrapperProps;
    };

/**
 * A component that renders a title for an alert.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/experimental-alpha-alert--alert)}
 */
const AlertTitle = ({
    children,
    className,
    element = "p",
    wrapperProps = {},
    ...rest
}: PropsWithChildren<AlertTitleProps>): JSX.Element => {
    const Element = element;
    const classes = concat(className);

    return (
        <div
            {...wrapperProps}
            className={concat("zest-alert-title", wrapperProps.className)}
        >
            <Element {...rest} className={classes}>
                {children}
            </Element>
        </div>
    );
};

AlertTitle.displayName = "AlertTitle";

export { AlertTitle };
