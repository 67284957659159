import { SiteDataApi } from "authentication/components/utils";
import { LayoutAPI } from "layouts/api/layoutAPI.types";

export const vendor = [
  "pro",
  "quicksupply",
  "arbys",
  "11400inc",
  "noblechemical",
  "ndcp",
] as const;
export type Vendor = typeof vendor[number];

/**
 *
 * @returns The hostname of the current window window.location.hostname
 */
export function getHostname(): string {
  return window.location.hostname;
}

/**
 * Returns the vendor based on the current window.location.hostname
 * @returns
 */
export function getVendorFromCurrentHost(): Vendor {
  return getVendorFromHost(window.location.hostname);
}

/**
 *
 * @param windowLocationHost window.location.hostname value
 * @returns Vendor string for the current site
 */
export function getVendorFromHost(windowLocationHost: string): Vendor {
  if (windowLocationHost.includes("ndcp")) {
    return "ndcp";
  }

  if (windowLocationHost.includes("arbys")) {
    return "arbys";
  }

  if (windowLocationHost.includes("quicksupply.com")) {
    return "quicksupply";
  }

  if (
    windowLocationHost.includes("cfse.biz") ||
    windowLocationHost.includes("promarketplace.biz")
  ) {
    return "pro";
  }

  if (windowLocationHost.includes("11400inc.com")) {
    return "11400inc";
  }

  if (windowLocationHost.includes("noblechemical.com")) {
    return "noblechemical";
  }

  return "quicksupply";
}

/**
 * Returns true if the host includes quicksupply.com
 */
export function isQuickSupplyHost(windowLocationHost: string): boolean {
  return windowLocationHost.includes("quicksupply.com");
}

/**
 * As a fallback for insecure routes (PasswordReset), returns the assumed siteData object based on the current window.location.hostname. We should always use the LayoutAPI SiteData instead if possible.
 */
export function getSiteDataApiModel(vendor: Vendor): SiteDataApi {
  switch (vendor) {
    case "pro":
      // TODO: We will need to update this if the backend updates its models to use promarketplace.biz instead
      return {
        subDomainName: "pro",
        domainName: "cfse.biz",
      };
    case "quicksupply":
      return {
        subDomainName: "",
        domainName: "quicksupply.com",
      };
    case "arbys":
      return {
        subDomainName: "arbys",
        domainName: "quicksupply.com",
      };
    case "11400inc":
      return {
        subDomainName: "",
        domainName: "11400inc.com",
      };
    case "noblechemical":
      return {
        subDomainName: "",
        domainName: "noblechemical.com",
      };
    case "ndcp":
      return {
        subDomainName: "ndcp",
        domainName: "quicksupply.com",
      };
  }
}

/**
 * Get the salesLocationDescription from the userLayoutData, but overrides the Pro value with "ClarkPro LLC"
 * @param userLayoutData
 * @returns
 */
export function getCompanyNameWithProOverride(
  userLayoutData: LayoutAPI | undefined
): string {
  return getVendorFromCurrentHost() === "pro"
    ? "ClarkPro LLC"
    : userLayoutData?.salesLocationDescription ?? "";
}
