import { useCallback, useEffect, useRef, useState } from "react";

export default function useToastFadeOut(
  closeToast: () => void,
  displayDuration: number,
  transitionDuration: number
): {
  opacity: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  closeWithTimers: () => void;
} {
  const [opacity, setOpacity] = useState(1);

  const removeToastTimerRef = useRef<NodeJS.Timeout | undefined>();
  const startFadeOutTimerRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (!startFadeOutTimerRef.current) {
      startFadeOutTimerRef.current = startFadeOutTimer(
        setOpacity,
        removeToastTimerRef,
        closeToast,
        displayDuration,
        transitionDuration
      );
    }
  }, [closeToast, displayDuration, transitionDuration]);

  const onMouseEnter = useCallback((): void => {
    clearTimeout(startFadeOutTimerRef.current);
    clearTimeout(removeToastTimerRef.current);
    setOpacity(1);
  }, [setOpacity]);

  const onMouseLeave = useCallback((): void => {
    clearTimeout(startFadeOutTimerRef.current);
    clearTimeout(removeToastTimerRef.current);
    startFadeOutTimerRef.current = startFadeOutTimer(
      setOpacity,
      removeToastTimerRef,
      closeToast,
      displayDuration,
      transitionDuration
    );
  }, [closeToast, setOpacity, displayDuration, transitionDuration]);

  return {
    opacity,
    onMouseEnter,
    onMouseLeave,
    closeWithTimers: (): void => {
      clearTimeout(startFadeOutTimerRef.current);
      clearTimeout(removeToastTimerRef.current);
      closeToast();
    },
  };
}

function startFadeOutTimer(
  setOpacity: (opacity: number) => void,
  removeToastTimerRef: React.MutableRefObject<NodeJS.Timeout | undefined>,
  closeToast: () => void,
  displayDuration: number,
  transitionDuration: number
): NodeJS.Timeout {
  return setTimeout((): void => {
    setOpacity(0);
    removeToastTimerRef.current = setTimeout(
      (): void => closeToast(),
      transitionDuration
    );
  }, displayDuration);
}
