import { FC, HTMLAttributes, ReactNode } from "react";
import { concat } from "@Utilities/string";
import { DataTypes, HTMLSpanAttributes } from "@Types/element";
import { InputError, InputErrorProps, InputHelper, InputHelperProps } from ".";

export type LegendProps = HTMLAttributes<HTMLLegendElement> &
    DataTypes & {
        error?: boolean;
        errorMessage?: ReactNode;
        errorProps?: InputErrorProps;
        helperProps?: InputHelperProps;
        helperText?: ReactNode;
        hideError?: boolean;
        hideLabel?: boolean;
        optional?: boolean;
        optionalProps?: HTMLSpanAttributes;
        required?: boolean;
        requiredProps?: HTMLSpanAttributes;
    };

/**
 * A component used to associate a legend with a group.
 *
 * It is not recommended to use this component directly.
 * Instead, use the other Zest input components (phone, text, email, checkbox, number, radio, select, textarea).
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-inputs-radio--radio)}
 */
const Legend: FC<LegendProps> = ({
    children,
    className,
    error = false,
    errorMessage = "",
    errorProps = {},
    helperProps = {},
    helperText,
    hideError,
    hideLabel,
    optional,
    optionalProps = {},
    required,
    requiredProps = {},
    ...rest
}) => {
    const classes = concat(className, "zest-legend");
    const errorClasses = concat("zest-legend-error", errorProps.className);
    const optionalClassName = concat(optionalProps.className, "zest-optional");
    const requiredClassName = concat(requiredProps.className, "zest-required");

    if (required && optional) {
        console.error(
            "%cZest Error:\n",
            "background-color: red; color: yellow; font-size: xsmall",
            "InputLabel: 'required' and 'optional' props cannot be used together."
        );
    }

    return (
        <legend {...rest} className={classes}>
            {!hideLabel && (
                <>
                    {children}
                    {!!required && (
                        <span {...requiredProps} className={requiredClassName}>
                            (required)
                        </span>
                    )}
                    {!!optional && !required && (
                        <span {...optionalProps} className={optionalClassName}>
                            (optional)
                        </span>
                    )}
                </>
            )}
            <InputHelper {...helperProps}>{helperText}</InputHelper>
            {!hideError && (
                <InputError
                    {...errorProps}
                    hasError={error}
                    className={errorClasses}
                    errorMessages={errorMessage}
                />
            )}
        </legend>
    );
};

export { Legend };
