import * as Sentry from "@sentry/react";

/**
 * ! Use this instead of regular console.error !
 *
 * Logs an error to the console and uploads it to Sentry.
 */
export function logError(
  error: Error | string,
  reason?: string,
  additionalContext?: { [key: string]: string }
): void {
  const scope = Sentry.getCurrentScope();
  if (reason) {
    scope.setContext("reason", {
      reason,
    });
    console.error(reason, error);
  } else {
    console.error(error);
  }
  additionalContext && scope.setTags(additionalContext);
  scope.captureException(error);
}
