import { useState, useEffect } from "react";
import { Breakpoint, getBreakpoint } from "util/breakpoints";

export default function useCurrentBreakpoint(): Breakpoint {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(
    (): Breakpoint => getBreakpoint(window.innerWidth)
  );

  useEffect(() => {
    function handleResize(): void {
      setCurrentBreakpoint(getBreakpoint(window.innerWidth));
    }

    window.addEventListener("resize", handleResize);
    return (): void => window.removeEventListener("resize", handleResize);
  }, []);

  return currentBreakpoint;
}
