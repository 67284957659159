import { createContext } from "react";
import { ListItemProps } from "./list.item";

/** This creates our context consumer and provider
 * @example
 * const MyProvider = () => {
 *    return <ListContext.Provider value={}>
 *        {children}
 *    </ListContext.Provider>
 * }
 *
 * const MyConsumer = () => {
 *   const context = useContext(ListContext);
 *   return (
 *      <div></div>
 *   )
 * }
 */

export const ListContext = createContext<ListItemProps | undefined>({
    listStyle: "bullet",
});
