import { Reducer } from "react";
import { FormValues } from "../types";
import clearAction from "./actions/clear";
import {
    RegisterAction,
    UpdateAction,
    ClearAction,
    ResetAction,
    MetaAction,
    UnregisterAction,
} from "./actions/types";
import registerAction from "./actions/register";
import unregisterAction from "./actions/unregister";
import resetAction from "./actions/reset";
import updateAction from "./actions/update";
import metaAction from "./actions/meta";

export enum FormAction {
    REGISTER = "REGISTER",
    UNREGISTER = "UNREGISTER",
    UPDATE = "UPDATE",
    CLEAR = "CLEAR",
    RESET = "RESET",
    META = "META",
}

type CommonActions<T, S extends FormValues> = T & {
    callback?: (state: S) => void;
};

export type FormActions<S extends FormValues = FormValues> =
    | CommonActions<RegisterAction, S>
    | CommonActions<UpdateAction, S>
    | CommonActions<ClearAction, S>
    | CommonActions<ResetAction, S>
    | CommonActions<MetaAction, S>
    | CommonActions<UnregisterAction, S>;

const reducer: Reducer<FormValues, FormActions> = (state, action) => {
    const type = action.type;
    let newState: FormValues;
    switch (action.type) {
        case FormAction.REGISTER: {
            newState = registerAction(state, action);
            break;
        }
        case FormAction.UPDATE: {
            newState = updateAction(state, action);
            break;
        }
        case FormAction.META: {
            newState = metaAction(state, action);
            break;
        }
        case FormAction.CLEAR: {
            newState = clearAction(state, action);
            break;
        }
        case FormAction.RESET: {
            newState = resetAction(state, action);
            break;
        }
        case FormAction.UNREGISTER: {
            newState = unregisterAction(state, action);
            break;
        }
        default: {
            throw new Error(`Invalid action "${type}" used in form dispatch`);
        }
    }
    action.callback?.(newState);
    return newState;
};

export default reducer;
