// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = (): void => {};

//  used in TW
export const call = <T = unknown, P = unknown>(
    valueOrFn: T | ((...props: Array<P>) => T),
    ...props: Array<P>
): T => {
    if (typeof valueOrFn === "function") {
        const fn = valueOrFn as (...props: Array<P>) => T;
        return fn.apply(this, props);
    }
    return valueOrFn;
};

/**
 * Debounce function that will postpone the execution of the given function by the given time delay
 * @param fn - Function to be executed
 * @param delay - Delay in milliseconds
 * @returns - Debounced function
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <F extends (...args: any[]) => any>(
    fn: F,
    delay: number
): ((...args: Parameters<F>) => void) => {
    let timeout: ReturnType<typeof setTimeout>;
    return (...args: Parameters<F>): void => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn(...args);
        }, delay);
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const delay = <F extends (...args: any[]) => any>(
    fn: F,
    delay: number
): ((...args: Parameters<F>) => void) => {
    let timeout: ReturnType<typeof setTimeout> | null;
    return (...args: Parameters<F>): void => {
        if (timeout) return;
        fn(...args);
        timeout = setTimeout(() => {
            if (timeout) clearTimeout(timeout);
            timeout = null;
        }, delay);
    };
};
