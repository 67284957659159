import { useContext } from "react";
import { FormContextMethods, FormContextMethodsType } from "../context";

/** Gets the form state and methods from context */
const useFormContextMethods = (): FormContextMethodsType => {
    const context = useContext(FormContextMethods);

    if (!context) {
        throw new Error(
            "useFormContextMethods must be used within a <FormContextMethods.Provider>"
        );
    }

    return context;
};

export { useFormContextMethods };
