import { useEffect } from "react";
import { Navigate, RouteProps, useLocation } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { useUserContext } from "authentication/contexts/user.context";
import { useStore } from "util/store";
import validateToken from "util/tokenUtil";
import Main from "Main";
import { encode } from "util/url";
import routes from "routes/routes";
import useToast from "contexts/toast/ToastContext";
import useSentryContextEffect from "util/hooks/useSentryContextEffect";
/**
 *  Secure content wrapper which will display the Main page and routes. Will automatically reroute to /login if authentication is invalid.
 *
 * @export
 * @param {RouteProps} { children }
 * @return {React.ReactElement}
 */
export default function SecureContent({
  children,
}: RouteProps): React.ReactElement {
  // If they tried to hit a secure route and get redirected, store that in the redirectTo query param
  const { pathname, search } = useLocation();
  const sanitizedPathName = pathname.replace("//", "/"); // Remove leading double slashes
  const prevLocationRedirect =
    pathname === "/" ? "" : `?redirectTo=${sanitizedPathName}${encode(search)}`; // need to encode search parameters to retain formatting such as & symbols

  const queryClient = useQueryClient();
  const userContext = useUserContext();
  const { showToast } = useToast();
  const userClaims = useStore(userContext.store, (user) => user.userClaims);
  const authToken = useStore(userContext.store, (user) => user.authToken);

  useEffect(() => {
    async function checkToken(): Promise<void> {
      const validToken = await validateToken(userClaims?.exp ?? 0, authToken);

      if (authToken && !validToken) {
        showToast("Your session has expired. Please log in again.", "error");
        userContext.logout();
      }
    }

    checkToken();

    const interval = setInterval(() => checkToken(), 300000); // 5 minutes
    return () => {
      clearInterval(interval);
    };
  }, [authToken, queryClient, showToast, userClaims?.exp, userContext]);

  useSentryContextEffect(userClaims);

  return authToken ? (
    <Main>{children}</Main>
  ) : (
    <Navigate to={`${routes.Login.getPath()}${prevLocationRedirect}`} />
  );
}
