import { ChangeEvent, FC, useRef, useState, AriaAttributes } from "react";
import { useControlledValue } from "@Utilities/hooks";
import { concat, props } from "@Utilities/string";
import {
    InputProps,
    Input,
    InputError,
    InputHelper,
    InputLabel,
    InputWrapper,
} from "@Components/formComponents/utilities";

export type CheckboxProps = FC<InputProps> & {
    /** @see {@link InputError} */
    Error: typeof InputError;
    /** @see {@link InputHelper} */
    Helper: typeof InputHelper;
    /** @see {@link Input} */
    Input: typeof Input;
    /** @see {@link InputLabel} */
    Label: typeof InputLabel;
    /** @see {@link InputWrapper} */
    Wrapper: typeof InputWrapper;
};

/**
 * Checkbox input enables users to mark an item as selected.
 * This component is used within a form and can be toggled between checked and not checked.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-checkbox--checkbox-form)}
 */
const Checkbox: CheckboxProps = ({
    checked: controlledChecked,
    className,
    element = "div",
    error,
    errorElement,
    errorMessage,
    errorProps = {},
    helperProps = {},
    helperText,
    hideError,
    hideLabel,
    id,
    label,
    labelProps = {},
    onChange,
    optional,
    required,
    theme,
    wrapperProps = {},
    ...rest
}) => {
    const errorId = `checkbox-error-${id}`;
    const helperId = `checkbox-helper-${id}`;
    const isHeadless = theme === "headless";
    const errors = Array.isArray(errorMessage)
        ? errorMessage.join(" ")
        : errorMessage;

    const [internalChecked, setInternalChecked] = useState(false);

    const isControlled = useRef(controlledChecked !== undefined);

    const checked = controlledChecked ?? internalChecked;

    useControlledValue(isControlled);

    const ariaProps: AriaAttributes = props({
        "aria-describedby": {
            condition: error || !!helperText,
            value: concat(error && errorId, !!helperText && helperId),
        },
        "aria-invalid": { condition: error, value: true },
        "aria-label": {
            condition: hideLabel || hideError,
            value: concat(label, hideError && errors),
        },
    });

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (!isControlled.current) {
            setInternalChecked(!internalChecked);
        }
        onChange?.(e);
    };

    const classes = concat(
        "zest-input-checkbox",
        className,
        !className && isHeadless && "focus:outline-blue-600"
    );

    return (
        <InputWrapper
            {...wrapperProps}
            element={element}
            theme={theme}
            className={concat("flex", wrapperProps.className)}
        >
            <Input
                {...rest}
                {...ariaProps}
                checked={checked}
                id={id}
                type="checkbox"
                onChange={handleCheckboxChange}
                className={classes}
                required={required}
            />
            <div className="flex flex-col ml-3">
                {!hideLabel && (
                    <InputLabel
                        {...labelProps}
                        hidden={hideLabel}
                        htmlFor={id as string}
                        optional={optional}
                        required={required}
                    >
                        {label}
                    </InputLabel>
                )}
                <InputHelper {...helperProps} id={helperId} theme={theme}>
                    {helperText}
                </InputHelper>
                <InputError
                    {...errorProps}
                    id={errorId}
                    hasError={error && !hideError}
                    theme={theme}
                    errorMessages={errorMessage}
                >
                    {errorElement}
                </InputError>
            </div>
        </InputWrapper>
    );
};

Checkbox.Wrapper = InputWrapper;
Checkbox.Helper = InputHelper;
Checkbox.Input = Input;
Checkbox.Error = InputError;
Checkbox.Label = InputLabel;

Checkbox.displayName = "Checkbox";

export { Checkbox };
