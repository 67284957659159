import HeaderIconMenu from "components/Header/components/HeaderIconMenu";
import SearchBar from "components/Header/components/SearchBar";
import HeaderRightButtonGroup from "components/Header/components/HeaderRightButtonGroup";
import HeaderAdminBar from "components/Header/AdminBar/HeaderAdminBar";
import CartButton from "components/buttons/CartButton";
import { concat } from "util/string";
import useCurrentBreakpoint from "util/hooks/useCurrentBreakpoint";

export const HeaderThemeFallback = "bg-white border-gray-200";
export const HeaderTheme = "action-bar";

export const HeaderButtonGroupTheme = "btn-group border rounded";

function Header(): JSX.Element {
  const currentBreakpoint = useCurrentBreakpoint();
  const isMobile: boolean = currentBreakpoint < 1024;
  const hideButtonLabels: boolean = currentBreakpoint < 1280;

  return (
    <header id="header" className="max-w-full">
      <div
        className={concat(
          HeaderThemeFallback,
          HeaderTheme,
          "border-b",
          "fixed z-30 flex h-32 w-screen flex-col items-center justify-between gap-y-4 p-4 xs:px-6 lg:h-24 lg:flex-row xl:py-7"
        )}
      >
        <div className="flex h-10 w-full min-w-fit lg:w-auto">
          <HeaderIconMenu
            className="w-1/2 lg:w-full"
            hideButtonLabels={hideButtonLabels}
          />
          {isMobile && (
            <div className="flex w-1/2 justify-end">{<CartButton />}</div>
          )}
        </div>
        <div className="flex h-10 w-full gap-x-2 lg:gap-x-1 xl:gap-x-0">
          <div className="hidden w-5 justify-self-start lg:block 2xl:w-16 3xl:w-1/12" />
          <SearchBar className="w-full justify-self-center" />
          <div className="hidden justify-self-end lg:inline-block xl:w-1/4 2xl:w-1/3 3xl:w-1/4" />
          <div className="flex space-x-2">
            <HeaderRightButtonGroup hideButtonLabels={hideButtonLabels} />
            {!isMobile && <CartButton />}
          </div>
        </div>
      </div>
      <HeaderAdminBar />
    </header>
  );
}

export default Header;
