import {
  QueryFunctionContext,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { AccountLinksData } from "layouts/Account/AccountLinksData.types";
import { gatewayApi } from "util/requests/gatewayApi";

export default function useAccountLinksAPI(): {
  data: AccountLinksData | undefined;
  isLoading: boolean;
  isError: boolean;
  refetch: () => Promise<UseQueryResult>;
} {
  const { data, isLoading, isError, refetch } = useQuery<
    AccountLinksData,
    Error,
    AccountLinksData,
    [string]
  >({
    queryKey: ["accounts/account-links"], // must be unique to identify this query
    queryFn: getData,
    staleTime: 60000,
  });

  return { data: data, isLoading, isError, refetch };
}

async function getData({
  queryKey,
}: QueryFunctionContext<[string]>): Promise<AccountLinksData> {
  const [urlSegment] = queryKey;

  const data = await gatewayApi.get<AccountLinksData>({ urlSegment });

  if (data === null || data === undefined) {
    throw new Error("Failed to retrieve Account Links data.");
  }

  return data;
}
