import { useRef, useCallback, useMemo, useEffect, RefObject } from "react";

export type UseTimeout = {
    clear: () => void;
    restart: () => void;
    running: RefObject<boolean>;
    start: () => void;
};

export const useTimeout = (callback: () => void, delay: number): UseTimeout => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const savedCallback = useRef(callback);

    const running = useRef(false);

    const clear = useCallback((): void => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
            running.current = false;
        }
    }, []);

    const start = useCallback((): void => {
        clear();
        timeoutRef.current = setTimeout(() => {
            savedCallback.current();
            clear();
        }, delay);
        running.current = true;
    }, [clear, delay]);

    //  if the callback changes we want to update the savedCallback to use the new callback passed in
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    return useMemo(
        () => ({
            start,
            clear,
            restart: start,
            running,
        }),
        [clear, start, running]
    );
};
