import { logError } from "util/logger";

export const themeNames = [
  // legacy themes
  "pro",
  "cna", // previously used by enfuego
  "cnaorange",
  "honeygrow", // previously used by arbys Flynn
  "bens", // previously used by arbys
  "cleaneatz",
  "countrycookin",
  "cfa",
  "kungfu",
  "pelican",
  "elevenfourhundred",
  "chebahut",
  "hometown",
  // new themes
  "arbys",
  "enfuego",
  "ndcp", // dunkin
] as const; // "as const" defines this array to be used as a constant for type definition

export type ThemeName = typeof themeNames[number];

/**
 * Conditionally Import & Add Theme to root
 * @param theme Theme to apply
 * @param callback Callback function to run after theme is applied or fails to apply
 */
export function applyTheme(
  theme: string,
  headerLite?: boolean,
  callback?: () => void
): void {
  importTheme(theme as ThemeName)
    .then(() => {
      // Remove previous theme classes in the case of Switch Account
      const anyPrevClasses = document.getElementById("root")?.classList;
      anyPrevClasses?.forEach((className) => {
        document.getElementById("root")?.classList.remove(className);
      });
      document.getElementById("root")?.classList.remove("header-lite");

      document.getElementById("root")?.classList.add(theme);
      if (headerLite) {
        import("themes/css/header-lite.css").then(() => {
          document.getElementById("root")?.classList.add("header-lite");
        });
      }
      callback?.();
    })
    .catch((e) => {
      logError(e, "Failed to apply theme");
      callback?.();
    });
}

function importTheme(theme: ThemeName): Promise<void> {
  return import(`./css/${theme}.css`); // must be ./ reference for variable import
}
