import { Pagination } from "util/pagination.types";

export type FilteredInvoices = {
  account: {
    accountId: number;
  };
  paginationWithRecords: {
    pagination?: Pagination;
    records: Invoice[];
  };
  selectedAccountId: number;
  subordinateAccounts: InvoiceSubordinateAccount[];
  isShippingAddressStoreNumberVisible: boolean;
};

export type Invoice = {
  idsInvoiceId?: number;
  mergedInvoiceId?: number;
  orderHeaderId?: number;
  invoiceNumber: string;
  originalInvoiceNumber: string;
  invoiceDate: string;
  totalPrice: number;
  grossDue: number;
  isCredit: boolean;
  isPaid: boolean;
  customerPurchaseOrderNumber?: string;
  pdfUri?: string;
  mergedInvoiceDetails?: MergedInvoiceDetail[];
  paymentStatus: PaymentStatus | number;
};

export enum PaymentStatus {
  PAID = 1,
  OUTSTANDING = 2,
  PAST_DUE = 4,
}

export type MergedInvoiceDetail = {
  orderHeaderId: number;
  customerPurchaseOrderNumber?: string;
};

export type InvoiceSubordinateAccount = {
  accountId: number;
  fullName: string;
};

export type ActiveInvoiceFilters = {
  issuedFrom?: string;
  issuedTo?: string;
  invoiceOrPONumber?: string;
  orderHeaderId?: string;
  storeNumber?: string;
  selectedAccountId?: string;
  searchInvoicePaymentStatus?: string;
  subordinatesSearchType?: string;
};

export type ZestInvoiceFilters = {
  issuedFrom?: string;
  issuedTo?: string;
  invoiceOrPONumber?: string;
  orderHeaderId?: string;
  storeNumber?: string;
  searchInvoicePaymentStatus?: string;
  subordinatesSearchType?: string;
};
