import { createContext, ReactNode, useContext } from "react";
import { UseUser } from "./useUserStore";

export type UserContextType = {
  store: UseUser["store"];
  login: UseUser["login"];
  logout: UseUser["logout"];
  removeAuthToken: UseUser["removeAuthToken"];
  updateClaims: UseUser["updateClaims"];
};

/**
 * User Context wrapper containing "state store" and manipulation functions.
 */
const UserContext = createContext<UserContextType | null>(null);

type UserProviderProps = UserContextType & {
  children: ReactNode;
};

/**
 * User Context Provider wrapper which allows the App.tsx to pass along the "state store" and manipulation functions.
 */
const UserProvider = ({
  children,
  ...rest
}: UserProviderProps): React.ReactElement => {
  return <UserContext.Provider value={rest}>{children}</UserContext.Provider>;
};

/**
 *  A hook to grant components access to the User Data Context.
 *  For use inside of the App.tsx component UserProvider the Context has been initialized via `useUserStore`
 */
const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error(
      '"useUserContext" needs to be used within the <App> component UserProvider.'
    );
  }
  return context;
};

export { UserContext, UserProvider, useUserContext };
