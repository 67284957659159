import { FC, HTMLAttributes, useLayoutEffect, useRef } from "react";
import { placements, useFloatingNoCaret } from "@Utilities/hooks";
import { concat } from "@Utilities/string";
import { useStore } from "@Utilities/hooks/useStoreData";
import { DataTypes } from "@Types/element";
import { useDropdownMenuContext } from "./dropdownMenu.context";
import { DropdownMenuState } from "./useDropdownMenu";

export type DropdownMenuContentProps = HTMLAttributes<HTMLElement> &
    DataTypes & {
        full?: boolean;
        id: string;
        offset?: number;
    };

function selectIsOpenId(state: DropdownMenuState): boolean {
    return state.isOpen;
}

const DropdownMenuContent: FC<DropdownMenuContentProps> = ({
    children,
    className,
    full = false,
    id,
    offset = 4,
    style,
    ...rest
}) => {
    const wrapperRef = useRef<HTMLUListElement>(null);

    const { store } = useDropdownMenuContext();
    const isOpen = useStore(store, selectIsOpenId);
    const { float, setAnchor, setFloating, setWrapper, unFloat, x, y } =
        useFloatingNoCaret({
            placement: placements.Bottom,
            offset,
        });

    /** Handles positioning the content before the browser displays the content */
    useLayoutEffect(() => {
        isOpen ? float() : unFloat();
        return function dropdownMenuFloatingCleanup(): void {
            if (isOpen) unFloat();
        };
    }, [isOpen, float, unFloat]);

    /** Handles finding the elements needed for the useFloating hook */
    useLayoutEffect(() => {
        if (wrapperRef.current) {
            setFloating(wrapperRef.current);
            let parentWithPosition = wrapperRef.current.parentElement;
            while (parentWithPosition !== document.body) {
                if (parentWithPosition instanceof HTMLElement) {
                    const { position: parentPosition } =
                        getComputedStyle(parentWithPosition);
                    if (parentPosition !== "static") {
                        break;
                    }
                    parentWithPosition = parentWithPosition.parentElement;
                }
            }
            if (parentWithPosition) {
                setWrapper(parentWithPosition);
            }
        }
        const anchor = document.querySelector<HTMLElement>(
            `#zest-dropdownMenu-trigger-${id}`
        );
        if (anchor) {
            setAnchor(anchor);
        }
    }, [id, setAnchor, setWrapper, setFloating]);

    const classes = concat(
        "zest-dropdownMenu-content",
        "absolute",
        isOpen ? "flex" : "hidden",
        full && "full-content",
        className
    );

    return (
        <ul
            id={`zest-dropdownMenu-content-${id}`}
            data-testid={`zest-dropdownMenu-content-${id}`}
            ref={wrapperRef}
            className={classes}
            aria-labelledby={`zest-dropdownMenu-trigger-${id}`}
            role="menu"
            style={{ ...style, top: `${y}px`, left: `${x}px` }}
            {...rest}
        >
            {children}
        </ul>
    );
};

export { DropdownMenuContent };
