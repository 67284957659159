import { ErrorMessages } from "util/requests/response.types";
import { concat } from "util/string";

export default function ErrorMessageToastDisplay({
  errors,
  className,
}: {
  readonly errors: ErrorMessages | string[] | undefined;
  readonly className?: string;
}): React.ReactElement {
  let errorMessagesWithKeys: { key: string; message: string }[] = [];
  if (Array.isArray(errors)) {
    errorMessagesWithKeys = errors.map((e) => {
      return {
        key: e,
        message: e,
      };
    });
  } else {
    for (const key in errors) {
      errorMessagesWithKeys = errorMessagesWithKeys.concat(
        errors[key].map((message) => {
          return { key: key + message, message: message };
        })
      );
    }
  }
  const uniqueErrors = errorMessagesWithKeys.filter(
    (messageWithKey, index, self) =>
      index === self.findIndex((mwk) => mwk.key === messageWithKey.key)
  );
  const errorMessages: React.ReactElement[] = uniqueErrors.map((e) => (
    <div key={e.key}>{e.message}</div>
  ));
  return (
    <div className={concat("text-xs text-red-800", className)}>
      {errorMessages}
    </div>
  );
}
