import { LayoutProps } from "./Layout.types";

/**
 * Return child components wrapped in a full-width layout container.
 *
 * @export
 * @param {LayoutProps} props
 * @return {*}  {JSX.Element}
 */
export const FullWidthLayout: React.FunctionComponent<LayoutProps> = (
  props: LayoutProps
): JSX.Element => {
  return (
    <section className="h-max min-h-screen max-w-full bg-white">
      {props.children}
    </section>
  );
};
