import {
    ButtonHTMLAttributes,
    FC,
    MouseEvent,
    useCallback,
    useEffect,
    useRef,
} from "react";
import { concat, props } from "@Utilities/string";
import { DataTypes } from "@Types/element";
import { usePopoverContext } from "./context";
import { useStore } from "@Utilities/hooks/useStoreData";

export type PopoverTriggerProps = ButtonHTMLAttributes<HTMLElement> &
    DataTypes & {
        contentId: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        element?: "button" | "div" | FC<any>;
    };

const PopoverTrigger: FC<PopoverTriggerProps> = ({
    children,
    className,
    contentId,
    element = "button",
    onClick,
    ...rest
}) => {
    const { store, toggle, triggerProps } = usePopoverContext();
    const Element = element;
    const isOpen = useStore(store, function selectIsOpenId(state) {
        return state.isOpen.has(contentId);
    });
    const ref = useRef<HTMLDivElement>(null);

    const conditionalProps = props({
        type: {
            value: "button",
            condition: element === "button",
        },
        "data-content": {
            value: contentId,
            condition: !!contentId,
        },
    });

    const classes = concat("zest-popover-trigger", className);

    const handleEnter = useCallback<(e: KeyboardEvent) => void>(
        (e) => {
            if (e.key === "Enter" && e.target instanceof HTMLElement) {
                const contents = store.get().contents;
                const dataContent = e.target.dataset.content;
                if (dataContent && contents.has(dataContent)) {
                    e.preventDefault();
                    toggle(e.target.dataset.content); // open the new popover
                }
            }
        },
        [store, toggle]
    );

    useEffect(() => {
        const popoverRef = ref.current;
        popoverRef?.addEventListener("keydown", handleEnter);
        return function popoverClickOutsideCleanup(): void {
            popoverRef?.removeEventListener("keydown", handleEnter);
        };
    }, [handleEnter]);

    const handleClick = (e: MouseEvent<HTMLElement>): void => {
        // Added to stop event bubbling and fix active issue in SF PDP not triggering onClick properly
        e.stopPropagation();
        toggle(contentId);
        onClick?.(e);
    };

    return (
        <Element
            ref={ref}
            {...triggerProps}
            {...rest}
            {...conditionalProps}
            className={classes}
            aria-expanded={isOpen}
            onClick={handleClick}
        >
            {children}
        </Element>
    );
};

export { PopoverTrigger };
