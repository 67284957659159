import { useNavigate } from "react-router";
import {
  Form as ZestForm,
  FormItem as ZestFormItem,
  TextInput as ZestTextInput,
  useForm,
} from "@clarkinc/zest-design-system";
import { useState } from "react";
import Modal, { toggle } from "components/Modal/Modal";
import { storeEmptyOrderGuide } from "components/OrderGuides/Modals/utils/storeEmptyOrderGuide";
import Button from "components/buttons/Button";
import LoadingSpinner from "components/Spinner/LoadingSpinner";
import useLayoutAPI from "layouts/api/useLayoutAPI";
import { ErrorMessages } from "util/requests/response.types";
import ModalFormErrors from "components/Modal/ModalFormErrors";
import routes from "routes/routes";

export const createOrderGuideModalId = "create-order-guide-modal";

export type StoreEmptyOrderGuideForm = {
  name: string;
};

const inputClassNames =
  "mt-1 w-full flex flex-col gap-1 rounded border border-gray-300";
const inputWrapperClassNames = "w-full ";
const inputLabelProps = { className: "text-sm font-semibold" };

export default function CreateOrderGuideModal(): React.ReactElement {
  const form = useForm<StoreEmptyOrderGuideForm>();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { refetch } = useLayoutAPI();
  const [errors, setErrors] = useState<ErrorMessages>();

  const reset = (): void => {
    form.reset();
    setErrors(undefined);
  };

  const onFinish = async (): Promise<void> => {
    const formData: StoreEmptyOrderGuideForm = {
      name: form.getFormState().name.value,
    };

    let orderGuideId: number | undefined;
    let errors: ErrorMessages | undefined;

    setErrors(undefined);
    setSubmitting(true);

    try {
      ({ orderGuideId, errors } = await storeEmptyOrderGuide(formData));
    } finally {
      setSubmitting(false);
    }

    if (orderGuideId) {
      reset();
      toggle(createOrderGuideModalId);
      refetch();
      navigate(routes.OrderGuideView.getPath(orderGuideId + ""));
    } else if (errors) {
      setErrors(errors);
    }
  };

  const body = (
    <ZestForm
      className="col-span-2 flex flex-col"
      form={form}
      name="Create Order Guide"
      onFinish={onFinish}
    >
      <ModalFormErrors errors={errors} />
      <CreateOrderGuideModalFormContent submitting={submitting} />
      <div className="px-6 py-5 flex gap-4 justify-end border-t border-gray-200">
        <Button
          disabled={submitting}
          label="Cancel"
          onClick={(): void => toggle(createOrderGuideModalId)}
          variant="tertiary"
        />
        <Button
          disabled={submitting}
          label="Create Guide"
          type="submit"
          variant="secondary"
        />
      </div>
    </ZestForm>
  );

  const header = (
    <span className="font-medium text-lg">Create Your Order Guide</span>
  );

  return (
    <Modal
      ariaLabel="Create Your Order Guide"
      className="px-0 py-0 rounded-2xl w-[480px]"
      body={body}
      bodyClassNames="pr-0 pb-0 pl-0 pt-0"
      header={header}
      headerClassNames="pb-6 pr-6 pl-6 font-medium text-lg"
      id={createOrderGuideModalId}
      showFooterSeparator={true}
      showHeaderSeparator={true}
      onClose={(): void => {
        form.reset();
      }}
    />
  );
}

function CreateOrderGuideModalFormContent({
  submitting,
}: {
  submitting: boolean;
}): React.ReactElement {
  if (submitting) {
    return (
      <div className="h-28 flex items-center justify-center">
        <LoadingSpinner className="h-20 w-20" />
      </div>
    );
  }

  return (
    <div className="p-6">
      <ZestFormItem required name="name">
        <ZestTextInput
          className={inputClassNames}
          label="Guide Title "
          labelProps={inputLabelProps}
          maxLength={100}
          errorProps={{ className: "text-red-600" }}
          wrapperProps={{ className: inputWrapperClassNames }}
        />
      </ZestFormItem>
    </div>
  );
}
