import { FC, ReactNode } from "react";
import { useUuid } from "@Utilities/hooks";
import { List } from "@Components/list";
import { Link } from "@Components/link";

export type FormAlertErrorProps = {
    errors: string[];
    id: string;
    label: ReactNode;
};

/**
 * An alert error that uses the list component to communicate errors in the form to the user.
 *
 * It is not recommended to use this component directly.
 * Instead use a `<Form.Alert>` component.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-form)}
 */
const FormAlertError: FC<FormAlertErrorProps> = ({ errors, id, label }) => {
    const internalId = useUuid("field-error");

    return (
        <>
            <Link
                aria-describedby={internalId}
                href={`#${id}`}
                className="underline"
            >
                {label}
            </Link>
            <List className="ml-4">
                {errors?.map((error, i) => (
                    <List.Item key={error + i.toString()}>{error}</List.Item>
                ))}
            </List>
        </>
    );
};

export { FormAlertError };
