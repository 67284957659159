import * as LazyRoutes from "routes/lazyRoutes";
import { appendIgnoreTimeout } from "util/string";
import { encode } from "util/url";

export type AppRoutes = {
  [key in keyof typeof routeKeys]: AppRoute;
};

export type AppRoute = {
  path: string; // match path for react-router-dom. Should match getPath with but use :variable syntax
  getPath: (args?: string | undefined, args2?: string | undefined) => string; // path for local routes to use. Must start with / and should match path but use ${variable} syntax.
  component: React.ReactElement;
  insecure?: boolean;
  sidebar?: React.ReactElement;
  header?: React.ReactElement;
};

export const routeKeys = {
  Home: "Home",
  Login: "Login",
  LoginAs: "LoginAs",
  LoginAsDeprecated: "LoginAsDeprecated",
  PunchoutLogin: "PunchoutLogin",
  LoginMultiAccount: "LoginMultiAccount",
  PDP: "PDP",
  PDPParts: "PDPParts",
  PDPItemId: "PDPItemId",
  PLP: "PLP",
  Categories: "Categories",
  Cart: "Cart",
  CartReview: "CartReview",
  ApprovedCreditKeyCheckout: "ApprovedCreditKeyCheckout",
  DeclinedCreditKeyCheckout: "DeclinedCreditKeyCheckout",
  CartConfirmation: "CartConfirmation",
  MyAccount: "MyAccount",
  OrderHistory: "OrderHistory",
  OrderDetails: "OrderDetails",
  OrdersPendingApproval: "OrdersPendingApproval",
  OrderApproval: "OrderApproval",
  RapidReorder: "RapidReorder",
  AccountSettings: "AccountSettings",
  ManageNotifications: "ManageNotifications",
  ShippingAddresses: "ShippingAddresses",
  BillingAddresses: "BillingAddresses",
  PaymentMethods: "PaymentMethods",
  Invoices: "Invoices",
  InvoiceDetailsIDS: "InvoiceDetailsIDS",
  InvoiceDetailsMerged: "InvoiceDetailsMerged",
  CompareItems: "CompareItems",
  Suppliers: "Suppliers",
  MySuppliers: "MySuppliers",
  ProjectBuildersLandingPage: "ProjectBuildersLandingPage",
  ProjectBuilderView: "ProjectBuilderView",
  ProjectBuilderEdit: "ProjectBuilderEdit",
  ProjectBuildersCompanyLandingPage: "ProjectBuildersCompanyLandingPage",
  ProjectBuilderCompanyView: "ProjectBuilderCompanyView",
  ProjectBuilderCompanyEdit: "ProjectBuilderCompanyEdit",
  EquipmentProjectsListing: "EquipmentProjectsListing",
  EquipmentProjectsView: "EquipmentProjectsView",
  BrandCategories: "BrandCategories",
  BrandsView: "BrandsView",
  Brands: "Brands",
  OrderGuideLandingPage: "OrderGuideLandingPage",
  OrderGuideView: "OrderGuideView",
  OrderGuideEdit: "OrderGuideEdit",
  OrderGuideCompanyLandingPage: "OrderGuideCompanyLandingPage",
  OrderGuideCompanyView: "OrderGuideCompanyView",
  OrderGuideCompanyEdit: "OrderGuideCompanyEdit",
  Support: "Support",
  Emails: "Emails",
  ManageUsers: "ManageUsers",
  ManageUsersCreate: "ManageUsersCreate",
  ManageUsersEdit: "ManageUsersEdit",
  ManageBudgets: "ManageBudgets",
  CreateBudget: "CreateBudget",
  EditBudget: "EditBudget",
  AboutSelect: "AboutSelect",
  ManageSelectAddresses: "ManageSelectAddresses",
  ManageSelectAddressesUseNewestCard: "ManageSelectAddressesUseNewestCard",
  ManageSelectAddressesUseNewestCardAndState:
    "ManageSelectAddressesUseNewestCardAndState",
  ConfirmationSelectAddresses: "ConfirmationSelectAddresses",
  Reporting: "Reporting",
  ViewReport: "ViewReport",
  Quotes: "Quotes",
  ViewQuote: "ViewQuote",
  Policies: "Policies",
  AccessibilityPolicy: "AccessibilityPolicy",
  FAQ: "FAQ",
  ForgotPassword: "ForgotPassword",
  ForgotPasswordConfirmation: "ForgotPasswordConfirmation",
  Activation: "Activation",
  ActivationConfirmation: "ActivationConfirmation",
  ResetPassword: "ResetPassword",
  ResetPasswordConfirmation: "ResetPasswordConfirmation",
  SetPassword: "SetPassword",
  SetPasswordConfirmation: "SetPasswordConfirmation",
  OrderApprovalEmailPage: "OrderApprovalEmailPage",
  SpecializedPage: "SpecializedPage",
  500: "500",
  404: "404",
  IncomingSSO: "IncomingSSO",
  OktaSSO: "OktaSSO",
  LoginAsSSO: "LoginAsSSO",
  LoginAsOld: "LoginAsOld",
  LoginSingleSignOnSessionPage: "LoginSingleSignOnSessionPage",
  FrequentlyOrderedItems: "FrequentlyOrderedItems",
  SelfSignUp: "SelfSignUp",
  SelfSignUpSetPassword: "SelfSignUpSetPassword",
} as const;

/**
 * Array of AppRoutes which defines URL path matching pages. Full-Width Layout will always be applied.
 * When adding a new page, you should add a new route object here and in routeKeys instead of App.tsx
 */
const routes: Record<keyof typeof routeKeys, AppRoute> = {
  Home: {
    path: "/",
    getPath: () => "/",
    component: <LazyRoutes.HomePage />,
  },
  Login: {
    path: "/accounts/login",
    getPath: () => "/accounts/login",
    component: <LazyRoutes.LoginPage />,
    insecure: true,
  },
  ForgotPassword: {
    path: "/accounts/forgotpassword",
    getPath: () => "/accounts/forgotpassword",
    component: <LazyRoutes.ForgotPasswordPage />,
    insecure: true,
  },
  ForgotPasswordConfirmation: {
    path: "/accounts/forgotpasswordconfirmation",
    getPath: () => "/accounts/forgotpasswordconfirmation",
    component: <LazyRoutes.ForgotPasswordConfirmationPage />,
    insecure: true,
  },
  Activation: {
    path: "/accounts/activate",
    getPath: () => "/accounts/activate",
    component: <LazyRoutes.ForgotPasswordPage isAccountActivation={true} />,
    insecure: true,
  },
  ActivationConfirmation: {
    path: "/accounts/activationconfirmation",
    getPath: () => "/accounts/activationconfirmation",
    component: (
      <LazyRoutes.ForgotPasswordConfirmationPage isAccountActivation={true} />
    ),
    insecure: true,
  },
  ResetPassword: {
    path: "/accounts/resetpassword",
    getPath: () => "/accounts/resetpassword",
    component: <LazyRoutes.PasswordResetPage />,
    insecure: true,
  },
  ResetPasswordConfirmation: {
    path: "/accounts/resetpasswordconfirmation",
    getPath: () => "/accounts/resetpasswordconfirmation",
    component: <LazyRoutes.PasswordResetConfirmationPage />,
    insecure: true,
  },
  SetPassword: {
    path: "/accounts/setpassword",
    getPath: () => "/accounts/setpassword",
    component: <LazyRoutes.PasswordResetPage isAccountActivation={true} />,
    insecure: true,
  },
  SetPasswordConfirmation: {
    path: "/accounts/setpasswordconfirmation",
    getPath: () => "/accounts/setpasswordconfirmation",
    component: (
      <LazyRoutes.PasswordResetConfirmationPage isAccountActivation={true} />
    ),
    insecure: true,
  },
  LoginAs: {
    path: "/accounts/login-as",
    getPath: () => "/accounts/login-as",
    component: <LazyRoutes.LoginAsPage />,
    insecure: true,
  },
  LoginAsOld: {
    path: "/accounts/login-as/:token/admin/:accountId",
    getPath: (token?: string, clarkAccountId?: string) =>
      `/accounts/login-as/${token}/admin/${clarkAccountId}`,
    component: <LazyRoutes.LoginAsPage />,
    insecure: true,
  },
  LoginAsSSO: {
    path: "/accounts/login-as-sso",
    getPath: () => "/accounts/login-as-sso",
    component: <LazyRoutes.LoginAsSsoPage />,
    insecure: true,
  },
  LoginAsDeprecated: {
    path: "/login-as",
    getPath: () => "/login-as",
    component: <LazyRoutes.LoginAsPage />,
    insecure: true,
  },
  PunchoutLogin: {
    path: "/accounts/punchout-login",
    getPath: () => "/accounts/punchout-login",
    component: <LazyRoutes.PunchOutLoginPage />,
    insecure: true,
  },
  LoginMultiAccount: {
    path: "/login-multi-account",
    getPath: () => "/login-multi-account",
    component: <LazyRoutes.LoginAccountSelectPage />,
    insecure: true,
  },
  PDP: {
    path: "/items/item-number/:itemNumber",
    getPath: (itemNumber, breadCrumbsString) =>
      `/items/item-number/${encode(itemNumber)}${
        breadCrumbsString && breadCrumbsString.length > 0
          ? `?breadCrumbs=${encode(breadCrumbsString)}`
          : ""
      }`,
    component: <LazyRoutes.ProductDisplayPage />, // Example of Full Layout which contains a Sidebar Layout inside of the component
  },
  PDPParts: {
    path: "/items/:itemNumber/parts",
    getPath: (itemNumber) => `/items/${encode(itemNumber)}/parts`,
    component: <LazyRoutes.ProductPartsAndAccessoriesPage />,
  },
  PDPItemId: {
    path: "/items/:itemId",
    getPath: (itemId, breadCrumbsString) =>
      `/items/${itemId}${
        breadCrumbsString && breadCrumbsString.length > 0
          ? `?breadCrumbs=${encode(breadCrumbsString)}`
          : ""
      }`,
    component: <LazyRoutes.ProductDisplayPage />,
  },
  PLP: {
    path: "/items",
    getPath: (searchTerm) => `/items?q=${encode(searchTerm)}`,
    component: <LazyRoutes.ProductListingPage />,
  },
  Categories: {
    path: "/categories/:categoryId",
    getPath: (categoryId, breadCrumbsString) =>
      `/categories/${categoryId}${
        breadCrumbsString && breadCrumbsString.length > 0
          ? `?breadCrumbs=${encode(breadCrumbsString)}`
          : ""
      }`,
    component: <LazyRoutes.CategoryDisplayPage />,
  },
  Cart: {
    path: "/carts",
    getPath: () => appendIgnoreTimeout("/carts"),
    component: <LazyRoutes.CartDisplayPage />,
  },
  CartReview: {
    path: "/cart/review",
    getPath: () => appendIgnoreTimeout("/cart/review"),
    component: <LazyRoutes.CartCheckoutPage />,
  },
  ApprovedCreditKeyCheckout: {
    path: "/cart/complete-creditkey-checkout/:logisticsOrderNumber",
    getPath: (logisticsOrderNumber) =>
      `/cart/complete-creditkey-checkout/${logisticsOrderNumber}`,
    component: <LazyRoutes.CartCreditKeyCheckoutPage />,
  },
  DeclinedCreditKeyCheckout: {
    path: "/cart/declined-creditkey-checkout",
    getPath: () => `/cart/declined-creditkey-checkout`,
    component: <LazyRoutes.CartCreditKeyCheckoutPage />,
  },
  CartConfirmation: {
    path: "/orders/:orderId/order-confirmation",
    getPath: (orderId) => `/orders/${orderId}/order-confirmation`,
    component: <LazyRoutes.CartConfirmationPage />,
  },
  MyAccount: {
    path: "/accounts",
    getPath: () => "/accounts",
    component: <LazyRoutes.MyAccountPage />,
  },
  OrderHistory: {
    path: "/accounts/orders",
    getPath: () => "/accounts/orders",
    component: <LazyRoutes.OrderHistoryPage />,
  },
  OrderDetails: {
    path: "/orders/:orderId",
    getPath: (orderId) => `/orders/${orderId}`,
    component: <LazyRoutes.OrderDetailsPage />,
  },
  OrdersPendingApproval: {
    path: "/accounts/orders-pending-approval",
    getPath: () => "/accounts/orders-pending-approval",
    component: <LazyRoutes.OrderApprovalListingPage />,
  },
  OrderApproval: {
    path: "/orders/:orderId/order-approval",
    getPath: (orderId) => `/orders/${orderId}/order-approval`,
    component: <LazyRoutes.OrderApprovalPage />,
  },
  RapidReorder: {
    path: "/orders/rapid-reorders",
    getPath: () => "/orders/rapid-reorders",
    component: <LazyRoutes.RapidReorderPage />,
  },
  AccountSettings: {
    path: "/accounts/edit",
    getPath: () => "/accounts/edit",
    component: <LazyRoutes.AccountSettingsPage />,
  },
  ManageNotifications: {
    path: "/accounts/notifications",
    getPath: () => "/accounts/notifications",
    component: <LazyRoutes.AccountSettingsPage />,
  },
  ShippingAddresses: {
    path: "/addresses/shipping",
    getPath: () => "/addresses/shipping",
    component: <LazyRoutes.AddressBookPage addressType="Shipping" />,
  },
  BillingAddresses: {
    path: "/addresses/billing",
    getPath: () => "/addresses/billing",
    component: <LazyRoutes.AddressBookPage addressType="Billing" />,
  },
  PaymentMethods: {
    path: "/payment-methods",
    getPath: () => "/payment-methods",
    component: <LazyRoutes.PaymentMethodsPage />,
  },
  Invoices: {
    path: "/invoices",
    getPath: (query) => `/invoices${query ?? ""}`,
    component: <LazyRoutes.InvoicePage />,
  },
  InvoiceDetailsIDS: {
    path: "/invoices/ids/:idsInvoiceId",
    getPath: (idsInvoiceId) => `/invoices/ids/${idsInvoiceId}`,
    component: <LazyRoutes.IDSInvoiceDetailPage />,
  },
  InvoiceDetailsMerged: {
    path: "/invoices/merged/:mergedInvoiceId",
    getPath: (mergedInvoiceId) => `/invoices/merged/${mergedInvoiceId}`,
    component: <LazyRoutes.MergedInvoiceDetailPage />,
  },
  CompareItems: {
    path: "/items/compare",
    getPath: () => "/items/compare",
    component: <LazyRoutes.CompareItemsPage />,
  },
  Suppliers: {
    path: "/suppliers",
    getPath: () => "/suppliers",
    component: <LazyRoutes.SuppliersPage />,
  },
  MySuppliers: {
    path: "/my-suppliers",
    getPath: () => "/my-suppliers",
    component: <LazyRoutes.MySuppliersPage />,
  },
  ProjectBuildersLandingPage: {
    path: "/project-builders",
    getPath: () => "/project-builders",
    component: <LazyRoutes.AccountProjectBuildersLandingPage />,
  },
  ProjectBuilderView: {
    path: "/project-builders/:projectBuilderId",
    getPath: (projectBuilderId) => `/project-builders/${projectBuilderId}`,
    component: <LazyRoutes.ViewAccountProjectBuilderPage />,
  },
  ProjectBuilderEdit: {
    path: "/project-builders/:projectBuilderId/edit",
    getPath: (projectBuilderId) => `/project-builders/${projectBuilderId}/edit`,
    component: <LazyRoutes.EditAccountProjectBuilderPage />,
  },
  ProjectBuildersCompanyLandingPage: {
    path: "/company-project-builders",
    getPath: () => "/company-project-builders",
    component: <LazyRoutes.CompanyProjectBuildersLandingPage />,
  },
  ProjectBuilderCompanyView: {
    path: "/company-project-builders/:projectBuilderId",
    getPath: (projectBuilderId) =>
      `/company-project-builders/${projectBuilderId}`,
    component: <LazyRoutes.ViewCompanyProjectBuilderPage />,
  },
  ProjectBuilderCompanyEdit: {
    path: "/company-project-builders/:projectBuilderId/edit",
    getPath: (projectBuilderId) =>
      `/company-project-builders/${projectBuilderId}/edit`,
    component: <LazyRoutes.EditCompanyProjectBuilderPage />,
  },
  EquipmentProjectsListing: {
    path: "/equipment-projects",
    getPath: () => `/equipment-projects`,
    component: <LazyRoutes.EquipmentProjectListingPage />,
  },
  EquipmentProjectsView: {
    path: "/equipment-projects/:equipmentProjectId",
    getPath: (equipmentProjectId) =>
      `/equipment-projects/${equipmentProjectId}`,
    component: <LazyRoutes.ViewEquipmentProjectPage />,
  },
  BrandCategories: {
    path: "/brands/:brandId/categories",
    getPath: (brandId) => `/brands/${brandId}/categories`,
    component: <LazyRoutes.BrandCategoriesPage />,
  },
  BrandsView: {
    path: "/brands/:brandId",
    getPath: (brandId) => `/brands/${brandId}`,
    component: <LazyRoutes.BrandCategoriesPage />,
  },
  Brands: {
    path: "/brands",
    getPath: () => `/brands`,
    component: <LazyRoutes.BrandsPage />,
  },
  OrderGuideLandingPage: {
    path: "/order-guides",
    getPath: () => "/order-guides",
    component: <LazyRoutes.AccountOrderGuidesLandingPage />,
  },
  OrderGuideView: {
    path: "/order-guides/:orderGuideId",
    getPath: (orderGuideId) => `/order-guides/${orderGuideId}`,
    component: <LazyRoutes.AccountOrderGuidePage />,
  },
  OrderGuideEdit: {
    // looks like this is not a unique route but it will redirect to the same page as OrderGuideView where permissions will be checked regardless
    path: "/order-guides/:orderGuideId/edit",
    getPath: (orderGuideId) => `/order-guides/${orderGuideId}/edit`,
    component: <LazyRoutes.AccountOrderGuidePage />,
  },
  OrderGuideCompanyLandingPage: {
    path: "/company-order-guides",
    getPath: () => "/company-order-guides",
    component: <LazyRoutes.CompanyOrderGuidesLandingPage />,
  },
  OrderGuideCompanyView: {
    path: "/company-order-guides/:orderGuideId",
    getPath: (orderGuideId) => `/company-order-guides/${orderGuideId}`,
    component: <LazyRoutes.CompanyOrderGuidePage />,
  },
  OrderGuideCompanyEdit: {
    // looks like this is not a unique route but it will redirect to the same page as OrderGuideCompanyView where permissions will be checked regardless
    path: "/company-order-guides/:orderGuideId/edit",
    getPath: (orderGuideId) => `/company-order-guides/${orderGuideId}/edit`,
    component: <LazyRoutes.CompanyOrderGuidePage />,
  },
  Support: {
    path: "/support",
    getPath: () => "/support",
    component: <LazyRoutes.MyAccountPage />,
  },
  Policies: {
    path: "/policies",
    getPath: () => `/policies`,
    component: <LazyRoutes.PoliciesPage />,
  },
  AccessibilityPolicy: {
    path: "/policies",
    getPath: () => `/policies#policies-accessibility-policy`,
    component: <LazyRoutes.PoliciesPage />,
  },
  FAQ: {
    path: "/faq",
    getPath: () => `/faq`,
    component: <LazyRoutes.FAQPage />,
  },
  Emails: {
    path: "/emails",
    getPath: () => `/emails`,
    component: <LazyRoutes.EmailsPage />,
  },
  ManageUsers: {
    path: "/accounts/users",
    getPath: () => `/accounts/users`,
    component: <LazyRoutes.ManageUsersListingPage />,
  },
  ManageUsersCreate: {
    path: "/accounts/users/create",
    getPath: () => `/accounts/users/create`,
    component: <LazyRoutes.ManageUsersCreatePage />,
  },
  ManageUsersEdit: {
    path: "/accounts/users/:accountId/edit",
    getPath: (accountId) => `/accounts/users/${accountId}/edit`,
    component: <LazyRoutes.ManageUsersEditPage />,
  },
  ManageBudgets: {
    path: "/accounts/budgets",
    getPath: () => `/accounts/budgets`,
    component: <LazyRoutes.ManageBudgetPage />,
  },
  CreateBudget: {
    path: "/budgets/create",
    getPath: () => `/budgets/create`,
    component: <LazyRoutes.CreateBudgetPage />,
  },
  EditBudget: {
    path: "/budgets/:budgetId/edit",
    getPath: (budgetId) => `/budgets/${budgetId}/edit`,
    component: <LazyRoutes.CreateBudgetPage />,
  },
  AboutSelect: {
    path: "/select",
    getPath: () => `/select`,
    component: <LazyRoutes.SelectPage />,
  },
  ManageSelectAddresses: {
    path: "/select/manage-account",
    getPath: () => `/select/manage-account`,
    component: <LazyRoutes.ManageSelectPage />,
  },
  ManageSelectAddressesUseNewestCard: {
    path: "/select/manage-account/:useNewestCard",
    getPath: (useNewestCard) => `/select/manage-account/${useNewestCard}`,
    component: <LazyRoutes.ManageSelectPage />,
  },
  ManageSelectAddressesUseNewestCardAndState: {
    path: "/select/manage-account/:useNewestCard/:selectedStateProvinceId",
    getPath: (useNewestCard, selectedStateProvinceId) =>
      `/select/manage-account/${useNewestCard}/${selectedStateProvinceId}`,
    component: <LazyRoutes.ManageSelectPage />,
  },
  ConfirmationSelectAddresses: {
    path: "/select/confirmation",
    getPath: () => `/select/confirmation`,
    component: <LazyRoutes.ConfirmationSelectPage />,
  },
  Reporting: {
    path: "/reports/powerbi/embedded/index",
    getPath: () => `/reports/powerbi/embedded/index`,
    component: <LazyRoutes.ReportingPage />,
  },
  ViewReport: {
    path: "/reports/powerbi/embedded/report-page/:reportName",
    getPath: (reportName) =>
      `/reports/powerbi/embedded/report-page/${reportName}`,
    component: <LazyRoutes.ViewReportPage />,
  },
  Quotes: {
    path: "/accounts/quotes",
    getPath: () => `/accounts/quotes`,
    component: <LazyRoutes.QuoteListingPage />,
  },
  ViewQuote: {
    path: "/quotes/:quoteId",
    getPath: (quoteId) => `/quotes/${quoteId}`,
    component: <LazyRoutes.ViewQuotePage />,
  },
  OrderApprovalEmailPage: {
    path: "/orders/:orderId/approve-order-email/:sessionKey",
    getPath: (orderId, sessionKey) =>
      `/orders/${orderId}/approve-order-email/${sessionKey}`,
    component: <LazyRoutes.OrderApprovalEmailPage />,
    insecure: true,
  },
  SpecializedPage: {
    path: "/specialized-pages/:specializedPageId",
    getPath: (specializedPageId) => `/specialized-pages/${specializedPageId}`,
    component: <LazyRoutes.SpecializedPage />,
  },
  500: {
    path: "/500",
    getPath: () => "/500",
    component: <LazyRoutes.InternalServerErrorPage />,
  },
  404: {
    path: "*",
    getPath: () => "/404",
    component: <LazyRoutes.NotFoundErrorPage />,
  },
  IncomingSSO: {
    path: "/accounts/sso/:clientId",
    getPath: (clientId) => `/accounts/sso/${clientId}`,
    component: <LazyRoutes.IncomingSSO />,
    insecure: true,
  },
  OktaSSO: {
    path: "/accounts/okta-sso",
    getPath: () => `/accounts/okta-sso`,
    component: <LazyRoutes.OktaSSO />,
    insecure: true,
  },
  LoginSingleSignOnSessionPage: {
    path: "/accounts/login-sso-session",
    getPath: () => "/accounts/login-sso-session",
    component: <LazyRoutes.LoginSingleSignOnSessionPage />,
    insecure: true,
  },
  FrequentlyOrderedItems: {
    path: "/orders/frequently-ordered-items/show",
    getPath: () => "/orders/frequently-ordered-items/show",
    component: <LazyRoutes.FrequentlyOrderedItemsPage />,
  },
  SelfSignUp: {
    path: "/activate/quickcode",
    getPath: () => "/activate/quickcode",
    component: <LazyRoutes.SelfSignUpPage />,
    insecure: true,
  },
  SelfSignUpSetPassword: {
    path: "/accounts/self-sign-up/set-password",
    getPath: () => "/accounts/self-sign-up/set-password",
    component: <LazyRoutes.SelfSignUpSetPasswordPage />,
    insecure: true,
  },
};
//! NOTE: When adding a new Route, add it to BOTH routeKeys and routes.

export default routes;
