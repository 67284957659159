import { useCallback, useMemo, useRef } from "react";
import {
    ReadOnlyUseCreateStore,
    useCreateStore,
} from "@Utilities/hooks/useStoreData";

export type DropdownMenuState = {
    focused: string;
    isOpen: boolean;
    items: string[];
    trigger: string;
};

export enum DropdownMenuEvents {
    OPEN = "open",
    CLOSE = "close",
}

export type DropdownMenuEventCallbacks = () => void;

export type UseDropdownMenuMethods = {
    /**
     * Closes the DropdownMenu
     * @example
     * const { close } = useDropdownMenu();
     * close();
     */
    close: () => void;
    handleKeyDown: (e: React.KeyboardEvent) => void;
    /**
     * Open the DropdownMenu
     * @example
     * const { open } = useDropdownMenu();
     * open();
     */
    open: () => void;
    /**
     * Registers a Content to DropdownMenu
     * Allows the other methods to open / close the content
     * @example
     * const { register } = useDropdownMenu();
     * register("my-content-id")
     */
    register: (id: string) => () => void;
    /**
     * Registers a Trigger to DropdownMenu
     * Allows the other methods to open / close the content
     * @example
     * const { registerTrigger } = useDropdownMenu();
     * register("my-content-id")
     */
    registerTrigger: (id: string) => void;
    resetFocus: () => void;
    /**
     * Subscribes a callback to run when the DropdownMenu opens or closes
     * @example
     * const { subscribe } = useDropdownMenu();
     * subscribe(DropdownMenuEvents.OPEN, cb);
     */
    subscribe: <E extends DropdownMenuEvents>(
        event: E,
        cb: DropdownMenuEventCallbacks
    ) => () => void;
    /**
     * Toggle the Content open or closed.
     * @example
     * const { toggle } = useDropdownMenu();
     * toggle();
     */
    toggle: () => void;
};

export type useDropdownMenu = UseDropdownMenuMethods & {
    store: ReadOnlyUseCreateStore<DropdownMenuState>;
};

const useDropdownMenu = (): useDropdownMenu => {
    const store = useCreateStore<DropdownMenuState>({
        items: [],
        focused: "",
        trigger: "",
        isOpen: false,
    });

    const subscriptions = useRef<{
        [index in DropdownMenuEvents]: Set<DropdownMenuEventCallbacks>;
    }>({ open: new Set(), close: new Set() });

    const subscribe = useCallback<UseDropdownMenuMethods["subscribe"]>(
        (e, cb) => {
            subscriptions.current[e].add(cb);
            return () => subscriptions.current[e].delete(cb);
        },
        [subscriptions]
    );

    const resetFocus = useCallback<UseDropdownMenuMethods["resetFocus"]>(() => {
        store.set({ focused: "" });
    }, [store]);

    const registerTrigger = useCallback<
        UseDropdownMenuMethods["registerTrigger"]
    >(
        (id) => {
            const fullTriggerId = `zest-dropdownMenu-trigger-${id}`;
            // Check if there is a corresponding contentId
            const content = document.querySelector(
                `#zest-dropdownMenu-content-${id}`
            );
            if (!content) {
                throw new Error(
                    "No matching id for DropdownMenu.Content found. DropdownMenu.Trigger elements should have a contentId matching the corresponding Content's id."
                );
            }
            const { trigger } = store.get();
            if (trigger) {
                throw new Error(
                    "Two triggers are being registered for the same DropdownMenu."
                );
            }
            store.set({ trigger: fullTriggerId });
            return function unregisterTrigger() {
                store.set({ trigger: "" });
            };
        },
        [store]
    );

    // adds a DropdownMenu trigger or content to the store
    const register = useCallback<UseDropdownMenuMethods["register"]>(
        (id) => {
            // Have to create new objec to actually update the store
            const items = [...store.get().items];
            // If the id is not already registered, add it to the items list
            if (!items.includes(id)) {
                items.push(id);
            } else {
                throw new Error(
                    `DropdownMenu trying to register ${id} twice. Id's should be unique.`
                );
            }
            store.set({ items });
            return function unregister() {
                const items = [...store.get().items];
                const index = items.indexOf(id);
                if (index !== -1) {
                    items.splice(index);
                }
                store.set({ items });
            };
        },
        [store]
    );

    const toggle = useCallback<UseDropdownMenuMethods["toggle"]>(() => {
        const { isOpen, items } = store.get();
        if (isOpen) {
            subscriptions.current.close.forEach((cb) => {
                cb();
            });
        } else {
            subscriptions.current.open.forEach((cb) => {
                cb();
            });
        }
        const focused = !isOpen ? items[0] : "";
        return store.set({ isOpen: !isOpen, focused });
    }, [store]);

    const close = useCallback<UseDropdownMenuMethods["close"]>(() => {
        subscriptions.current.close.forEach((cb) => {
            cb();
        });
        return store.set({ isOpen: false });
    }, [store]);

    const open = useCallback<UseDropdownMenuMethods["close"]>(() => {
        subscriptions.current.open.forEach((cb) => {
            cb();
        });
        store.set({ isOpen: true, focused: store.get().items[0] });
    }, [store]);

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent): void => {
            const { focused, items: itemsInStore, trigger } = store.get();
            const { key } = e;
            if (
                ["ArrowDown", "ArrowUp", "Home", "End", "Escape"].includes(
                    e.key
                )
            ) {
                e.preventDefault();
                e.stopPropagation();
            }
            if (key === "Escape") {
                close();
                document
                    .querySelector<HTMLButtonElement>(`#${trigger}`)
                    ?.focus();
            }

            if (store.get().isOpen) {
                const firstItem = document.querySelector<
                    HTMLButtonElement | HTMLLinkElement
                >(`#${itemsInStore[0]}`);
                const lastItem = document.querySelector<
                    HTMLButtonElement | HTMLLinkElement
                >(`#${itemsInStore[itemsInStore.length - 1]}`);

                switch (key) {
                    case "ArrowDown":
                        if (
                            // document.activeElement === menuButtonRef.current
                            focused === itemsInStore[itemsInStore.length - 1]
                        ) {
                            firstItem?.focus();
                            store.set({ focused: itemsInStore[0] });
                        } else {
                            const indexOfFocused =
                                itemsInStore.indexOf(focused);
                            store.set({
                                focused: itemsInStore[indexOfFocused + 1],
                            });
                            document
                                .querySelector<
                                    HTMLButtonElement | HTMLLinkElement
                                >(`#${itemsInStore[indexOfFocused + 1]}`)
                                ?.focus();
                        }
                        break;

                    case "ArrowUp":
                        if (
                            // document.activeElement === menuButtonRef.current ||
                            focused === itemsInStore[0]
                        ) {
                            lastItem?.focus();
                            store.set({
                                focused: itemsInStore[itemsInStore.length - 1],
                            });
                        } else {
                            const indexOfFocused =
                                itemsInStore.indexOf(focused);
                            store.set({
                                focused: itemsInStore[indexOfFocused - 1],
                            });
                            document
                                .querySelector<
                                    HTMLButtonElement | HTMLLinkElement
                                >(`#${itemsInStore[indexOfFocused - 1]}`)
                                ?.focus();
                        }
                        break;

                    case "Home":
                        firstItem?.focus();
                        break;

                    case "End":
                        lastItem?.focus();
                        break;

                    default:
                        break;
                }
            }
        },
        [close, store]
    );

    return useMemo<useDropdownMenu>(
        () => ({
            store,
            open,
            handleKeyDown,
            registerTrigger,
            resetFocus,
            register,
            toggle,
            close,
            subscribe,
        }),
        [
            store,
            open,
            registerTrigger,
            handleKeyDown,
            register,
            toggle,
            close,
            resetFocus,
            subscribe,
        ]
    );
};

export { useDropdownMenu };
