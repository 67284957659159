import { useState, FC, useRef, useEffect, KeyboardEventHandler } from "react";
import { useStore } from "@Utilities/hooks/useStoreData";
import { concat } from "@Utilities/string";
import {
    currentTimeSelector,
    durationSelector,
    isPlayingSelector,
    thumbnailUrlsSelector,
    thumbnailsLoadedSelector,
} from "./selectors";
import { formatTime, useVideoPlayerContext } from "./utilities";
import { Icon } from "..";
import { LoadThumbnails } from "./loadThumbnails";

const ProgressBar: FC<{ spriteSheetHref?: string }> = ({ spriteSheetHref }) => {
    const { pause, play, ref, seek, setShowControls, store } =
        useVideoPlayerContext();
    const duration = useStore(store, durationSelector);
    const currentTime = useStore(store, currentTimeSelector);
    const isPlaying = useStore(store, isPlayingSelector);
    const thumbnailUrls = useStore(store, thumbnailUrlsSelector);
    const thumbnailsLoaded = useStore(store, thumbnailsLoadedSelector);
    const [isPlayingPrevDrag, setIsPlayingPrevDrag] = useState(isPlaying);
    const hoverTimeRef = useRef(0);
    const [loadThumbnails, setLoadThumbnails] = useState<boolean>(false);

    const [showThumbnail, setShowThumbnail] = useState(false);
    const [thumbnailX, setThumbnailX] = useState(0);
    const thumbnailRef = useRef<HTMLDivElement>(null);
    const [hoverTime, setHoverTime] = useState("0:00");
    const [activeThumbnail, setActiveThumbnail] = useState("");
    const [previousTimeBreak, setPreviousTimeBreak] = useState<number | null>(
        null
    );
    const zestProgressBar = useRef<HTMLInputElement>(null);

    const formatTimeLabel = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);

        const minutesString = minutes
            ? `${minutes} minute${minutes > 1 ? "s" : ""} `
            : "";

        return `${minutesString} ${seconds} seconds`;
    };

    const handleMouseDown = (): void => {
        setIsPlayingPrevDrag(isPlaying);
        pause();
    };

    const handleTouchDown = (
        event: React.TouchEvent<HTMLInputElement>
    ): void => {
        // Find where the user touched relative to the progress bar
        const progressRange = zestProgressBar.current;
        if (!progressRange) return;
        const { left, width } = progressRange.getBoundingClientRect();
        const percentHover =
            (event.touches[0].clientX - left) / Math.round(width);
        const time = Math.floor(duration * percentHover);
        hoverTimeRef.current = time;
        setIsPlayingPrevDrag(isPlaying);
        pause();
    };

    const handleMouseUp = (): void => {
        // if the video was playing previous to the drag event, then play it when dropped
        isPlayingPrevDrag && play();
    };

    const handleSeek = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setHoverTime(formatTime(Number(e.target.value)));
        seek(Number(e.target.value));
    };

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (
        event
    ): void => {
        setShowControls(true);
        switch (event.code) {
            case "ArrowRight": {
                event.preventDefault();
                seek(currentTime + 10);
                break;
            }
            case "ArrowLeft": {
                event.preventDefault();
                seek(currentTime - 10);
                break;
            }
            case "Space": {
                event.preventDefault();
                isPlaying ? pause() : play();
                break;
            }
            default:
                break;
        }
    };

    function handleLoadThumbnails(): void {
        setLoadThumbnails(true);
    }

    useEffect(() => {
        const progressRange = zestProgressBar.current;
        const thumbnail = thumbnailRef.current;
        if (!progressRange || !thumbnail) return;

        const handleMouseMove = (event: MouseEvent): void => {
            const { left, width } = progressRange.getBoundingClientRect();

            if (!ref.current) return;
            const videoElem = ref.current.getBoundingClientRect();

            const thumbnailWidth = thumbnail.getBoundingClientRect().width;
            const thumbnailCenter = thumbnailWidth / 2;

            const pxToVideoElem = left - videoElem.left;

            // cursor x-coordinate relative to the progress bar
            const progressBarX = event.clientX - left;

            // Position it to 4px off of the left side of the video when thumbnail is at the beginning of the range
            const imageX = Math.max(
                thumbnailCenter + 4,
                progressBarX + pxToVideoElem
            );

            if (progressBarX < 0) return;
            let percentHover = progressBarX / Math.round(width);

            if (ref.current) {
                if (percentHover > 1) percentHover = 1;
                const thumbnailBreaks = 1;
                const time = Math.floor(duration * percentHover);
                hoverTimeRef.current = time;
                const timeBreak =
                    Math.floor(time / thumbnailBreaks) * thumbnailBreaks;

                setHoverTime(formatTime(time));
                setShowThumbnail(true);
                setThumbnailX(imageX);

                if (timeBreak !== previousTimeBreak) {
                    const blob = thumbnailUrls[timeBreak];
                    const reader = new FileReader();
                    reader.addEventListener("load", () => {
                        if (typeof reader.result === "string") {
                            setActiveThumbnail(reader.result);
                        }
                    });
                    if (blob) {
                        reader.readAsDataURL(blob);
                    }
                }

                setPreviousTimeBreak(timeBreak);
            }
        };

        const handleMouseOut = (): void => {
            setShowThumbnail(false);
        };

        const min = Number(progressRange.min);
        const max = Number(progressRange.max);
        const val = Number(progressRange.value);
        progressRange.style.backgroundSize =
            ((val - min) * 100) / (max - min) + "% 100%";

        if (progressRange) {
            progressRange.addEventListener("mousemove", handleMouseMove);
            progressRange.addEventListener("mouseout", handleMouseOut);
        }

        return (): void => {
            if (progressRange) {
                progressRange.removeEventListener("mousemove", handleMouseMove);
                progressRange.removeEventListener("mouseout", handleMouseOut);
            }
        };
    }, [duration, previousTimeBreak, ref, thumbnailUrls, currentTime]);

    return (
        <>
            <input
                aria-label="video playback"
                aria-valuetext={formatTimeLabel(currentTime)}
                role="progressbar"
                ref={zestProgressBar}
                className="zest-status-bar progress"
                type="range"
                value={currentTime}
                onChange={handleSeek}
                min={0}
                max={Math.floor(duration)}
                onMouseDown={handleMouseDown}
                onTouchStart={handleTouchDown}
                onMouseEnter={handleLoadThumbnails}
                onMouseUp={handleMouseUp}
                onTouchEnd={handleMouseUp}
                onKeyDown={handleKeyDown}
                step={1}
            />
            {loadThumbnails ? <LoadThumbnails /> : null}
            <div
                className={concat(
                    showThumbnail ? "block" : "hidden",
                    "zest-video-thumbnail"
                )}
                ref={thumbnailRef}
                style={{
                    left: `${thumbnailX}px`,
                }}
            >
                <>
                    {thumbnailsLoaded ? (
                        <img src={activeThumbnail} alt="" />
                    ) : (
                        <Icon
                            name="ri-image-fill"
                            size="medium"
                            spriteSheetHref={spriteSheetHref}
                        />
                    )}
                    <div className="zest-video-thumbnail-time">{hoverTime}</div>
                </>
            </div>
        </>
    );
};

export default ProgressBar;
