import { useCallback, useEffect, useState } from "react";
import { contains } from "util/string";

export type RovingTabIndex = [number, (index: number) => void];

/**
 * Event listener hook for applying accessibility tabIndex to traverse a list using arrow keys.
 * Tab functionality will also be disabled while the menu is displayed.
 * @param {(React.MutableRefObject<HTMLButtonElement | null>)} ref - the Element which will perform the callback when clicked outside of it.
 * @param {() => void} callback - the function which will trigger.
 */
export default function useRovingTabIndex(
  ref: React.MutableRefObject<HTMLElement | null>,
  size: number
): RovingTabIndex {
  const [currentFocus, setCurrentFocus] = useState(-1);

  // Handle customized "keyup" functionality to change focus
  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (ref.current) {
        switch (e.key) {
          case "ArrowDown":
            e.preventDefault();
            setCurrentFocus(currentFocus >= size - 1 ? 0 : currentFocus + 1);
            break;
          case "ArrowUp":
            e.preventDefault();
            setCurrentFocus(currentFocus <= 0 ? size - 1 : currentFocus - 1);
            break;
        }
      }
    },
    [ref, size, currentFocus, setCurrentFocus]
  );

  // Disable defaults for "keydown" buttons
  const disableKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (ref.current && contains(["Tab", "ArrowDown", "ArrowUp"], e.key)) {
        e.preventDefault();
      }
    },
    [ref]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("keydown", disableKeyDown);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("keydown", disableKeyDown);
    };
  }, [handleKeyUp, disableKeyDown]);

  return [currentFocus, setCurrentFocus];
}
