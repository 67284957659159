import { concat } from "util/string";
export type CTABadgeWithTextProps = {
  className?: string;
  text?: string;
};

/**
 * @param className, Tailwind classes to apply to the SVG such as width/height. Use text-{color} to apply text color.
 * @param text, Text to display in the Call to Action circle
 * @param fillColor = "fill-white", Tailwind fill-color class to fill the circle
 * @returns SVG icon JSX.Element
 */
const CTABadgeWithText = ({
  text,
  className,
  ...props
}: CTABadgeWithTextProps): JSX.Element => {
  return (
    <div
      className={concat(
        "flex justify-center place-items-center",
        "bg-[#DD0031]", // tailwind fallback color
        "count-bubble", // theme token class
        "rounded-full",
        "text-xs",
        className
      )}
      {...props}
    >
      {text}
    </div>
  );
};

export default CTABadgeWithText;
