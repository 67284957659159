import { LayoutAPI } from "layouts/api/layoutAPI.types";
import { gatewayApi } from "util/requests/gatewayApi";

/** /layout Gateway URI
 * @type {string} */
export const userLayoutURI = "layout";

/**
 *  Fetch the current user's layout data from the Gateway API.
 */
export default async function getUserLayoutData(): Promise<LayoutAPI> {
  const data = await gatewayApi.get<LayoutAPI>({ urlSegment: userLayoutURI });

  if (!data) {
    throw new Error("Failed to retrieve User Layout Data.");
  }

  return data;
}
