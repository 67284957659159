import { AnchorHTMLAttributes, FC } from "react";
import { concat } from "@Utilities/string";
import { Sizes } from "@Types/element";
import { Icon, IconProps } from "@Components/icon";

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
    href: string;
    iconPosition?: "left" | "right";
    iconProps?: IconProps;
    size?: Sizes;
    theme?: string;
    type?: "button" | "inline" | "standalone";
};

const headlessStyles = concat(
    "cursor-pointer",
    "focus:outline",
    "focus:outline-2",
    "focus:outline-blue-600",
    "focus:outline-offset-2",
    "focus-visible:outline",
    "focus-visible:outline-2",
    "focus-visible:outline-blue-600",
    "focus-visible:outline-offset-2",
    "hover:underline"
);

/**
 * A link component that navigates users to another page.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-link)}
 */
const Link: FC<LinkProps> = ({
    children,
    className,
    iconPosition,
    iconProps = {},
    size,
    theme,
    type,
    ...rest
}) => {
    const isHeadless = theme === "headless";
    const classes = concat(
        theme,
        "zest-link",
        iconProps.name && "icon",
        size,
        type,
        className,
        !className && isHeadless && headlessStyles
    );

    return (
        <a className={classes} rel="noopener noreferrer" {...rest}>
            {!!iconProps.name && iconPosition !== "right" && (
                <Icon
                    {...iconProps}
                    className={concat(iconProps.className, "icon-left")}
                />
            )}
            {children}
            {!!iconProps.name && iconPosition === "right" && (
                <Icon
                    {...iconProps}
                    className={concat(iconProps.className, "icon-right")}
                />
            )}
        </a>
    );
};

Link.displayName = "Link";

export { Link };
