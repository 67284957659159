import { createGlobalStore } from "@Utilities/hooks/useStoreData";

export type ModalState = {
    modalIds: Set<string>;
    openModalId: string | null;
};

export const modalStore = createGlobalStore<ModalState>({
    modalIds: new Set(),
    openModalId: null,
});

export enum ModalEvents {
    OPEN = "open",
    CLOSE = "close",
}

export type ModalEventCallbacks = {
    [ModalEvents.OPEN]: () => void;
    [ModalEvents.CLOSE]: () => void;
};

type allModalSubs = {
    [Key in ModalEvents]: Set<ModalEventCallbacks[Key]>;
};

export const modalSubscriptions: { [modalId: string]: allModalSubs } = {};

export function register(id: string): () => void {
    // if no subscriptions are present, create the key for the modal id
    if (!modalSubscriptions[id]) {
        modalSubscriptions[id] = {
            open: new Set<ModalEventCallbacks[ModalEvents.OPEN]>(),
            close: new Set<ModalEventCallbacks[ModalEvents.CLOSE]>(),
        };
    }
    modalStore.set({ modalIds: modalStore.get().modalIds.add(id) });
    return function unregister() {
        // If no more events remain, delete the key from the subscriptions
        if (
            modalSubscriptions[id].open.size === 0 &&
            modalSubscriptions[id].close.size === 0
        ) {
            delete modalSubscriptions[id];
        }
        const { modalIds: newKeys, openModalId } = modalStore.get();
        newKeys.delete(id);
        if (openModalId === id) {
            modalStore.set({ modalIds: newKeys, openModalId: null });
        } else {
            modalStore.set({ modalIds: newKeys });
        }
    };
}

export function unsubscribe<E extends ModalEvents>(
    id: string,
    event: E,
    cb: ModalEventCallbacks[E]
): void {
    if (!modalSubscriptions[id] || modalSubscriptions[id][event].size == 0) {
        console.error(
            "%cZest Error:\n",
            "background-color: red; color: yellow; font-size: xsmall",
            `No subscriptions have been set for the "${event}" event`
        );
        return;
    }
    modalSubscriptions[id][event].delete(cb);
}

export function subscribe<E extends ModalEvents>(
    id: string,
    event: E,
    cb: ModalEventCallbacks[E]
): () => void {
    modalSubscriptions[id][event]?.add(cb);
    return (): void => unsubscribe(id, event, cb);
}

export const toggle = (id: string): void => {
    const currentId = modalStore.get().openModalId;
    if (currentId !== id) {
        open(id);
    } else {
        close(id);
    }
};

export const open = (id: string): void => {
    modalStore.set({ openModalId: id });
    // trigger callbacks here
    modalSubscriptions[id].open.forEach((cb) => {
        cb();
    });
};

export const close = (id: string): void => {
    modalStore.set({ openModalId: null });
    // trigger callbacks here
    modalSubscriptions[id].close.forEach((cb) => {
        cb();
    });
};
