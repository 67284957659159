import { useQuery } from "@tanstack/react-query";
import { gatewayApi } from "util/requests/gatewayApi";

export default function useCartItemCountApi(): {
  data: CartItemCountApiResponse | undefined;
  isLoading: boolean;
  isRefetching: boolean;
  isError: boolean;
  refetch: () => void;
} {
  const { data, isLoading, isError, refetch, isRefetching } = useQuery<
    CartItemCountApiResponse | undefined,
    Error,
    CartItemCountApiResponse | undefined,
    [string]
  >({
    queryKey: ["item-count"],
    queryFn: getCartItemCount,
    staleTime: 60000,
    refetchOnMount: "always",
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
    isRefetching,
  };
}

async function getCartItemCount(): Promise<
  CartItemCountApiResponse | undefined
> {
  const response = await gatewayApi.get<CartItemCountApiResponse>({
    urlSegment: "carts/item-count",
  });
  if (response === null || response === undefined) {
    throw new Error("Failed to retrieve cart item count.");
  }
  return response;
}

export type CartItemCountApiResponse = {
  totalItemCount: number;
};
