import {
    Dispatch,
    FormEvent,
    ReactNode,
    RefObject,
    useCallback,
    useMemo,
    useRef,
} from "react";
import { Status } from "@Utilities/hooks";
import {
    FormEventCallbacks,
    FormEvents,
    FormValue,
    FormValues,
    PromiseLike,
    FieldValue,
} from "../types";
import { FormAction, FormActions } from "../state/reducer";
import { FormMeta } from "./useFormValues";

export type Validation<
    V extends FieldValue = FieldValue,
    T extends FormValues = FormValues
> = (value: V, key: keyof T, state: T) => PromiseLike<void>;

export type WatchCallback<T extends FormValues = FormValues> = (
    name: keyof T,
    value: FieldValue,
    state: T
) => void;

/** Required check that is used for any required form inputs. Ran during validation */
const _requiredFn = (value: FieldValue): void => {
    if (typeof value === "string" || typeof value === "number") {
        if (!value.toString().length) {
            throw new Error(`This field is required.`);
        }
    }
    if (value === undefined || value === null || value === false) {
        throw new Error("This field is required.");
    }
};

export type UseFormMethods<T extends FormValues = FormValues> = {
    /** Clears form field and runs clear subscription and watchers for the cleared field
     * @example
     * form.clear('fieldName')
     * form.clear(['fieldName', 'otherFieldName'])
     */
    clear: (name: string | string[]) => void;
    /**
     * Returns the fields required for the form alerts.
     * @example
     * form.getAlerts() => { id: "exampleId", alertLabel: "exampleLabel"}
     */
    getAlerts: () => Partial<{
        [key in keyof T]: { alertLabel: ReactNode; id: string };
    }>;
    getErrors: () => Partial<{ [key in keyof T]: string[] }>;
    ref: RefObject<HTMLFormElement>;
    /** Registers a field on the form state
     * @example
     * form.register('firstName')
     * form.register('firstName', 'Jim')
     * form.register('myCheckboxField', true)
     */
    register: (
        name: string,
        id: string,
        value?: FieldValue,
        required?: boolean,
        validation?: Validation<FieldValue, T> | Validation<FieldValue, T>[]
    ) => () => void;
    /** Resets a field on the form and runs reset subscriptions
     * @example
     * form.reset()
     */
    reset: () => void;
    /** Updates only the meta data of a form field.
     * @example
     * form.meta('fieldName', { touched: false })
     */
    setFieldMeta: (
        name: keyof T,
        meta: Partial<
            Omit<FormValue, "value"> & {
                alert: { alertLabel: ReactNode; id?: string };
            }
        >
    ) => void;
    /** Sets a value to a registered form field.
     * @example
     * form.setValue('fieldName', 'value', { touched: false })
     */
    setValue: (
        name: keyof T,
        value: FieldValue,
        optionals?: {
            errors?: string[];
            touched?: boolean;
        }
    ) => void;
    /** Sets multile values to registered form fields.
     * @example
     * form.setValues({firstName: "Bob", lastName: {value: "Belcher", touched: false}})
     */
    setValues: (
        values: Partial<Record<keyof T, FieldValue | Partial<FormValue>>>
    ) => void;
    /** Submits the form, runs all validation, runs the "submit" subscriptions if validation passes
     * @example
     * form.submit()
     */
    submit: (e?: FormEvent<HTMLFormElement>) => Promise<void>;
    /** Subscribes a callback(s) to run on a specific form event
     * @example
     * form.subscribe(FormEvents.UPDATE, callbackFn)
     */
    subscribe: <E extends FormEvents>(
        event: E,
        cb: FormEventCallbacks<T>[E]
    ) => () => void;
    /** Unregisters a field from the form state, including removing validation functions.
     * @example
     * form.unregister('firstName')
     */
    unregister: (name: string) => void;
    unsubscribe: <E extends FormEvents>(
        event: E,
        cb: FormEventCallbacks<T>[E]
    ) => void;
    /** Validates a form field and returns true if successful
     * @example
     * form.validate('firstName')
     */
    validate: (name: keyof T | (keyof T)[]) => Promise<(keyof T)[]>;
    /** Watches a specific field for changes
     * @example
     * form.watch('firstName', (name, value) => {})
     */
    watch: (field: keyof T, cb: WatchCallback<T>) => void;
};

/** All the methods on a useForm instance */
const useFormMethod = <T extends FormValues = FormValues>(
    getState: () => T,
    dispatch: Dispatch<FormActions<T>>,
    getFormMeta: () => FormMeta,
    setFormMeta: Dispatch<Partial<FormMeta>>
): UseFormMethods<T> => {
    /** A partial object that can have all the form events as keys and an array of callbacks as the values */
    const eventCallbacks = useRef<
        Partial<{
            [index in FormEvents]: FormEventCallbacks<T>[index][];
        }>
    >({});

    /** Watch ref - Callbacks are called as fields are updated */
    const watchSubscriptions = useRef<
        Partial<Record<keyof T, WatchCallback<T>[]>>
    >({});

    const validationFns = useRef<
        Partial<{ [P in keyof T]: Validation<FieldValue, T>[] }>
    >({});
    const alertsRef = useRef<
        Partial<{
            [key in keyof T]: { alertLabel: ReactNode; id: string };
        }>
    >({});

    /** Form ref */
    const ref = useRef<HTMLFormElement>(null);

    /** Runs all the watch callbacks for a field */
    const _runWatch = useCallback<
        (name: keyof T, value: FieldValue, state: T) => void
    >((name, value, state) => {
        watchSubscriptions.current[name]?.forEach((cb) =>
            cb(name, value, state)
        );
    }, []);

    const watch = useCallback<UseFormMethods<T>["watch"]>((field, cb) => {
        if (!watchSubscriptions.current[field]) {
            watchSubscriptions.current[field] = [];
        }
        watchSubscriptions.current[field]?.push(cb);
    }, []);

    const unregister = useCallback<UseFormMethods<T>["unregister"]>(
        (name) => {
            delete validationFns.current[name];
            dispatch({
                type: FormAction.UNREGISTER,
                value: name,
            });
        },
        [validationFns, dispatch]
    );

    const register = useCallback<UseFormMethods<T>["register"]>(
        (name, id, defaultValue, required, validation) => {
            if (validation) {
                validationFns.current[name as keyof T] = Array.isArray(
                    validation
                )
                    ? validation
                    : ([validation] as Validation<FieldValue, T>[]);
            }
            dispatch({
                type: FormAction.REGISTER,
                value: {
                    [name]: {
                        value: defaultValue,
                        id,
                        required: required ?? false,
                        defaultValue,
                    },
                },
            });
            return (): void => unregister(name);
        },
        [dispatch, unregister]
    );

    const setValue = useCallback<UseFormMethods<T>["setValue"]>(
        (name, value, optionals) => {
            const dispatchValue: { [key: string]: Partial<FormValue> } = {
                [name]: {
                    value,
                    touched: optionals?.touched,
                    errors: optionals?.errors,
                },
            };
            dispatch({
                type: FormAction.UPDATE,
                value: dispatchValue,
                callback: (state) => {
                    _runWatch(name, value, state);
                    eventCallbacks.current.update?.forEach((cb) => {
                        cb(state, [name]);
                    });
                },
            });
        },
        [_runWatch, dispatch]
    );

    const setValues = useCallback<UseFormMethods<T>["setValues"]>(
        (values) => {
            const dispatchValue: Record<string, Partial<FormValue>> = {};
            Object.keys(values).forEach((key) => {
                const value = values[key];
                dispatchValue[key] =
                    typeof value === "object" ? value : { value };
            });
            dispatch({
                type: FormAction.UPDATE,
                value: dispatchValue,
                callback: (state) => {
                    Object.keys(dispatchValue).forEach((name: keyof T) => {
                        _runWatch(name, state[name].value, state);
                    });
                    eventCallbacks.current.update?.forEach((cb) => [
                        cb(state, Object.keys(values)),
                    ]);
                },
            });
        },
        [_runWatch, dispatch]
    );

    const setFieldMeta = useCallback<UseFormMethods<T>["setFieldMeta"]>(
        (name, { alert, ...meta }) => {
            if (alert) {
                const id = alert.id || alertsRef.current[name]?.id;
                if (!id) {
                    console.error(
                        "%cZest Error:\n",
                        "background-color: red; color: yellow; font-size: xsmall",
                        `An Alert label for form field "${name.toString()}" is attempting to be set without an id.`
                    );
                } else {
                    alertsRef.current[name] = {
                        ...alertsRef.current[name],
                        ...alert,
                    };
                }
            }
            dispatch({ type: FormAction.META, value: { [name]: meta } });
        },
        [dispatch]
    );

    const reset = useCallback<UseFormMethods<T>["reset"]>(() => {
        return dispatch({
            type: FormAction.RESET,
        });
    }, [dispatch]);

    const clear = useCallback<UseFormMethods<T>["clear"]>(
        (names) => {
            return dispatch({
                type: FormAction.CLEAR,
                value: names,
            });
        },
        [dispatch]
    );

    const validate = useCallback<UseFormMethods<T>["validate"]>(
        async (name) => {
            const validatingFields = Array.isArray(name) ? name : [name];
            const { status } = getFormMeta();
            if (status === Status.Validating) {
                throw new Error("Form is currently validating or submitting.");
            }

            setFormMeta({ status: Status.Validating });
            const state = getState();
            const dispatchValue: Partial<{
                [key in keyof T]: string[];
            }> = {};

            for (const field of validatingFields) {
                const promises: Promise<void>[] = [];
                const validations: Validation<FieldValue, T>[] =
                    validationFns.current[field] ?? [];
                dispatchValue[field] = [];

                if (
                    state[field].required &&
                    !validations.includes(_requiredFn)
                ) {
                    validations.push(_requiredFn);
                }

                if (!validations?.length) {
                    continue;
                }

                validations.forEach((fn) => {
                    try {
                        promises.push(
                            Promise.resolve(
                                fn(state[field].value, field, state)
                            )
                        );
                    } catch (error) {
                        if (error instanceof Error) {
                            dispatchValue?.[field]?.push(error.message);
                        }
                    }
                });
                const res = await Promise.allSettled(promises);
                res.forEach((result) => {
                    if (result.status === "rejected") {
                        dispatchValue?.[field]?.push(result.reason.message);
                    }
                });
            }
            const invalidFields: string[] = [];
            const value: { [fieldName: string]: Partial<FormValue> } = {};
            Object.keys(dispatchValue).forEach((key) => {
                value[key] = { errors: dispatchValue[key] };
            });
            // Loop through all errors to keep dispatch updates and return invalid fields
            for (const key in dispatchValue) {
                const errors = dispatchValue[key];
                if (errors?.length) {
                    invalidFields.push(key);
                    value[key] = { errors };
                }
            }
            dispatch({
                type: FormAction.UPDATE,
                value,
            });

            setFormMeta({
                status: invalidFields.length ? Status.Error : Status.Idle,
            });
            return invalidFields;
        },
        [getFormMeta, setFormMeta, getState, dispatch]
    );

    const submit = useCallback<UseFormMethods<T>["submit"]>(
        async (e) => {
            e?.preventDefault();
            const { status } = getFormMeta();
            if (status === Status.Submitting || status === Status.Validating) {
                throw new Error("Form is currently submitting.");
            }
            setFormMeta({ status: Status.Submitting });
            const state = getState();
            if (Object.keys(state).length === 0) {
                throw new Error("Form has no registered fields.");
            }
            const invalidFields = await validate(Object.keys(state));
            if (invalidFields.length) {
                eventCallbacks.current[FormEvents.FINISH_FAILED]?.forEach(
                    (cb) => cb(state, invalidFields)
                );
                const formMeta = getFormMeta();
                if (
                    formMeta.errorDisplay === "inline" &&
                    !formMeta.disableMoveFocusOnSubmit
                ) {
                    document
                        .querySelector<HTMLFormElement>(
                            `#${state[invalidFields[0]].id}`
                        )
                        ?.focus();
                }
            } else {
                eventCallbacks.current[FormEvents.FINISH]?.forEach((cb) =>
                    cb(getState())
                );
                setFormMeta({ status: Status.Success });
            }
        },
        [getFormMeta, getState, setFormMeta, validate]
    );

    const unsubscribe = useCallback<UseFormMethods<T>["unsubscribe"]>(
        (name, cb) => {
            if (!eventCallbacks.current[name]) {
                console.error(
                    "%cZest Error:\n",
                    "background-color: red; color: yellow; font-size: xsmall",
                    `No subscriptions have been set for the "${name}" event`
                );
                return;
            }
            eventCallbacks.current[name] = eventCallbacks.current[name]?.filter(
                (sub) => sub !== cb
            );
        },
        []
    );

    const subscribe = useCallback<UseFormMethods<T>["subscribe"]>(
        (name, cb) => {
            if (!eventCallbacks.current[name]) {
                eventCallbacks.current[name] = [];
            }
            eventCallbacks.current[name]?.push(cb);
            return (): void => unsubscribe(name, cb);
        },
        [unsubscribe]
    );

    const getErrors = useCallback<UseFormMethods<T>["getErrors"]>(() => {
        const state = getState();
        const errors: ReturnType<UseFormMethods<T>["getErrors"]> = {};
        Object.keys(state).forEach((key: keyof T) => {
            if (state[key].errors.length) errors[key] = state[key].errors;
        });
        return errors;
    }, [getState]);

    const getAlerts = useCallback<UseFormMethods<T>["getAlerts"]>(() => {
        return alertsRef.current;
    }, [alertsRef]);

    return useMemo(
        () => ({
            ref,
            watch,
            register,
            setValue,
            setValues,
            setFieldMeta,
            reset,
            clear,
            validate,
            submit,
            subscribe,
            unsubscribe,
            getErrors,
            getAlerts,
            unregister,
        }),
        [
            clear,
            register,
            reset,
            setValue,
            setValues,
            setFieldMeta,
            submit,
            validate,
            watch,
            subscribe,
            unsubscribe,
            getErrors,
            getAlerts,
            unregister,
        ]
    );
};

export default useFormMethod;
