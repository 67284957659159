import { ShoppingCartIcon } from "@heroicons/react/outline";
import { memo } from "react";
import ButtonLink from "components/buttons/ButtonLink";
import routes from "routes/routes";
import { useStore } from "util/store";
import { useCartNumberContext } from "contexts/cart/cartnumber.context";
import { concat } from "util/string";

function CartButton({ className }: { className?: string }): JSX.Element {
  const cartNumberContext = useCartNumberContext();
  const cartNumber = useStore(
    cartNumberContext.store,
    (store) => store.cartNumber
  );
  return (
    <ButtonLink
      className={concat("btn-cart", className)}
      id="header-cart-button"
      label={cartNumber + ""}
      aria-label="Cart Button"
      iconOrientation="left"
      icon={
        <ShoppingCartIcon name="cart" className="size-5x5" strokeWidth={2.5} />
      }
      variant="primary"
      href={routes.Cart.getPath()}
    />
  );
}

export default memo(CartButton);
