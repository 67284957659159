import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { noop } from "@Utilities/functions";
import { UseVideoPlayer } from "./utilities";

export type ThumbnailUrls = {
    [key: string]: Blob;
};

export const thumbnailBreaks = 3;

/** Creates a temporary video element used to download the still frames of the video */
function handleCreateVideo(url: string): HTMLVideoElement {
    const video = window.document.createElement("video");
    video.crossOrigin = "anonymous";
    video.muted = true;
    video.src = url;
    return video;
}

/** Creates a temporary canvas used to export the video frames as a blob */
function handleCreateCanvas(): HTMLCanvasElement {
    const canvas = window.document.createElement("canvas");
    canvas.width = 119;
    canvas.height = 67;
    return canvas;
}

/** Returns the current video frame as a thumbnail */
function handleGetThumbnail(canvas: HTMLCanvasElement): Promise<Blob | null> {
    return new Promise((resolve) => {
        // 25% image quality to save memory
        canvas.toBlob(resolve, "image/jpeg", 0.25);
    });
}

/** Loads and returns the thumbnails for a video based */
async function getThumbnails(
    maxThumbnails: number,
    url: string
): Promise<ThumbnailUrls> {
    const video = handleCreateVideo(url);
    const canvas = handleCreateCanvas();
    const context = canvas?.getContext("2d");
    const frames: ThumbnailUrls = {};
    const promises: Promise<void>[] = [];
    let resolver = noop;

    /** Used to wait for the "timeupdate" event to fire */
    function delay(): Promise<void> {
        return new Promise(function handleInternalResolver(resolve) {
            resolver = resolve;
        });
    }

    function handleThumbnailVideoTimeUpdateEvent(): void {
        if (!context) {
            throw new Error("2D context is not defined");
        }
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const current = video.currentTime;
        const promise = handleGetThumbnail(canvas).then((blobOrNull) => {
            if (blobOrNull) frames[current] = blobOrNull;
        });
        promises.push(promise);
        /** Lets the loop know this is done getting the current frame from the video */
        resolver();
    }

    /** Once the video has gotten the new frame we can begin getting it as an image */
    video.addEventListener("timeupdate", handleThumbnailVideoTimeUpdateEvent);

    let frameCount = 0;
    while (frameCount < maxThumbnails) {
        video.currentTime = Math.max(frameCount * thumbnailBreaks, 0.05);
        /** Wait for the "timeupdate" event to run on the video player */
        // eslint-disable-next-line no-await-in-loop
        await delay();
        frameCount += 1;
    }

    await Promise.all(promises);

    video.removeEventListener(
        "timeupdate",
        handleThumbnailVideoTimeUpdateEvent
    );

    return frames;
}

/** Loads the thumbnails for a video when the video is on screen
 * @example
 * const thumbnailUrls = useThumbnails(videoRef, "/my-video.mp4");
 */
export function useThumbnails(
    videoRef: RefObject<HTMLVideoElement>,
    url: string,
    videoPlayer: UseVideoPlayer
): ThumbnailUrls {
    const [thumbnailUrls, setThumbnailUrls] = useState<ThumbnailUrls>({});
    const observer = useRef<IntersectionObserver | null>(null);

    /**
     * Waits until the video is on the screen to begin downloading the thumbnails.
     * If the video is already on the screen when being setup it will run immediately.
     * Once complete, the observer is removed so a video is not loaded more than once.
     */
    const handleThumbnailsIntersectionObserverCallback = useCallback(
        function handleThumbnailsIntersectionObserver() {
            async function callback(
                entries: IntersectionObserverEntry[]
            ): Promise<void> {
                if (entries[0]?.isIntersecting) {
                    const duration = videoRef.current?.duration ?? 0;
                    const maxThumbnails = Math.floor(
                        duration / thumbnailBreaks
                    );
                    const frames = await getThumbnails(maxThumbnails, url);

                    if (videoRef.current) {
                        setThumbnailUrls(frames);
                        videoPlayer.store.set({
                            thumbnailUrls: frames,
                            thumbnailsLoaded: true,
                        });
                    }

                    observer.current?.disconnect();
                }
            }

            observer.current = new IntersectionObserver(callback, {
                rootMargin: "300px", // Loads the thumbnails when the video is 300px below the screen
            });
            if (videoRef.current) {
                observer.current.observe(videoRef.current);
            }
        },
        [url, videoPlayer.store, videoRef]
    );

    /** Will setup the intersection observer once the video's metadata has loaded */
    useEffect(
        function initSetIsVideoLoaded() {
            const video = videoRef.current;

            if (video && video.readyState > 0) {
                handleThumbnailsIntersectionObserverCallback();
                return function () {
                    observer.current?.disconnect();
                };
            }

            video?.addEventListener(
                "loadeddata",
                handleThumbnailsIntersectionObserverCallback
            );

            return function cleanupSetIsVideoLoaded() {
                observer.current?.disconnect();
                video?.removeEventListener(
                    "loadeddata",
                    handleThumbnailsIntersectionObserverCallback
                );
            };
        },
        [handleThumbnailsIntersectionObserverCallback, videoRef]
    );

    return thumbnailUrls;
}
