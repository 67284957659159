import {
    UseCreateStore,
    useCreateStore,
    useStore,
} from "@Utilities/hooks/useStoreData";
import { ThumbnailUrls } from "./videoPlayer";
import {
    useEffect,
    createContext,
    FC,
    ReactNode,
    RefObject,
    useCallback,
    useContext,
    useMemo,
    useRef,
} from "react";

export function getRefValue<Elem extends HTMLElement>(
    ref: RefObject<Elem>
): Elem {
    if (!ref.current) {
        throw new Error("The ref is not set to the video player.");
    }
    return ref.current;
}

export type VideoPlayerStore = {
    currentTime: number;
    duration: number;
    isFullscreen: boolean;
    isHoveringControls: boolean;
    isLoading: boolean;
    isMouseInside: boolean;
    isMuted: boolean;
    isPlaying: boolean;
    showControls: boolean;
    showCursor: boolean;
    showOverlay: boolean;
    src: string;
    thumbnailsLoaded: boolean;
    thumbnailUrls: ThumbnailUrls;
    volume: number;
};

export type UseVideoPlayer = {
    pause: () => void;
    play: () => void;
    ref: RefObject<HTMLVideoElement>;
    seek: (time: number) => void;
    setFullscreen: (value: boolean) => void;
    setMute: (value: boolean) => void;
    setShowControls: (value: boolean) => void;
    setShowCursor: (value: boolean) => void;
    setShowOverlay: (value: boolean) => void;
    setThumbnailUrls: (value: ThumbnailUrls) => void;
    setVolume: (value: number) => void;
    store: UseCreateStore<VideoPlayerStore>;
    togglePlayPause: () => void;
};

export function useVideoPlayer(): UseVideoPlayer {
    const store = useCreateStore<VideoPlayerStore>({
        currentTime: 0,
        duration: 0,
        isLoading: true,
        isHoveringControls: false,
        isPlaying: false,
        showOverlay: true,
        showControls: true,
        showCursor: true,
        isMuted: false,
        isMouseInside: false,
        isFullscreen: false,
        src: "",
        thumbnailsLoaded: false,
        volume: 100,
        thumbnailUrls: {},
    });

    const ref = useRef<HTMLVideoElement>(null);

    const play = useCallback<UseVideoPlayer["play"]>(() => {
        const videoElem = getRefValue(ref);
        videoElem.play();
        store.set({ isPlaying: true });
    }, [store]);

    const pause = useCallback<UseVideoPlayer["pause"]>(() => {
        const videoElem = getRefValue(ref);
        videoElem.pause();
        store.set({ isPlaying: false, showOverlay: true });
    }, [store]);

    const seek = useCallback<UseVideoPlayer["seek"]>(
        (time: number) => {
            const videoElem = getRefValue(ref);
            videoElem.currentTime = time;
            store.set({ currentTime: time });
        },
        [store]
    );

    const setMute = useCallback<UseVideoPlayer["setMute"]>(
        (value) => {
            const videoElem = getRefValue(ref);
            videoElem.muted = value;
            store.set({ isMuted: value });
            value && store.set({ volume: 0 });
        },
        [store]
    );

    const setFullscreen = useCallback<UseVideoPlayer["setFullscreen"]>(
        (value: boolean) => {
            store.set({ isFullscreen: value });
        },
        [store]
    );

    const setVolume = useCallback<UseVideoPlayer["setVolume"]>(
        (value) => {
            const videoElem = getRefValue(ref);
            // Input range values are between 0 and 100 but the video element volume is between 0 and 1
            videoElem.volume = value / 100;
            store.set({ volume: value });
        },
        [store]
    );

    const setShowControls = useCallback<UseVideoPlayer["setShowControls"]>(
        (value) => {
            // if the video is at the beginning then do not hide the controls
            if (store.get().currentTime === 0) return;
            store.set({ showControls: value });
        },
        [store]
    );

    const setShowOverlay = useCallback<UseVideoPlayer["setShowOverlay"]>(
        (value) => {
            // if the video is at the beginning then do not hide the overlay
            if (store.get().currentTime === 0) return;
            store.set({ showOverlay: value });
        },
        [store]
    );

    const setShowCursor = useCallback<UseVideoPlayer["setShowCursor"]>(
        (value) => {
            store.set({ showCursor: value });
        },
        [store]
    );

    const setThumbnailUrls = useCallback<UseVideoPlayer["setThumbnailUrls"]>(
        (value) => {
            store.set({ thumbnailUrls: value });
        },
        [store]
    );

    // Add event listeners to the video element to update the store based on events
    // This is done to keep the store in sync with the video element
    // NOTICE: temporary fix until we update video player to expose store functions | PBI: https://tfs.clarkinc.biz/DefaultCollection/Design/_workitems/edit/1072451
    const addVideoListeners = useCallback(() => {
        const videoElem = getRefValue(ref);

        // update isPlaying on pause
        videoElem.addEventListener("pause", () => {
            store.set({ isPlaying: false, showOverlay: true });
        });

        // update isPlaying on play
        videoElem.addEventListener("play", () => {
            store.set({ isPlaying: true });
        });

        // update volume on volume change
        videoElem.addEventListener("volumechange", () => {
            store.set({
                volume: videoElem.muted ? 0 : videoElem.volume * 100,
                isMuted: videoElem.muted,
            });
        });

        // update current time on time update
        videoElem.addEventListener("seeked", () => {
            store.set({ currentTime: Math.floor(videoElem.currentTime) });
        });

        return () => {
            videoElem.removeEventListener("pause", () => {
                store.set({ isPlaying: false, showOverlay: true });
            });

            videoElem.removeEventListener("play", () => {
                store.set({ isPlaying: true });
            });

            videoElem.removeEventListener("volumechange", () => {
                store.set({
                    volume: videoElem.muted ? 0 : videoElem.volume * 100,
                    isMuted: videoElem.muted,
                });
            });

            videoElem.addEventListener("seeked", () => {
                store.set({ currentTime: Math.floor(videoElem.currentTime) });
            });
        };
    }, [store]);

    const togglePlayPause = useCallback<
        UseVideoPlayer["togglePlayPause"]
    >(() => {
        const videoElem = getRefValue(ref);
        if (videoElem.paused) {
            play();
        } else {
            pause();
        }
    }, [pause, play]);

    // If the video element is passed an autoPlay prop set the store to reflect that
    useEffect(() => {
        const videoElem = getRefValue(ref);
        if (videoElem.autoplay) {
            store.set({ isPlaying: true });
        }
    }, [play, store]);

    // Once the video ref loads then we automatically set loading to false
    useEffect(() => {
        let ready = false;
        function handleFinishLoad(event: Event): void {
            if (event.currentTarget instanceof HTMLVideoElement) {
                store.set({
                    isLoading: false,
                    duration: Math.floor(event.currentTarget.duration),
                });
            }
        }
        const videoElem = getRefValue(ref);
        // Check if the video player is ready first. Only add event listener if it is not
        if (videoElem.readyState >= 4) {
            store.set({
                isLoading: false,
                duration: Math.floor(videoElem.duration),
            });
            ready = true;
        } else {
            videoElem.addEventListener("canplay", handleFinishLoad);
        }

        // set video listeners to update store based on events
        const removeVideoListeners = addVideoListeners();

        return function handleFinishLoadCleanup(): void {
            removeVideoListeners();
            if (ready) return;
            videoElem.removeEventListener("canplay", handleFinishLoad);
        };
    }, [store, addVideoListeners]);

    // Set the initial volume level and src once the video element loads
    useEffect(() => {
        const videoElem = getRefValue(ref);

        store.set({
            volume: videoElem.volume * 100,
        });
    }, [store]);

    return useMemo(
        () => ({
            store,
            play,
            pause,
            seek,
            ref,
            setMute,
            setFullscreen,
            setVolume,
            setShowControls,
            setShowCursor,
            setShowOverlay,
            togglePlayPause,
            setThumbnailUrls,
        }),
        [
            pause,
            play,
            seek,
            store,
            ref,
            setMute,
            setFullscreen,
            setVolume,
            setShowControls,
            setShowCursor,
            setShowOverlay,
            togglePlayPause,
            setThumbnailUrls,
        ]
    );
}

export const VideoPlayerContext = createContext<UseVideoPlayer | null>(null);

type VideoPlayerProviderProps = {
    children?: ReactNode;
    value: UseVideoPlayer;
};

// Provider allows all children to be able to control the Video Player and access any functions
export const VideoPlayerProvider: FC<VideoPlayerProviderProps> = ({
    children,
    value,
}) => {
    return (
        <VideoPlayerContext.Provider value={value}>
            {children}
        </VideoPlayerContext.Provider>
    );
};

// will inform a dev if they misuse the Video Player outside of the Video Player provider
export const useVideoPlayerContext = (): UseVideoPlayer => {
    // if there is a provider for this Video Player in existence and the value has been set
    const context = useContext(VideoPlayerContext);

    if (!context) {
        throw new Error(
            "useVideoPlayerContext can only be used within a <VideoPlayer> component."
        );
    }

    return context;
};

export const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

export const openFullScreen = (): void => {
    const videoWrapper =
        document.getElementsByClassName("zest-video-wrapper")[0];
    if (videoWrapper.requestFullscreen) {
        videoWrapper.requestFullscreen();
    } else if (videoWrapper.webkitRequestFullscreen) {
        videoWrapper.webkitRequestFullscreen();
    }
};

export const closeFullScreen = (): void => {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    }
};
declare global {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Document {
        fullScreenMode: boolean;
        mozCancelFullScreen?: () => Promise<void>;
        mozFullScreen?: boolean;
        mozFullScreenElement?: Element;
        msExitFullscreen?: () => Promise<void>;
        msFullscreenElement?: Element;
        webkitExitFullscreen?: () => Promise<void>;
        webkitFullscreenElement?: Element;
        webkitIsFullScreen: boolean;
    }

    interface Element {
        webkitRequestFullscreen?: () => Promise<void>;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface HTMLElement {
        mozRequestFullscreen?: () => Promise<void>;
        msRequestFullscreen?: () => Promise<void>;
        webkitRequestFullscreen?: () => Promise<void>;
    }

    interface HTMLVideoElement {
        webkitEnterFullScreen(): void;
    }
}

/**
 * determine if we are in fullscreen mode
 * @param {object} el
 */
function isFullScreenElement(el: any): boolean {
    if (el && el.current) {
        return Boolean(
            document.fullscreenElement === el.current ||
                document.mozFullScreenElement === el.current ||
                document.webkitFullscreenElement === el.current ||
                document.msFullscreenElement === el.current
        );
    }

    return Boolean(
        document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement ||
            document.fullscreen ||
            document.mozFullScreen ||
            document.webkitIsFullScreen ||
            document.fullScreenMode
    );
}

function useFullScreen(element: any): {
    close: () => void;
    open: () => void;
    toggle: () => void;
} {
    const { setFullscreen, store } = useVideoPlayerContext();
    const fullscreen = useStore(store, (state) => state.isFullscreen);

    // access various open fullscreen methods
    function openFullScreen(): void {
        const el = (element && element.current) || document.documentElement;

        if (el.requestFullscreen) return el.requestFullscreen();
        if (el.mozRequestFullScreen) return el.mozRequestFullScreen();
        if (el.webkitRequestFullscreen) return el.webkitRequestFullscreen();
        if (el.msRequestFullscreen) return el.msRequestFullscreen();
    }

    // access various exit fullscreen methods
    function closeFullScreen(): Promise<void> | void {
        if (document.exitFullscreen) return document.exitFullscreen();
        if (document.mozCancelFullScreen) return document.mozCancelFullScreen();
        if (document.webkitExitFullscreen)
            return document.webkitExitFullscreen();
        if (document.msExitFullscreen) return document.msExitFullscreen();
    }

    useEffect(() => {
        function handleChange(): void {
            setFullscreen(isFullScreenElement(element));
        }

        document.addEventListener(
            "webkitfullscreenchange",
            handleChange,
            false
        );
        document.addEventListener("mozfullscreenchange", handleChange, false);
        document.addEventListener("msfullscreenchange", handleChange, false);
        document.addEventListener("MSFullscreenChange", handleChange, false); // IE11
        document.addEventListener("fullscreenchange", handleChange, false);

        return () => {
            document.removeEventListener(
                "webkitfullscreenchange",
                handleChange
            );
            document.removeEventListener("mozfullscreenchange", handleChange);
            document.removeEventListener("msfullscreenchange", handleChange);
            document.removeEventListener("MSFullscreenChange", handleChange);
            document.removeEventListener("fullscreenchange", handleChange);
        };
    }, [element, setFullscreen]);

    return {
        open: openFullScreen,
        close: closeFullScreen,
        toggle: fullscreen ? closeFullScreen : openFullScreen,
    };
}

export default useFullScreen;
