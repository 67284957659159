import {
  QueryFunctionContext,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query";
import { PaymentStatus } from "components/Invoice/Invoices.types";
import { gatewayApi } from "util/requests/gatewayApi";

export default function useInvoiceCountApi(
  enabled: boolean,
  paymentStatus?: PaymentStatus
): {
  data: number | undefined;
  isLoading: boolean;
  isRefetching: boolean;
  isError: boolean;
  refetch: () => Promise<UseQueryResult>;
} {
  const { data, isLoading, isError, refetch, isRefetching } = useQuery<
    PaymentStatus | undefined,
    Error,
    number | undefined,
    [string, PaymentStatus | undefined]
  >({
    queryKey: ["invoice-count", paymentStatus],
    queryFn: getInvoiceCount,
    refetchInterval: 60_000,
    enabled,
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
    isRefetching,
  };
}

async function getInvoiceCount({
  queryKey,
}: QueryFunctionContext<[string, PaymentStatus | undefined]>): Promise<
  number | undefined
> {
  const [, paymentStatus] = queryKey;
  const params = new URLSearchParams();

  if (paymentStatus) {
    params.append("paymentStatus", paymentStatus.toString());
  }

  const response = await gatewayApi.get<number>({
    urlSegment: `invoices/count${
      params?.toString().length ? `?${params.toString()}` : ""
    }`,
  });

  return response;
}
