import { useCallback, useRef } from "react";
import {
    Cords,
    getRect,
    placements,
    Rect,
    UseSharedFloating,
    useSharedFloating,
} from "./useSharedFloating";

type TopBottomPlacementTypes = "top" | "bottom";

type UseFloatingNoCaretProps = {
    offset: number;
    placement: TopBottomPlacementTypes;
};
const useFloatingNoCaret = ({
    offset,
    placement,
}: UseFloatingNoCaretProps): UseSharedFloating => {
    const floating = useRef<HTMLElement | null>(null);
    const anchor = useRef<HTMLElement | null>(null);
    const wrapper = useRef<HTMLElement | null>(null);
    const placementsToCheck = useRef<TopBottomPlacementTypes[]>([
        "top",
        "bottom",
    ]);

    /** Returns the x & y position of the floating element relative to an anchor element */
    const positionResolver = useCallback(
        (placement: TopBottomPlacementTypes): Rect => {
            if (!anchor.current || !floating.current)
                return { height: 0, width: 0, x: 0, y: 0 };
            const anchorRect = getRect(anchor.current);
            const floatingRect = getRect(floating.current);
            const leftX = anchorRect.x;

            const resolver: {
                [P in TopBottomPlacementTypes]: () => Cords;
            } = {
                [placements.Top]: () => ({
                    x: leftX,
                    y: anchorRect.y - floatingRect.height - offset,
                }),
                [placements.Bottom]: () => ({
                    x: leftX,
                    y: anchorRect.y + anchorRect.height + offset,
                }),
            };

            return {
                ...floatingRect,
                ...resolver[placement](),
            };
        },
        [offset]
    );

    const sharedHook = useSharedFloating<TopBottomPlacementTypes>({
        positionResolver,
        offset,
        placement,
        placementsToCheck,
        wrapper,
        floating,
        anchor,
    });

    return { ...sharedHook };
};

export { useFloatingNoCaret };
