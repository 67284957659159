import { Button as ZestButton } from "@clarkinc/zest-design-system";
import { OnClickButtonProps } from "components/buttons/Button.types";
import { generateButtonStyle } from "components/buttons/buttons.styles";
import { concat } from "util/string";
import { useUuid } from "util/hooks/useUuid";

/**
 * Marketplace Button which either wraps ZestButton or makes a custom ref button for hooking purposes.
 * @export
 * @param {OnClickButtonProps}
 * @return {*}  {JSX.Element}
 */
export default function Button({
  className,
  hideLabel,
  icon,
  iconOrientation = "right",
  id,
  onClick,
  size = "default",
  label,
  labelClassName,
  type = "button",
  variant,
  innerRef,
  ...rest
}: OnClickButtonProps): JSX.Element {
  const shouldFlex = icon && !hideLabel; // only flex content if both icon+label present
  const style = generateButtonStyle(variant, size, shouldFlex);
  const uuid = useUuid("button");
  const buttonID = id || uuid;

  const passthroughProps = {
    id: buttonID,
    className: concat(style, className),
    "aria-label": hideLabel ? label : undefined,
    onClick,
  };

  const buttonContent = (
    <>
      {iconOrientation === "left" && icon}
      {!hideLabel && <span className={labelClassName}>{label}</span>}
      {iconOrientation === "right" && icon}
    </>
  );

  if (!innerRef) {
    return (
      <ZestButton
        type={type}
        {...passthroughProps}
        iconPosition={iconOrientation}
        {...rest}
      >
        {buttonContent}
      </ZestButton>
    );
  } else {
    return (
      <button type={type} {...passthroughProps} {...rest} ref={innerRef}>
        {buttonContent}
      </button>
    );
  }
}
