import { memo } from "react";
import HeaderAdminMenuButtonGroup from "components/Header/AdminBar/components/AdminMenuButtonGroup";
import HeaderAdminReviewButtonGroup from "components/Header/AdminBar/components/AdminReviewButtonGroup";
import AdminUserInfoDisplay from "components/Header/AdminBar/components/AdminUserInfoDisplay";
import { concat } from "util/string";
import useLayoutAPI from "layouts/api/useLayoutAPI";
import { LayoutAPI } from "layouts/api/layoutAPI.types";
import CreateProjectBuilderModal from "components/ProjectBuilders/Modals/CreateProjectBuilderModal";
import CreateOrderGuideModal from "components/OrderGuides/Modals/CreateOrderGuideModal";

export const headerAdminBarId = "header-admin-bar";

export const HeaderAdminThemeFallback = "bg-[#F0FAFB] text-[#261F1B]";
export const HeaderAdminTheme = "admin-bar";

function HeaderAdminBar(): JSX.Element {
  const { data: userLayoutData } = useLayoutAPI();

  return (
    <HeaderAdminBarContent
      isUserAbleToViewPendingOrders={
        userLayoutData?.isUserAbleToViewPendingOrders
      }
      ordersWaitingForApprovalCount={
        userLayoutData?.ordersWaitingForApprovalCount
      }
      isPastDueInvoicesNoticeEnabled={
        userLayoutData?.isPastDueInvoicesNoticeEnabled
      }
      firstName={userLayoutData?.baseAccount.firstName ?? ""}
      lastName={userLayoutData?.baseAccount.lastName ?? ""}
      remainingBudget={userLayoutData?.remainingBudget}
    />
  );
}

export type HeaderAdminBarContentProps = {
  isUserAbleToViewPendingOrders?: LayoutAPI["isUserAbleToViewPendingOrders"];
  ordersWaitingForApprovalCount?: LayoutAPI["ordersWaitingForApprovalCount"];
  isPastDueInvoicesNoticeEnabled?: LayoutAPI["isPastDueInvoicesNoticeEnabled"];
  firstName: LayoutAPI["baseAccount"]["firstName"];
  lastName: LayoutAPI["baseAccount"]["lastName"];
  remainingBudget?: LayoutAPI["remainingBudget"];
};

export function HeaderAdminBarContent({
  isUserAbleToViewPendingOrders,
  ordersWaitingForApprovalCount,
  isPastDueInvoicesNoticeEnabled,
  firstName,
  lastName,
  remainingBudget,
}: Readonly<HeaderAdminBarContentProps>): JSX.Element {
  return (
    <>
      <CreateProjectBuilderModal />
      <CreateOrderGuideModal />

      <div
        id={headerAdminBarId}
        data-testid={headerAdminBarId}
        className={concat(
          HeaderAdminThemeFallback,
          HeaderAdminTheme,
          "header-top-spacing",
          "flex flex-col lg:flex-row",
          "justify-start px-4 pb-6 space-y-2 w-full xs:px-6 sm:px-5 sm:space-y-2 lg:space-y-0"
        )}
      >
        <div
          className={concat(
            "flex flex-col sm:flex-row",
            "justify-center space-y-2 pt-0 w-full sm:space-y-0 sm:space-x-0 sm:justify-between"
          )}
        >
          <AdminUserInfoDisplay
            firstName={firstName}
            lastName={lastName}
            remainingBudget={remainingBudget}
          />
          <HeaderAdminReviewButtonGroup
            className={concat(
              "flex flex-col sm:flex-row",
              "justify-end space-y-2 w-full sm:space-x-2 sm:pr-0 sm:space-y-0 lg:justify-start lg:pl-4 xl:pl-24"
            )}
            isUserAbleToViewPendingOrders={isUserAbleToViewPendingOrders}
            ordersWaitingForApprovalCount={ordersWaitingForApprovalCount}
            isPastDueInvoicesNoticeEnabled={isPastDueInvoicesNoticeEnabled}
          />
        </div>
        <HeaderAdminMenuButtonGroup
          className={concat(
            "flex flex-col sm:flex-row",
            "justify-end space-y-2 w-full sm:justify-evenly sm:space-x-2 sm:space-y-0 lg:justify-end"
          )}
          buttonClassNames="w-full justify-between"
        />
      </div>
    </>
  );
}
export default memo(HeaderAdminBar);
