import { Reducer } from "react";
import { FormValues } from "../../types";
import { ClearAction } from "./types";

const clearAction: Reducer<FormValues, ClearAction> = (state, action) => {
    const clonedState = { ...state };
    const keys = Array.isArray(action.value) ? action.value : [action.value];
    keys.forEach((key) => {
        // Check that a value exists in state
        // All values need to be registered before being cleared
        if (clonedState[key] === undefined) {
            throw new Error(
                `Cannot clear form key "${key}". "${key}" is not registered in the form.`
            );
        }
        clonedState[key].value = "";
        clonedState[key].errors = [];
        clonedState[key].touched = false;
    });

    return {
        ...state,
        ...clonedState,
    };
};

export default clearAction;
