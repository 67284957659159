import { Dispatch, useMemo } from "react";
import { FormValueMap, FormValues, GenericFieldValue } from "../types";
import useFormMethod, { UseFormMethods } from "./useFormMethods";
import { FormMeta, default as useFormValues } from "./useFormValues";

export type UseForm<T extends FormValues = FormValues> = UseFormMethods<T> & {
    getFormMeta: () => FormMeta;
    getFormState: () => T;
    setFormMeta: Dispatch<Partial<FormMeta>>;
};

/** A custom hook to connect the form values and the form methods to the form. */
const useForm = <T extends GenericFieldValue>(): UseForm<FormValueMap<T>> => {
    const [getFormState, dispatch, getFormMeta, setFormMeta] =
        useFormValues<FormValueMap<T>>();
    const methods = useFormMethod<FormValueMap<T>>(
        getFormState,
        dispatch,
        getFormMeta,
        setFormMeta
    );

    return useMemo(
        () => ({
            getFormState,
            getFormMeta,
            setFormMeta,
            ...methods,
        }),
        [getFormMeta, getFormState, methods, setFormMeta]
    );
};

export { useForm };
