import Alert from "components/Alerts";
import { ErrorMessages } from "util/requests/response.types";

export default function FormErrors({
  className,
  errors,
  ...rest
}: {
  className?: string;
  errors: ErrorMessages | string[] | undefined;
} & React.HTMLAttributes<HTMLElement>): React.ReactElement | null {
  if (!errors) {
    return null;
  }

  const alerts: React.ReactElement[] = [];

  if (Array.isArray(errors)) {
    for (const message of errors.filter((x) => x)) {
      alerts.push(
        <Alert type="Danger" key={message}>
          {message}
        </Alert>
      );
    }
  } else {
    for (const key in errors) {
      for (const message of errors[key].filter((x) => x)) {
        alerts.push(
          <Alert type="Danger" key={message}>
            {message}
          </Alert>
        );
      }
    }
  }

  if (alerts.length === 0) {
    return null;
  }

  return (
    <div className={className} {...rest}>
      {alerts}
    </div>
  );
}
