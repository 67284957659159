import {
    AriaAttributes,
    ChangeEvent,
    Children,
    cloneElement,
    FC,
    FieldsetHTMLAttributes,
    isValidElement,
    ReactNode,
} from "react";
import { noop } from "@Utilities/functions";
import { concat, props } from "@Utilities/string";
import { isElement } from "@Utilities/react";
import { RadioContext } from "./context";
import { RadioLegend, RadioLegendProps } from "./legend";
import { InputHelperProps, InputWrapper, InputWrapperProps } from "..";

type FieldSetProps = Omit<
    FieldsetHTMLAttributes<HTMLFieldSetElement>,
    "onChange"
>;

export type GroupProps = FieldSetProps & {
    /** Should the error be shown. */
    error?: boolean;
    /** The message that will be shown if the `error` prop is true. */
    errorMessage?: string;
    /** The props for `<InputHelper />` */
    helperProps?: InputHelperProps;
    /** Additional information for communicating the purpose of a field */
    helperText?: ReactNode;
    /** Does not render any error messages inline with the legend. */
    hideError?: boolean;
    /** Does not render a legend component but instead adds a label via the `aria-label` prop */
    hideLabel?: boolean;
    /** A label for communicating the purpose of the radio buttons. */
    label: ReactNode;
    /** The props passed to the `<Radio.Legend>` component. */
    labelProps?: RadioLegendProps;
    /** Callback for handling when a input is changed */
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    /** Will display the "(Optional)" text in the Radio's legend. */
    optional?: boolean;
    /** Will display the "(Required)" text in the Radio's legend. */
    required?: boolean;
    /** The theme of the Radio's fieldset. */
    theme?: string;
    /** The selected radio input within the `<Radio.Group>` component. */
    value?: string;
    /** The props for `<InputWrapper />` */
    wrapperProps?: InputWrapperProps;
};

/**
 * A component used to group radio elements.
 *
 * @see {@link [Storybook](https://zest.prod.clarkinc.biz/?path=/story/components-inputs-radio--radio)}
 */
const Group: FC<GroupProps> = ({
    children,
    error = false,
    errorMessage,
    helperProps = {},
    helperText,
    hideError,
    hideLabel,
    id,
    label,
    labelProps = {},
    name = "RADIO",
    onChange = noop,
    optional = false,
    required = false,
    role,
    theme,
    value = "",
    wrapperProps = {},
    ...rest
}) => {
    const labelFallback =
        "<Radio.Group> label is invalid for use as an aria-label. Instead use a string.";
    const errors = Array.isArray(errorMessage)
        ? errorMessage.join(" ")
        : errorMessage;

    const ariaProps: AriaAttributes = props({
        "aria-label": {
            value:
                typeof label === "string"
                    ? concat(label, hideError && errors)
                    : labelFallback,
            condition: (hideLabel && !!label) || hideError,
        },
        "aria-required": {
            value: required,
            condition: required,
        },
        "aria-invalid": { condition: error, value: "true" },
    });

    const classes = concat(theme, "zest-radio-group", wrapperProps.className);

    return (
        <RadioContext.Provider
            value={{
                name,
                optional,
                required,
                value,
                onChange,
            }}
        >
            <InputWrapper
                {...rest}
                {...ariaProps}
                element="fieldset"
                className={classes}
                required={required}
                role={role ?? "radiogroup"}
            >
                <RadioLegend
                    {...labelProps}
                    error={error}
                    errorMessage={errorMessage}
                    errorProps={labelProps.errorProps}
                    helperText={helperText}
                    helperProps={helperProps}
                    hideError={hideError}
                    hideLabel={hideLabel}
                >
                    {label}
                </RadioLegend>
                {/* Map over the children passed to the listed props to only the children that need them */}
                {Children.map(children, (child, i) => {
                    return isValidElement(child) &&
                        isElement(["Radio"], ["input"], child)
                        ? cloneElement(child, {
                              id: `${id}-${i}`,
                              error,
                              ...child.props,
                          })
                        : child;
                })}
            </InputWrapper>
        </RadioContext.Provider>
    );
};

Group.displayName = "Group";

export { Group };
