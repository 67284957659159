import { FC, ReactNode } from "react";
import { ModalContent, ModalContentProps } from "./modal.content";
import { ModalTrigger } from "./modal.trigger";
import { ModalHeader, ModalHeaderProps } from "./modal.header";
import { ModalContextProps, ModalProvider } from "./modal.context";
import { ModalContainer, ModalContainerProps } from "./modal.container";
import { ModalFooter, ModalFooterProps } from "./modal.footer";
import {
    register,
    subscribe,
    unsubscribe,
    toggle,
    close,
    open,
} from "./modal.store";

export type ModalProps = ModalContextProps &
    (
        | {
              hideFooter: boolean;
              primaryButtonText?: never;
          }
        | {
              hideFooter?: boolean;
              primaryButtonText: string;
          }
    ) &
    (
        | { headerTitle?: never; hideHeader: boolean }
        | { headerTitle: string; hideHeader?: boolean }
    ) &
    ModalContainerProps & {
        "aria-label": string;
        contentProps?: ModalContentProps;
        footerProps?: Partial<ModalFooterProps>;
        headerChildren?: ReactNode;
        headerProps?: ModalHeaderProps;
        id?: string;
        theme?: string;
    };

type ComposedModalProps = FC<ModalProps> & {
    close: typeof close;
    Container: typeof ModalContainer;
    Content: typeof ModalContent;
    Footer: typeof ModalFooter;
    Header: typeof ModalHeader;
    open: typeof open;
    Provider: typeof ModalProvider;
    register: typeof register;
    subscribe: typeof subscribe;
    toggle: typeof toggle;
    Trigger: typeof ModalTrigger;
    unsubscribe: typeof unsubscribe;
};

/**
 * Modals focus the attention of the user exclusively on one task or piece of information via a window that sits on top of the page content.
 *
 * @see {@link [Storybook](https://zest.clarkinc.biz/?path=/story/components-modal--modal)}
 */
const Modal: ComposedModalProps = ({
    "aria-label": ariaLabel,
    children,
    className,
    contentProps,
    footerProps,
    headerChildren,
    headerProps,
    headerTitle,
    hideFooter,
    hideHeader,
    id,
    primaryButtonText,
    theme = "wss",
    ...rest
}) => {
    return (
        <Modal.Provider id={id}>
            <Modal.Container
                aria-label={ariaLabel}
                theme={theme}
                className={className}
                {...rest}
            >
                {!hideHeader ? (
                    <Modal.Header {...headerProps} headerTitle={headerTitle}>
                        {headerChildren}
                    </Modal.Header>
                ) : null}
                <Modal.Content {...contentProps}>{children}</Modal.Content>
                {!hideFooter ? (
                    <Modal.Footer
                        {...footerProps}
                        primaryButtonText={primaryButtonText}
                    />
                ) : null}
            </Modal.Container>
        </Modal.Provider>
    );
};

Modal.Content = ModalContent;
Modal.Trigger = ModalTrigger;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
Modal.Container = ModalContainer;
Modal.Provider = ModalProvider;
Modal.Content = ModalContent;
Modal.displayName = "Modal";

// Methods
Modal.register = register;
Modal.subscribe = subscribe;
Modal.unsubscribe = unsubscribe;
Modal.toggle = toggle;
Modal.open = open;
Modal.close = close;

export { Modal };
