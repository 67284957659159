import { useCallback, useMemo, useRef } from "react";
import { getChatUrl } from "components/Header/components/utils/chatUtils";
import { env } from "env";
import { ApiLayoutSiteDataDTO } from "layouts/api/layoutAPI.types";

export type ChatWindowParameters = {
  readonly encryptedUserIndex: string | undefined;
  readonly siteData: ApiLayoutSiteDataDTO;
  readonly useSwitchboardRewrite: boolean;
};

export function useChatWindow({
  encryptedUserIndex,
  siteData,
  useSwitchboardRewrite,
}: ChatWindowParameters): () => void {
  const environment = env.REACT_APP_CD_ENV;
  const chatUrl = useMemo(
    () =>
      getChatUrl(
        encryptedUserIndex,
        environment,
        siteData,
        useSwitchboardRewrite
      ),
    [encryptedUserIndex, environment, siteData, useSwitchboardRewrite]
  );
  const target = "LiveChatWindow";
  const windowRef = useRef<Window | null>(null);
  const onChatOpen = useCallback((): void => {
    if (windowRef.current && !windowRef.current.closed) {
      windowRef.current.focus();
    } else {
      windowRef.current = window.open(
        chatUrl,
        target,
        "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=650,height=700"
      );
    }
  }, [chatUrl]);

  return onChatOpen;
}
