import { useCallback } from "react";
import { useNavigate } from "react-router";
import { UserClaims } from "authentication/contexts/UserData.types";
import { useUserContext } from "authentication/contexts/user.context";
import { LayoutAPI } from "layouts/api/layoutAPI.types";
import useLayoutAPI from "layouts/api/useLayoutAPI";
import { useStore } from "util/store";
import {
  getActiveCatalog,
  hasMultipleCatalogs,
  isNonPrimaryCatalog,
} from "util/user/userClaims";
import { gatewayApi } from "util/requests/gatewayApi";

export type UseCatalogSwitchData =
  | {
      label: string;
      targetCatalog: number;
    } & (
      | { enableFullCatalogDisclaimerModal: false }
      | { enableFullCatalogDisclaimerModal: true }
    );

export type SwitchCatalogApiResponse = {
  authToken: string;
  refreshToken?: number;
  expiresIn: number;
};

/**
 * Hook into state required for Catalog Switching. References userClaims and LayoutAPI.
 */
export function useCatalogSwitch(): {
  catalogData: UseCatalogSwitchData | null;
  switchCatalog: () => Promise<void>;
} {
  const userContext = useUserContext();
  const userClaims = useStore(userContext.store, (user) => user.userClaims);
  const { data: userLayoutData } = useLayoutAPI();
  const navigate = useNavigate();

  const catalogData = getCatalogSwitchData(userLayoutData, userClaims);

  const switchCatalog = useCallback(async () => {
    if (catalogData) {
      const newAuthToken = await switchCatalogAPI(catalogData.targetCatalog);
      if (newAuthToken.length > 0) {
        userContext.login(newAuthToken);
        navigate("/");
        navigate(0);
      }
    }
  }, [catalogData, navigate, userContext]);

  return { catalogData, switchCatalog };
}

export function getCatalogSwitchData(
  userLayoutData: LayoutAPI | undefined,
  userClaims: UserClaims | null
): UseCatalogSwitchData | null {
  if (
    !userLayoutData ||
    !userClaims ||
    !showSwitchCatalogButton(userLayoutData) ||
    !userLayoutData.catalogSwitchLinkText
  ) {
    return null;
  }

  const activeCatalog = getActiveCatalog(userClaims);

  const targetCatalog = userLayoutData.contentConfigurationToCatalogs.find(
    (cctc) => cctc.contentConfigurationToCatalogId !== activeCatalog
  )?.contentConfigurationToCatalogId;

  if (targetCatalog) {
    if (
      isNonPrimaryCatalog(
        userClaims,
        userLayoutData.contentConfigurationToCatalogs
      )
    ) {
      // Switch to their Primary Catalog from the LayoutAPI:
      return {
        label: "View Primary Catalog",
        targetCatalog: targetCatalog,
        enableFullCatalogDisclaimerModal: false,
      };
    }
    // else switch to Full WSS catalog and show disclaimer Modal
    return {
      label: "View Full Catalog",
      targetCatalog: targetCatalog,
      enableFullCatalogDisclaimerModal: true,
    };
  }
  return null;
}

export function showSwitchCatalogButton(userLayoutData: LayoutAPI): boolean {
  return (
    !!userLayoutData.catalogSwitchLinkText &&
    hasMultipleCatalogs(
      userLayoutData.catalogSwitchLinkText,
      userLayoutData.contentConfigurationToCatalogs
    )
  );
}

export async function switchCatalogAPI(targetCatalog: number): Promise<string> {
  const response = await gatewayApi.get<SwitchCatalogApiResponse>({
    urlSegment: `accounts/switch-catalog/${targetCatalog}`,
  });
  if (response === undefined) {
    return "";
  } else {
    return response.authToken;
  }
}
