import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/**
 * @description Trigger a callback function when the URL Location changes
 * @export
 * @param {() => void} callback
 */
export default function useOnLocationChange(callback: () => void): void {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location);
  useEffect(() => {
    if (location !== currentLocation) {
      setCurrentLocation(location);
      callback();
    }
  }, [location, currentLocation, callback]);
}
