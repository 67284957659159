import FormErrors from "components/FormErrors";
import { ErrorMessages } from "util/requests/response.types";
import { concat } from "util/string";

export default function ModalFormErrors({
  className,
  errors,
}: {
  className?: string;
  errors: ErrorMessages | undefined;
}): JSX.Element | null {
  return (
    <FormErrors
      className={concat("pt-6 px-6 flex flex-col gap-2", className)}
      data-testid="modal-form-errors"
      errors={errors}
    />
  );
}
