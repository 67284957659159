import { getAuthToken } from "util/requests/gatewayApi";

/**
 * Overrides the global `fetch` function to add the authorization header to
 * requests related to the Payments Client "Add Credit Card" process.
 */
export default function fetch(): void {
  const originalFetch = globalThis.fetch;

  function customFetch(
    input: RequestInfo | URL,
    init?: RequestInit
  ): Promise<Response> {
    ({ input, init } = interceptFetchRequest(input, init));

    return originalFetch(input, init);
  }

  globalThis.fetch = customFetch;
}

export function interceptFetchRequest(
  input: RequestInfo | URL,
  init: RequestInit | undefined
): { input: RequestInfo | URL; init: RequestInit | undefined } {
  const pathname = getPathname(input);

  if (pathname) {
    if (isAddCreditCardRelatedUrl(pathname)) {
      ({ input, init } = addAuthorizationHeader(input, init));
    }
  }

  return {
    input,
    init,
  };
}

function addAuthorizationHeader(
  input: RequestInfo | URL,
  init?: RequestInit | undefined
): { input: RequestInfo | URL; init: RequestInit | undefined } {
  const headerKey = "Authorization";
  const headerValue = `Bearer ${getAuthToken()}`;

  if (input instanceof URL || typeof input === "string") {
    const headers = init?.headers ? new Headers(init.headers) : new Headers();
    headers.append(headerKey, headerValue);

    if (init) {
      init.headers = headers;
    } else {
      init = { headers };
    }
  } else {
    input.headers.append(headerKey, headerValue);
  }

  return {
    input,
    init,
  };
}

export function getPathname(input: RequestInfo | URL): string {
  if (input instanceof URL) {
    return input.pathname;
  }

  const rawUrl = typeof input === "string" ? input : input.url;

  try {
    const url = new URL(rawUrl);
    return url.pathname;
  } finally {
    // Ignore
  }
}

export function isAddCreditCardRelatedUrl(pathname: string): boolean {
  return (
    pathname === "/web-bff/credit-cards/token/renderform" ||
    pathname === "/web-bff/credit-cards/token/tokenizecard" ||
    pathname === "/web-bff/credit-cards/token/validate"
  );
}
