import { JSX, PropsWithChildren, ReactNode, useCallback } from "react";
import { Button, ButtonProps } from "..";
import { toggle } from "./modal.store";
import { useMaybeModalContext } from "./useModalContext";

export type ModalTriggerProps = ButtonProps & {
    className?: string;
    CustomTrigger?: ReactNode;
    htmlFor?: string;
    theme?: string;
};

const ModalTrigger = ({
    children,
    CustomTrigger,
    htmlFor: controlledHtmlFor,
    onClick,
    theme,
    ...rest
}: PropsWithChildren<ModalTriggerProps>): JSX.Element => {
    // Grabs modal from Prop or Context
    // Context will only be useful for a custom composed Modal
    const modalContext = useMaybeModalContext();
    const htmlFor = controlledHtmlFor ?? modalContext?.id;

    if (!htmlFor) {
        throw new Error(
            "<Modal.Trigger> needs an `htmlFor` attribute to toggle a modal."
        );
    }

    const handleClick = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any) => {
            // Added 1/15/2024 to prevent possible issues when opening a modal in SF
            e.stopPropagation();
            toggle(htmlFor);
            onClick?.(e);
        },
        [onClick, htmlFor]
    );

    return (
        <>
            {CustomTrigger ? (
                <Button {...rest} theme={theme} onClick={handleClick}>
                    {CustomTrigger}
                </Button>
            ) : (
                <Button
                    theme={theme}
                    variant="green"
                    {...rest}
                    onClick={handleClick}
                >
                    {children}
                </Button>
            )}
        </>
    );
};

export { ModalTrigger };
