import { logError } from "util/logger";

export const api = {
  get,
  post,
  put,
  delete: _delete,
};

export const APIVerbConstants = {
  httpGet: "GET",
  httpPost: "POST",
  httpPut: "PUT",
  httpDelete: "DELETE",
};

async function ApiRequest<T>({
  url,
  method,
  authToken,
  keepAlive,
  additionalHeaders,
  bodyParams,
  ignoreErrorCodes,
  throwErrorCodes,
}: {
  url: string;
  method: string;
  keepAlive?: boolean;
  authToken?: string;
  additionalHeaders?: object;
  bodyParams?: T;
  ignoreErrorCodes?: number[];
  throwErrorCodes?: number[];
}): Promise<Response | undefined> {
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    ...additionalHeaders,
  });

  authToken && headers.append("Authorization", "Bearer " + authToken);
  let respStatus = 0;
  try {
    const resp = await fetch(url, {
      method,
      headers: headers,
      credentials: "include",
      keepalive: keepAlive,
      body: bodyParams ? JSON.stringify(bodyParams) : undefined, // we should only stringify here. (in 1 place)
    });
    respStatus = resp.status;
    if (!resp.ok) {
      const responseText: string = await resp.text();
      const message =
        responseText || `A ${resp.status} API error occurred calling ${url}`;

      if (
        isUnauthorized(resp.status) ||
        ignoreErrorCodes?.includes(resp.status)
      ) {
        console.warn(message); // prevent sentry error logs from 400 and 401s
        return;
      } else {
        throw new Error(message, {
          cause: `Code: ${resp.status}, Text: ${resp.statusText}`,
        });
      }
    }
    return resp;
  } catch (err) {
    if (throwErrorCodes?.includes(respStatus)) {
      throw err; // Used to throw 422 up, and skip logging to sentry because this is due to "out of stock". Must be handled within the calling component.
    } else if ((err as Error).name !== "TypeError") {
      logError(
        new Error(`A Fetch API Failure occurred while calling ${method} ${url}`)
      );
    }
    return; // return undefined if a failure has been caught.
  }
}

/**
 * GET from an API. Returns undefined if there's a failure, catching and logging the exception.
 * 400s and 401s will not log as errors to sentry.
 */
async function get({
  url,
  authToken,
  headers,
  keepAlive,
  ignoreErrorCodes = [],
  throwErrorCodes = [],
}: {
  url: string;
  authToken: string;
  keepAlive?: boolean;
  headers?: object;
  ignoreErrorCodes?: number[];
  throwErrorCodes?: number[];
}): Promise<Response | undefined> {
  return ApiRequest({
    url,
    method: APIVerbConstants.httpGet,
    authToken,
    additionalHeaders: headers,
    keepAlive: keepAlive,
    bodyParams: undefined,
    ignoreErrorCodes,
    throwErrorCodes,
  });
}

/**
 * POST to an API. Returns undefined if there's a failure, catching and logging the exception.
 * 400s and 401s will not log as errors to sentry.
 */
async function post<T>({
  url,
  authToken,
  headers,
  bodyParams,
  ignoreErrorCodes = [],
  throwErrorCodes = [],
}: {
  url: string;
  authToken: string;
  headers?: object;
  bodyParams?: T;
  ignoreErrorCodes?: number[];
  throwErrorCodes?: number[];
}): Promise<Response | undefined> {
  return ApiRequest({
    url,
    method: APIVerbConstants.httpPost,
    authToken,
    additionalHeaders: headers,
    bodyParams,
    ignoreErrorCodes,
    throwErrorCodes,
  });
}

/**
 * PUT to an API. Returns undefined if there's a failure, catching and logging the exception.
 * 400s and 401s will not log as errors to sentry.
 */
async function put<T>({
  url,
  authToken,
  headers,
  bodyParams,
  ignoreErrorCodes = [],
  throwErrorCodes = [],
}: {
  url: string;
  authToken: string;
  headers?: object;
  bodyParams?: T;
  ignoreErrorCodes?: number[];
  throwErrorCodes?: number[];
}): Promise<Response | undefined> {
  return ApiRequest<T>({
    url,
    method: APIVerbConstants.httpPut,
    authToken,
    additionalHeaders: headers,
    bodyParams,
    ignoreErrorCodes,
    throwErrorCodes,
  });
}

/**
 * DELETE from an API. Returns undefined if there's a failure, catching and logging the exception.
 * 400s and 401s will not log as errors to sentry.
 */
async function _delete({
  url,
  authToken,
  headers,
  ignoreErrorCodes = [],
  throwErrorCodes = [],
}: {
  url: string;
  authToken: string;
  headers?: object;
  ignoreErrorCodes?: number[];
  throwErrorCodes?: number[];
}): Promise<Response | undefined> {
  return ApiRequest({
    url,
    method: APIVerbConstants.httpDelete,
    authToken,
    additionalHeaders: headers,
    bodyParams: undefined,
    ignoreErrorCodes,
    throwErrorCodes,
  });
}

/* Status Code Helpers */
export function isUnauthorized(code: number): boolean {
  return code === 400 || code === 401;
}

export function unprocessableEntityCode(): number {
  return 422;
}
