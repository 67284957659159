import { useEffect, useState } from "react";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { AutoCompleteSearchResult } from "components/Header/components/Search.types";
import { gatewayApi } from "util/requests/gatewayApi";

export default function useAutoCompleteSearchAPI(
  searchTerm: string,
  delay: number
): {
  results: AutoCompleteSearchResult;
  isLoading: boolean;
  isError: boolean;
} {
  // TODO: Convert to reusable "useDebouncedQuery" hook.
  const [effectiveSearchTerm, setEffectiveSearchTerm] = useState("");

  useEffect(() => {
    const trimmedSearchTerm = searchTerm.trim();
    if (trimmedSearchTerm !== effectiveSearchTerm) {
      const timeoutId = setTimeout(
        () => setEffectiveSearchTerm(trimmedSearchTerm),
        delay
      );

      return () => clearTimeout(timeoutId);
    }
  }, [searchTerm, delay, effectiveSearchTerm]);

  const { data, isLoading, isError } = useQuery<
    AutoCompleteSearchResult,
    Error,
    AutoCompleteSearchResult,
    [string, string]
  >({
    queryKey: ["search/autocomplete", effectiveSearchTerm],
    queryFn: getData,
    staleTime: Infinity,
  });

  const results = data ?? {
    brands: [],
    categories: [],
    items: [],
    keywords: [],
  };

  return {
    results,
    isLoading,
    isError,
  };
}

async function getData({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<AutoCompleteSearchResult> {
  const [, searchTerm] = queryKey;

  if (!searchTerm) {
    return {
      brands: [],
      categories: [],
      items: [],
      keywords: [],
    };
  }

  const data = await gatewayApi.get<AutoCompleteSearchResult>({
    urlSegment: `search/autocomplete?searchTerm=${searchTerm}`,
  });

  if (data === null || data === undefined) {
    throw new Error("Failed to retrieve autocomplete search results.");
  }

  return data;
}
