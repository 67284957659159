import { Reducer } from "react";
import { FormValues } from "../../types";
import { RegisterAction } from "./types";

const register: Reducer<FormValues, RegisterAction> = (state, action) => {
    const newFormItem: FormValues = {};
    Object.keys(action.value).forEach((key) => {
        // For each new item being registered
        // Add the default value and required options

        if (state[key] !== undefined) {
            console.warn(
                "%cZest Error:\n",
                "background-color: red; color: yellow; font-size: xsmall",
                `Form item "${key}" is trying to re-register.`,
                "This typically occurs when a form item is rendered conditionally.",
                "Remove the condition and hide the form item with CSS instead."
            );
        } else {
            newFormItem[key] = {
                value: action.value[key].value ?? "",
                required: action.value[key].required ?? false,
                id: action.value[key].id ?? "",
                errors: [],
                touched: false,
                defaultValue: action.value[key].value ?? "",
            };
        }
    });

    return {
        ...state,
        ...newFormItem,
    };
};

export default register;
