import { SyntheticEvent, useEffect, useState, JSX } from "react";
import { Tooltip } from "@Components/tooltip";
import { Button } from "@Components/button";
import { useStore } from "@Utilities/hooks/useStoreData";
import { concat } from "@Utilities/string";
import { useIsMobile } from "@Utilities/hooks";
import { useVideoPlayerContext } from "./utilities";
import { isMutedSelector, volumeSelector } from "./selectors";

type VolumeBarProps = {
    muted?: boolean;
    spriteSheetHref?: string;
};

const VolumeBar = ({ muted, spriteSheetHref }: VolumeBarProps): JSX.Element => {
    const { setMute, setVolume, store } = useVideoPlayerContext();
    const isMuted = useStore(store, isMutedSelector);
    const volume = useStore(store, volumeSelector);
    const [prevVolume, setPrevVolume] = useState(volume);

    const isMobile = useIsMobile();

    const handleVolumeChange = (
        event: SyntheticEvent<HTMLInputElement>
    ): void => {
        setPrevVolume(Number(event.currentTarget.value));
        setVolume(Number(event.currentTarget.value));
    };

    const handleMute = (): void => {
        isMuted && setVolume(prevVolume);
        setMute(!isMuted);
    };

    useEffect(() => {
        if (isMobile) return;
        const volumeRange = document.getElementById(
            "zest-volume-range"
        ) as HTMLInputElement;

        const min = Number(volumeRange.min);
        const max = Number(volumeRange.max);
        const val = Number(volumeRange.value);
        volumeRange.style.backgroundSize =
            ((val - min) * 100) / (max - min) + "% 100%";
    }, [volume, isMobile]);

    useEffect(() => {
        // If the volume slider is set to 0 then set mute
        if (volume === 0) {
            setMute(true);
        } else {
            setMute(false);
        }
    }, [setMute, volume]);

    useEffect(() => {
        if (typeof muted === "boolean") {
            setVolume(!muted ? prevVolume : 0);
            setMute(muted);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [muted, setMute, setVolume]);

    return (
        <div
            className={concat(
                "zest-volume-bar-wrapper",
                isMobile && "zest-volume-bar-mobile-wrapper"
            )}
        >
            <Tooltip
                content={isMuted ? "Unmute" : "Mute"}
                contentId="zest-video-player-mute-button"
            >
                <Button
                    size="small"
                    className={concat("zest-mute-button")}
                    iconProps={{
                        name: isMuted
                            ? "ri-volume-mute-fill"
                            : "ri-volume-up-fill",
                        spriteSheetHref: spriteSheetHref,
                    }}
                    onClick={handleMute}
                    aria-label={isMuted ? "unmute" : "mute"}
                />
            </Tooltip>
            {!isMobile && (
                <input
                    aria-label="volume"
                    id="zest-volume-range"
                    className="zest-status-bar volume"
                    type="range"
                    value={volume}
                    min={0}
                    onChange={handleVolumeChange}
                    max={100}
                />
            )}
        </div>
    );
};

export default VolumeBar;
