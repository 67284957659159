import { concat } from "@Utilities/string";
import { useState } from "react";

let sequentialId = 0;

const useUuid = (prefix?: string): string => {
    const [id] = useState(() => {
        sequentialId++;
        return `${concat(prefix && prefix + "-")}${sequentialId}`;
    });

    return id;
};

export { useUuid };
