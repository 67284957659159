export default function MenuSectionHeader({
  sectionName,
  countSetBefore,
  countSet,
}: {
  sectionName: string;
  countSetBefore: number;
  countSet: number;
}): Array<React.ReactElement> {
  if (countSet === 0) {
    return [];
  }
  const divider: Array<React.ReactElement> = [];

  const linkProps = {
    disablelink: "true",
  };

  if (countSetBefore > 0) {
    divider.push(
      <hr key={`hr-${sectionName.replace(" ", "-")}`} {...linkProps} />
    );
  }

  divider.push(
    <div
      key={sectionName.replace(" ", "-")}
      className="flex flex-row gap-3 text-gray-500"
      {...linkProps}
    >
      <p className="text-gray-500 font-bold">{sectionName.toUpperCase()}</p>
    </div>
  );

  return divider;
}
