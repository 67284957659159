import { VideoPlayerStore } from "./utilities";
import { ThumbnailUrls } from "./videoPlayer";

export const isPlayingSelector = (
    state: VideoPlayerStore,
    prev: boolean | null
): boolean => {
    if (prev !== state.isPlaying) {
        return state.isPlaying;
    }
    return prev;
};

export const showOverlaySelector = (
    state: VideoPlayerStore,
    prev: boolean | null
): boolean => {
    if (prev !== state.showOverlay) {
        return state.showOverlay;
    }
    return prev;
};

export const currentTimeSelector = (
    state: VideoPlayerStore,
    prev: number | null
): number => {
    if (prev !== state.currentTime) {
        return Math.round(state.currentTime);
    }
    return prev;
};

export const isAtBeginningSelector = (state: VideoPlayerStore): boolean => {
    return !state.currentTime;
};

export const isMutedSelector = (
    state: VideoPlayerStore,
    prev: boolean | null
): boolean => {
    if (prev !== state.isMuted) {
        return state.isMuted;
    }
    return prev;
};

export const volumeSelector = (
    state: VideoPlayerStore,
    prev: number | null
): number => {
    if (prev !== state.volume) {
        return state.volume;
    }
    return prev;
};

export const durationSelector = (
    state: VideoPlayerStore,
    prev: number | null
): number => {
    if (prev !== state.duration) {
        return state.duration;
    }
    return prev;
};

export const showControlsSelector = (
    state: VideoPlayerStore,
    prev: boolean | null
): boolean => {
    if (prev !== state.showControls) {
        return state.showControls;
    }
    return prev;
};

export const thumbnailUrlsSelector = (
    state: VideoPlayerStore,
    prev: ThumbnailUrls | null
): ThumbnailUrls => {
    if (prev !== state.thumbnailUrls) {
        return state.thumbnailUrls;
    }
    return prev;
};

export const thumbnailsLoadedSelector = (
    state: VideoPlayerStore,
    prev: boolean | null
): boolean => {
    if (prev !== state.thumbnailsLoaded) {
        return state.thumbnailsLoaded;
    }
    return prev;
};

export const isLoadingSelector = (
    state: VideoPlayerStore,
    prev: boolean | null
): boolean => {
    if (prev !== state.isLoading) {
        return state.isLoading;
    }
    return prev;
};

export const srcSelector = (
    state: VideoPlayerStore,
    prev: string | null
): string => {
    if (prev !== state.src) {
        return state.src;
    }
    return prev;
};
